import { useEffect, useState, useRef } from "react";
import {
  optionModel,
  questionModel,
  questionnaireModel,
  userAnsweredModel,
} from "../interface/questionnaireInterface";

export default function Questionarie(props: any) {
  const {
    questionsData,
    controlAreaSubMenuClick,
    controlAreaSelectedId,
    controlAreaSubMenuSelectedId,
    mainQuestions,
    branchQuestions,
    setMainQuestions,
    setBranchQuestions,
    notAssessedSubMenus,
    isLoading,
    saveQuestionnaire,
  } = props;

  let [questionarieData, setQuestionarieData] = useState<questionnaireModel[]>(
    []
  );

  let [mainQuestionsData, setMainQuestionsData] =
    useState<questionModel[]>(mainQuestions);
  let [branchQuestionsData, setBranchQuestionsData] =
    useState<questionModel[]>(branchQuestions);
  let [removedQuestions, setRemovedQuestions] = useState<questionModel[]>([]);

  let [notAssessedControlAreaSubMenus, setNotAssessedControlAreaSubMenus] =
    useState<string[]>(notAssessedSubMenus);

  const ref: any = useRef();

  const nextRefs: any = useRef([]);
  const questionnaireRef: any = useRef([]);

  const [ds, setds] = useState(false);

  useEffect(() => {
    setQuestionarieData(questionsData);
    setMainQuestionsData(mainQuestions);
    setBranchQuestionsData(branchQuestions);
  }, [mainQuestions, branchQuestions, questionsData]);

  useEffect(() => {
    setNotAssessedControlAreaSubMenus(notAssessedSubMenus);
  }, [notAssessedSubMenus]);

  useEffect(() => {
    if (nextRefs?.current?.length) {
      let offset = Number(ref?.current?.offsetWidth);
      let newOffset = 0;
      for (let i = 0; i < nextRefs?.current?.length; i++) {
        if (nextRefs?.current[i] !== null)
          newOffset += nextRefs?.current[i]?.offsetWidth;
      }
      if (offset - newOffset < -5) setds(true);
      else setds(false);
    }
  }, [mainQuestions, branchQuestions, questionsData]);

  /**
   * PC_QF_1.39, Checks whether the user answered options were duplicated or not
   * @param array
   * @returns
   */
  const hasDuplicateObjects = (array: userAnsweredModel[]) => {
    for (let i = 0; i < array.length; i++) {
      for (let j = i + 1; j < array.length; j++) {
        if (array[i].optionId === array[j].optionId) {
          return true;
        }
      }
    }
    return false;
  };

  const handleClear = () => {
    mainQuestionsData.forEach((question) => {
      question.userAnswered = [];
    });
    branchQuestionsData.forEach((question) => {
      question.userAnswered = [];
    });
    setMainQuestionsData([...mainQuestionsData]);
    setBranchQuestionsData([...branchQuestionsData]);
  };

  /**
   * PC_QF_1.46 - PC_QF_1.69, Handle the onchange event of radio select or checkbox select
   * @param e
   * @param optionsObj
   */
  const questionnaireOnChange = (e: any, optionsObj: optionModel) => {
    const cloneMainQuestions = JSON.parse(
      JSON.stringify(removeMainQuestionDuplicates(mainQuestionsData))
    );

    const isNoneOfThese = optionsObj.option === "None of these"
    // PC_QF_1.49
    const removeBranchQuestionByIndex = (arr: number[]) => {
      for (let i = arr.length - 1; i >= 0; i--) {
        removedQuestions.push(cloneMainQuestions[arr[i]]);
        cloneMainQuestions.splice(arr[i], 1);
      }
    };

    // PC_QF_1.51
    const filterUserAnsweredOptions = (arr: questionModel) => {
      return (arr.userAnswered = arr.userAnswered.filter(
        (
          value: userAnsweredModel,
          index: number,
          self: userAnsweredModel[]
        ) => {
          return (
            index ===
            self.findIndex(
              (t: userAnsweredModel) =>
                t.optionId === value.optionId && t.optionId === value.optionId
            )
          );
        }
      ));
    };

    // PC_QF_1.54
    const uncheckCheckBox = (arr: questionModel[]) => {
      arr.forEach((obj: questionModel) => {
        if (obj.questionId == parseInt(e.target.name)) {
          obj.userAnswered.forEach((opt: userAnsweredModel, index: number) => {
            if (opt?.optionId == parseInt(e.target.value)) {
              obj.userAnswered.splice(index, 1);
            }
          });
        }
      });
    };

    if (e.target.checked) {
      let removeIndex: number[] = [];

      cloneMainQuestions.forEach((obj: questionModel, index: number) => {
        if (obj.branchingQuestion == parseInt(e.target.name))
          removeIndex.push(index);
      });

      if (e.target.type != "checkbox") removeBranchQuestionByIndex(removeIndex);

      if (branchQuestionsData.length === 0)
        removeMainQuestionDuplicates(cloneMainQuestions).forEach(
          (main: questionModel, index: number) => {
            let optAns = {
              optionId: parseInt(e.target.value),
              optionScore: optionsObj.optionScore,
            };
            if (
              main.questionId == parseInt(e.target.name) &&
              !main.userAnswered.includes(optAns) &&
              e.target.type === "radio"
            ) {
              main.userAnswered[0] = optAns;
            } else if (
              main.questionId == parseInt(e.target.name) &&
              e.target.type === "checkbox" &&
              !hasDuplicateObjects(main.userAnswered)
            ) {
              if (isNoneOfThese) {
                // Clear previously selected options for this question
                main.userAnswered = [];
              }
              main.userAnswered.push(optAns);
              main.userAnswered = filterUserAnsweredOptions(main);
            }
          }
        );

      branchQuestionsData.forEach((obj: questionModel) => {
        if (
          obj.branchingOption == e.target.value &&
          !cloneMainQuestions.includes(obj)
        ) {
          let findIndex = cloneMainQuestions.findIndex(
            (ind: questionModel) => ind.questionId == parseInt(e.target.name)
          );
          cloneMainQuestions.splice(findIndex + 1, 0, obj);
        }

        cloneMainQuestions.forEach((main: questionModel, index: number) => {
          let optAns = {
            optionId: parseInt(e.target.value),
            optionScore: optionsObj.optionScore,
          };

          if (
            main.questionId == parseInt(e.target.name) &&
            !main.userAnswered.includes(optAns) &&
            e.target.type === "radio" &&
            branchQuestionsData.length
          ) {
            main.userAnswered[0] = optAns;
          } else if (
            main.questionId == parseInt(e.target.name) &&
            e.target.type === "checkbox" &&
            !hasDuplicateObjects(main.userAnswered) &&
            branchQuestionsData.length
          ) {
            if (isNoneOfThese) {
              // Clear previously selected options for this question
              main.userAnswered = [];
            }
            main.userAnswered.push(optAns);
            main.userAnswered = filterUserAnsweredOptions(main);
          }

          if (
            main.userAnswered.some(
              (ind: userAnsweredModel) => ind.optionId == obj.branchingOption
            ) &&
            !removeMainQuestionDuplicates(cloneMainQuestions).includes(obj)
          ) {
            removeMainQuestionDuplicates(cloneMainQuestions).splice(
              index + 1,
              0,
              obj
            );
          }
        });
      });

      if (removedQuestions.length === 0)
        setMainQuestionsData(removeMainQuestionDuplicates(cloneMainQuestions));

      removeBranchedQuestions(removeMainQuestionDuplicates(cloneMainQuestions));
    } else {
      if (e.target.type === "checkbox") {
        cloneMainQuestions.forEach((obj: questionModel) => {
          obj.userAnswered = filterUserAnsweredOptions(obj);
        });

        uncheckCheckBox(cloneMainQuestions);

        uncheckCheckBox(branchQuestionsData);

        let removeIndex: number[] = [];
        cloneMainQuestions.forEach((obj: questionModel, index: number) => {
          if (
            obj.branchingQuestion == parseInt(e.target.name) &&
            obj.branchingOption == parseInt(e.target.value)
          )
            removeIndex.push(index);
        });

        removeBranchQuestionByIndex(removeIndex);
        removeBranchedQuestions(
          removeMainQuestionDuplicates(cloneMainQuestions)
        );
      }
    }
  };

  /**
   * PC_QF_1.31 - PC_QF_1.38, Removes the branched question if the option doesn't have any branchings
   * @param mainArray
   */
  const removeBranchedQuestions = (mainArray: questionModel[]) => {
    let removeBranchIndex: number[] = [];
    mainArray.forEach((main: questionModel, index: number) => {
      removedQuestions.forEach((obj: questionModel) => {
        if (main.branchingQuestion == obj.questionId) {
          removedQuestions.push(main);
          removeBranchIndex.push(index);
        }
      });
    });
    for (let i = removeBranchIndex.length - 1; i >= 0; i--) {
      mainArray.splice(removeBranchIndex[i], 1);
    }

    branchQuestionsData.forEach((obj: questionModel) => {
      removedQuestions.forEach((main: questionModel, index: number) => {
        if (main.questionId == obj.questionId) {
          obj.userAnswered = [];
        }
      });
    });
    setBranchQuestionsData(removeMainQuestionDuplicates(branchQuestionsData));
    setBranchQuestions(removeMainQuestionDuplicates(branchQuestionsData));
    setMainQuestionsData(removeMainQuestionDuplicates(mainArray));
    setMainQuestions(removeMainQuestionDuplicates(mainArray));
    setRemovedQuestions([]);
  };

  /**
   * PC_LN_1.53 - Remove the duplicate objects from the main Questions array
   * @param arr
   * @returns
   */
  const removeMainQuestionDuplicates = (arr: questionModel[]) => {
    arr = arr.filter(
      (value: questionModel, index: number, self: questionModel[]) =>
        index ===
        self.findIndex((t: questionModel) => t.questionId === value.questionId)
    );
    return arr;
  };

  /**
   * PC_QF_1.42
   * @returns questions and options html
   */
  const bindQuestionnaire = () => {
    try {
      if (
        questionarieData?.some(
          (obj) => obj.controlAreaId === controlAreaSelectedId
        ) === false
      ) {
        return (
          <>
            <div className="font-14 font-medium color-grey-v3 mt-3 d-flex justify-content-center">
              <p className="mb-0">
                Sorry, Selected cloud doesn't have any sub menu in this control
                area.
              </p>
            </div>
          </>
        );
      }
      if (questionarieData === null) {
        return (
          <>
            <div className="font-14 font-medium color-grey-v3 mt-3 d-flex justify-content-center">
              <p className="mb-0">
                Please select a Cloud name to start the assessment.
              </p>
            </div>
          </>
        );
      }
      return questionarieData?.map(
        ({ controlAreaId, controlAreaValue }: any, mainIndex: number) => {
          if (controlAreaSelectedId.trim() == controlAreaId.trim()) {
            return (
              <div
                key={mainIndex}
                className={`tab-pane fade active show`}
                role="tabpanel"
              >
                <div
                  className="row sticky-top bg-white"
                  style={{ zIndex: "1" }}
                >
                  <div className="d-flex nav-tabs">
                    {ds && (
                      <button
                        style={{
                          position: "absolute",
                          zIndex: "1",
                          left: "2px",
                          top: "6px",
                        }}
                        type="button"
                        className="btn border-0 p-0 shadow-none"
                        onClick={() => {
                          ref.current.scrollLeft -= 200;
                        }}
                      >
                        <img
                          src="images/date-left-arrow.svg"
                          alt="date-left-arrow"
                        />
                      </button>
                    )}
                    <div
                      ref={ref}
                      className="nav border-tabs flex-nowrap table-responsive nav-scroll"
                      role="tablist"
                    >
                      {controlAreaValue?.map((tabs: any, tabsIndex: number) => {
                        return (
                          <button
                            ref={(el: any) => {
                              nextRefs.current[tabsIndex] = el;
                            }}
                            key={tabsIndex}
                            className={`nav-link text-nowrap ${tabs.controlAreaSubMenuId ==
                              controlAreaSubMenuSelectedId
                              ? "active"
                              : ""
                              }`}
                            type="button"
                            role="tab"
                            onClick={(e) => {
                              controlAreaSubMenuClick(
                                tabs.controlAreaSubMenuId,
                                tabs.controlAreaSubMenuName
                              );
                              questionnaireRef.current?.scrollIntoView({
                                behavior: "smooth",
                                inline: "start",
                              });
                            }}
                          >
                            {tabs.controlAreaSubMenuName}
                            <span
                              hidden={
                                !notAssessedControlAreaSubMenus.includes(
                                  tabs.controlAreaSubMenuId
                                )
                              }
                              className="action-needed ms-2"
                            ></span>
                          </button>
                        );
                      })}
                    </div>
                    {ds && (
                      <button
                        style={{
                          position: "absolute",
                          right: "0px",
                          top: "6px",
                        }}
                        type="button"
                        className="btn border-0 p-0 shadow-none"
                        onClick={() => {
                          ref.current.scrollLeft += 200;
                        }}
                      >
                        <img
                          src="images/date-right-arrow.svg"
                          alt="date-left-arrow"
                        />
                      </button>
                    )}
                  </div>
                </div>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade pt-4 active show mb-4"
                    role="tabpanel"
                    ref={questionnaireRef}
                  >
                    {controlAreaValue?.map(({ controlAreaSubMenuId }: any) => {
                      if (
                        controlAreaSubMenuId.trim() ==
                        controlAreaSubMenuSelectedId.trim()
                      ) {
                        if (mainQuestionsData.length === 0) {
                          return (
                            <div className="font-14 font-medium color-grey-v3 mt-3 d-flex justify-content-center">
                              <p className="mb-0">
                                Sorry, no questions have been configured in the
                                admin. Please upload questions to assess.
                              </p>
                            </div>
                          );
                        } else
                          return mainQuestionsData?.map(
                            (
                              {
                                question,
                                questionId,
                                options,
                                userAnswered,
                                type,
                              }: any,
                              mainIndex: number
                            ) => {
                              const noneOfTheseOption = options.find((opt: optionModel) => opt.option === "None of these");
                              // Check if "None of these" has been answered
                              let noneOfTheseAnswered = noneOfTheseOption ? userAnswered.some((ans: userAnsweredModel) => ans.optionId === noneOfTheseOption.optionId) : false;
                              return (
                                <div
                                  className="mb-3"
                                  key={mainIndex}
                                // style={
                                //   mainIndex === 0 ? { paddingTop: "25px" } : {}
                                // }
                                >
                                  <p className="font-16 font-regular">
                                    {mainIndex + 1}. {question}
                                    <span hidden={!(notAssessedControlAreaSubMenus.length != 0 && userAnswered.length === 0)} className="action-needed ms-2" />
                                  </p>
                                  {options.map(
                                    (opt: optionModel, optIndex: number) => {
                                      const isNoneOfThese = opt.option === "None of these";
                                      return type === "Checkbox" ? (
                                        <div className="row" key={optIndex}>
                                          <div className="col-md-6">
                                            <div className="check-card">
                                              <span className="form-check align-items-center ms-3">
                                                <input
                                                  className="theme-check form-check-input label-bold"
                                                  type="checkbox"
                                                  id={opt.optionId?.toString()}
                                                  name={questionId}
                                                  value={opt.optionId}
                                                  onChange={(e) => {
                                                    questionnaireOnChange(
                                                      e,
                                                      opt
                                                    )
                                                    if (isNoneOfThese) {
                                                      noneOfTheseAnswered = e.target.checked;
                                                    }
                                                  }}
                                                  checked={userAnswered.some(
                                                    (ans: userAnsweredModel) =>
                                                      ans.optionId ==
                                                      opt.optionId
                                                  )}
                                                  disabled={noneOfTheseAnswered && !isNoneOfThese}
                                                />
                                                <label
                                                  className="form-check-label mb-2 ms-2 font-14 font-regular"
                                                  htmlFor={opt.optionId?.toString()}
                                                >
                                                  {opt.option}
                                                </label>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className="form-check ms-3"
                                          key={optIndex}
                                        >
                                          <input
                                            className="form-check-input theme-radio label-bold"
                                            type="radio"
                                            id={opt.optionId?.toString()}
                                            name={questionId}
                                            value={opt.optionId}
                                            onChange={(e) => {
                                              questionnaireOnChange(e, opt);
                                              if (isNoneOfThese) {
                                                noneOfTheseAnswered = e.target.checked;
                                              }
                                            }}
                                            checked={userAnswered.some(
                                              (ans: userAnsweredModel) =>
                                                ans.optionId == opt.optionId
                                            )}
                                            disabled={noneOfTheseAnswered && !isNoneOfThese}
                                          />
                                          <label
                                            className="form-check-label mb-2 ms-2 font-14 font-regular"
                                            htmlFor={opt.optionId?.toString()}
                                          >
                                            {opt.option}
                                          </label>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            }
                          );
                      }
                    })}
                  </div>
                  <div className="fixed-bottom py-2 mx-3 Admin-footer bg-white">
                    <div className="d-grid d-md-flex justify-content-md-end mx-3 ">
                      <div className="button-container">
                        <a
                          className="theme-link-btn font-16 font-semibold text-center order-md-first cursor-pointer"
                          onClick={handleClear}
                        >Clear</a>
                        <button
                          type="button"
                          className="btn theme-primary-btn font-14 font-medium my-2"
                          onClick={() => saveQuestionnaire()}
                          disabled={
                            !mainQuestionsData.some((question) => question.userAnswered.length > 0) &&
                            !branchQuestionsData.some((question) => question.userAnswered.length > 0)
                          } >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        }
      );
    } catch (e) {
      // console.log(e);
      return (
        <>
          <div className="font-14 font-medium color-grey-v3 mt-3 d-flex justify-content-center">
            <p className="mb-0">
              Sorry, This control area menu may be removed or has some issues.
            </p>
          </div>
        </>
      );
    }
  };

  /**********
   * Return Main HTML body
   **********/
  return <>{bindQuestionnaire()}</>;
}
