import React, { useEffect, useState } from "react";
import {
    XAxis,
    YAxis,
    LineChart,
    Tooltip,
    Line,
    ResponsiveContainer,
    LabelList
} from "recharts";


const MapLineChart: React.FC<any> = (props: any) => {
    const data = props?.lineChartData?.map((element: any) => {
        return ({
            name: element.submenuName,
            value: element.submenuScore,
        })
    })


    const customYAxisFormatter = (value: string, index: number) => {
        if (index >= 1) {
            return `${value}`;
        } else {
            return "";
        }
    };


    return (
        <ResponsiveContainer id={"chart_container"} width="100%" height={400}>
            <LineChart width={600} height={300} data={[{},...data,{}] }>
                <XAxis dataKey="name" tickLine={false} interval={"preserveStartEnd"} axisLine={{ stroke: '#bbb', strokeWidth: 0.5 }} style={{ fontSize: 14, fontWeight: 'bold', fill: '#777', fontFamily: "SFProText-Medium" }} />
                <YAxis tickLine={false} dataKey="value" tickFormatter={customYAxisFormatter} axisLine={{ stroke: '#bbb', strokeWidth: 0.5 }} style={{ fontSize: 13, fontWeight: 'bold', fill: '#777', fontFamily: "SFProText-Medium" }} />
                <Tooltip />
                <Line connectNulls type="linear" dataKey="value" stroke="red" >
                    <LabelList dataKey="value" position="right" /></Line>
            </LineChart>
        </ResponsiveContainer>

    );
};

export default MapLineChart;
