import { useEffect, useState } from "react";
import { DeleteCostEstimation, GetCostEstimation, PostCostEstimation } from "../../service/QuestionnaireApi";

const CostEstimation = ({ type, state, setIsLoading }: any = {}) => {

    const [showToasterLoading, setShowToasterLoading] = useState(false);
    const [showToasterPopup, setShowToasterPopup] = useState(false);
    const [showToasterFailedPopup, setShowToasterFailedPopup] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [hideToast, setHideToast] = useState(true);
    const [toastProp, setToastProp] = useState({
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: '',
    });

    const closeModal = () => {
        setShowDeleteModal(false);
        setItemToDelete(null);
    };

    const [costData, setCostData] = useState<any>(null);
    const [approaches, setApproaches] = useState<any[]>([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<{ costEstimationId?: string, approachId?: string } | null>(null);
    const [editingId, setEditingId] = useState<string | null>(null);
    const [editedValues, setEditedValues] = useState<any>({});
    const [validationErrors, setValidationErrors] = useState({
        softwareName: '',
        monthlyCost: '',
        yearlyCost: '',
    });
    const [awsValidationErrors, setAwsValidationErrors] = useState({ ...validationErrors });
    const [inventoryValidationErrors, setInventoryValidationErrors] = useState({ ...validationErrors });

    const [inputValues, setInputValues] = useState({
        softwareName: "",
        monthlyCost: "",
        yearlyCost: "",
        comments: ""
    });

    const [approachValidationErrors, setApproachValidationErrors] = useState({
        approachName: '',
        awsPricingUrl: ''
    });
    const [approachInputs, setApproachInputs] = useState({
        approachName: "",
        awsPricingUrl: ""
    });
    const [awsInputValues, setAwsInputValues] = useState({
        softwareName: "",
        monthlyCost: "",
        yearlyCost: "",
        comments: ""
    });

    const [inventoryInputValues, setInventoryInputValues] = useState({
        softwareName: "",
        monthlyCost: "",
        yearlyCost: "",
        comments: ""
    });
    const [editValidationErrors, setEditValidationErrors] = useState({
        softwareName: '',
        monthlyCost: '',
        yearlyCost: '',
    });

    useEffect(() => {
        getInitialPageData();
    }, []);

    const handleEditClick = (id: string) => {
        setEditingId(id);
        setAwsValidationErrors({ softwareName: '', monthlyCost: '', yearlyCost: '' });
        setInventoryValidationErrors({ softwareName: '', monthlyCost: '', yearlyCost: '' });
        setApproaches(prevApproaches =>
            prevApproaches.map(approach => ({
                ...approach,
                validationErrors: { softwareName: '', monthlyCost: '', yearlyCost: '' }
            }))
        );
        setEditValidationErrors({ softwareName: '', monthlyCost: '', yearlyCost: '' });

        let itemToEdit;

        if (type === 'current') {
            if (costData?.currentCost?.AWS?.current) {
                itemToEdit = costData.currentCost.AWS.current.find((item: any) => item.costEstimationId === id);
            }
            if (!itemToEdit && costData?.currentCost?.INVENTORY) {
                itemToEdit = costData.currentCost.INVENTORY.find((item: any) => item.costEstimationId === id);
            }
        } else {

            for (const approach of approaches) {
                itemToEdit = approach.data.find((item: any) => item.costEstimationId === id);
                if (itemToEdit) break;
            }
        }

        if (itemToEdit) {
            setEditedValues({
                softwareName: itemToEdit.softwareName || '',
                monthlyCost: itemToEdit.monthlyCost.replace('$', ''),
                yearlyCost: itemToEdit.yearlyCost.replace('$', ''),
                comments: itemToEdit.comments || ''
            });
        }
    };

    const handleSaveClick = async (item: any) => {
        const errors = {
            softwareName: editedValues.softwareName ? '' : 'Required',
            monthlyCost: editedValues.monthlyCost ? '' : 'Required',
            yearlyCost: editedValues.yearlyCost ? '' : 'Required'
        };

        setEditValidationErrors(errors);

        if (Object.values(errors).some(error => error !== '')) {
            return;
        }


        const payload = {
            "organizationId": state.organizationId,
            "verticalId": state.verticalId,
            "projectId": state.projectId,
            costEstimationId: item.costEstimationId,
            ...editedValues,
            comments: editedValues.comments.trim() || "-"
        };
        setShowToasterLoading(true);
        try {
            const response = await PostCostEstimation(payload);
            if (response.status === 200) {
                setShowToasterPopup(true);
                setIsLoading(true)
                await getInitialPageData();
            } else {
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }
        } catch (error) {
            // console.error("Error submitting cost data", error);
            setShowToasterFailedPopup(true);
            setIsLoading(false)
        }
        finally {
            setIsLoading(false)
            setShowToasterLoading(false);
            setTimeout(() => {
                setShowToasterPopup(false);
                setShowToasterFailedPopup(false);
            }, 4000);
            setEditingId(null);
        }

    };

    const handleCancelClick = () => {
        setEditingId(null);
        setAwsValidationErrors({ softwareName: '', monthlyCost: '', yearlyCost: '' });
        setInventoryValidationErrors({ softwareName: '', monthlyCost: '', yearlyCost: '' });
        setApproaches(prevApproaches =>
            prevApproaches.map(approach => ({
                ...approach,
                validationErrors: { softwareName: '', monthlyCost: '', yearlyCost: '' }
            }))
        );
        setEditValidationErrors({ softwareName: '', monthlyCost: '', yearlyCost: '' });
    };

    const sortKeysDesc = (obj: any) => {
        const sortedKeys = Object.keys(obj).sort((a, b) => b.localeCompare(a));
        const sortedObject: { [key: string]: any } = {};
        sortedKeys.forEach((key: string) => {
            sortedObject[key] = obj[key];
        });
        return sortedObject;
    };

    const getInitialPageData = async () => {

        try {
            const response = await GetCostEstimation(state);

            if (response.status === 200) {
                setCostData(response.data);
                setIsLoading(false)
                if (type === 'future') {
                    const awsCostData = response.data.futureCost.AWS;
                    const newApproaches = Object.entries(awsCostData).map(([approachName, approachData]) => {
                        if (Array.isArray(approachData) && approachData.length > 0) {
                            const firstItem = approachData[0];
                            return {
                                name: approachName,
                                approachId: firstItem.approachId,
                                data: approachData,
                                inputValues: {
                                    softwareName: "",
                                    monthlyCost: "",
                                    yearlyCost: "",
                                    comments: ""
                                },
                                validationErrors: { ...validationErrors }
                            };
                        } else {
                            // console.warn(`Invalid or empty data for approach: ${approachName}`);
                            return {
                                name: approachName,
                                approachId: '',
                                data: [],
                                inputValues: {
                                    softwareName: "",
                                    monthlyCost: "",
                                    yearlyCost: "",
                                    comments: ""
                                },
                                validationErrors: { ...validationErrors }
                            };
                        }
                    });
                    setApproaches(newApproaches);
                }
            } else {
                // console.error("Error: ", response.statusText);
            }
        } catch (error) {

            // console.error("Error fetching cost estimation data", error);
        }
    };

    const handleFutureInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, approachId: string) => {
        const { name, value } = e.target;
        if (name === 'monthlyCost' || name === 'yearlyCost') {
            // Only allow numbers and one decimal point
            if (!/^\d*\.?\d*$/.test(value)) {
                return;
            }
        }

        setApproaches(prevApproaches =>
            prevApproaches.map(approach =>
                approach.approachId === approachId
                    ? {
                        ...approach,
                        inputValues: { ...approach.inputValues, [name]: value },
                        validationErrors: { ...approach.validationErrors, [name]: '' }
                    }
                    : approach
            )
        );
    };
    const isValidAwsUrl = (url: string) => {
        const awsUrlPattern = /^https:\/\/calculator\.aws/;
        return awsUrlPattern.test(url);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, inputType: 'aws' | 'inventory' | 'future', approachId?: string) => {
        const { name, value } = e.target;
        setAwsValidationErrors(prev => ({ ...prev, [name]: '' }));
        setInventoryValidationErrors(prev => ({ ...prev, [name]: '' }))
            ;
        if (name === 'monthlyCost' || name === 'yearlyCost') {
            if (!/^\d*\.?\d*$/.test(value)) {
                return;
            }
        }
        if (name === "awsPricingUrl" && inputType === 'future') {
            const isValidUrl = isValidAwsUrl(value);
            setApproachValidationErrors(prev => ({
                ...prev,
                [name]: isValidUrl ? '' : 'Please enter a valid AWS calculator URL'
            }));
        }

        if (name === "approachName" || name === "awsPricingUrl") {
            setApproachInputs(prevValues => ({
                ...prevValues,
                [name]: value
            }));
            setApproachValidationErrors(prev => ({ ...prev, [name]: '' }));
        } else {

            if (inputType === 'aws') {
                setAwsInputValues(prev => ({ ...prev, [name]: value }));

            } else if (inputType === 'inventory') {
                setInventoryInputValues(prev => ({ ...prev, [name]: value }));
                setInventoryValidationErrors(prev => ({ ...prev, [name]: '' }));
            } else if (inputType === 'future') {

                if (approachId) {
                    handleFutureInputChange(e, approachId);
                }
            }
        }

    };
    const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;


        if (name === 'monthlyCost' || name === 'yearlyCost') {
            const currentValue = editedValues[name];
            const hasDecimal = currentValue.includes('.');
            const regex = hasDecimal ? /^\d*\.?\d*$/ : /^\d*\.?\d*$/;

            if (regex.test(value)) {
                setEditedValues((prev: any) => ({
                    ...prev,
                    [name]: value
                }));
            }
        } else {
            setEditedValues((prev: any) => ({
                ...prev,
                [name]: value
            }));
        }

        setEditValidationErrors(prev => ({
            ...prev,
            [name]: ''
        }));

    };

    const handleAddClick = async (inputType: 'aws' | 'inventory' | 'future', approachId?: string) => {
        let inputValues: any;
        let errorSetter: ((newErrors: any) => void) | undefined;
        let connectorId = '';

        switch (inputType) {
            case 'aws':
                inputValues = awsInputValues;
                errorSetter = setAwsValidationErrors;
                connectorId = state.awsconnectorId;
                break;
            case 'inventory':
                inputValues = inventoryInputValues;
                errorSetter = setInventoryValidationErrors;
                connectorId = state.inventoryconnectorId;
                break;
            case 'future':
                if (approachId) {
                    const approach = approaches.find(a => a.approachId === approachId);
                    inputValues = approach ? approach.inputValues : {};
                    errorSetter = (newErrors: any) => {
                        setApproaches(prevApproaches =>
                            prevApproaches.map(a =>
                                a.approachId === approachId ? { ...a, validationErrors: newErrors } : a
                            )
                        );
                    };
                    connectorId = state.connectorId;
                } else {
                    // console.error("Approach ID is required for future cost estimation");
                    return;
                }
                break;
            default:
                // console.error("Invalid input type");
                return;
        }

        if (!errorSetter) {
            // console.error("Error setter is not defined");
            return;
        }

        let errors: {
            softwareName: string;
            monthlyCost: string;
            yearlyCost: string;
        } = {
            softwareName: inputValues.softwareName ? '' : 'Required',
            monthlyCost: inputValues.monthlyCost ? '' : 'Required',
            yearlyCost: inputValues.yearlyCost ? '' : 'Required'
        };

        errorSetter(errors);

        if (Object.values(errors).some(error => error !== '')) {
            return;
        }

        let formattedInputValues = {
            ...inputValues,
            monthlyCost: inputValues.monthlyCost ? `$${parseFloat(inputValues.monthlyCost).toFixed(2)}` : '',
            yearlyCost: inputValues.yearlyCost ? `$${parseFloat(inputValues.yearlyCost).toFixed(2)}` : ''
        };

        const payload = {
            "organizationId": state.organizationId,
            "verticalId": state.verticalId,
            "projectId": state.projectId,
            "connectorId": connectorId,
            approachId: approachId,
            ...formattedInputValues
        };
        setShowToasterLoading(true);

        try {
            const response = await PostCostEstimation(payload);
            if (response.status === 200) {
                setShowToasterPopup(true);
                setIsLoading(true)
                switch (inputType) {
                    case 'aws':
                        setAwsInputValues({ softwareName: "", monthlyCost: "", yearlyCost: "", comments: "" });
                        break;
                    case 'inventory':
                        setInventoryInputValues({ softwareName: "", monthlyCost: "", yearlyCost: "", comments: "" });
                        break;
                    case 'future':
                        if (approachId) {
                            setApproaches(prevApproaches =>
                                prevApproaches.map(approach =>
                                    approach.approachId === approachId
                                        ? { ...approach, inputValues: { softwareName: "", monthlyCost: "", yearlyCost: "", comments: "" } }
                                        : approach
                                )
                            );
                        } else {
                            setApproachInputs({ approachName: "", awsPricingUrl: "" });
                        }
                        break;
                }
                setValidationErrors({ softwareName: '', monthlyCost: '', yearlyCost: '' });
                await getInitialPageData();
            } else {
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }
        } catch (error) {
            // console.error("Error submitting form data", error);
            setShowToasterFailedPopup(true);
            setIsLoading(false)
        }
        finally {
            setIsLoading(false)
            setShowToasterLoading(false);
            setTimeout(() => {
                setShowToasterPopup(false);
                setShowToasterFailedPopup(false);
            }, 4000);
        }

    };
    const handleAddSubmit = async () => {

        const errors = {
            approachName: approachInputs.approachName ? '' : 'Please enter approach name ',
            awsPricingUrl: approachInputs.awsPricingUrl
                ? (isValidAwsUrl(approachInputs.awsPricingUrl) ? '' : 'Please enter a valid URL')
                : 'Please enter URL'
        };

        setApproachValidationErrors(errors);

        if (Object.values(errors).some(error => error !== '')) {
            return;
        }
        const payload = {
            "organizationId": state.organizationId,
            "verticalId": state.verticalId,
            "projectId": state.projectId,
            "connectorId": state.connectorId,
            "approachId": "",
            "approachName": approachInputs.approachName,
            "awsPricingUrl": approachInputs.awsPricingUrl,
            "costEstimationId": "",
            "softwareName": "",
            "monthlyCost": "",
            "yearlyCost": "",
            "comments": ""

        };
        setShowToasterLoading(true);
        try {

            const response = await PostCostEstimation(payload);
            if (response.status === 200) {
                setShowToasterPopup(true);
                setIsLoading(true);
                await getInitialPageData();
                setApproachInputs({ approachName: "", awsPricingUrl: "" });
                setApproachValidationErrors({ approachName: '', awsPricingUrl: '' });
            } else {
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API Failed'
                };
                setToastProp(toastData);
                setHideToast(false);
            }

        } catch (error) {
            // console.error("Error submitting cost data", error);
            setShowToasterFailedPopup(true);
            setIsLoading(false);
        }
        finally {
            setIsLoading(false)
            setShowToasterLoading(false);
            setTimeout(() => {
                setShowToasterPopup(false);
                setShowToasterFailedPopup(false);
            }, 4000);
        }
    };

    const handleDeleteClick = (item: { costEstimationId?: string, approachId?: string }) => {
        setItemToDelete(item);
        setShowDeleteModal(true);
    };

    const confirmDelete = async () => {
        if (itemToDelete) {
            const deletePayload = {
                costEstimationId: itemToDelete.costEstimationId,
                approachId: itemToDelete.approachId
            };
            try {
                const response = await DeleteCostEstimation(deletePayload);

                if (response.status === 200) {
                    setShowDeletePopup(true);
                    setIsLoading(true);
                    closeModal();
                    await getInitialPageData();
                } else {
                    setIsLoading(false);
                    let toastData = {
                        toastType: 'error',
                        toastHeaderMessage: 'Error',
                        toastBodyMessage: 'API failed'
                    };
                    setToastProp(toastData);
                    setHideToast(false);
                }
            } catch (error) {
                setShowToasterFailedPopup(true);
                setIsLoading(false);
            }
            finally {
                setIsLoading(false)
                setTimeout(() => {
                    setShowDeletePopup(false);
                    setShowToasterFailedPopup(false);
                }, 4000);
            }
        }
    };

    const renderTable = (data: any, title: any, inputType: 'aws' | 'inventory' | 'future', approachId?: string) => {

        if (!Array.isArray(data)) {
            // console.error(`Data for ${title} is not an array:`, data);
            return null;
        }
        const cleanNumericString = (str: string) => str.replace(/[^0-9.-]+/g, "");

        const calculateTotal = (key: string) => {
            const total = data.reduce((sum, item) => {
                const value = parseFloat(cleanNumericString(item[key]));
                return sum + (isNaN(value) ? 0 : value);
            }, 0);
            return total.toFixed(2);
        };


        const monthlyTotal = calculateTotal('monthlyCost');
        const yearlyTotal = calculateTotal('yearlyCost');

        let inputValues;
        let validationErrors: any
        let handleChange;

        if (inputType === 'aws') {
            inputValues = awsInputValues;
            validationErrors = awsValidationErrors;
            handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleInputChange(e, 'aws');
        } else if (inputType === 'inventory') {
            inputValues = inventoryInputValues;
            validationErrors = inventoryValidationErrors;
            handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleInputChange(e, 'inventory');
        } else if (inputType === 'future' && approachId) {
            const approach = approaches.find(a => a.approachId === approachId);
            inputValues = approach ? approach.inputValues : {};
            validationErrors = approach ? approach.validationErrors : {};
            handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleFutureInputChange(e, approachId);
        } else {
            inputValues = { softwareName: "", monthlyCost: "", yearlyCost: "", comments: "" };
            validationErrors = { softwareName: '', monthlyCost: '', yearlyCost: '' };
            handleChange = () => { };
        }


        return (
            <>
                <div className="table-responsive mb-4">
                    <table className="table table-bordered themeTable alter-typo">
                        <thead className="font-medium">
                            <tr>
                                <th className="fw-unset">Service/Software</th>
                                <th className="fw-unset text-end">Monthly Cost</th>
                                <th className="fw-unset text-end">Yearly Cost</th>
                                <th className="fw-unset">Comments</th>
                                <th className="fw-unset text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="font-regular">
                            <tr>
                                <td>
                                    <input type="text" name="softwareName" aria-label="services" className={`form-control font-14 font-regular color-black shadow-none ${validationErrors.softwareName ? 'is-invalid' : ''}`} placeholder="Enter" value={inputValues.softwareName} onChange={handleChange} />
                                    {validationErrors.softwareName && <div className="invalid-feedback">{validationErrors.softwareName}</div>}
                                </td>
                                <td className="text">
                                    <input type="text" name="monthlyCost" aria-label="monthly cost" className={`form-control font-14 font-regular color-black shadow-none ${validationErrors.monthlyCost ? 'is-invalid' : ''}`} placeholder="Enter" value={inputValues.monthlyCost} onChange={handleChange} />
                                    {validationErrors.monthlyCost && <div className="invalid-feedback">{validationErrors.monthlyCost}</div>}
                                </td>
                                <td className="text">
                                    <input type="text" name="yearlyCost" aria-label="yearly cost" className={`form-control font-14 font-regular color-black shadow-none ${validationErrors.yearlyCost ? 'is-invalid' : ''}`} placeholder="Enter" value={inputValues.yearlyCost} onChange={handleChange} />
                                    {validationErrors.yearlyCost && <div className="invalid-feedback">{validationErrors.yearlyCost}</div>}
                                </td>
                                <td className="text-wrap">
                                    <textarea aria-label="comments" name="comments" className="form-control resize-none font-14 font-regular color-black shadow-none h-60px" placeholder="Comments" defaultValue={""} value={inputValues.comments} onChange={handleChange} />
                                </td>
                                <td className="text-center">
                                    <button type="button" className="border-0 add-btn d-inline-flex align-items-center justify-content-center p-0" onClick={() => handleAddClick(inputType, approachId)}>
                                        <img src="images/plus.svg" alt="add" />
                                    </button>
                                </td>
                            </tr>
                            {data.map((item: any) => (
                                <tr key={item.costEstimationId}>
                                    {editingId === item.costEstimationId ? (
                                        <>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="softwareName"
                                                    value={editedValues.softwareName || ''}
                                                    onChange={handleEditInputChange}
                                                    className={`form-control font-14 font-regular color-black shadow-none ${editValidationErrors.softwareName ? 'is-invalid' : ''}`}
                                                />
                                                {editValidationErrors.softwareName && <div className="invalid-feedback">{editValidationErrors.softwareName}</div>}
                                            </td>
                                            <td className="text">
                                                <input
                                                    type="text"
                                                    name="monthlyCost"
                                                    value={editedValues.monthlyCost || ''}
                                                    onChange={handleEditInputChange}
                                                    className={`form-control font-14 font-regular color-black shadow-none ${editValidationErrors.monthlyCost ? 'is-invalid' : ''}`}
                                                />
                                                {editValidationErrors.monthlyCost && <div className="invalid-feedback">{editValidationErrors.monthlyCost}</div>}
                                            </td>
                                            <td className="text">
                                                <input
                                                    type="text"
                                                    name="yearlyCost"
                                                    value={editedValues.yearlyCost || ''}
                                                    onChange={handleEditInputChange}
                                                    className={`form-control font-14 font-regular color-black shadow-none ${editValidationErrors.yearlyCost ? 'is-invalid' : ''}`}
                                                />
                                                {editValidationErrors.yearlyCost && <div className="invalid-feedback">{editValidationErrors.yearlyCost}</div>}
                                            </td>
                                            <td className="text-wrap">
                                                <textarea
                                                    name="comments"
                                                    value={editedValues.comments || ''}
                                                    onChange={handleEditInputChange}
                                                    className="form-control resize-none font-14 font-regular color-black shadow-none h-60px"
                                                />
                                            </td>
                                            <td className="text-center">
                                                <span className="cursor-pointer" onClick={() => handleSaveClick(item)}>
                                                    <img src="images/check.svg" alt="saveIcon" title="Save" />
                                                </span>
                                                <span className="ms-3 cursor-pointer" onClick={handleCancelClick}>
                                                    <img src="images/cancel-icon.svg" alt="cancelIcon" title="Cancel" />
                                                </span>
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td>{item.softwareName}</td>
                                            <td className="text-end">{item.monthlyCost.startsWith('$') ? item.monthlyCost : `$${item.monthlyCost}`}</td>
                                            <td className="text-end">{item.yearlyCost.startsWith('$') ? item.yearlyCost : `$${item.yearlyCost}`}</td>
                                            <td className="text-wrap">{item.comments ? item.comments : "-"}</td>
                                            <td className="text-center">
                                                <span className="cursor-pointer" onClick={() => handleEditClick(item.costEstimationId)}>
                                                    <img src="images/editIcon.svg" className="action-btn-hover" alt="editIcon" title="Edit" />
                                                </span>
                                                <span className="ms-3 cursor-pointer" onClick={() => handleDeleteClick({ costEstimationId: item.costEstimationId })}>
                                                    <img src="images/deleteIcon.svg" className="action-btn-hover" alt="deleteIcon" title="Delete" />
                                                </span>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            ))}

                            <tr>
                                <td>Total</td>
                                <td className="text-end font-semibold">
                                    $ {calculateTotal('monthlyCost')}
                                </td>
                                <td className="text-end font-semibold">
                                    $ {calculateTotal('yearlyCost')}
                                </td>
                                <td className="text-wrap" />
                                <td className="text-center" />
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        )
    };
    return (
        <div className="ms-lg-4">
            {type === 'current' ? (
                <>
                    {state.isAWS && costData?.currentCost?.AWS?.current && (
                        <>
                            <h3 className="font-semibold font-18 mb-4">Current AWS Cost</h3>
                            {renderTable(costData.currentCost.AWS.current, "Current AWS Cost Details", 'aws')}
                        </>
                    )}
                    {state.isInventory && costData?.currentCost?.INVENTORY && (
                        <>
                            <h3 className="font-semibold font-18 mb-4">Current Inventory Cost</h3>
                            {renderTable(costData.currentCost.INVENTORY, "Current Inventory Cost Details", 'inventory')}
                        </>
                    )}
                </>
            ) : (
                <>
                    <h3 className="font-18 font-semibold color-black m-0">Future State AWS Cost</h3>
                    {/* Add new approach form */}
                    <div className="my-4 pb-4 border-bottom">
                        <div className="row  justify-content-between row-gap-4">
                            <div className="col-12 col-md-6 col-lg-3">
                                <div>
                                    <label htmlFor="approachName" className="form-label font-14 font-semibold color-black">
                                        Approach Name  <span className="mandate">*</span>
                                    </label>
                                    <input type="text"
                                        className="form-control theme-form font-regular font-14 "
                                        id="approachName"
                                        name="approachName"
                                        placeholder="Approach Name"
                                        value={approachInputs.approachName}
                                        onChange={(e) => handleInputChange(e, "future")} />
                                    {approachValidationErrors.approachName && <div className="text-danger font-12 mt-1" >{approachValidationErrors.approachName}</div>}
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-3">
                                <div>
                                    <label htmlFor="_url" className="form-label font-14 font-semibold color-black">
                                        Enter URL <span className="mandate">*</span>
                                    </label>
                                    <input type="text"
                                        className="form-control theme-form font-regular font-14"
                                        id="awsPricingUrl"
                                        name="awsPricingUrl"
                                        placeholder="URL"
                                        value={approachInputs.awsPricingUrl}
                                        onChange={(e) => handleInputChange(e, "future")} />
                                    {approachValidationErrors.awsPricingUrl && (
                                        <div className="text-danger font-12 mt-1">{approachValidationErrors.awsPricingUrl}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5 mt-4 pb-3 pt-3">
                                <button type="button" className="btn btn-dark theme-primary-btn-md d-flex gap-2 align-items-center border-0 font-14 font-semibold py-2" onClick={() => handleAddSubmit()} >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    {approaches.map((approach: any, index: any) => (
                        <div key={approach.approachId || index}>
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h3 className="font-semibold font-18">{approach.data[0].approachName}</h3>
                                <button type="button" className="btn btn-outline-danger px-3 rounded-1 font-12" onClick={() => handleDeleteClick({ approachId: approach.approachId })}> Delete
                                </button>
                            </div>
                            {renderTable(approach.data, approach.name, 'future', approach.approachId)}
                        </div>
                    ))}
                </>
            )}
            {showDeleteModal && (
                <div
                    className="modal fade show"
                    id="delete"
                    tabIndex={-1}
                    aria-hidden="true"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    style={{ display: 'block' }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-3">
                            <div className="modal-header pb-0 border-0">

                                <button
                                    type="button"
                                    className="btn-close cursor-pointer"
                                    onClick={closeModal}
                                    aria-label="Close"
                                ></button>
                            </div>

                            <div className="modal-body">
                                <div className="w-100 text-center mb-3">
                                    <img src="images/delete-icon.svg" alt="delete" />
                                </div>

                                <p className="font-semibold font-24 text-center mb-2 red-400">
                                    Delete Confirmation
                                </p>

                                <p className="font-semibold font-16 text-center mb-5">
                                    Are you sure you want to delete?
                                </p>

                                <div className="d-grid gap-2 d-md-inline-flex justify-content-md-center mb-2 mb-md-0 w-100">
                                    <a
                                        className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer px-4"
                                        onClick={confirmDelete}
                                    >
                                        Yes
                                    </a>

                                    <button
                                        type="button"
                                        className="btn btn-danger red-400 btn-lg px-4 text-white font-14 font-semibold"
                                        onClick={closeModal}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showDeleteModal && <div className="modal-backdrop fade show"></div>}
            {showToasterLoading && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img
                                    src="images/loading.gif"
                                    alt="toast-success"
                                    style={{ height: "15px", width: "15px" }}
                                />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Saving...
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showToasterPopup && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/toast-success.svg" alt="toast-success" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Cost has been saved successfully
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showToasterFailedPopup && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/fail-icon.svg" alt="toast-fail" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Unable to retrieve the cost. Please enter a valid URL
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showDeletePopup && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/toast-success.svg" alt="toast-success" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Cost has been Deleted successfully.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CostEstimation