import React from 'react';
import { PieChart, Pie, Cell, Text, ResponsiveContainer } from 'recharts';

const CircularPieChart = (props: any) => {
    //h- 400, x- 120, y- 150, x- 120, y - 205
     //h- 250, x- 120, y- 230, x- 250, y - 150
   const  {chartHeight, chartXaxis, chartYaxis, countXaxis, countYaxis} = props
    const totalCount = props?.data?.reduce((acc: number, entry: any) => {
        return acc + entry.value
    }, 0)
    return (
        <ResponsiveContainer width="100%" height={chartHeight}>
            {
                totalCount === 0 ?
                    <PieChart>
                        <Pie
                            data={[{ name: "empty", value: 10 }]}
                            cx={chartXaxis}
                            cy={chartYaxis}
                           
                            startAngle={180}
                            endAngle={0}
                            innerRadius={100}
                            outerRadius={120}
                            fill="#8884d8"
                            paddingAngle={2}
                            cornerRadius={8}
                            dataKey="value"
                        >

                            <Cell fill="grey" />
                        </Pie>
                        <svg>
                            <text x={countXaxis} y={countYaxis} textAnchor="middle" dominantBaseline="middle" fill="#333" fontSize={30} fontWeight="bold">
                                {totalCount}
                            </text>
                        </svg>
                    </PieChart>
                    :
                    <PieChart>
                        <Pie
                            data={props.data}
                            // cx={120}
                            // cy={230}
                            cx={chartXaxis}
                            cy={chartYaxis}
                            startAngle={180}
                            endAngle={0}
                            innerRadius={100}
                            outerRadius={120}
                            fill="#8884d8"
                            paddingAngle={2}
                            cornerRadius={8}
                            dataKey="value"
                        >
                            {props?.data?.map((entry: any, index: number) => (
                                <Cell key={`cell-${index}`} fill={props?.colorCode[index % props?.colorCode?.length]} />
                            ))}
                        </Pie>
                        <svg>
                            <text x={countXaxis} y={countYaxis} textAnchor="middle" dominantBaseline="middle" fill="#333" fontSize={30} fontWeight="bold">
                                {totalCount}
                            </text>
                        </svg>
                    </PieChart>
            }
        </ResponsiveContainer>
    )
}

export default CircularPieChart;