import { apiCall, apiCall2 } from "../client/apiCall";
import { baseUrl} from "../constant/constant";

// let baseUrl = "http://127.0.0.1:8080"

// PC_API_1.2
export async function getLeftNavigation(id: string) {
  const response = await apiCall(
    baseUrl + `/connectors/list/vertical/${id}`,
    "GET",
    {}
  );
  return response.data;
}

// PC_API_1.3
export async function getControlAreas(data: any) {
  const response = await apiCall(baseUrl + `/connectors/controlareas/list`, 'POST', data)
  return response.data;
}

// PC_API_1.4
export async function getControlAreaSubMenuQuestions(data: any) {
  const response = await apiCall(baseUrl + `/connectors/questionnaire`, 'POST', data)
  return response.data;
}

// PC_API_1.5
export async function getProjects(verticalId: string, organizationId: string) {
  const response = await apiCall(
    baseUrl + `/connectors/projects/${organizationId}/list/${verticalId}`,
    "GET",
    {}
  );
  return response.data;
}

// PC_API_1.6
export async function getConnectedConnectors(data: any) {
  const response = await apiCall(baseUrl + `/connectors/list`, "POST", data);
  return response.data;
}

// PC_API_1.7
export async function postConnectors(data: any) {
  const response = await apiCall(baseUrl + `/connectors/add`, "POST", data);
  return response;
}

export async function validateAwsConnector(data: any) {
  const response = await apiCall(baseUrl + `/report/validateAwsConnectors`, "POST", data);
  return response;
}

export async function PostAutoQuestionnarie(data: any) {
  const response = apiCall2(baseUrl + `/connectors/autoQuestionnarie`, "POST", data);
  return response;
}

export async function getProfileAndWorkLoad(data: any) {
  const response = await apiCall(baseUrl + `/report/getProfileAndWorkload`, "POST", data);
  return response.data;
}

export async function postProfileAndWorkload(data: any) {
  const response = await apiCall(baseUrl + `/report/postProfileAndWorkload`, "POST", data);
  return response.data;
}

export async function checkSecurityHubStatus(data: any) {
  const response = await apiCall(baseUrl+ `/report/checkSecurityHubStatus`, "POST", data);
  return response.data;
}

export async function checkTrustedAdvisorStatus(data: any) {
  const response = await apiCall(baseUrl + `/report/checkTrustedAdvisorStatus`, "POST", data);
  return response.data;
}


// PC_API_1.8
export async function postQuestionnaire(
  organizationId: string,
  verticalId: string,
  data: any
) {
  const response = await apiCall(
    baseUrl +
    `/connectors/controlareas/questions/${organizationId}/${verticalId}`,
    "POST",
    data
  );
  return response;
}

// PC_API_1.12
export async function deleteConnectors(attributeId: string) {
  const response = await apiCall(baseUrl + `/connectors/delete`, "POST", {
    attributeId: attributeId,
  });
  return response;
}

// PC_API_1.14
export async function addProjects(data: any) {
  const response = await apiCall(
    baseUrl + `/connectors/project/add`,
    "POST",
    data
  );
  return response;
}

// PC_API_1.15
export async function deleteProject(data: any) {
  const response = await apiCall(
    baseUrl + `/connectors/project/delete`,
    "POST",
    data
  );
  return response;
}

// PC_API_1.16
export async function postQuestionnaireReport(
  organizationId: string,
  verticalId: string,
  projectId: string | null,
  data: any
) {
  const response = await apiCall(
    baseUrl +
    `/connectors/questionnaire/report/${organizationId}/${verticalId}/${projectId}`,
    "POST",
    data
  );
  return response;
}

export async function postChanllengesAndRecommendations(data: any) {
  const response = await apiCall(baseUrl + `/report/postChanllengesAndRecommendations`, 'POST', data)
  return response.data;
}

// PC_API_1.17
export async function getGenerateReportButtonFlag(data: any) {
  const response = await apiCall(baseUrl + `/connectors/generateReportButton`, 'POST', data)
  return response.data;
}

// PC_API_1.18
export async function postReassessProject(data: any) {
  const response = await apiCall(
    baseUrl + `/assessments/reassess`,
    "POST",
    data
  );
  return response;
}

// PC_API_1.18
export async function getSalesforceCloud(data: any) {
  const response = await apiCall(baseUrl + `/connectors/getSalesforceCloud`, 'POST', data)
  return response.data;
}

// PC_API_1.18
export async function postSalesforceCloud(data: any) {
  const response = await apiCall(baseUrl + `/connectors/postSalesforceCloud`, 'POST', data)
  return response;
}

export async function getServiceNowDropdown() {
  const response = await apiCall(baseUrl + `/connectors/getServiceNowDropdowns`, 'GET', {})
  return response.data;
}

export async function getNotReviewedItems(data: any) {
  const response = await apiCall(baseUrl + `/connectors/reviewQuestionsAnswers`, 'POST', data)
  return response.data;
}

export async function getIntraAssessmentDetails() {
  const response = await apiCall(baseUrl + "/assessments/infraAssessment/getInfraOrganizationMenus", 'GET')
  return response;
}

export async function GetCostEstimation(data: any) {
  const response = await apiCall(baseUrl + "/connectors/GetCostEstimation", 'POST', data)
  return response;
}

export async function postBusinessWorkFlow(data: any) {
  const response = await apiCall(baseUrl + "/connectors/postAttributeDetails", 'POST', data)
  return response;
}

export async function generateConclusionDetails(data: any) {
  const response = await apiCall(baseUrl + "/connectors/generateConclusion", "POST", data)
  return response
}

export async function postArchitectureDetails(data: any) {
  const response = await apiCall(baseUrl + "/connectors/architectureDetails", "POST", data)
  return response
}

export async function deleteAttributeRecords(data: any) {
  const response = await apiCall(baseUrl + "/connectors/deleteAttribute", "DELETE", data)
  return response
}

export async function PostCostEstimation(data: any) {
  const response = await apiCall(baseUrl + "/connectors/postCostEstimation", 'POST', data)
  return response;
}

export async function DeleteCostEstimation(data: any) {
  const response = await apiCall(baseUrl + "/connectors/deleteCostEstimation", 'POST', data)
  return response;
}
export async function postCurrentCost(data: any) {
  const response = await apiCall(baseUrl + `/connectors/postCurrentCost`, "POST", data);
  return response;
}
