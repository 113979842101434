import { useEffect, useState } from "react";
import { MigrationStrategyProps, Question, SubmenuItem } from "../interface/InfraAssessmentModel";
import { getInfraAssessmentApplications, getInfraAssessmentQuestions, getInfraAssessmentSubMenus, postMigrationStrategyAnswers } from "../service/InfraAssessmentApi";
import ToastComponent from "./ToastComponent";

const MigrationStrategy: React.FC<MigrationStrategyProps> = (props) => {

    const toastData: any = {
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: '',
    };

    // PS_MigrationStrategy_01        
    const { setApplicationDropDown, applicationDropDown, setSelectedApplicationId, selectedApplicationId, setIsLoading, state, menuId } = props

    // PS_MigrationStrategy_02-  PS_MigrationStrategy_13
    const [questions, setQuestions] = useState<Question[]>([]);
    const [renderedQuestions, setRenderedQuestions] = useState<Question[]>([]);
    const [answers, setAnswers] = useState<{ [key: string]: string | null }>({});
    const [renderedQuestionIds, setRenderedQuestionIds] = useState<string[]>([]);
    const [showToasterPopup, setShowToasterPopup] = useState(false);
    const [showToasterLoading, setShowToasterLoading] = useState<boolean>(false);
    const [isSaveBtnVisible, setIsSaveBtnVisible] = useState(false);
    const [isNoRecordPageVisible, setIsNoRecordPageVisible] = useState(false);
    const [toastProp, setToastProp] = useState<any>(toastData)
    const [hideToast, setHideToast] = useState<boolean>(false);
    const [subMenus, setSubMenus] = useState<SubmenuItem[]>([]);

    // PS_MigrationStrategy_15
    useEffect(() => {
        getInitialPageData();
    }, [selectedApplicationId]);

    useEffect(() => {
        setTimeout(() => setHideToast(false), 2000);
    }, [hideToast]);


    const getSubMenus = async () => {
        try {
            const submenupayload = {
                infraAssessmentMenuId: menuId,
            };

            const response = await getInfraAssessmentSubMenus(submenupayload);
            if (response.status !== 200) {
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }
            setSubMenus(response.data[0].submenuId);
            const submenuId = response.data[0].submenuId
            getApplicationQuestions(submenuId)

        } catch (error) {
            console.error("Error fetching submenu data:", error);
        }
    }

    const getApplicationQuestions = async (submenuId: string) => {
        try {
            const questionPayload = {
                organizationId: state.organizationId,
                applicationId: selectedApplicationId,
                verticalId: state.verticalId,
                menuId: menuId,
                submenuId: submenuId
            }
            // PS_MigrationStrategy_38
            const fetchedQuestions = await getInfraAssessmentQuestions(questionPayload);
            if (fetchedQuestions.status !== 200) {
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }
            if (fetchedQuestions.status == 200) {
                setIsLoading(false)
                setHideToast(true);
                setQuestions(fetchedQuestions.data.questions);
            }
            renderInitialQuestion(fetchedQuestions.data.questions);
        } catch (error) {
            console.error("Error fetching question data:", error);
        }        
    }

    const getInitialPageData = async () => {
        try {
            setIsLoading(true)
            setIsSaveBtnVisible(false)
            getSubMenus()
        } catch (error) {
            setIsLoading(false)
            console.error("Error fetching initial data:", error);
        }
    };

    const renderInitialQuestion = (renderQuestions: any) => {
        if (renderQuestions.length > 0) {
            const initialQuestion = renderQuestions[0];
            setRenderedQuestions([initialQuestion]);
        }
    };


    // PS_MigrationStrategy_40 -   PS_MigrationStrategy_51
    const handleOptionSelect = (questionId: string, optionId: string, questionIndex: number) => {
        let updatedQuestions = [...renderedQuestions];

        // Set answeredOptionId for the current question
        updatedQuestions[questionIndex] = {
            ...updatedQuestions[questionIndex],
            answeredOptionId: optionId
        };

        // Now let's handle adding or removing subsequent questions based on the selection
        const currentQuestion = updatedQuestions[questionIndex];
        if (currentQuestion) {
            const selectedOption = currentQuestion.options.find(o => o.optionId === optionId);
            if (selectedOption) {
                // Remove all questions after the current one as the branch might change
                updatedQuestions = updatedQuestions.slice(0, questionIndex + 1);
                // Find the branch question based on the selected option            
                const nextQuestion = questions.find(q => q.questionId === selectedOption.branchId);
                if (nextQuestion && !updatedQuestions.some(q => q.questionId === nextQuestion.questionId)) {
                    // Add the next question to the updated list
                    updatedQuestions.push(nextQuestion);
                }

                // Update the state with the new question list and answers
                setRenderedQuestions(updatedQuestions);
                setRenderedQuestionIds(updatedQuestions.map(q => q.questionId));
                setAnswers(prevAnswers => ({
                    ...prevAnswers,
                    [questionId]: optionId,
                }));
            }
        }
        setIsSaveBtnVisible(prevIsSaveBtnVisible => {
            const isLastQuestion = checkIsLastQuestion(updatedQuestions, questions);
            return isLastQuestion;
        });
    };

    // Separate isLastQuestion into its own function for reusability and clarity
    const checkIsLastQuestion = (renderedQuestions: Question[], allQuestions: Question[]) => {
        if (renderedQuestions.length === 0) return false;
        const lastRenderedQuestion = renderedQuestions[renderedQuestions.length - 1];
        const lastOptionSelected = lastRenderedQuestion.answeredOptionId;
        if (!lastOptionSelected) return false;
        const selectedOption = lastRenderedQuestion.options.find(o => o.optionId === lastOptionSelected);
        // Assuming there's a branchId, check if there's no subsequent question for the selected option
        if (!selectedOption) return false;
        return !allQuestions.some(q => q.questionId === selectedOption.branchId);
    }

    // PS_MigrationStrategy_54 -  PS_MigrationStrategy_78
    const handleSave = async () => {
        const applicationId = selectedApplicationId
        const userAnsweredDetails = Object.entries(answers).map(([questionId, answeredOptionId]) => ({
            questionId,
            answeredOptionId
        }));

        // Derive migrationStrategyId by looking at the last answered question's selected branchId
        const lastQuestionId = userAnsweredDetails[userAnsweredDetails.length - 1]?.questionId;
        const lastQuestion = renderedQuestions.find(q => q.questionId === lastQuestionId);
        const lastOption = lastQuestion?.options.find(o => o.optionId === answers[lastQuestionId]);
        const strategyId = lastOption?.branchId || ''; // Use an empty string if there's no branchId

        const payloadData = {
            organizationId: state.organizationId,
            verticalId: state.verticalId,
            menuId: menuId,
            submenuId: subMenus,
            applicationId: applicationId,
            migrationStrategyId: strategyId,
            userAnsweredDetails: userAnsweredDetails
        };

        setShowToasterLoading(true);

        try {
            const submitApiResponse = await postMigrationStrategyAnswers(payloadData);

            if (submitApiResponse.status === 200) {

                setShowToasterPopup(true);
                resetForm();

            } else {
                setIsLoading(false)
                setShowToasterPopup(true);
                resetForm();
            }
        } catch (error) {
            console.error("Error submitting form data", error);

            setIsLoading(false)
        } finally {

            setShowToasterLoading(false);
            setTimeout(() => {
                setShowToasterPopup(false);
                setHideToast(true);
            }, 2000);
            resetForm();
        }
    };

    const resetForm = async () => {
        setAnswers({});
        await getInitialPageData();
    };

    return (
        <>
            {applicationDropDown.length !== 0 && (
                <div className="tab-pane fade active show" id="CAFtab" role="tabpanel">
                    <h2 className="font-22 font-semibold color-black mb-0">
                        Migration Modernization recommendation Strategy
                    </h2>
                    <div className="row">
                        <div className="tab-content" id="nav-UserExperience">
                            <div className="tab-pane fade show active pt-5" id="Businesstab" role="tabpanel">
                                {renderedQuestions.map((question, questionIndex) => (
                                    <div className="mb-4" key={questionIndex}>
                                        <p className="font-16 font-medium">{questionIndex + 1 + "." + question.question}</p>
                                        {question.options.map((option, optionIndex) => (
                                            <div className="form-check form-check-inline ms-3" key={"option" + optionIndex}>
                                                <input className="form-check-input theme-radio label-bold" type={question.questionType} name={"question" + questionIndex} id={"option" + optionIndex}
                                                    checked={option.optionId === question?.answeredOptionId}
                                                    onChange={() => handleOptionSelect(question.questionId, option.optionId, questionIndex)} />
                                                <label className="form-check-label mb-2 ms-2 font-14 font-regular" htmlFor="option1">
                                                    {option.option}
                                                </label>
                                            </div>

                                        ))}
                                    </div>
                                ))}
                            </div>
                            {isSaveBtnVisible && (<div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
                                <button type="button" className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold " onClick={handleSave}>Save</button>
                            </div>)}
                        </div>
                    </div>
                </div>)}

            {applicationDropDown.length == 0 && (
                <div className="row h-100 align-items-center text-center justify-content-center">
                    <div className="col-lg-6 col-md-12 col-sm-12 text-center">
                        <img src="images/assessment-norec-img.svg" alt="assessment-norec-img" className="ass-norec-img" />
                        <p className="font-16 font-medium color-black mt-4">Please add an application to the portfolio discovery to move forward with the migration strategy - 7Rs</p>
                    </div>
                </div>
            )}

            {showToasterLoading ? (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img
                                    src="images/loading.gif"
                                    alt="toast-success"
                                    style={{ height: "15px", width: "15px" }}
                                />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Saving...
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            {showToasterPopup ? (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/toast-success.svg" alt="toast-success" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Saved successfully.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            {
                toastProp.visible &&
                <ToastComponent name={toastProp} />
            }
        </>
    )
}

export default MigrationStrategy