import { useEffect, useRef, useState } from "react";
import { deleteAttributeRecords, getFlowComponents, postFlowComponents } from "../../service/QuestionnaireApi";
import { getSasToken } from "../../service/ReportApi";
import uploadFileToBlob1 from "../../helpers/BlobUpload";
import TextEditor from "./TextEditor";

const FlowComponents = ({ type, title, state, setIsLoading }: any) => {

    const [flowData, setFlowData] = useState<any[]>([]);
    const [sasToken, setSasToken] = useState('');
    const [componentName, setComponentName] = useState('');
    const [file, setFile] = useState<any>(null);
    const [fileName, setFileName] = useState('');
    const [nameError, setNameError] = useState('');
    const [fileError, setFileError] = useState('');
    const [showToasterLoading, setShowToasterLoading] = useState(false);
    const [showToasterPopup, setShowToasterPopup] = useState(false);
    const [showToasterFailedPopup, setShowToasterFailedPopup] = useState(false);
    const [editingItem, setEditingItem] = useState<any>(null);
    const [editComponentName, setEditComponentName] = useState('');
    const [editComponentDescription, setEditComponentDescription] = useState('');
    const [editFile, setEditFile] = useState<any>(null);
    const [editFileName, setEditFileName] = useState('');
    const [editNameError, setEditNameError] = useState('');
    const [editFileError, setEditFileError] = useState('');
    const [editDescriptionError, setEditDescriptionError] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletingItemId, setDeletingItemId] = useState(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [showEditSavePopup, setShowEditSavePopup] = useState(false)
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [hadValidationError, setHadValidationError] = useState(false);
    const [isFileRemoved, setIsFileRemoved] = useState(false);

    const fileInputRef = useRef<any>(null);
    const fileEditInputRef = useRef<any>(null);

    const getInitialPageData = async () => {
        setIsLoading(true); // Start loading
        const payload = {
            organizationId: state.organizationId,
            verticalId: state.verticalId,
            projectId: state.projectId,
            connectorId: state.connectorId
        }
        try {
            const response = await getFlowComponents(payload);
            if (response.status === 200) {
                setIsLoading(false); // Start loading

                setFlowData(response.data.flowComponents || []);
            } else {
                setShowToasterFailedPopup(true)
            }
        } catch (error) {
            console.error('Failed to fetch flow components:', error);
        }
    }

    useEffect(() => {
        getInitialPageData();
    }, []);

    useEffect(() => {
        const fetchSasToken = async () => {
            try {
                const sas = await getSasToken('r');
                setSasToken(sas?.data);
            } catch (error) {
                console.error("Error fetching SAS token:", error);
            }
        };

        fetchSasToken();
    }, []);

    const generateImageUrl = (blobUrl: string, sasToken: string) => {
        if (!blobUrl || !sasToken) return '';
        return `${blobUrl}?${sasToken}`;
    };

    const resetFileInput = () => {
        if (fileInputRef?.current) {
            fileInputRef.current.value = '';
            fileInputRef.current.files = null;
        }
    };

    const resetEditFileInput = () => {
        if (fileEditInputRef?.current) {
            fileEditInputRef.current.value = '';
            fileEditInputRef.current.files = null;
        }
    }

    const validateImage = (file: any, minWidth = 1000, minHeight = 1500, minDPI = 300) => {
        return new Promise((resolve, reject) => {
            if (!file) {
                resolve({ isValid: false, errorMessage: 'No file selected.' });
                return;
            }

            const reader = new FileReader();

            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {

                    const dpiX = (img.width / (file.width / 25.4));
                    const dpiY = (img.height / (file.height / 25.4));

                    if (img.width < minWidth || img.height < minHeight || dpiX < minDPI || dpiY < minDPI) {
                        reject(`Image resolution is too low`);
                    } else {
                        resolve({ isValid: true, errorMessage: '' });
                    }
                };
                img.onerror = () => {
                    reject('Error loading image. Please try again.');
                };
                img.src = (event?.target?.result as string);
            };

            reader.onerror = () => {
                reject('Error reading file. Please try again.');
            };

            reader.readAsDataURL(file);
        });
    };

    const handleFileChange = (event: any, isEdit = false) => {
        const selectedFile = event.target.files?.[0];

        // If there was a previous validation error then reset the flags
        if (hadValidationError) {
            setHadValidationError(false);
        }

        if (selectedFile) {
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

            if (!allowedTypes.includes(selectedFile.type)) {
                if (isEdit) {
                    setEditFileError('File should be PNG, JPG, or JPEG');
                    setEditFile(null);
                    setEditFileName('');
                } else {
                    setFileError('File should be PNG, JPG, or JPEG');
                    setFile(null);
                    setFileName('');
                }
                return;
            }

            if (selectedFile.size > 20 * 1024 * 1024) {
                if (isEdit) {
                    setEditFileError('File size should be less than 20 MB');
                    setEditFile(null);
                    setEditFileName('');
                } else {
                    setFileError('File size should be less than 20 MB');
                    setFile(null);
                    setFileName('');
                }
                return;
            }

            // Proceed with further validation
            validateImage(selectedFile, 1000, 1500, 300)
                .then((result: any) => {
                    if (result.isValid) {
                        if (isEdit) {
                            setEditFileError('');
                            setEditFile(selectedFile);
                            setEditFileName(selectedFile.name);
                        } else {
                            setFileError('');
                            setFile(selectedFile);
                            setFileName(selectedFile.name);
                        }
                    } else {
                        if (isEdit) {
                            setEditFileError(result.errorMessage);
                            setEditFile(null);
                            setEditFileName('');
                        } else {
                            setFileError(result.errorMessage);
                            setFile(null);
                            setFileName('');
                        }
                    }
                })
                .catch((error) => {
                    if (isEdit) {
                        setEditFileError(error);
                        setEditFile(null);
                        setEditFileName('');
                    } else {
                        setFileError(error);
                        setFile(null);
                        setFileName('');
                    }
                });
        } else {
            // No file selected, so clear the data
            if (isEdit) {
                setEditFile(null);
                setEditFileName('');
                setEditFileError('');
            } else {
                setFile(null);
                setFileName('');
                setFileError('');
            }
        }
    };

    const handleAdd = async () => {

        let isValid = true;

        if (!componentName.trim()) {
            if (type === "Business Flow") {
                setNameError('Please enter a Business Flow Name');
            } else if (type === "Workflow Component") {
                setNameError('Please enter a Work Flow Name');
            } else {
                setNameError('Please enter an Architecture Name');
            }
            isValid = false;
        } else {
            setNameError('');
        }

        if (!file) {
            setFileError('Please select a file to upload');
            isValid = false;
        } else {
            setFileError('');
        }

        if (!isValid) {
            setHadValidationError(true);
            return;
        }

        setHadValidationError(false);

        setShowToasterLoading(true);

        try {
            const blobUrl = await uploadFileToBlob1(file, 'flowComponents');

            const payload = {
                organizationId: state.organizationId,
                verticalId: state.verticalId,
                projectId: state.projectId,
                connectorId: state.connectorId,
                connectorName: state.connectorName,
                base64Value: await fileToBase64(file),
                fileType: `.${file.name.split('.').pop()}`,
                attributeMasterId: "",
                attributeRecords: [
                    {
                        attributeID: "",
                        attributeName: `${type} Name`,
                        attributeValue: componentName
                    },
                    {
                        attributeID: "",
                        attributeName: `${type} URL`,
                        attributeValue: blobUrl
                    },
                    {
                        attributeID: "",
                        attributeName: `${type} Description`,
                        attributeValue: ""
                    }
                ]
            };
            const response = await postFlowComponents(payload);
            if (response.data && response.status === 200) {
                setShowToasterPopup(true);
                await new Promise(resolve => setTimeout(resolve, 1000));

                setFlowData((prevData: any) => [response.data, ...prevData]);

                setComponentName('');
                setFile(null);
                setFileName('');
                resetFileInput();
            } else {
                setFileError("Error: " + response.statusText);
                setShowToasterFailedPopup(true);
            }
        } catch (error) {
            console.error('Error adding flow component:', error);
            setFileError("An error occurred while adding the flow component.");
            setShowToasterFailedPopup(true);
        } finally {
            setShowToasterLoading(false);
            setTimeout(() => {
                setShowToasterPopup(false);
                setShowToasterFailedPopup(false);
            }, 4000);
        }
    };

    const fileToBase64 = (file: any): any => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const result = reader.result as string;
                resolve(result.split(',')[1]);
            };
            reader.onerror = error => reject(error);
        });
    };

    const handleRemoveFile = () => {
        setFile(null);
        setFileName('');
        setIsFileRemoved(true);
        resetFileInput();
    };

    const extractFileNameFromBlobUrl = (blobUrl: string) => {
        const urlParts = blobUrl.split('/');
        let fileName = urlParts[urlParts.length - 1];
        if (!fileName.includes('.')) {
            const dashParts = blobUrl.split('-');
            fileName = dashParts[dashParts.length - 1];
        }
        fileName = fileName.split('?')[0];
        const iiiiiIndex = fileName.indexOf('iiiii-');
        if (iiiiiIndex !== -1) {
            fileName = fileName.substring(iiiiiIndex + 6);
        }
        fileName = decodeURIComponent(fileName);
        return fileName || 'Unknown File';
    };

    const handleComponentNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newName = event.target.value;
        setComponentName(newName);
        if (newName.trim() && newName.length <= 40) {
            setNameError('');
        }
        setEditComponentName(newName);
        setEditNameError(newName.trim() ? '' : 'Please enter an Architecture Name');
    };

    const handleEditNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newName = event.target.value;
        setEditComponentName(newName);
        if (type === "Business Flow") {
            setEditNameError(newName.trim() ? '' : 'Please enter a Business Flow Name');
        } else if (type === "Workflow Component") {
            setEditNameError(newName.trim() ? '' : 'Please enter a Work Flow Name');
        } else {
            setEditNameError(newName.trim() ? '' : 'Please enter an Architecture Name');
        }
    };

    const handleOpenEditModal = (flowItem: any) => {
        setEditingItem(flowItem);
        const nameAttribute = flowItem.find((attr: any) => attr.attributeName === `${type} Name`);
        const descriptionAttribute = flowItem.find((attr: any) => attr.attributeName === `${type} Description`);
        const blobUrl = flowItem.find((attr: any) => attr.attributeName === `${type} URL`)?.attributeValue;
        const extractedFileName = extractFileNameFromBlobUrl(blobUrl);
        setEditComponentName(nameAttribute?.attributeValue || '');
        setEditComponentDescription(descriptionAttribute?.attributeValue || '');
        setEditFileName(extractedFileName);
        setEditFile(null);
        setIsEditPopupOpen(true);
        setEditNameError('');
        setEditFileError('');
        setEditDescriptionError('');
    };

    const handleCloseEditModal = () => {
        setEditingItem(null);
        setEditComponentName('');
        setEditComponentDescription('');
        setEditFile(null);
        setEditFileName('');
        setEditNameError('');
        setEditFileError('');
        setIsEditPopupOpen(false);
    };

    const handleClearEditModal = () => {
        setEditComponentName('');
        setEditComponentDescription('');
        setEditFile(null);
        setEditFileName('');
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }

        // Clear the existing file URL from the editingItem
        if (editingItem) {
            const updatedEditingItem = editingItem.map((attr: any) => {
                if (attr.attributeName === `${type} URL`) {
                    return { ...attr, attributeValue: '' };
                }
                return attr;
            });
            setEditingItem(updatedEditingItem);
        }

        setEditNameError('');
        setEditDescriptionError('');
        setEditFileError('');
        setIsFileRemoved(false);
    };

    const handleDeleteFlowComponent = (item: any) => {
        setDeletingItemId(item[0].attributeMasterId);
        setShowDeleteModal(true);
    };

    const regex = /"|[*]/g;
    const textEditorRegex = /<[^>]*>/g;

    const escapeJsonString = (str: string) => {
        return str.replace(/"/g, '\\"')
    }

    const confirmDeleteFlowComponent = async () => {
        closeModal();
        setIsLoading(true); // Start loading
        try {
            const payload = { attributeMasterId: deletingItemId };
            const response = await deleteAttributeRecords(payload);
            if (response.status === 200) {

                await new Promise(resolve => setTimeout(resolve, 1000));
                setFlowData((prevData: any) => prevData.filter((dataItem: any) => dataItem[0].attributeMasterId !== deletingItemId));
                setShowDeletePopup(true);
            } else {
                setFileError("Error: " + response.statusText);
                setShowToasterFailedPopup(true);
            }
        } catch (error) {
            console.error('Error deleting flow component:', error);
            setShowToasterFailedPopup(true);
        } finally {
            setIsLoading(false); // Stop loading
            setDeletingItemId(null);
            setTimeout(() => {
                setShowDeletePopup(false);
                setShowToasterFailedPopup(false);
            }, 4000);
        }
    };

    const closeModal = () => {
        setShowDeleteModal(false);
    };

    const handleEditSave = async (newDescription: any) => {
        setEditNameError('');
        setEditFileError('');
        setEditDescriptionError('');

        let isValid = true;

        // Validate Component Name
        if (!editComponentName.trim()) {
            if (type === "Business Flow") {
                setEditNameError('Please enter a Business Flow Name');
            } else if (type === "Workflow Component") {
                setEditNameError('Please enter a Work Flow Name');
            } else {
                setEditNameError('Please enter an Architecture Name');
            }
            isValid = false;
        }

        // Validate Description
        if (!newDescription.trim().replace(textEditorRegex, "")) {
            setEditDescriptionError('Please enter the description');
            isValid = false;
        }

        const existingFileUrl = editingItem.find((attr: any) => attr.attributeName === `${type} URL`)?.attributeValue;

        if (isFileRemoved && !editFile) {
            setEditFileError('Please select a file to upload');
            isValid = false;
        } else {

            if (!editFile && !existingFileUrl) {
                setEditFileError('Please select a file to upload');
                isValid = false;
            } else if (editFile) {
                const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
                if (!allowedTypes.includes(editFile.type)) {
                    setEditFileError('Allowed file types: PNG, JPG, or JPEG');
                    isValid = false;
                } else if (editFile.size > 20 * 1024 * 1024) {
                    setEditFileError('File size should be less than 20 MB');
                    isValid = false;
                }
            }

        }
        if (!isValid) {
            return;
        }

        setShowToasterLoading(true);

        try {
            handleCloseEditModal();

            let blobUrl = existingFileUrl
            let base64Value = '';
            let fileType = '';
            let isNewFile = false;

            if (editFile) {
                blobUrl = await uploadFileToBlob1(editFile, 'flowComponents');
                base64Value = await fileToBase64(editFile);
                fileType = `.${editFile.name.split('.').pop()}`;
                isNewFile = true;
            } else if (existingFileUrl) {
                // If no new file is uploaded, fetch the existing file's data
                const response = await fetch(existingFileUrl);
                const blob = await response.blob();
                base64Value = await fileToBase64(blob);
                fileType = `.${blobUrl.split('.').pop().split('?')[0]}`;
            } else {
                // This case should not occur due to earlier validation, but just in case
                setEditFileError('File is required');
                setShowToasterLoading(false);
                return;
            }

            const payload = {
                organizationId: state.organizationId,
                verticalId: state.verticalId,
                projectId: state.projectId,
                connectorId: state.connectorId,
                connectorName: state.connectorName,
                base64Value: base64Value,
                fileType: fileType,
                attributeMasterId: editingItem[0].attributeMasterId,
                attributeRecords: [
                    {
                        attributeID: editingItem.find((attr: any) => attr.attributeName === `${type} Name`)?.attributeId,
                        attributeName: `${type} Name`,
                        attributeValue: editComponentName
                    },
                    {
                        attributeID: editingItem.find((attr: any) => attr.attributeName === `${type} URL`)?.attributeId,
                        attributeName: `${type} URL`,
                        attributeValue: blobUrl
                    },
                    {
                        attributeID: editingItem.find((attr: any) => attr.attributeName === `${type} Description`)?.attributeId,
                        attributeName: `${type} Description`,
                        attributeValue: isNewFile ? "" : escapeJsonString(newDescription.trim().replace(regex, ""))
                    }
                ]
            };
            const response = await postFlowComponents(payload);
            if (response.data && response.status === 200) {
                setShowEditSavePopup(true);
                await new Promise(resolve => setTimeout(resolve, 1000));

                // Update the flowData state with the edited data
                setFlowData((prevData: any) =>
                    prevData.map((item: any) =>
                        item[0].attributeMasterId === editingItem[0].attributeMasterId ? response.data : item
                    )
                );
                setIsFileRemoved(false);
            } else {
                setEditFileError("Error: " + response.statusText);
                setShowToasterFailedPopup(true);
            }
        } catch (error) {
            console.error('Error editing flow component:', error);
            setEditFileError("An error occurred while editing the flow component.");
            setShowToasterFailedPopup(true);
        } finally {
            setShowToasterLoading(false);
            setTimeout(() => {
                setShowEditSavePopup(false);
                setShowToasterFailedPopup(false);
            }, 4000);
        }
    };

    const renderFlowData = () => {
        return flowData.map((flowComponent: any, index: number) => {
            const flowComponentId = `flowComponent-${flowComponent[0].connectorId}-${index}`;
            const componentTitle = flowComponent.find((attr: any) => attr.attributeName === `${type} Name`)?.attributeValue;
            const bloburl = flowComponent.find((attr: any) => attr.attributeName === `${type} URL`)?.attributeValue;
            const componentDescription = flowComponent.find((attr: any) => attr.attributeName === `${type} Description`)?.attributeValue;
            return (
                <div className="border rounded-3 p-3 mb-4" key={flowComponentId}>
                    <div className="d-flex align-items-center font-16 font-medium color-black-v1">
                        <div className="d-flex align-items-center">
                            <img
                                src="images/accordion-arrow-grey.svg"
                                alt="accordion-arrow"
                                className={`cursor-pointer accordion-arrow me-2 ${index === 0 ? '' : 'collapsed'}`}
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse-${flowComponentId}`}
                                aria-expanded={index === 0 ? "true" : "false"}
                            />
                            <span className="color-black font-16 font-semibold">{componentTitle}</span>
                        </div>
                        <div className="d-flex align-items-center ms-auto">
                            <button type="button" className="btn edit-btn custom-btn font-medium" onClick={() => handleOpenEditModal(flowComponent)}>Edit</button>
                            <button type="button" className="btn btn-outline-danger custom-btn ms-3 font-medium" onClick={() => handleDeleteFlowComponent(flowComponent)}>Delete</button>
                        </div>
                    </div>
                    <div className={`px-3 collapse ${index === 0 ? 'show' : ''}`} id={`collapse-${flowComponentId}`}>
                        <div className="text-center pt-3">
                            <img
                                src={generateImageUrl(bloburl, sasToken)}
                                alt={`${type} Diagram`}
                                className="w-100"
                            />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-5 my-2 mb-4">
                            <h3 className="font-18 font-semibold color-black mb-0">
                                {title}
                            </h3>
                        </div>
                        <div>
                            <div
                                className="font-14 font-regular mb-4"
                                dangerouslySetInnerHTML={{ __html: componentDescription }}
                            />                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="tab-pane fade active show" id="business-flow" role="tabpanel">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-12 mb-5">
                    <div className="ribbon py-2 px-3 ms-md-4">
                        <span className="font-12 font-medium">Once the {title} diagram is uploaded, a description of
                            the {title} will be generated based on the uploaded image.</span>
                        <span className="ribbon-border" />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-12">
                    <div className="ps-lg-4">
                        <h3 className="font-20 font-semibold mt-1 mb-4">{title} Diagram</h3>
                        <div className="border-bottom my-4 pt-2 pb-4">
                            <div className="row row-gap-4">
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div>
                                        <label htmlFor="connectorName" className="form-label font-14 font-semibold color-black">
                                            {title} Name<span className="mandate">*</span>
                                        </label>
                                        <input type="text" className="form-control theme-form font-regular font-14" id="connectorName" placeholder={`${title} Name`} value={componentName} onChange={handleComponentNameChange} maxLength={40} />
                                    </div>
                                    {nameError && <div className="text-danger font-12 mt-1">{nameError}</div>}
                                </div>
                                <div className="col-12 col-md-6 col-lg-5">
                                    <div className="d-flex align-items-end justify-content-between">
                                        <div >
                                            <label htmlFor="connectorName" className="form-label font-14 font-semibold color-black">
                                                Upload {title}  <span className="mandate">*</span>
                                            </label>
                                            <div className="position-relative d-flex align-items-center gap-3">
                                                <label className="file-upload-outline py-2 position-absolute rounded-3 d-flex align-items-center gap-2 font-12 font-semibold">
                                                    <img src="images/upload-icon-blue.svg" alt="upload-icon" />Upload
                                                </label>
                                                <input type="file" className="form-control custom-file py-1" id="orgLogo" aria-label="upload" accept=".png,.jpg,.jpeg" onChange={(e) => handleFileChange(e)} ref={fileInputRef} />
                                                {fileName && (<span className="font-12 font-medium color-grey"> {fileName}
                                                    <img
                                                        src="images/close-icon-grey.svg"
                                                        alt="close icon"
                                                        className="ms-2 cursor-pointer"
                                                        onClick={handleRemoveFile}
                                                    />
                                                </span>)}
                                            </div>
                                            {fileError && <div className="text-danger font-12 mt-1">{fileError}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 ms-auto ms-md-0 mt-0 mt-md-auto">
                                    <button type="button" className="btn btn-dark theme-primary-btn-md d-flex gap-2 align-items-center border-0 font-14 font-semibold" onClick={handleAdd}>
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 col-md-12 col-12 mt-5 mb-5">
                    <div className="ps-lg-4">
                        {flowData && flowData.length > 0 && renderFlowData()}
                    </div>
                </div>

                {isEditPopupOpen && (
                    <>
                        <div className="modal-backdrop fade show"></div>
                        <div className="modal fade show" style={{ display: "block" }}>
                            <div className="modal-dialog modal-dialog-centered">

                                <div className="modal-content custom-popup w-650">
                                    <div className="modal-header border-0 justify-content-between align-items-center position-relative px-4 pt-4 pb-2">
                                        <h2 className="modal-title mb-0 font-20 font-semibold primary-textcolor" id="accept-promptLabel">
                                            Edit {title}</h2>
                                        <button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close"><img src="images/popup-close.svg" alt="Close" onClick={() => handleCloseEditModal()} /></button>
                                    </div>
                                    <div className="modal-body border-0 px-4">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="form-label font-14 font-medium color-black" htmlFor="assesName">{title} Name <span className="mandate">*</span></label>
                                                <input
                                                    type="text"
                                                    id="editComponentName"
                                                    className="form-control custom-form color-black"
                                                    value={editComponentName}
                                                    onChange={handleEditNameChange}
                                                    maxLength={40}
                                                />
                                                {editNameError && (
                                                    <div className="text-danger font-12 mt-1">{editNameError}</div>
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label font-14 font-medium color-black" htmlFor="assesName">Upload {title} <span className="mandate">*</span></label>
                                                <div className="position-relative d-flex align-items-center edit-architecture-popup gap-3">
                                                    <label className="file-upload-outline position-absolute rounded-3 d-flex align-items-center gap-2 font-12 font-semibold">
                                                        <img src="images/upload-icon-blue.svg" alt="upload-icon" />Upload
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control custom-file"
                                                        id="editComponentFile"
                                                        aria-label="upload"
                                                        onChange={(e) => handleFileChange(e, true)}
                                                        accept=".png,.jpg,.jpeg"
                                                        ref={fileEditInputRef}
                                                    />
                                                    {editFileName && (
                                                        <span className="font-12 font-medium color-grey fileName">
                                                            {editFileName}
                                                            <img
                                                                src="images/close-icon-grey.svg"
                                                                alt="close icon"
                                                                className="ms-3 cursor-pointer"
                                                                onClick={() => {
                                                                    setEditFile(null);
                                                                    setEditFileName('');
                                                                    setIsFileRemoved(true);
                                                                    resetEditFileInput();
                                                                }}
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                                {
                                                    editFileError && (
                                                        <div className="text-danger font-12 mt-1">{editFileError}</div>
                                                    )
                                                }
                                            </div>
                                            <div className="mt-3">
                                                <label className="form-label font-14 font-medium color-black">
                                                    Description<span className="mandate">*</span>
                                                </label>
                                                <div className="mt-2">
                                                    <TextEditor
                                                        value={editComponentDescription}
                                                        onSave={(newValue: any) => {
                                                            setEditComponentDescription(newValue);
                                                            handleEditSave(newValue);
                                                        }}
                                                        onCancel={handleClearEditModal}
                                                        summaryType={type}
                                                        verticalName={"MDPW"}
                                                    />
                                                </div>
                                                {/* {editDescriptionError && (
                                                    <div className="text-danger font-12 mt-1">{editDescriptionError}</div>
                                                )} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>)}

                {showDeleteModal && (
                    <div
                        className="modal fade show"
                        id="delete"
                        tabIndex={-1}
                        aria-hidden="true"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        style={{ display: 'block' }}
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content p-3">
                                <div className="modal-header pb-0 border-0">
                                    <button
                                        type="button"
                                        className="btn-close cursor-pointer"
                                        onClick={closeModal}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <div className="w-100 text-center mb-3">
                                        <img src="images/delete-icon.svg" alt="delete" />
                                    </div>
                                    <p className="font-semibold font-24 text-center mb-2 red-400">
                                        Delete Confirmation
                                    </p>
                                    <p className="font-semibold font-16 text-center mb-5">
                                        Are you sure you want to delete?
                                    </p>
                                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-center mb-2 mb-md-0 w-100">
                                        <a
                                            className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer px-4"
                                            onClick={confirmDeleteFlowComponent}
                                        >
                                            Yes
                                        </a>
                                        <button
                                            type="button"
                                            className="btn btn-danger red-400 btn-lg px-4 text-white font-14 font-semibold"
                                            onClick={closeModal}
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showDeleteModal && <div className="modal-backdrop fade show"></div>}





            </div>


            {showToasterLoading ? (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img
                                    src="images/loading.gif"
                                    alt="toast-success"
                                    style={{ height: "15px", width: "15px" }}
                                />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Saving...
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}


            {showDeletePopup && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/toast-success.svg" alt="toast-success" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    {title} has been deleted successfully.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {showToasterPopup && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/toast-success.svg" alt="toast-success" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    {title} has been added successfully
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {showEditSavePopup && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/toast-success.svg" alt="toast-success" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    {title} has been saved successfully
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showToasterFailedPopup && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/fail-icon.svg" alt="toast-fail" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Api failed.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
}

export default FlowComponents