import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RoadMap from "./component/RoadmapPlan";
import NavBar from "./component/NavBar";
import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import AdminNavbar from "./component/AdminNavbar";
import GenReport from "./component/GenerateReport";
import Pdf from "./component/DownloadReport";
import InitiativeForm from "./component/InitiativeForm";
import Sequence from "./component/sequencing";
import AssessmentMain from "./component/AssessmentMain";
import Loader from "./component/Loader";
import { isAuth } from "./component/Login/Auth";
import ErrorPage from "./component/ErrorPage";
import { UserDetails, encryptStorage } from "./constant/constant";
import { useMsal, useIsAuthenticated, useAccount } from "@azure/msal-react";
import axios from "axios";
import LoginPage from "./component/Login/LoginPage";
import { getReportData } from "./helpers/encryption";
import { generateAccessToken } from "./service/CommonApi";
import { jwtDecode } from "jwt-decode";
import InfraAssessment from "./component/InfraAssessment";
import InfrastructureAssessmentPage from "./component/InfrastructureAssessmentPage";
import OrganizationGrid from "./component/OrganizationGrid";
import AssessmentGrid from "./component/AssessmentGrid";
import WafrReport from "./component/WafrReport";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: any) {
  const [userData, setUserData]: any = useState({});

  const [acnt, setAcnt]: any = useState(encryptStorage.getItem("account"));

  {
    /** start */
  }
  const { instance, accounts } = useMsal();
  const location = window.location;
  // Define a list of paths where you want to hide the NavBar
  // const pathsToHideNavBar = ["/previewpage"];

  // Check if the current location pathname is in the list of paths to hide NavBar
  // const shouldHideNavBar = pathsToHideNavBar.includes(location.pathname);
  let account: any = useAccount(accounts[0] || null);

  if (account) encryptStorage.setItem("account", account);
  else account = acnt;

  // if (account || !acnt) {
  //   setAcnt(account);
  //   account = account;
  // }

  // if (acnt?.name) account = acnt;

  const token = encryptStorage.getItem("access-token");

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (account || acnt) {
      fetchUserData();
    }
  }, [account, token]);

  useEffect(() => {
    // if (!isAuth(accounts) && location.pathname != '/') {
    //   window.location.href = '/'

    // }
    // else

    if (isAuth(accounts) && location.pathname == "/") {
      window.location.href = "/homepage";
    }
  }, []);

  useEffect(() => {
    setInterval(async () => {
      if (encryptStorage.getItem("userDetails")) {
        await generateJWT(
          encryptStorage.getItem("userDetails").userName,
          encryptStorage.getItem("userDetails").email,
          encryptStorage.getItem("userDetails").role
        );
      }
    }, 840000);
  }, []);

  const fetchUserData = async () => {

    setLoader(false);
    let userDetails: any = {
      userName: account?.name,
      email: account?.username,
      role: "",
      jobTitle: "",
      profileImage: "images/default-profile-image.svg",
    };
    const accountResponse = await instance.acquireTokenSilent({
      account,
      scopes: ["User.Read"],
    });

    try {
      const profileImage = await axios.get(
        `https://graph.microsoft.com/v1.0/me/photo/$value`,
        {
          headers: {
            Authorization: `Bearer ${accountResponse.accessToken}`,
          },
          responseType: "blob",
        }
      );

      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrl = reader.result;
        userDetails.profileImage = dataUrl;
      };
      reader.readAsDataURL(profileImage.data);
    } catch (error) {
      // console.log(error);
    }

    try {
      //Check User is in leadership DL or not
      await axios.get(
        `https://graph.microsoft.com/v1.0/groups/${getReportData(
          process.env.REACT_APP_MS_ADMIN_GROUP_ID!
        )}/members/${account.localAccountId}`,
        {
          headers: {
            Authorization: `Bearer ${accountResponse.accessToken}`,
          },
        }
      );

      userDetails.role = "Admin";
    } catch (err) {
      try {
        await axios.get(
          `https://graph.microsoft.com/v1.0/groups/${getReportData(
            process.env.REACT_APP_MS_USER_GROUP_ID!
          )}/members/${account.localAccountId}`,
          {
            headers: {
              Authorization: `Bearer ${accountResponse.accessToken}`,
            },
          }
        );

        userDetails.role = "User";
      } catch (err) {
        userDetails.role = "Invalid";
      }
    }
    setUserData(userDetails);
    encryptStorage.setItem("userDetails", JSON.stringify(userDetails));
    encryptStorage.setItem("access-token", accountResponse.accessToken);
    let generatedToken = await generateAccessToken(
      userDetails.userName,
      userDetails.email,
      userDetails.role
    );
    encryptStorage.setItem("jwt", generatedToken.access_token);
    setLoader(true);
  };

  async function generateJWT(userName: string, email: string, role: string) {
    let generatedToken = await generateAccessToken(userName, email, role);
    if (generatedToken) {
      encryptStorage.setItem("jwt", generatedToken.access_token);
    }
  }

  {
    /** end */
  }

  return (
    <>
      {!loader ? (
        <Loader isLoading={!loader} />
      ) : (
        <MsalProvider instance={pca}>
          <UserDetails.Provider value={{ userData, setUserData }}>
            <Router>
              {userData.role && userData.role != "Invalid" && <NavBar />}
              <Routes>
                <Route path="/" element={<LoginPage />} />

                {userData.role == "Admin" && (
                  <Route path="/adminmodule" element={<AdminNavbar />}></Route>
                )}

                {userData.role && userData.role != "Invalid" && (
                  <>
                    <Route path="/homepage" element={<OrganizationGrid userData={userData}/>} />
                    <Route path="/manageassessment" element={<AssessmentGrid/>}/>
                    <Route path="/newinitiative" element={<InitiativeForm />} />
                    <Route path="/sequencing" element={<Sequence />} />
                    <Route path="/assessment" element={<AssessmentMain />} />
                    <Route path="/reports" element={<GenReport />} />
                    <Route path="/previewpage" element={<Pdf />} />
                    <Route path="/roadmapplanning" element={<RoadMap />} />
                    <Route path="/infraassessment" element={<InfraAssessment />} />
                    <Route path="/infraassessmentselection" element={<InfrastructureAssessmentPage/>}/>
                    <Route path="/wafrReport" element={<WafrReport/>}/>
                  </>
                )}

                {userData.role != "" && (
                  <Route path="*" element={<ErrorPage />} />
                )}
              </Routes>
            </Router>
          </UserDetails.Provider>
        </MsalProvider>
      )}
    </>
  );
}

export default App;
