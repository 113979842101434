import { useEffect, useState } from "react";
import { getMigrationPlans } from "../service/InfraAssessmentApi";
import { getSasToken } from "../service/ReportApi";
import moment from "moment";

import {
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { BarChart, Bar, XAxis, YAxis, LabelList, Text } from "recharts";
import { PricingModel } from "../interface/CostBreakdownModel";

const VMwareDownloadReport = (props: any) => {
  const reportData = props.props;

  const migrationPlanData = reportData.migrationPlan;

  const [minDateAndRoundValue, setMinDateAndRoundValue] = useState<any>({
    minDate: new Date(),
    weekNumber: 1,
  });
  const [weeks, setWeeks] = useState<string[]>([]);

  useEffect(() => {
    const weekData = updateWeeks();
    setMinDateAndRoundValue(weekData);
  }, [migrationPlanData]);

  const extractDatesFromProcesses = (data: any) => {
    let datesArray: string[] = [];

    data?.forEach((phase: any) => {
      phase.processTimeLine?.forEach((process: any) => {
        let startDate = moment(process.startWeek);
        let endDate = moment(process.endWeek);
        datesArray.push(startDate.format("YYYY-MM-DD"));
        datesArray.push(endDate.format("YYYY-MM-DD"));
      });
    });
    return datesArray;
  };

  const updateWeeks = () => {
    const allDates = extractDatesFromProcesses(migrationPlanData);

    const uniqueDates = Array.from(new Set(allDates));
    const dateObjects: Date[] = uniqueDates?.map((date) => new Date(date));

    let minDate = new Date(dateObjects[0]);
    let maxDate = new Date(dateObjects[0]);

    dateObjects.forEach((date) => {
      if (date < minDate) minDate = new Date(date);
      if (date > maxDate) maxDate = new Date(date);
    });

    let weeks: string[] = [];
    let currentDate: Date = new Date(minDate);
    const millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7; // Milliseconds in a week
    // const weeksBetween = Math.ceil((maxDate.getTime() - minDate.getTime()) / millisecondsPerWeek);
    const weeksBetween = getWeekIndex(maxDate, minDate);

    let roundedIncrement;

    if (weeksBetween % 4 != 0) {
      roundedIncrement = Math.ceil(weeksBetween / 4);
    } else {
      roundedIncrement = Math.round(weeksBetween / 4);
    }

    let weekNumber: number;
    if (roundedIncrement == 0) {
      roundedIncrement = 1;
      weekNumber = roundedIncrement;
    } else {
      weekNumber = roundedIncrement;
    }

    for (let i = 1; i <= 4; i++) {
      weeks.push(`week ${weekNumber}`);
      currentDate.setDate(currentDate.getDate() + 7);
      weekNumber += roundedIncrement;
    }

    setWeeks(weeks);
    return { minDate: minDate, weekNumber: roundedIncrement };
  };

  const differenceInDays = (dateA: Date, dateB: Date): number => {
    const timeDifference = dateA.getTime() - dateB.getTime();
    return Math.abs(Math.round(timeDifference / (1000 * 3600 * 24)));
  };

  const getWeekIndex = (date: Date, minDate: Date): number => {
    const diffDays = differenceInDays(date, minDate);
    const diffWeeks = Math.ceil(diffDays / 6);
    return diffWeeks > 0 ? diffWeeks : 1;
  };

  const getWeekRange = (
    startDate: Date,
    endDate: Date,
    process: any,
    minDate: Date,
    weekNumber: number
  ): any => {
    const startWeekIndex = getWeekIndex(startDate, minDate);
    const endWeekIndex = getWeekIndex(endDate, minDate);

    if (startWeekIndex !== -1 && endWeekIndex !== -1) {
      const totalBlocks = 4;
      const weeksPerBlock = weekNumber;

      const startBlock = Math.floor((startWeekIndex - 1) / weeksPerBlock);
      const endBlock = Math.floor((endWeekIndex - 1) / weeksPerBlock);

      const marginLeft = (startBlock / totalBlocks) * 100;
      const marginRight = ((totalBlocks - endBlock - 1) / totalBlocks) * 100;

      if (startWeekIndex === endWeekIndex) {
        return {
          weekRange: `Week ${startWeekIndex}`,
          marginLeft: `${marginLeft}%`,
          marginRight: `${marginRight}%`,
        };
      } else {
        return {
          weekRange: `Week ${startWeekIndex} - Week ${endWeekIndex}`,
          marginLeft: `${marginLeft}%`,
          marginRight: `${marginRight}%`,
        };
      }
    } else {
      return {};
    }
  };

  const handleBindingWeekRange = (process: any, index: any) => {
    const startDate = new Date(process.startWeek);
    const endDate = new Date(process.endWeek);

    const weekData = getWeekRange(
      startDate,
      endDate,
      process,
      minDateAndRoundValue.minDate,
      minDateAndRoundValue.weekNumber
    );

    return (
      <td
        colSpan={4}
        style={{
          width: "58%",
          borderRight: "1px solid #E7E7E7",
          padding: "1%",
          textAlign: "left",
          verticalAlign: "top",
          fontSize: "10px",
          fontFamily: '"Roboto"',
          fontWeight: 600,
          color: "#242424",
          lineHeight: "1.5",
        }}
      >
        <div
          style={{
            borderLeft: "4px solid #0F7FAF",
            borderRadius: "5px",
            backgroundColor: "#F1FBFF",
            padding: "10px 16px",
            marginLeft: weekData.marginLeft,
            marginRight: weekData.marginRight,
          }}
        >
          <span>{weekData.weekRange}</span>
        </div>
      </td>
    );
  };

  const [initialSummaryData, setinitialSummaryData] = useState({
    executiveSummary: "",
    businessOverview: "",
    recommendationBreakdown: "",
  });
  const [strategyData, setstrategyData] = useState(
    reportData?.vmWareRecommendations
  );

  useEffect(() => {
    reportData?.infraSummary?.forEach((attributes: any) => {
      switch (attributes.attributeName) {
        case "Executive Summary":
          setinitialSummaryData((prev) => ({
            ...prev,
            executiveSummary: attributes?.attributeValue,
          }));
          break;

        case "Business Overview":
          setinitialSummaryData((prev) => ({
            ...prev,
            businessOverview: attributes?.attributeValue,
          }));
          break;

        case "Recommendation Breakdown":
          setinitialSummaryData((prev) => ({
            ...prev,
            recommendationBreakdown: attributes?.attributeValue,
          }));
          break;

        default:
          setinitialSummaryData((prev) => ({
            ...prev,
            recommendationBreakdown:
              reportData?.vmWareRecommendations?.strategySummary,
          }));
          break;
      }
    });
  }, [reportData]);

  const ExecutiveSummary = () => {
    return (
      <>
        {/*Business Overview Header starts here */}
        <div
          style={{
            width: "98%",
            justifyContent: "center",
            padding: "1.5% 1%",
            backgroundColor: "#F5F5F5",
          }}
        >
          <h5
            style={{
              color: "#050505",
              fontFamily: "Arial",
              fontSize: "15px",
              fontWeight: 600,
              margin: "0px",
              textAlign: "center",
              wordSpacing: "4px",
            }}
          >
            Executive Summary
          </h5>
        </div>
        {/*Business Overview Header  ends here */}
        <div style={{ width: "98%", float: "left", margin: "1% 0%" }}>
          {/* Main header style */}
          <p
            style={{
              fontFamily: "Arial",
              color: "#000",
              fontWeight: 500,
              fontSize: "11px",
              margin: "3% 0% 2% 0%",
              lineHeight: "20px",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: initialSummaryData?.executiveSummary }} />
          </p>
        </div>
      </>
    );
  };

  const BusinessOverview = () => {
    return (
      <>
        {/*Business Overview Header starts here */}
        <div
          style={{
            width: "98%",
            justifyContent: "center",
            padding: "1.5% 1%",
            backgroundColor: "#F5F5F5",
          }}
        >
          <h5
            style={{
              color: "#050505",
              fontFamily: "Arial",
              fontSize: "15px",
              fontWeight: 600,
              margin: "0px",
              textAlign: "center",
              wordSpacing: "4px",
            }}
          >
            Business Overview
          </h5>
        </div>
        {/*Business Overview Header  ends here */}
        <div style={{ width: "98%", float: "left", margin: "1% 0%" }}>
          {/* Main header style */}
          <p
            style={{
              fontFamily: "Arial",
              color: "#000",
              fontWeight: 500,
              fontSize: "11px",
              margin: "3% 0% 2% 0%",
              lineHeight: "20px",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: initialSummaryData?.businessOverview }} />
          </p>
        </div>
      </>
    );
  };

  const renderPieChart = (
    pieChartMockData: any,
    vMwareStats: any,
    renderCustomizedLegend: any
  ) => {
    const totalValue = pieChartMockData.reduce(
      (sum: number, item: any) => sum + item.value,
      0
    );

    const dataToRender =
      totalValue === 0
        ? [{ label: "No Data", value: 1, color: "#E0E0E0" }]
        : pieChartMockData;

    return (
      <div style={{ width: 300, height: 350, marginLeft: "-50px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={dataToRender}
              cx="50%"
              cy="50%"
              innerRadius={90}
              outerRadius={110}
              dataKey="value"
              startAngle={90}
              endAngle={-270}
            >
              {dataToRender.map((entry: any, index: any) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
              <Label
                content={({ viewBox: { cx, cy } }: any) => (
                  <g>
                    <text
                      x={cx}
                      y={cy - 30}
                      textAnchor="middle"
                      dominantBaseline="central"
                      style={{
                        fontSize: "14px",
                        fill: "#000",
                        fontWeight: "bold",
                      }}
                    >
                      Total VM Count
                    </text>
                    <text
                      x={cx}
                      y={cy}
                      textAnchor="middle"
                      dominantBaseline="central"
                      style={{ fontSize: "24px", fontWeight: "bold" }}
                    >
                      {vMwareStats.totalVmCount || 0}
                    </text>
                  </g>
                )}
                position="center"
              />
            </Pie>
            <Legend
              content={renderCustomizedLegend}
              verticalAlign="bottom"
              height={36}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  };
  const calculateYAxisMax = (maxValue: number) => {
    if (maxValue === 0) return 100;

    let roundedUp = Math.ceil(maxValue / 10) * 10;
    if (roundedUp === 10) {
      roundedUp = 20
    }


    return Math.ceil(roundedUp);
  };

  const renderVerticalBarChart = (data: any, CustomXAxisTick: any) => {
    const safeData = Array.isArray(data) ? data : [];

    const chartData = safeData.length > 0 ? safeData : [{ name: "", value: 0 }];

    const maxValue = Math.max(...chartData.map((item: any) => item.value || 0));
    const yAxisMaxValue = calculateYAxisMax(maxValue);
    // const yAxisMaxValue = maxValue === 0 ? 100 : Math.ceil(maxValue / 20) * 20;

    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis
            dataKey="name"
            tick={<CustomXAxisTick />}
            height={100}
            interval={0}
            axisLine={true}
            tickLine={false}
            tickFormatter={(value) => value}
            style={{
              fontSize: "12px",
              fontWeight: "bold",
            }}
          />
          <YAxis
            axisLine={true}
            tickLine={false}
            domain={[0, yAxisMaxValue]}
            tickFormatter={(value) => value.toString()}
            style={{
              fontSize: "12px",
              fontWeight: "bold",
            }}
            scale="linear"
            // allowDataOverflow={true}
            ticks={[0, Math.round(yAxisMaxValue / 4), Math.round(yAxisMaxValue / 2), Math.round(yAxisMaxValue * 3 / 4), yAxisMaxValue]}
          />
          <Bar dataKey="value" fill="#acbcfc" barSize={40} minPointSize={2}>
            <LabelList dataKey="value" position="top" fill="#000" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  };


  const renderHorizontalBarChart = (data: any, CustomYAxisTick: any) => {
    const safeData = Array.isArray(data) ? data : [];

    const chartData =
      safeData.length > 0
        ? safeData
        : [
          { name: "No Data", value: 0 },
          { name: " ", value: 0 },
        ];

    const maxValue = Math.max(...chartData.map((item: any) => item.value || 0));
    // const xAxisMaxValue = maxValue === 0 ? 100 : Math.ceil(maxValue / 20) * 20;
    const xAxisMaxValue = calculateYAxisMax(maxValue);
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData}
          layout="vertical"
          margin={{
            top: -15,
            right: 50,
            left: 65,
            bottom: 5,
          }}
        >
          <XAxis
            type="number"
            // domain={[0, xAxisMaxValue]}
            // ticks={[0, 20, 40, 60, 80, 100].filter(
            //   (tick) => tick <= xAxisMaxValue
            // )}
            axisLine={true}
            tickLine={false}
            style={{
              fontSize: "12px",
              fontWeight: "bold",
            }}
            tickFormatter={(value) => `${value}`}
            domain={[0, xAxisMaxValue]}
            ticks={[0, Math.round(xAxisMaxValue / 4), Math.round(xAxisMaxValue / 2), Math.round(xAxisMaxValue * 3 / 4), xAxisMaxValue]}
          />
          <YAxis
            dataKey="name"
            type="category"
            axisLine={true}
            tickLine={false}
            tick={<CustomYAxisTick />}
            width={5}
            tickMargin={10}
            interval={0}

          />
          <Bar dataKey="value" fill="#acbcfc" barSize={40} minPointSize={2}>
            <LabelList dataKey="value" position="right" fill="#000" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  };

  // const renderCustomizedLegend = (props: any) => {
  //   const { payload } = props;
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         gap: "80px",
  //       }}
  //     >
  //       {payload.map((entry: any, index: any) => (
  //         <div
  //           key={`item-${index}`}
  //           style={{ display: "flex", alignItems: "center" }}
  //         >
  //           <span
  //             style={{
  //               display: "inline-block",
  //               width: "12px",
  //               height: "12px",
  //               backgroundColor: entry.color,
  //               marginRight: "8px",
  //               borderRadius: "50%",
  //             }}
  //           ></span>
  //           <span style={{ fontSize: "14px" }}>{entry.payload.label}</span>
  //           <span
  //             style={{
  //               marginLeft: "8px",
  //               fontWeight: "bold",
  //               fontSize: "14px",
  //             }}
  //           >
  //             {entry.payload.value}
  //           </span>
  //         </div>
  //       ))}
  //     </div>
  //   );
  // };

  const renderCustomizedLegend = (props: any) => {
    const { payload } = props;
    return (

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "80px",
        }}
      >
        {payload.map((entry: any, index: any) => (
          <div
            key={`item-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                backgroundColor: entry.color,
                marginRight: "8px",
                borderRadius: "50%",
                flexShrink: 0, // Prevent the color dot from shrinking
              }}
            ></span>
            <span
              style={{
                fontSize: "14px",
                whiteSpace: "nowrap", // Prevent text wrapping
                overflow: "hidden", // Hide overflowing text
                textOverflow: "ellipsis", // Show ellipsis for overflowing text
                maxWidth: "calc(100% - 20px)", // Adjust based on the width of the color dot and spacing
              }}
            >
              {entry.payload.label}
            </span>
            <span
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontSize: "14px",
                flexShrink: 0, // Prevent the value from shrinking
              }}
            >
              {entry.payload.value}
            </span>
          </div>
        ))}
      </div>
    );
  };

  // const CustomXAxisTick = ({ x, y, payload }: any) => {
  //   const words = payload.value.split(" ");
  //   const maxWordsPerLine = 2;
  //   const lines = [];

  //   for (let i = 0; i < words.length; i += maxWordsPerLine) {
  //     lines.push(words.slice(i, i + maxWordsPerLine).join(" "));
  //   }

  //   return (
  //     <g transform={`translate(${x},${y})`}>
  //       {lines.map((line, index) => (
  //         <Text
  //           key={index}
  //           x={0}
  //           y={index * 20}
  //           dy={16}
  //           textAnchor="middle"
  //           fill="#000"
  //           fontSize={12}
  //           fontWeight="bold"
  //         >
  //           {line}
  //         </Text>
  //       ))}
  //     </g>
  //   );
  // };

  const CustomXAxisTick = ({ x, y, payload }: any) => {
    const words = payload.value.split(" ");
    const maxWordsPerLine = 2;
    const lines = [];

    for (let i = 0; i < words.length; i += maxWordsPerLine) {
      lines.push(words.slice(i, i + maxWordsPerLine).join(" "));
    }

    return (
      <g transform={`translate(${x},${y})`}>
        {lines.map((line, index) => (
          <Text
            key={index}
            x={0}
            y={index * 20}
            dy={16}
            textAnchor="middle"
            fill="#000"
            fontSize={12}
            fontWeight="bold"
          >
            {line}
          </Text>
        ))}
      </g>
    );
  };

  // const CustomYAxisTick = ({ x, y, payload }: any) => {
  //   const words = payload.value.split(" ");
  //   const maxWordsPerLine = 2;
  //   const lines = [];

  //   for (let i = 0; i < words.length; i += maxWordsPerLine) {
  //     lines.push(words.slice(i, i + maxWordsPerLine).join(" "));
  //   }

  //   return (
  //     <g transform={`translate(${x},${y})`}>
  //       {lines.map((line, index) => (
  //         <Text
  //           key={index}
  //           x={-10}
  //           y={index * 50}
  //           dy={4}
  //           textAnchor="end"
  //           fill="#000"
  //           fontSize={12}
  //           fontWeight="bold"
  //         >
  //           {line}
  //         </Text>
  //       ))}
  //     </g>
  //   );
  // };
  const CustomYAxisTick = ({ x, y, payload }: any) => {
    const words = payload.value.split(" ");
    const maxWordsPerLine = 2;
    const lines = [];

    for (let i = 0; i < words.length; i += maxWordsPerLine) {
      lines.push(words.slice(i, i + maxWordsPerLine).join(" "));
    }

    return (
      <g transform={`translate(${x},${y})`}>
        {lines.map((line, index) => (
          <Text
            key={index}
            x={-10}
            y={index * 50}
            dy={4}
            textAnchor="end"
            fill="#000"
            fontSize={12}
            fontWeight="bold"
          >
            {line}
          </Text>
        ))}
      </g>
    );
  };

  const DiscoveryGraphComponent = () => {
    let vMwareStats = reportData?.vMwareStats;

    const [selectedCluster, setSelectedCluster] = useState(
      reportData?.vMwareStats?.totalClusters[0]?.clusterName || ""
    );
    const selectedClusterData = reportData?.vMwareStats?.totalClusters.find(
      (cluster: any) => cluster?.clusterName === selectedCluster
    );

    const pieChartMockData = [
      {
        label: "Power on",
        value: reportData?.vMwareStats?.totalPowerOn,
        color: "#9FF5A2",
      },
      {
        label: "Power off",
        value: reportData?.vMwareStats?.totalPowerOff,
        color: "#FFA79D",
      },
    ];

    const barGraphMockData = reportData?.vMwareStats?.totalVmOscount?.map(
      (item: any) => ({
        name: item?.osName,
        value: item?.osByVm,
      })
    );

    const totalNodes = reportData?.vMwareStats?.totalClusters?.reduce(
      (acc: any, cluster: any) => acc + cluster.totalNoOfNodes,
      0
    );

    const totalClustersAndNodes = [
      { name: "Cluster", value: reportData?.vMwareStats?.totalNoOfCluster },
      { name: "Node", value: totalNodes },
    ];

    const barGraphData = selectedClusterData?.vmHosts?.map((item: any) => ({
      name: item?.hostname,
      value: item?.vmcount,
    }));

    useEffect(() => {
      if (reportData?.vMwareStats?.totalClusters?.length > 0) {
        setSelectedCluster(reportData.vMwareStats.totalClusters[0].clusterName);
      }
    }, [reportData]);

    const dropdownGraps = () => {
      return reportData?.vMwareStats?.totalClusters?.map(
        (cluster: any, index: number) => {
          return (
            <div
              key={cluster.clusterName}
              className="col-lg-12 col-xl-12 col-md-12 col-12 mb-4 end-page-pdf new-page-pdf"
              //   style={{
              //     width: "100%",
              //     float: "left",
              //     marginTop: "2%",
              //     padding: "2%",
              //     textAlign: "center",
              //     border: "1px solid #E3E3E3",
              //     borderRadius: "0.5rem",
              //     margin: "1% 0%",
              //     marginBottom: "300px",
              //     pageBreakInside: "avoid",
              //     pageBreakAfter: "always",
              //     pageBreakBefore: "always",
              //   }}
              style={{
                width: "99%",
                float: "left",
                margin: "1% 0%",
                paddingRight: "2%",
              }}
            >
              <div
                // className="d-flex justify-content-between align-items-center mb-4"
                style={{
                  border: "1px solid #E3E3E3",
                  borderRadius: 6,
                  padding: "2% 4%",
                  width: "94%",
                }}
              >
                <h5
                  //    className="font-semibold font-18 color-black mb-0"
                  // style={{
                  //   float: "left",
                  //   fontFamily: "Arial",
                  //   color: "#000000",
                  //   fontWeight: 600,
                  //   fontSize: "17px",
                  //   marginTop: 0,
                  //   marginBottom: "35px",
                  //   textAlign: "left",
                  // }}
                  style={{
                    color: "#000",
                    fontFamily: "Arial",
                    fontSize: 13,
                    fontWeight: "bold",
                    marginTop: 10,
                  }}
                >
                  Total no of Nodes for Cluster - {cluster.clusterName}
                </h5>
                <div
                  className="w-100 d-flex justify-content-center align-items-center mt-4 totalClustersAndNodesElement"
                //   style={{ height: "400px" }}
                >
                  {renderVerticalBarChart(barGraphData, CustomXAxisTick)}
                </div>
              </div>
            </div>
          );
        }
      );
    };

    return (
      <>
        <div style={{ width: "100%", float: "left", margin: "1% 0%" }}>
          {/* Main header style */}
          <div
            style={{
              width: "48%",
              float: "left",
              margin: "1% 0%",
              paddingRight: "2%",
            }}
          >
            <div
              style={{
                border: "1px solid #E3E3E3",
                borderRadius: 6,
                padding: "2% 4%",
                width: "94%",
              }}
            >
              <h5
                style={{
                  color: "#000",
                  fontFamily: "Arial",
                  fontSize: 13,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Count of Cluster & Node
              </h5>
              {/* <img
                src="Images/sample-chart.svg"
                alt="sample-chart"
                style={{ width: "100%", height: 350 }}
              /> */}
              <div style={{ width: "100%", height: 320 }}>
                {renderHorizontalBarChart(
                  totalClustersAndNodes,
                  CustomYAxisTick
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "48%",
              float: "left",
              margin: "1% 0%",
              paddingLeft: "2%",
            }}
          >
            <div
              style={{
                border: "1px solid #E3E3E3",
                borderRadius: 6,
                padding: "2%  4%",
                width: "94%",
                float: "left",
              }}
            >
              <h5
                style={{
                  color: "#000",
                  fontFamily: "Arial",
                  fontSize: 13,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                VM Status
              </h5>
              {/* <img
                src="Images/sample-donut-chart.svg"
                alt="sample-chart"
                style={{ width: "100%", height: 320, float: "left" }}
              /> */}
              <div style={{ width: "100%", height: 320, float: "left" }}>
                {renderPieChart(
                  pieChartMockData,
                  vMwareStats,
                  renderCustomizedLegend
                )}
              </div>
            </div>
          </div>
          {/* Dropdown and Vertical Bar Chart */}
          <div style={{ width: "100%", height: 350 }}>{dropdownGraps()}</div>
          <div
            style={{
              width: "99%",
              float: "left",
              margin: "1% 0%",
              paddingRight: "2%",
            }}
          >
            <div
              style={{
                border: "1px solid #E3E3E3",
                borderRadius: 6,
                padding: "2% 4%",
                width: "94%",
              }}
            >
              <h5
                style={{
                  color: "#000",
                  fontFamily: "Arial",
                  fontSize: 13,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                VM Count by OS
              </h5>
              {/* <img
                src="Images/sample-chart.svg"
                alt="sample-chart"
                style={{ width: "100%", height: 350 }}
              /> */}
              <div style={{ width: "100%", height: 350 }}>
                {renderVerticalBarChart(barGraphMockData, CustomXAxisTick)}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const discoveryGraph = () => {
    return (
      <>
        {/*Business Overview Header starts here */}
        <div
          style={{
            width: "98%",
            justifyContent: "center",
            padding: "1.5% 1%",
            backgroundColor: "#F5F5F5",
          }}
        >
          <h5
            style={{
              color: "#050505",
              fontFamily: "Arial",
              fontSize: "15px",
              fontWeight: 600,
              margin: "0px",
              textAlign: "center",
              wordSpacing: "4px",
            }}
          >
            Discovery - Graph
          </h5>
          <div
            style={{
              width: "100%",
              float: "left",
              margin: "1% 0%",
              pageBreakInside: "avoid",
            }}
          >
            <div style={{ width: "98%", float: "left", margin: "1% 0%" }}>
              {/* Main header style */}

              {DiscoveryGraphComponent()}
            </div>
          </div>
        </div>
      </>
    );
  };

  const recommendationBreakdown = () => {
    return (
      <>
        {/*Recommendation Breakdown Header starts here */}
        <div
          style={{
            width: "98%",
            justifyContent: "center",
            padding: "1.5% 1%",
            backgroundColor: "#F5F5F5",
          }}
        >
          <h5
            style={{
              color: "#050505",
              fontFamily: "Arial",
              fontSize: "15px",
              fontWeight: 600,
              margin: "0px",
              textAlign: "center",
              wordSpacing: "4px",
            }}
          >
            Recommendation Breakdown
          </h5>
        </div>
        {/*Recommendation Breakdown Header  ends here */}
        <div style={{ width: "98%", float: "left", margin: "1% 0%" }}>
          {/* Main header style */}
          <p
            style={{
              fontFamily: "Arial",
              color: "#000",
              fontWeight: 500,
              fontSize: "11px",
              margin: "3% 0% 2% 0%",
              lineHeight: "20px",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: initialSummaryData?.recommendationBreakdown }} />
          </p>
        </div>
      </>
    );
  };

  const CostBreakdownComponent = () => {
    let costBreakdownInfo = reportData.costBreakdownDetails;
    const [sampleData, setSampleData] = useState<PricingModel[]>([]);

    useEffect(() => {
      setSampleData(costBreakdownInfo || []);
    }, [costBreakdownInfo]);

    const order = [
      "On Demand",
      "Pay as you Go",
      "Saving Plans",
      "Reservation",
      "Reserved Instance",
      "Reserved Instances",
    ];
    const sortPricingModels = (a: PricingModel, b: PricingModel) => {
      return order.indexOf(a.pricingModel) - order.indexOf(b.pricingModel);
    };

    const renderTable = (pricingModel: PricingModel) => {
      return pricingModel.paymentOptions.map((paymentOption) => {
        const durationsSet = new Set(
          paymentOption.categoryDetails.map((detail) => detail.duration)
        );
        const durations = Array.from(durationsSet).sort((a, b) => {
          const aYear = parseInt(a.split(" ")[0], 10);
          const bYear = parseInt(b.split(" ")[0], 10);
          return isNaN(aYear) || isNaN(bYear) ? 0 : aYear - bYear;
        });

        const VMwarecategories = ["Compute", "Network & Monitoring"];

        const getCategoryDetail = (category: string, duration: string) => {
          return (
            paymentOption.categoryDetails.find(
              (detail) =>
                detail.category === category && detail.duration === duration
            ) || { category, duration, cost: "0" }
          );
        };

        const calculateTotal = (duration: string) => {
          return VMwarecategories.reduce((total, category) => {
            const detail = getCategoryDetail(category, duration);
            return total + parseFloat(detail.cost || "0");
          }, 0);
        };

        return (
          <div key={paymentOption.paymentOption}>
            <h3
              //   className="mt-4 font-20 mb-3 font-semibold"
              style={{
                marginTop: "50px",
                fontSize: "20px",
                marginBottom: "1rem",
                fontFamily: "SFProText-Semibold",
              }}
            >
              {pricingModel.pricingModel} - {paymentOption.paymentOption}
            </h3>
            <div
              // className="table-responsive mb-4 mt-2"
              style={{ width: "max-content", minWidth: "100%" }}
            >
              <table
                //   className="table table-bordered themeTable alter-typo custom-table"
                style={{
                  borderTop: "1px",
                  borderLeft: "1px",
                  borderRight: "1px",
                  borderBottom: "1px",
                  borderWidth: "0 2px",
                  borderColor: "gray",
                  width: "fit-content",
                }}
              >
                <thead
                  // className="font-medium"
                  style={{ fontFamily: "SFProText-Medium" }}
                >
                  <tr>
                    <th
                      //   className="fw-unset"
                      style={{
                        color: "#454545",
                        fontSize: "14px",
                        padding: "14px 40px",
                        backgroundColor: "#F0F0F0",
                        whiteSpace: "nowrap",
                        fontWeight: "unset",
                      }}
                    >
                      Category
                    </th>
                    {durations.map((duration) => (
                      <th
                        key={duration}
                        //   className="fw-unset"
                        style={{
                          color: "#454545",
                          fontSize: "14px",
                          padding: "14px 40px",
                          backgroundColor: "#F0F0F0",
                          whiteSpace: "nowrap",
                          fontWeight: "unset",
                        }}
                      >
                        {duration}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody
                  // className="font-regular"
                  style={{
                    fontFamily: "SFProText-Regular",
                    padding: "14px 40px",
                  }}
                >
                  {VMwarecategories.map((category) => (
                    <tr key={category}>
                      <td style={{ padding: "14px 40px" }}>{category}</td>
                      {durations.map((duration) => {
                        const detail = getCategoryDetail(category, duration);
                        return (
                          <td
                            key={duration}
                            //   className="text-end"
                            style={{ textAlign: "right", padding: "14px 40px" }}
                          >
                            {/* $ {parseFloat(detail.cost).toFixed(2)} */}$
                            {parseFloat(detail.cost).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                  <tr>
                    <td style={{ padding: "14px 40px" }}>Total</td>
                    {durations.map((duration) => (
                      <td
                        key={duration}
                        //   className="text-end font-semibold"
                        style={{
                          textAlign: "right",
                          fontFamily: "SFProText-Semibold",
                          padding: "14px 40px",
                        }}
                      >
                        {/* $ {calculateTotal(duration).toFixed(2)} */}$
                        {calculateTotal(duration).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );
      });
    };

    const renderContent = () => {
      return sampleData.sort(sortPricingModels).map((pricingModel) => {
        if (pricingModel.pricingModel === "On Demand" || pricingModel.pricingModel === "Pay as you Go") {
          const onDemandCost =
            pricingModel.paymentOptions[0].categoryDetails[0].cost;
          return (
            <>
              {/* <h3
                //   className="font-20 mb-3 font-semibold"
                style={{
                  fontSize: "20px",
                  marginBottom: "1rem",
                  fontFamily: "SFProText-Semibold",
                  marginTop: "50px",
                }}
              >
                {pricingModel.pricingModel}
              </h3> */}
              <div
                key={pricingModel.pricingModel}
                //   className="payment-section mt-4 text-center"
                style={{
                  border: "1px solid #e4e4e4",
                  width: "fit-content",
                  borderRadius: "8px",
                  padding: "16px",
                  marginLeft: "15px",
                  marginTop: "15px",
                }}
              >
                <p
                  //   className="font-32 font-bold mb-0"
                  style={{
                    fontSize: "32px",
                    fontFamily: "SFProText-Bold",
                    marginBottom: "0",
                  }}
                >
                  {/* $ {parseFloat(onDemandCost).toFixed(2)} */}
                  {parseFloat(onDemandCost).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
                <p
                  // className="font-14 mt-1 mb-0 color-grey-v8 font-medium"
                  style={{
                    fontSize: "14px",
                    marginTop: "0.25rem",
                    marginBottom: "0",
                    color: "#616161",
                    fontFamily: "SFProText-Medium",
                  }}
                >
                  Pay as you Go
                </p>
              </div>
            </>
          );
        } else {
          return (
            <div key={pricingModel.pricingModel}>
              {renderTable(pricingModel)}
            </div>
          );
        }
      });
    };

    return (
      <div>
        <h3
          //   className="font-18 font-semibold color-black"
          style={{
            // fontSize: "24px",
            fontFamily: "SFProText-Semibold",
            color: "#000000",
          }}
        >
          {reportData?.vmWareRecommendations?.strategyName}
        </h3>
        <div
          // className="col-lg-12 col-xl-12 col-md-12 col-12 my-4"
          style={{
            flex: "0 0 auto",
            width: "100%",
            marginTop: "1.5px",
            marginBottom: "1.5px",
          }}
        >
          {renderContent()}
        </div>
      </div>
    );
  };

  const costBreakdown = () => {
    return (
      <>
        {/*Cost breakdown Header starts here */}
        <div
          style={{
            width: "98%",
            justifyContent: "center",
            padding: "1.5% 1%",
            backgroundColor: "#F5F5F5",
          }}
        >
          <h5
            style={{
              color: "#050505",
              fontFamily: "Arial",
              fontSize: "15px",
              fontWeight: 600,
              margin: "0px",
              textAlign: "center",
              wordSpacing: "4px",
            }}
          >
            Cost Breakdown
          </h5>
        </div>
        {/*Business Overview Header  ends here */}
        <div style={{ width: "98%", float: "left", margin: "1% 0%" }}>
          {/* Main header style */}
          <p
            style={{
              fontFamily: "Arial",
              color: "#000",
              fontWeight: 500,
              fontSize: "11px",
              margin: "3% 0% 2% 0%",
              lineHeight: "20px",
            }}
          >
            {CostBreakdownComponent()}
          </p>
        </div>
      </>
    );
  };

  const Roadmap = () => {
    return (
      <>
        {/*AWS Well-Architected Framework - Findings Header starts here */}
        <div
          style={{
            width: "98%",
            justifyContent: "center",
            padding: "12px 8px",
            backgroundColor: "#F5F5F5",
          }}
        >
          <h5
            style={{
              color: "#050505",
              fontFamily: "Arial",
              fontSize: "15px",
              fontWeight: 600,
              margin: "0px",
              textAlign: "center",
              wordSpacing: "4px",
            }}
          >
            Roadmap
          </h5>
        </div>
        {/*AWS Well-Architected Framework - Findings Header  ends here */}
        <div style={{ width: "96%", float: "left", padding: "16px" }}>
          {/* <h5 style="color: #000; font-family: Arial ; font-size: 13px; font-weight: bold; margin-top:0px;">Migration Strategy Table</h5> */}
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "16px%",
            }}
          >
            <thead style={{ backgroundColor: "#F0F0F0" }}>
              {
                <tr style={{ borderBottom: "1px solid #E7E7E7" }}>
                  <th
                    style={{
                      width: "18%",
                      textAlign: "left",
                      color: "#454545",
                      fontSize: "11px",
                      fontFamily: '"Roboto"',
                      padding: "1%",
                    }}
                  >
                    Phase
                  </th>
                  <th
                    style={{
                      width: "18%",
                      textAlign: "left",
                      color: "#454545",
                      fontSize: "11px",
                      fontFamily: '"Roboto"',
                      padding: "1%",
                    }}
                  >
                    Process
                  </th>
                  {weeks?.map((week, index, processWeek) => (
                    <th
                      key={index}
                      style={{
                        width: "13%",
                        textAlign: "left",
                        color: "#454545",
                        fontSize: "11px",
                        fontFamily: '"Roboto"',
                        padding: "1%",
                        whiteSpace: "nowrap",
                        borderRight:
                          processWeek.length - 1 === index
                            ? "1px solid #E7E7E7"
                            : "",
                      }}
                    >
                      {week}
                    </th>
                  ))}
                </tr>
              }
            </thead>
            <tbody>
              {migrationPlanData &&
                migrationPlanData?.map((phaseData: any, phaseIndex: number) =>
                  phaseData?.processTimeLine?.map(
                    (process: any, processIndex: number) => (
                      <tr
                        key={`${phaseIndex}-${processIndex}`}
                        style={{ borderBottom: "1px solid #E7E7E7" }}
                      >
                        {processIndex === 0 && (
                          <td
                            rowSpan={phaseData.processTimeLine.length}
                            style={{
                              width: "18%",
                              padding: "1%",
                              textAlign: "left",
                              border: "1px solid #E7E7E7",
                              verticalAlign: "top",
                              fontSize: "10px",
                              fontFamily: '"Roboto"',
                              color: "#242424",
                              lineHeight: "1.5",
                            }}
                          >
                            {phaseData.phaseName}
                          </td>
                        )}

                        <td
                          style={{
                            width: "18%",
                            padding: "1%",
                            textAlign: "left",
                            border: "1px solid #E7E7E7",
                            verticalAlign: "top",
                            fontSize: "10px",
                            fontFamily: '"Roboto"',
                            color: "#242424",
                            lineHeight: "1.5",
                          }}
                        >
                          {process.processName}
                        </td>

                        {/* Assuming handleBindingWeekRange is a function that returns a valid JSX component or HTML element */}
                        {handleBindingWeekRange(process, processIndex)}
                      </tr>
                    )
                  )
                )}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  return (
    <div style={{ width: '100%', marginTop: '45%' }}>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td>
              <div style={{ width: '100%', float: 'left', margin: '2% 0 0% 0%', pageBreakInside: 'avoid' }}>
                {/*Reports Header starts here */}
                <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                  <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '15px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                    Reports</h5>
                </div>
                {/*Reports Header  ends here */}
                <div style={{ width: '100%', float: 'left', margin: '1% 0%' }}>
                  {/* Main header style */}
                  <h4 style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 700, fontSize: '14px', margin: '3% 0% 2% 0%' }}>
                    Table of content</h4>
                  {/*page header style */}
                  <div style={{ width: '100%', float: 'left' }}>
                    <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                      Executive Summary
                    </label>
                    <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 6%', float: 'right' }}>
                      3
                    </label>
                  </div>
                  <div style={{ width: '100%', float: 'left' }}>
                    <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                      Business Overview
                    </label>
                    <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 6%', float: 'right' }}>
                      4
                    </label>
                  </div>
                  <div style={{ width: '100%', float: 'left' }}>
                    <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                      Discovery - Graph
                    </label>
                    <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 6%', float: 'right' }}>
                      5
                    </label>
                  </div>
                  <div style={{ width: '100%', float: 'left' }}>
                    <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                      Recommendation Breakdown
                    </label>
                    <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 6%', float: 'right' }}>
                      6
                    </label>
                  </div>
                  <div style={{ width: '100%', float: 'left' }}>
                    <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                      Cost Breakdown
                    </label>
                    <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 6%', float: 'right' }}>
                      7
                    </label>
                  </div>
                  <div style={{ width: '100%', float: 'left' }}>
                    <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                      Roadmap
                    </label>
                    <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 6%', float: 'right' }}>
                      8
                    </label>
                  </div>
                </div>
              </div>

              <div style={{ width: '100%', float: 'left', margin: '106% 0% 0% 0%', pageBreakInside: 'avoid', height: '297mm' }}>
                {ExecutiveSummary()}
              </div>

              <div style={{ width: '100%', float: 'left', margin: '54% 0% 0% 0%', pageBreakInside: 'avoid', breakBefore: 'page' }}  >
                {BusinessOverview()}
              </div>

              <div style={{ width: '100%', float: 'left', margin: '86% 0% 0% 0%', pageBreakInside: 'avoid', breakBefore: 'page' }}>
                {discoveryGraph()}
              </div>

              <div style={{ width: '100%', float: 'left', margin: '30% 0% 0% 0%', pageBreakInside: 'avoid', breakBefore: 'page' }}>
                {recommendationBreakdown()}
              </div>

              <div style={{ width: '100%', float: 'left', margin: '120% 0% 0% 0%', pageBreakInside: 'avoid', breakBefore: 'page' }}>
                {costBreakdown()}
              </div>

              <div style={{ width: '100%', float: 'left', margin: '110% 0% 0% 0%', pageBreakInside: 'avoid', breakBefore: 'page' }}>
                {Roadmap()}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
};

export default VMwareDownloadReport;