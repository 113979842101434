const ToastComponent = (props :any) =>{
    return(
        <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
        <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
            <div className="toast-body d-flex align-items-center ">
                <div className="d-block me-3">
                    <img src={props.name?.toastType==="error" ? "images/fail-icon.svg":"images/toast-success.svg" } alt={props.name?.toastType}/>
                </div>
                <div className="d-block">
                    <span className="font-medium font-16 color-grey">{props.name?.toastBodyMessage}</span>
                </div>
            </div>

        </div>
    </div>
    )
}

export default ToastComponent;