import { useEffect, useState } from "react";
import Inventory from "./Inventory";
import Questionnaire from "./InfraQuestionnaire";
import Portfolio from "./PortfolioDiscovery";
import PortfolioComponent from "./PortfolioDiscovery";
import { getInfraAssessmentApplications, getInfraAssessmentMenus, reportGenerationButton } from "../service/InfraAssessmentApi";
import { MenuItem, ApplicationModel } from "../interface/InfraAssessmentModel";
import MigrationStrategy from "./MigrationStrategy";
import MigrationPlan from "./MigrationPlan";
import Loader from "./Loader";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumbs from "./breadCrumb";


const InfraAssessment = () => {

    const { state } = useLocation();
    const navigate = useNavigate();

    const [menus, setMenus] = useState<MenuItem[]>([]);
    const [activeTab, setActiveTab] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedMenuId, setSelectedMenuId] = useState<string>("");
    const [showDropdown, setShowDropdown] = useState(false);
    const [applicationDropDown, setApplicationDropDown] = useState<ApplicationModel[]>([])
    const [selectedApplicationId, setSelectedApplicationId] = useState('');
    const [showGenerateButton, setShowGenerateButton] = useState(false);

    const [breadCrumbData, setBreadCrumbData] = useState([
        { title: "Assessments", path: "/homepage", isActive: true },
        { title: state.organizationName, path: "", isActive: true },
        { title: state.verticalName, path: "", isActive: false },
    ]);

    useEffect(() => {
        getInitialPageData();
    }, []);

    useEffect(() => {
        getReportGenerationButtonFlag()
    })

    useEffect(() => {
        const handleBeforeUnload = (e: any) => {
          e.preventDefault(); // Prevent the page from reloading
          e.returnValue =
            "You may have unsaved changes. Are you sure you want to reload or leave?"; // Some browsers require a return value
        };
    
        window.onbeforeunload = function () {
          return "You may have unsaved changes. Are you sure you want to reload or leave?";
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
      }, []);

    const getReportGenerationButtonFlag = async () => {
        const generateButtonPayload = {
            organizationId: state.organizationId,
            verticalId: state.verticalId
        }
        const reportGenerationButtonRes = await reportGenerationButton(generateButtonPayload);
        if (reportGenerationButtonRes.status === 200) {
            setShowGenerateButton(reportGenerationButtonRes.data.enableInfraReportGenerationButton)
        }
    }

    const getInitialPageData = async () => {
        try {
            const payload = {
                infraAssessmentTypeId: state.infraAssessmentTypeId
            }

            const response = await getInfraAssessmentMenus(payload);
            if (response.status === 200) {
                setMenus(response.data);
                setActiveTab(response.data[0].menuName)
                setSelectedMenuId(response.data[0].menuId)
            }
        } catch (error) {
            console.error("Error fetching initial data:", error);
        }
    };

    const handleTabChange = async(tabName: string, menuId: string) => {
        setActiveTab(tabName);
        setSelectedMenuId(menuId);
        if (tabName === menus[3]?.menuName) {
            await getApplicationDetails();
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    };

    const getApplicationDetails = async () => {
        const applicationPayload = {
            organizationId: state.organizationId,
            verticalId: state.verticalId,
            infraAssessmentTypeId: state.infraAssessmentTypeId
        };
        // PS_MigrationStrategy_16
        const applicationResponse = await getInfraAssessmentApplications(applicationPayload); // Replace with your actual API call
        if (applicationResponse.status !== 200) {
            //TODO: add api failed toast
            return;
        }

        if (applicationResponse.data && applicationResponse.data.length > 0) {
            setApplicationDropDown(applicationResponse.data);
            setSelectedApplicationId(applicationResponse.data[0].applicationId);
        } else {
            setApplicationDropDown([]);
            setSelectedApplicationId(""); 
        }
    }


    const bindApplicationDropdown = () => {
        return applicationDropDown.map((data) => {
            return <option key={data.applicationId} value={data.applicationId}> {data.applicationName}</option>
        })
    }

    const onApplicationChange = (event: any) => {
        setSelectedApplicationId(event.target.value);
    };

    const navigateReportGrid = () => {
        navigate("/reports", {
            state: {
                organizationId: state.organizationId,
                organizationName: state.organizationName,
                verticalId: state.verticalId,
                verticalName: state.verticalName,
                verticalAssessmentTypeId: state.infraAssessmentTypeId,
                verticalAssessmentTypeName: state.infraAssessmentTypeName,
                projectId: "",
                projectName: "",
                projectStatus: "",
                IsInitialReportGeneration: true,
            }
        }
        )
    }

    return (
        <>
            <div className="container-fluid" style={{ marginTop: "105px" }}>
                <div className="row justify-content-center">
                    <div className="px-4">
                        {/* breadcrumb starts here */}
                        <BreadCrumbs data={breadCrumbData} />
                        {/* breadcrumb ends here */}
                        {/* heading content starts here */}
                        <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center mb-2">
                            <div className="d-flex align-items-center mb-3 mb-md-0">
                                <a href="#" onClick={() => navigate("/homepage")}><img src="images/backarrow.svg" alt="back-arrow" title="Back" /></a>
                                <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                                    Infrastructure Assessment
                                </h2>
                            </div>

                            <div className="d-grid gap-3 d-md-inline-flex justify-content-md-end">
                                {(showDropdown && applicationDropDown.length != 0) && (
                                    // <div className="btn-group" role="group">
                                    <select className="theme-form form-select font-regular font-14"
                                        value={selectedApplicationId} onChange={(e) => onApplicationChange(e)}
                                    >
                                        {bindApplicationDropdown()}
                                    </select>
                                    // </div>
                                )}
                                {showGenerateButton && (<button
                                    type="button"
                                    className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold pt-2 pb-2 cursor-pointer text-nowrap"
                                    onClick={() => {
                                        navigateReportGrid();
                                    }}
                                >
                                    Generate Report
                                </button>)}
                            </div>
                        </div>
                        {/* heading content ends here */}

                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                                <div className="row">
                                    <div className="d-lg-flex d-none pe-0 align-items-start v-tab-border left-nav-brd">
                                        <div className="nav flex-column nav-pills custom-v-pills w-100" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            <p className="font-16 font-semibold my-lg-4 my-2 ps-0 ps-lg-3">MAP</p>
                                            {menus.map((menu) => (
                                                <button
                                                    className={`nav-link text-start rounded-0 font-medium font-14 ${activeTab === menu.menuName ? "active" : ""}`}
                                                    data-bs-toggle="pill"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls={`${menu.menuName.toLowerCase().replace(/\s/g, "")}`}
                                                    aria-selected="true"
                                                    onClick={() => handleTabChange(menu.menuName, menu.menuId)}>
                                                    {menu.menuName == menus[2].menuName ? "CAF" : menu.menuName}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                                <div className="row">
                                    <div className="tab-content px-4" id="v-pills-tabContent">
                                        {(activeTab == menus[0]?.menuName) && <Inventory setIsLoading={setIsLoading} infraAssessmentMenuId={selectedMenuId} />}
                                        {(activeTab == menus[1]?.menuName) && <PortfolioComponent setIsLoading={setIsLoading} state={state} menuId={selectedMenuId} />}
                                        {(activeTab == menus[2]?.menuName) && <Questionnaire menuId={selectedMenuId} state={state} setIsLoading={setIsLoading} />}
                                        {(activeTab == menus[3]?.menuName) && <MigrationStrategy
                                            setApplicationDropDown={setApplicationDropDown}
                                            applicationDropDown={applicationDropDown}
                                            setSelectedApplicationId={setSelectedApplicationId}
                                            selectedApplicationId={selectedApplicationId}
                                            setIsLoading={setIsLoading}
                                            state={state}
                                            menuId={selectedMenuId}
                                        />
                                        }
                                        {(activeTab == menus[4]?.menuName) && <MigrationPlan
                                            state={state}
                                            menuId={selectedMenuId}
                                            setIsLoading={setIsLoading}
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Loader isLoading={isLoading} />
        </>
    )
}

export default InfraAssessment;