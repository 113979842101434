import { useNavigate } from "react-router-dom"
import BreadCrumbs from "./breadCrumb"
import { useEffect, useRef, useState } from "react"
import MigrationPlan from "./MigrationPlan";
import Loader from "./Loader";
import { editedSessionDataModel, sessionDataModel, summarySessionsModel } from "../interface/WafrReportModel";
import UploadTranscript from "./reportComponents/UploadTranscript";
import CostEstimation from "./reportComponents/CostEstimation";
import ExecutiveSummary from "./reportComponents/ExecutiveSummary";
import { deleteAttributeRecords, generateConclusionDetails, postArchitectureDetails, postBusinessWorkFlow } from "../service/QuestionnaireApi";
import uploadFileToBlob1 from "../helpers/BlobUpload";
import { getSasToken, postSummaryData, postUploadedTranscriptData } from "../service/ReportApi";
import TextEditor from "./reportComponents/TextEditor";
import { controlAreasImpactImgModel, controlAreasImpactModel, controlAreasModel, inventoryDetailsModel, isEditModel, postSummaryModel, summaryEditModel, uploadTranscriptModel, wafrFileInfoModel, wafrReportFileModel, wafrReportSortOrderModel } from "../interface/WafrReportModel"
import { postSessionData } from "../service/ReportApi";


const AwsMdpwReport = ({ initialReportData }: any) => {
    const initialFileInfo: wafrFileInfoModel = {
        fileData: "",
        fileName: "",
        fileType: "",
        base64Transcript: ""
    }

    const initialSummaryData = {
        executiveSummary: "",
        businessOverview: "",
    }

    const initialSessionData: sessionDataModel = {
        attributeMasterId: "",
        titleAttributeId: "",
        titleName: "",
        descriptionId: "",
        description: "",
    }

    const mapSessionData = () => {
        return reportData?.session?.map((session: any) => {
            let titleAttribute = session.find((attribute: any) => attribute.attributeName === "Session Title");
            let descriptionAttribute = session.find((attribute: any) => attribute.attributeName === "Session Description");
            return {
                attributeMasterId: titleAttribute.attributeMasterId,
                titleAttributeId: titleAttribute.attributeId,
                titleName: titleAttribute.attributeName,
                descriptionId: descriptionAttribute.attributeId,
                description: descriptionAttribute.attributeValue
            };
        });

    };

    const closeModal = () => {
        setShowDeleteModal(false);
        setDeletingItemId(null)
    };

    const navigate = useNavigate()
    const [selectedNavBar, setSelectedNavBar] = useState<string>("uploadTranscript")
    const [reportData, setReportData] = useState<any>(initialReportData)
    const [execSummary, setExecSummary] = useState<string>("")
    const [businessOverview, setBusinessOverview] = useState<string>("")
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sessionDetials, setSessionDetials] = useState<sessionDataModel[]>(mapSessionData() || [])
    const [summaryFile, setSummaryFile] = useState<wafrFileInfoModel>(initialFileInfo)
    const [businessFlowData, setBusinessFlowData] = useState<any>(reportData.businessFlow && reportData.businessFlow.length > 0 ? reportData.businessFlow[0] : []);
    const [workFlowData, setWorkflowData] = useState<any>(reportData.workFlow && reportData.workFlow.length > 0 ? reportData.workFlow[0] : []);
    const [challengesRecommendations, setChallengesRecommendations] = useState<any>(reportData.challengesRecommendations || []);
    const [conclusionData, setConclusionData] = useState<any>(reportData.conclusion || []);
    const [isSaving, setIsSaving] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletingItemId, setDeletingItemId] = useState<string | null>(null);
    const [deleteId, setDeleteId] = useState<string | null>(null);
    const [showToasterLoading, setShowToasterLoading] = useState(false);
    const [showToasterPopup, setShowToasterPopup] = useState(false);
    const [showToasterFailedPopup, setShowToasterFailedPopup] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [hideToast, setHideToast] = useState(true);
    const [toastProp, setToastProp] = useState({
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: '',
    });
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (initialReportData?.summary?.length > 0) {
            const summaryData = initialReportData.summary;

            const execSummaryValue = summaryData.flat().find((value: any) => value?.attributeName === "Executive Summary")?.attributeValue || "";
            setExecSummary(execSummaryValue);

            const businessOverviewValue = summaryData.flat().find((value: any) => value?.attributeName === "Business Overview")?.attributeValue || "";
            setBusinessOverview(businessOverviewValue);
        }
    }, [initialReportData]);

    useEffect(() => {
        const handleBeforeUnload = (e: any) => {
            e.preventDefault(); // Prevent the page from reloading
            e.returnValue =
                "You may have unsaved changes. Are you sure you want to reload or leave?"; // Some browsers require a return value
        };

        window.onbeforeunload = function () {
            return "You may have unsaved changes. Are you sure you want to reload or leave?";
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
    }, []);

    const breadCrumbs =
        [
            { title: "Organization", path: "/homepage", isActive: true },
            { title: initialReportData.organizationName, path: "/manageassessment", isActive: true, state: { organizationId: initialReportData.organizationId, organizationName: initialReportData.organizationName } },
            { title: initialReportData.verticalName, path: "", isActive: true },
            { title: "Report", path: "", isActive: false },
        ]

    const regex = /"|[*]/g;


    const escapeJsonString = (str: string) => {
        return str.replace(/\\/g, '\\\\')
            .replace(/"/g, '\\"')
            .replace(/\n/g, '\\n')
            .replace(/\r/g, '\\r')
            .replace(/\t/g, '\\t')
            .replace(/\f/g, '\\f');
    }

    const validateImage = (file: any, minWidth = 1000, minHeight = 1500, minDPI = 300) => {
        return new Promise((resolve, reject) => {
            if (!file) {
                resolve({ isValid: false, errorMessage: 'No file selected.' });
                return;
            }

            const reader = new FileReader();

            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {

                    const dpiX = (img.width / (file.width / 25.4));
                    const dpiY = (img.height / (file.height / 25.4));

                    if (img.width < minWidth || img.height < minHeight || dpiX < minDPI || dpiY < minDPI) {
                        reject(`Image resolution is too low`);
                    } else {
                        resolve({ isValid: true, errorMessage: '' });
                    }
                };
                img.onerror = () => {
                    reject('Error loading image. Please try again.');
                };
                img.src = (event?.target?.result as string);
            };

            reader.onerror = () => {
                reject('Error reading file. Please try again.');
            };

            reader.readAsDataURL(file);
        });
    };

    const postSession = async (sessionData: editedSessionDataModel, value: string) => {
        value = value ? escapeJsonString(value?.trim().replace(regex, "")) : ""
        const payload = {
            organizationId: reportData?.organizationId,
            verticalId: reportData?.verticalId,
            projectId: reportData?.projectId,
            connectorId: reportData.connectors.find((c: any) => c.connectorName === "Session")?.connectorId,
            connectorName: "Session",
            isConnector: false,
            base64Value: sessionData?.base64Transcript,
            fileType: ".docx",
            attributeMasterId: sessionData?.attributeMasterId,
            attributeRecords: [
                {
                    attributeID: sessionData?.titleAttributeId,
                    attributeName: "Session Title",
                    attributeValue: sessionData?.titleName
                },
                {
                    attributeID: sessionData?.descriptionId,
                    attributeName: "Session Description",
                    attributeValue: escapeJsonString(value?.trim().replace(regex, ""))
                }
            ]
        }
        setShowToasterLoading(true);
        try {

            const response = await postSessionData(payload)

            if (response.status === 200) {
                setShowToasterPopup(true);
                setIsLoading(true);
                //await new Promise(resolve => setTimeout(resolve, 1000));

                response?.data?.forEach((data: any) => {
                    sessionData.attributeMasterId = data?.attributeMasterId
                    if (data?.attributeName?.toLowerCase()?.replace(" ", "") === "sessiondescription") {
                        sessionData.description = data?.attributeValue
                        sessionData.descriptionId = data?.attributeId
                    }
                    else {
                        sessionData.titleAttributeId = data?.attributeId
                    }
                }
                )
                setSessionDetials([...(sessionDetials?.filter((data: sessionDataModel) => {
                    if (data?.attributeMasterId !== sessionData?.attributeMasterId)
                        return data
                }) || []), {
                    attributeMasterId: sessionData.attributeMasterId,
                    titleAttributeId: sessionData.titleAttributeId,
                    descriptionId: sessionData.descriptionId,
                    titleName: sessionData.titleName,
                    description: sessionData.description
                }])

            }
            else {
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);

            }
        }
        catch (error) {
            setShowToasterFailedPopup(true);
            setIsLoading(false);
        }
        finally {
            setIsLoading(false)
            setShowToasterLoading(false);
            setTimeout(() => {
                setShowToasterPopup(false);
                setShowToasterFailedPopup(false);
            }, 4000);
        }
    }

    const deleteSession = async (id: string) => {
        setDeleteId(id);
        setShowDeleteModal(true);
    }

    const confirmDeletesession = async () => {
        if (deleteId) {
            try {
                const response = await deleteAttributeRecords({ attributeMasterId: deleteId })
                if (response.status === 200) {
                    setShowDeletePopup(true);
                    setIsLoading(true);
                    closeModal();
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    setSessionDetials(sessionDetials?.filter((data: sessionDataModel) => {
                        return data?.attributeMasterId !== deleteId
                    }))
                    setReportData((prevState: any) => ({
                        ...prevState,
                        session: Array.isArray(prevState.session)
                            ? prevState.session.filter((dataItem: any) => dataItem[0].attributeMasterId !== dataItem?.attributeMasterId)
                            : []
                    }));
                } else {
                    setIsLoading(false);
                    let toastData = {
                        toastType: 'error',
                        toastHeaderMessage: 'Error',
                        toastBodyMessage: 'API failed'
                    };
                    setToastProp(toastData);
                    setHideToast(false);
                }
            }
            catch (error) {
                setShowToasterFailedPopup(true);
                setIsLoading(false)
                console.error('Error deleting session details:', error);
            } finally {
                setIsLoading(false)
                setDeletingItemId(null)
                setTimeout(() => {
                    setShowDeletePopup(false);
                    setShowToasterFailedPopup(false);
                }, 4000);
            }
        }
    }

    const postUploadedTranscript = async (key: string) => {
        let payload: uploadTranscriptModel = {
            organizationId: reportData?.organizationId,
            projectId: reportData?.projectId,
            verticalName: "MDPW",
            verticalId: reportData?.verticalId,
            isSummary: true,
            recommendations: [],
            url: [],
            fileName: summaryFile.fileName,
            fileType: '.docx',
            fileData: summaryFile.base64Transcript,
        }
        setShowToasterLoading(true);

        try {
            const response = await postUploadedTranscriptData(payload)
            if (response.status === 200) {
                setShowToasterPopup(true);
                setIsLoading(true);
                await new Promise(resolve => setTimeout(resolve, 1000));
                setExecSummary(response?.data?.executiveSummary)
                setBusinessOverview(response?.data?.businessOverview)
                setConclusionData([])
            }
            else {
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }
        }
        catch (error) {
            setShowToasterFailedPopup(true);
            setIsLoading(false)
        } finally {
            setIsLoading(false);
            setShowToasterLoading(false);
            setTimeout(() => {
                setShowToasterPopup(false);
                setShowToasterFailedPopup(false);
            }, 4000);
        }

    }
    const resetFileInput = () => {
        if (fileInputRef.current) {
            (fileInputRef.current as HTMLInputElement).value = '';
        }
    };

    const postSummary = async (key: string, value: string) => {
        value = value ? escapeJsonString(value?.trim().replace(regex, "")) : ""
        let payload: postSummaryModel = {
            organizationId: reportData.organizationId,
            verticalId: reportData.verticalId,
            projectId: reportData.projectId,
            attributeName: key === "executiveSummary" ? "Executive Summary" : "Business Overview",
            attributeValue: value.trim(),
            attributeId: ""
        }
        setShowToasterLoading(true);

        try {
            const response = await postSummaryData(payload)
            if (response.status === 200) {
                setShowToasterPopup(true);
                setIsLoading(true);
                await new Promise(resolve => setTimeout(resolve, 1000));
                if (key === "executiveSummary")
                    setExecSummary(value?.trim())
                else
                    setBusinessOverview(value?.trim())
                setConclusionData([]);

            }
            else {
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }
        }
        catch (error) {
            setShowToasterFailedPopup(true);
            setIsLoading(false);
        } finally {
            setIsLoading(false)
            setShowToasterLoading(false);
            setTimeout(() => {
                setShowToasterPopup(false);
                setShowToasterFailedPopup(false);
            }, 4000);
        }
    }

    const BindMenus = () => {
        return reportData.menus?.map((data: any) => {
            return (
                <button
                    className={`nav-link text-start rounded-0 font-medium font-14 text-truncate menu-list ${selectedNavBar === data.menuName ? "active" : ""}`}
                    key={data.menuId}
                    id={data.menuName}
                    type="button"
                    role="tab"
                    onClick={() => { setSelectedNavBar((prev) => data.menuName) }}
                    title={data.menuName}
                >
                    {data.menuName}
                </button>
            );
        });
    };

    const updateFlowData = (flowType: any, newData: any) => {
        if (flowType === 'businessFlow') {
            setBusinessFlowData(newData);
        } else {
            setWorkflowData(newData);
        }
    };

    const updateReportData = (flowType: any, newFlowData: any) => {
        setReportData((prevData: any) => ({
            ...prevData,
            [flowType]: [newFlowData]
        }));
    };

    const handleUpdate = (updatedChallengesRecommendations: any) => {
        setChallengesRecommendations(updatedChallengesRecommendations);
        setReportData((prevData: any) => ({
            ...prevData,
            challengesRecommendations: updatedChallengesRecommendations
        }));
    };

    const BusinessAndWorkFlowTab = ({ flowType, flowData, updateFlowData, reportData, updateReportData }: any) => {
        const [file, setFile] = useState<any>(null);
        const [error, setError] = useState('');
        const [isReuploading, setIsReuploading] = useState(false);
        const [filename, setFilename] = useState('');
        const [isEditing, setIsEditing] = useState(false);
        const [sasToken, setSasToken] = useState('');
        const [showUploadForm, setShowUploadForm] = useState(!flowData.length);
        const [currentDescription, setCurrentDescription] = useState('');
        const blobURLData = flowData.find((item: any) => item.attributeName === `${flowType} BlobURL`);
        const descriptionData = flowData.find((item: any) => item.attributeName === `${flowType} Description`);
        const base64Image = blobURLData ? blobURLData.attributeValue : '';
        const description = descriptionData ? descriptionData.attributeValue : '';

        useEffect(() => {
            setCurrentDescription(description);
        }, [description]);

        useEffect(() => {
            const fetchSasToken = async () => {
                try {
                    const sas = await getSasToken('r');
                    setSasToken(sas?.data);
                } catch (error) {
                    console.error("Error fetching SAS token:", error);
                }
            };

            fetchSasToken();
        }, []);

        const generateImageUrl = (blobUrl: string, sasToken: string) => {
            if (!blobUrl || !sasToken) return '';
            return `${blobUrl}?${sasToken}`;
        };

        const handleReuploadClick = () => {
            setShowUploadForm(true);
            setIsReuploading(true);
            setFilename('');
            updateFlowData([]);
            setError('');
        };

        const handleEditClick = () => {
            setIsEditing(!isEditing);
        };

        const handleFileChange = (event: any) => {
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

                if (!allowedTypes.includes(selectedFile.type)) {
                    setError('File should be PNG, JPG, or JPEG');
                    setFile(null);
                    setFilename('');
                    resetFileInput();
                    return;
                }

                if (selectedFile.size > 20 * 1024 * 1024) {
                    setError('File size should be less than 20 MB');
                    setFile(null);
                    setFilename('');
                    resetFileInput();
                    return;
                }

                validateImage(selectedFile, 1000, 1500, 300)
                    .then((result: any) => {
                        if (result.isValid) {
                            setError('');
                            setFile(selectedFile);
                            setFilename(selectedFile.name);
                        } else {
                            setError(result.errorMessage);
                            setFile(null);
                            setFilename('');
                            resetFileInput();
                        }
                    })
                    .catch((error) => {
                        setError(error);
                        setFile(null);
                        setFilename('');
                        resetFileInput();
                    });
            } else {
                setFile(null);
                setFilename('');
                setError('');
            }
        };

        const generatePayload = (blobUrl: string | null = null, base64Value: string | null = null, newDescription: string | null = null) => {
            const blobURLItem = flowData.find((item: any) => item.attributeName === `${flowType} BlobURL`) || {};
            const descriptionItem = flowData.find((item: any) => item.attributeName === `${flowType} Description`) || {};

            const connector = flowType === "Work Flow"
                ? reportData.connectors.find((c: any) => c.connectorName === "Workflow Component")
                : reportData.connectors.find((c: any) => c.connectorName === "Business Flow");

            return {
                "organizationId": reportData.organizationId,
                "verticalId": reportData.verticalId,
                "projectId": reportData.projectId,
                "connectorId": connector ? connector.connectorId : "",
                "connectorName": connector ? connector.connectorName : "",
                "isFlowConnector": true,
                "fileType": file ? file.name.split('.').pop() : "",
                "base64Value": base64Value || "",
                "attributeMasterId": blobURLItem.attributeMasterId || "",
                "attributeRecords": [
                    {
                        "attributeId": blobURLItem.attributeId || "",
                        "attributeName": flowType === "Workflow" ? "Work Flow BlobURL" : `${flowType} BlobURL`,
                        "attributeValue": blobUrl || blobURLItem.attributeValue || ""
                    },
                    {
                        "attributeId": descriptionItem.attributeId || "",
                        "attributeName": flowType === "Workflow" ? "Work Flow Description" : `${flowType} Description`,
                        "attributeValue": newDescription ? escapeJsonString(newDescription.trim().replace(regex, "")) : "" || currentDescription ? escapeJsonString(currentDescription.trim().replace(regex, "")) : "" || descriptionItem.attributeValue ? escapeJsonString(descriptionItem?.attributeValue.trim().replace(regex, "")) : ""
                    }
                ]
            };
        };

        const handleApiResponse = (response: any) => {
            setIsLoading(true)
            if (response.data && response.status === 200) {
                const newFlowData = response.data.map((attr: any) => ({
                    attributeId: attr.attributeId,
                    attributeMasterId: attr.attributeMasterId,
                    attributeName: attr.attributeName,
                    attributeValue: attr.attributeValue,
                    connectorId: attr.connectorId,
                    connectorName: attr.connectorName
                }));
                updateFlowData(newFlowData);
                updateReportData(flowType, newFlowData);
                setIsReuploading(false);
                setIsEditing(false);
                setFile(null);
                setFilename('');
                setError('');

                const newDescriptionData = newFlowData.find((item: any) => item.attributeName === `${flowType} Description`);
                if (newDescriptionData) {
                    setCurrentDescription(newDescriptionData.attributeValue);
                }
                setIsLoading(false)
            } else {
                setIsLoading(false)
                setError("Error: " + response.statusText);
            }
        };

        const handleApiError = (error: any) => {
            setError("Error processing request");
            console.error("Error processing request", error);
        };

        const handleUpload = async () => {
            if (!file) {
                setError('Please select a file to upload');
                return;
            }

            setError('');
            setShowToasterLoading(true);

            const reader = new FileReader();

            reader.onloadend = async () => {
                if (reader.result && typeof reader.result === 'string') {
                    const base64String = reader.result.split(',')[1];
                    let blobUrl = blobURLData ? blobURLData.attributeValue : null;
                    blobUrl = await uploadFileToBlob1(file, flowType.toLowerCase().replace(' ', ''));
                    const payload = generatePayload(blobUrl, base64String);

                    try {

                        const response = await postBusinessWorkFlow(payload);
                        if (response.status === 200) {
                            setShowToasterPopup(true);
                            setIsLoading(true);
                            await new Promise(resolve => setTimeout(resolve, 1000));
                            handleApiResponse(response);

                        } else {
                            setIsLoading(false);
                            setError("Error: " + response.statusText);
                            let toastData = {
                                toastType: 'error',
                                toastHeaderMessage: 'Error',
                                toastBodyMessage: 'API failed'
                            }
                            setToastProp(toastData)
                            setHideToast(false);
                        }
                    } catch (error) {
                        setShowToasterFailedPopup(true);
                        handleApiError(error);
                        setIsLoading(false)
                        setShowToasterLoading(false);
                    } finally {
                        setIsLoading(false);
                        setShowToasterLoading(false);
                        setTimeout(() => {
                            setShowToasterPopup(false);
                            setShowToasterFailedPopup(false);
                        }, 4000);
                    }
                } else {
                    setError("Error reading file");
                    setIsLoading(false);
                }
            };

            reader.onerror = () => {
                setError("Error reading file");
                setIsLoading(false);
                setShowToasterLoading(false);
            };

            reader.readAsDataURL(file);
        };

        const handleEditSave = async (newDescription: string) => {
            setError('');
            const payload = generatePayload(null, null, newDescription);
            setShowToasterLoading(true);
            try {
                const response = await postBusinessWorkFlow(payload);
                setShowToasterPopup(true);
                setIsLoading(true);
                await new Promise(resolve => setTimeout(resolve, 1000));
                handleApiResponse(response);
            } catch (error) {
                setIsLoading(false);
                handleApiError(error);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            } finally {
                setIsLoading(false)
                setShowToasterLoading(false);
                setTimeout(() => {
                    setShowToasterPopup(false);
                    setShowToasterFailedPopup(false);
                }, 4000);
            }
        };
        const handleRemoveFile = () => {
            setFile(null);
            setFilename('');
            setError('');
            resetFileInput();
        };

        useEffect(() => {
            if (saveSuccess) {
                const timer = setTimeout(() => {
                    setSaveSuccess(false);
                    setIsSaving(false);
                }, 2000);
                return () => clearTimeout(timer);
            }
        }, [saveSuccess]);

        return (
            <div className="ms-lg-4">
                {(flowData.length > 0 && !isReuploading && !isSaving) ? (
                    <div className="border-bottom my-4 pt-2 pb-4">
                        <div className="row row-gap-4">

                            <div className="col-lg-12 col-md-12 col-12 mt-5 mb-5">
                                <div className="ps-lg-4">
                                    <div className="d-flex justify-content-between align-items-center my-2 mb-5">
                                        <h3 className="font-18 font-semibold color-black mb-0">
                                            {flowType} Diagram
                                        </h3>
                                        <button type="button" className="btn btn-dark theme-outline-btn custom-btn font-medium px-4" onClick={handleReuploadClick}>Reupload</button>
                                    </div>
                                    <div className="flow-diagram mb-3">
                                        {base64Image && sasToken && (
                                            <img
                                                src={generateImageUrl(base64Image, sasToken)}
                                                alt={`${flowType}`}
                                            />
                                        )}
                                    </div>
                                    {isEditing ? (
                                        <div className="mt-5 pt-5">
                                            <h3 className="font-18 font-semibold color-black mb-0">
                                                {flowType}
                                            </h3>
                                            <div className="mt-4">
                                                <TextEditor
                                                    value={currentDescription}
                                                    onChange={(value: string) => setCurrentDescription(value)}
                                                    onSave={handleEditSave}
                                                    setIsLoading={setIsLoading}
                                                    onCancel={handleEditClick}

                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="ps-lg-4">
                                            <div className="d-flex justify-content-between align-items-center mt-5 my-2 mb-4">
                                                <h3 className="font-18 font-semibold color-black mb-0">
                                                    {flowType}
                                                </h3>
                                                <button type="button" onClick={handleEditClick} className="btn edit-btn custom-btn font-medium px-4">Edit</button>
                                            </div>
                                            <div>
                                                {currentDescription ? (
                                                    <div
                                                        className="font-14 font-regular mb-4"
                                                        dangerouslySetInnerHTML={{ __html: currentDescription }}
                                                    />
                                                ) : (
                                                    <p className="font-14 font-regular mb-4">No description available.</p>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-12 mb-5">
                            <div className="ribbon py-2 px-3 ms-md-4">
                                <span className="font-12 font-medium">Once the {flowType.toLowerCase()} diagram is uploaded, a description of
                                    the {flowType.toLowerCase()} will be generated based on the uploaded image.</span>
                                <span className="ribbon-border" />
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12 col-12">
                            <div className="ps-lg-4">
                                <h3 className="font-20 font-semibold mt-1 mb-4">{flowType} Diagram</h3>
                                <div className="position-relative">
                                    <label className="file-upload-outline position-absolute rounded-3 d-flex align-items-center gap-2 font-12 font-semibold mt-2">
                                        <img src="images/upload-icon-blue.svg" alt="upload-icon" />Upload
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control custom-file mb-4"
                                        id="orgLogo"
                                        aria-label="upload"
                                        onChange={handleFileChange}
                                        accept=".png,.jpg,.jpeg"
                                        ref={fileInputRef}
                                    />
                                    {filename && <span className="font-12 font-medium color-grey">{filename} <img src="images/close-icon-grey.svg" alt="close icon" className="ms-2 cursor-pointer" onClick={handleRemoveFile} /></span>}
                                </div>
                            </div>
                            {error && <div className="mt-2 mb-0 color-red font-14 font-regular">{error}</div>}
                        </div>
                        <div className="modal-footer py-4 border-0 justify-content-end py-5 mt-5 gap-1">
                            <button type="button" className="btn btn-dark theme-primary-btn-md border-0 font-semibold " onClick={handleUpload}>Save</button>
                        </div>
                    </div>
                )}
                {showToasterLoading && (
                    <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                        <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                            <div className="toast-body d-flex align-items-center ">
                                <div className="d-block me-3">
                                    <img
                                        src="images/loading.gif"
                                        alt="toast-success"
                                        style={{ height: "15px", width: "15px" }}
                                    />
                                </div>
                                <div className="d-block">
                                    <span className="font-medium font-16 color-grey">
                                        Generating...
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {showToasterPopup && (
                    <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                        <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                            <div className="toast-body d-flex align-items-center ">
                                <div className="d-block me-3">
                                    <img src="images/toast-success.svg" alt="toast-success" />
                                </div>
                                <div className="d-block">
                                    <span className="font-medium font-16 color-grey">
                                        {flowType} has been saved successfully
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {showToasterFailedPopup && (
                    <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                        <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                            <div className="toast-body d-flex align-items-center ">
                                <div className="d-block me-3">
                                    <img src="images/fail-icon.svg" alt="toast-fail" />
                                </div>
                                <div className="d-block">
                                    <span className="font-medium font-16 color-grey">
                                        Api failed.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {showDeletePopup && (
                    <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                        <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                            <div className="toast-body d-flex align-items-center ">
                                <div className="d-block me-3">
                                    <img src="images/toast-success.svg" alt="toast-success" />
                                </div>
                                <div className="d-block">
                                    <span className="font-medium font-16 color-grey">
                                        {flowType} has been Deleted successfully.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const FutureStateArchitecture = () => {
        const [architectureData, setArchitectureData] = useState<any[]>(reportData.futureStateArchitecture || []);
        const [architectureName, setArchitectureName] = useState<string>("");
        const [file, setFile] = useState<any>(null);
        const [fileName, setFileName] = useState<string>("");
        const [nameError, setNameError] = useState<string>("");
        const [fileError, setFileError] = useState<string>("");
        const [isSaving, setIsSaving] = useState<boolean>(false);
        const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
        const [error, setError] = useState<string>("");
        const [editingItem, setEditingItem] = useState<any>(null);
        const [editArchitectureName, setEditArchitectureName] = useState<string>("");
        const [editFile, setEditFile] = useState<any>(null);
        const [editFileName, setEditFileName] = useState<string>("");
        const [editNameError, setEditNameError] = useState<string>("");
        const [editFileError, setEditFileError] = useState<string>("");
        const [sasToken, setSasToken] = useState<string>('');

        useEffect(() => {
            setArchitectureData(reportData.futureStateArchitecture || []);
        }, [reportData.futureStateArchitecture]);

        useEffect(() => {
            const fetchSasToken = async () => {
                try {
                    const sas = await getSasToken('r');
                    setSasToken(sas?.data);
                } catch (error) {
                    console.error("Error fetching SAS token:", error);
                }
            };

            fetchSasToken();
        }, []);

        const generateImageUrl = (blobUrl: string, sasToken: string) => {
            if (!blobUrl || !sasToken) return '';
            return `${blobUrl}?${sasToken}`;
        };

        const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, isEdit = false) => {
            const selectedFile = event.target.files?.[0];
            if (selectedFile) {
                const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

                if (!allowedTypes.includes(selectedFile.type)) {
                    if (isEdit) {
                        setEditFileError('File should be PNG, JPG, or JPEG');
                        setEditFile(null);
                        setEditFileName('');
                    } else {
                        setFileError('File should be PNG, JPG, or JPEG');
                        setFile(null);
                        setFileName('');
                    }
                    resetFileInput();
                    return;
                }

                if (selectedFile.size > 20 * 1024 * 1024) {
                    if (isEdit) {
                        setEditFileError('File size should be less than 20 MB');
                        setEditFile(null);
                        setEditFileName('');
                    } else {
                        setFileError('File size should be less than 20 MB');
                        setFile(null);
                        setFileName('');
                    }
                    resetFileInput();
                    return;
                }

                validateImage(selectedFile, 1000, 1500, 300)
                    .then((result: any) => {
                        if (result.isValid) {
                            if (isEdit) {
                                setEditFileError('');
                                setEditFile(selectedFile);
                                setEditFileName(selectedFile.name);
                            } else {
                                setFileError('');
                                setFile(selectedFile);
                                setFileName(selectedFile.name);
                            }
                        } else {
                            if (isEdit) {
                                setEditFileError(result.errorMessage);
                                setEditFile(null);
                                setEditFileName('');
                            } else {
                                setFileError(result.errorMessage);
                                setFile(null);
                                setFileName('');
                            }
                            resetFileInput();
                        }
                    })
                    .catch((error) => {
                        if (isEdit) {
                            setEditFileError(error);
                            setEditFile(null);
                            setEditFileName('');
                        } else {
                            setFileError(error);
                            setFile(null);
                            setFileName('');
                        }
                        resetFileInput();
                    });
            } else {
                if (isEdit) {
                    setEditFile(null);
                    setEditFileName('');
                    setEditFileError('');
                } else {
                    setFile(null);
                    setFileName('');
                    setFileError('');
                }
            }
        };

        const handleAdd = async () => {
            let isValid = true;

            if (!architectureName.trim()) {
                setNameError('Please enter an Architecture Name');
                isValid = false;
            } else if (architectureName.length > 40) {
                setNameError('Please enter a valid Architecture Name');
                isValid = false;
            } else {
                setNameError('');
            }

            if (!file) {
                setFileError('Please select a file to upload');
                isValid = false;
            } else {
                setFileError('');
            }

            if (!isValid) return;

            setError('');
            setShowToasterLoading(true);

            try {
                const blobUrl = await uploadFileToBlob1(file, 'futureStateArchitecture');

                const payload = [
                    {
                        organizationId: reportData.organizationId,
                        verticalId: reportData.verticalId,
                        projectId: reportData.projectId,
                        connectorId: reportData.connectors.find((c: any) => c.connectorName === "Future State Architecture")?.connectorId,
                        connectorName: 'Future State Architecture',
                        attributeMasterId: '',
                        attributeId: '',
                        attributeName: 'Architecture Name',
                        attributeValue: architectureName,
                    },
                    {
                        organizationId: reportData.organizationId,
                        verticalId: reportData.verticalId,
                        projectId: reportData.projectId,
                        connectorId: reportData.connectors.find((c: any) => c.connectorName === "Future State Architecture")?.connectorId,
                        connectorName: 'Future State Architecture',
                        attributeMasterId: '',
                        attributeId: '',
                        attributeName: 'Architecture Blob URL',
                        attributeValue: blobUrl,
                    },
                ];
                const response = await postArchitectureDetails(payload);
                if (response.data && response.status === 200) {
                    setShowToasterPopup(true);
                    setIsLoading(true);
                    await new Promise(resolve => setTimeout(resolve, 1000));

                    setArchitectureData((prevData: any) => [response.data, ...prevData]);
                    setReportData((prevState: any) => ({
                        ...prevState,
                        futureStateArchitecture: Array.isArray(prevState.futureStateArchitecture)
                            ? [response.data, ...prevState.futureStateArchitecture]
                            : [response.data]
                    }));
                    setArchitectureName('');
                    setFile(null);
                    setFileName('');
                } else {
                    setFileError("Error: " + response.statusText);
                    setIsLoading(false);
                    setError("Error: " + response.statusText);
                    let toastData = {
                        toastType: 'error',
                        toastHeaderMessage: 'Error',
                        toastBodyMessage: 'API failed'
                    }
                    setToastProp(toastData)
                    setHideToast(false);
                }
            } catch (error) {
                setShowToasterFailedPopup(true);
                console.error('Error adding architecture details:', error);
                setFileError("An error occurred while adding the architecture details.");
            } finally {
                setIsLoading(false)
                setShowToasterLoading(false);
                setTimeout(() => {
                    setShowToasterPopup(false);
                    setShowToasterFailedPopup(false);
                }, 4000);
            }
        };

        const handleDeleteArchitecture = (item: any) => {
            setDeletingItemId(item[0].attributeMasterId);
            setShowDeleteModal(true);
        };

        const confirmDeleteArchitecture = async () => {
            try {
                const payload = { attributeMasterId: deletingItemId };
                const response = await deleteAttributeRecords(payload);
                if (response.status === 200) {
                    setShowDeletePopup(true);
                    setIsLoading(true);
                    closeModal();
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    setArchitectureData((prevData: any) => prevData.filter((dataItem: any) => dataItem[0].attributeMasterId !== deletingItemId));
                    setReportData((prevState: any) => ({
                        ...prevState,
                        futureStateArchitecture: Array.isArray(prevState.futureStateArchitecture)
                            ? prevState.futureStateArchitecture.filter((dataItem: any) => dataItem[0].attributeMasterId !== deletingItemId)
                            : []
                    }));
                } else {
                    setFileError("Error: " + response.statusText);
                    setIsLoading(false);
                    let toastData = {
                        toastType: 'error',
                        toastHeaderMessage: 'Error',
                        toastBodyMessage: 'API failed'
                    };
                    setToastProp(toastData);
                    setHideToast(false);
                    setError("Error: " + response.statusText);
                }
            } catch (error) {
                setShowToasterFailedPopup(true);
                setIsLoading(false);
                console.error('Error deleting architecture details:', error);
                setFileError("An error occurred while deleting the architecture details.");
            } finally {
                setIsLoading(false)
                setDeletingItemId(null)
                setTimeout(() => {
                    setShowDeletePopup(false);
                    setShowToasterFailedPopup(false);
                }, 4000);
            }

        }

        const handleEdit = (item: any) => {
            setEditingItem(item);
            const nameItem = item.find((attr: any) => attr.attributeName === 'Architecture Name');
            const blobItem = item.find((attr: any) => attr.attributeName === 'Architecture Blob URL');
            setEditArchitectureName(nameItem ? nameItem.attributeValue : '');

            if (blobItem && blobItem.attributeValue) {
                const fileName = extractFileNameFromBlobUrl(blobItem.attributeValue);
                setEditFileName(fileName);
            } else {
                setEditFileName('');
            }

            setEditNameError('');
            setEditFileError('');
        };

        const handleEditSave = async () => {
            let isValid = true;

            if (!editArchitectureName.trim()) {
                setEditNameError('Please enter an Architecture Name');
                isValid = false;
            } else if (editArchitectureName.length > 40) {
                setEditNameError('Please enter a valid Architecture Name');
                isValid = false;
            } else {
                setEditNameError('');
            }

            if (!editFile && !editFileName) {
                setEditFileError('Please select a file to upload');
                isValid = false;
            } else {
                setEditFileError('');
            }

            if (!isValid) return;
            setShowToasterLoading(true);

            try {
                let blobUrl = editingItem.find((attr: any) => attr.attributeName === 'Architecture Blob URL')?.attributeValue;
                if (editFile) {
                    blobUrl = await uploadFileToBlob1(editFile, 'futureStateArchitecture');
                }

                const payload = editingItem.map((attr: any) => {
                    if (attr.attributeName === 'Architecture Name') {
                        return {
                            ...attr,
                            attributeValue: editArchitectureName,
                        };
                    } else if (attr.attributeName === 'Architecture Blob URL') {
                        return {
                            ...attr,
                            attributeValue: blobUrl,
                        };
                    }
                    return attr;
                });

                const response = await postArchitectureDetails(payload);

                if (response.data && response.status === 200) {
                    setShowToasterPopup(true);
                    setIsLoading(true);
                    await new Promise(resolve => setTimeout(resolve, 1000));

                    setArchitectureData((prevData) => prevData.map((item) =>
                        item[0].attributeMasterId === editingItem[0].attributeMasterId ? response.data : item
                    ));
                    setReportData((prevState: any) => ({
                        ...prevState,
                        futureStateArchitecture: Array.isArray(prevState.futureStateArchitecture)
                            ? prevState.futureStateArchitecture.map((item: any) =>
                                item[0].attributeMasterId === editingItem[0].attributeMasterId ? response.data : item
                            )
                            : [response.data]
                    }));
                    setEditingItem(null);
                } else {
                    setIsLoading(false);
                    setFileError("Error: " + response.statusText);
                    let toastData = {
                        toastType: 'error',
                        toastHeaderMessage: 'Error',
                        toastBodyMessage: 'API failed'
                    }
                    setToastProp(toastData)
                    setHideToast(false);
                    setError("Error: " + response.statusText);
                }
            } catch (error) {
                console.error('Error editing architecture details:', error);
                setFileError("An error occurred while editing the architecture details.");
                setError("An error occurred while editing the architecture details.");
                setShowToasterFailedPopup(true);
                setIsLoading(false)
            } finally {
                setIsLoading(false)
                setShowToasterLoading(false);
                setTimeout(() => {
                    setShowToasterPopup(false);
                    setShowToasterFailedPopup(false);
                }, 4000);
            }
        };

        const extractFileNameFromBlobUrl = (blobUrl: string) => {
            const urlParts = blobUrl.split('/');
            let fileName = urlParts[urlParts.length - 1];

            if (!fileName.includes('.')) {
                const dashParts = blobUrl.split('-');
                fileName = dashParts[dashParts.length - 1];
            }

            fileName = fileName.split('?')[0];

            const iiiiiIndex = fileName.indexOf('iiiii-');
            if (iiiiiIndex !== -1) {
                fileName = fileName.substring(iiiiiIndex + 6);
            }

            fileName = decodeURIComponent(fileName);
            return fileName || 'Unknown File';
        };

        const handleCloseEditModal = () => {
            setEditingItem(null);
            setEditNameError('');
            setEditFileError('');
        };
        const handleRemoveFile = () => {
            console.log("removed")
            setFile(null);
            setFileName('');
            setError('');
            resetFileInput();
        };

        return (
            <div className="ms-lg-4">
                <h3 className="font-18 font-semibold color-black m-0 mt-4 mt-lg-0">Future State Architecture</h3>
                <div className="border-bottom my-4 pt-2 pb-4">
                    <div className="row row-gap-4">
                        <div className="col-12 col-md-6 col-lg-3">
                            <div>
                                <label htmlFor="architectureName" className="form-label font-14 font-semibold color-black">
                                    Architecture Name <span className="mandate">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control theme-form font-regular font-14"
                                    id="architectureName"
                                    placeholder="Architecture Name"
                                    value={architectureName}
                                    onChange={(e) => setArchitectureName(e.target.value)}
                                />
                                {nameError && <div className="text-danger font-12 mt-1">{nameError}</div>}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-5">
                            <div className="d-flex align-items-end justify-content-between">
                                <div>
                                    <label htmlFor="architectureUpload" className="form-label font-14 font-semibold color-black">
                                        Upload Architecture <span className="mandate">*</span>
                                    </label>

                                    <div className="position-relative d-flex align-items-center gap-3">
                                        <label className="file-upload-outline position-absolute rounded-3 d-flex align-items-center gap-2 font-12 font-semibold pd-12">
                                            <img src="images/upload-icon-blue.svg" alt="upload-icon" />Upload
                                        </label>
                                        <input
                                            type="file"
                                            className="form-control custom-file p-0"
                                            id="architectureUpload"
                                            aria-label="upload"
                                            onChange={handleFileChange}
                                            accept=".png,.jpg,.jpeg"
                                            style={{ opacity: 0 }}
                                            ref={fileInputRef}
                                        />
                                        {fileName && (
                                            <span className="font-12 font-medium color-grey">
                                                {fileName}
                                                <img
                                                    src="images/close-icon-grey.svg"
                                                    alt="close icon"
                                                    className="ms-2 cursor-pointer"
                                                    onClick={handleRemoveFile}
                                                />
                                            </span>
                                        )}
                                    </div>
                                    {fileError && <div className="text-danger font-12 mt-1">{fileError}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 ms-auto ms-md-0 mt-0 mt-4 pb-3">
                            <button
                                type="button"
                                className="btn btn-dark theme-primary-btn-md d-flex gap-2 align-items-center border-0 font-14 font-semibold"
                                onClick={handleAdd}
                                disabled={isSaving}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </div>
                {saveSuccess && <div className="alert alert-success mt-2">Architecture details has been saved successfully!</div>}

                {/* Display existing architecture data */}
                {architectureData && architectureData.length > 0 && (
                    <div>
                        {architectureData.map((item: any, index: number) => {
                            const nameItem = item.find((attr: any) => attr.attributeName === 'Architecture Name');
                            const blobItem = item.find((attr: any) => attr.attributeName === 'Architecture Blob URL');

                            return (
                                <div key={index} className="border rounded-3 p-3 mb-4">
                                    <div className="d-flex align-items-center font-16 font-medium color-black-v1">
                                        <div className="d-flex align-items-center">
                                            <img
                                                src="images/accordion-arrow-grey.svg"
                                                alt="accordion-arrow"
                                                className="cursor-pointer accordion-arrow me-2"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#architecture${index}`}
                                                aria-expanded={index === 0 ? "true" : "false"}
                                            />
                                            <span className="color-black font-16 font-semibold">{nameItem ? nameItem.attributeValue : 'Unnamed Architecture'}</span>
                                        </div>
                                        <div className="d-flex align-items-center ms-auto">
                                            <button type="button" className="btn edit-btn custom-btn font-medium" onClick={() => handleEdit(item)}>Edit</button>
                                            <button
                                                type="button"
                                                className="btn btn-outline-danger custom-btn ms-3 font-medium"
                                                onClick={() => {
                                                    handleDeleteArchitecture(item)
                                                }
                                                }
                                                disabled={deletingItemId === item[0].attributeMasterId}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                    <div className={`px-3 collapse ${index === 0 ? 'show' : ''}`} id={`architecture${index}`}>
                                        <div className="text-center pt-3">
                                            {blobItem && blobItem.attributeValue && (
                                                <img src={generateImageUrl(blobItem.attributeValue, sasToken)} alt="architecture diagram" className="w-100" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}

                {/* Edit Modal */}
                {editingItem && (
                    <>
                        <div className="modal-backdrop fade show"></div>
                        <div className="modal fade show" id="start-new-assessment" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="accept-promptLabel" aria-modal="true" role="dialog" style={{ display: 'block' }}>
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content custom-popup w-650">
                                    <div className="modal-header border-0 justify-content-between align-items-center position-relative px-4 pt-4 pb-2">
                                        <h2 className="modal-title mb-0 font-20 font-semibold primary-textcolor" id="accept-promptLabel">
                                            Edit Architecture
                                        </h2>
                                        <button type="button" className="btn" onClick={handleCloseEditModal} aria-label="Close">
                                            <img src="images/popup-close.svg" alt="Close" />
                                        </button>
                                    </div>
                                    <div className="modal-body border-0 px-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label className="form-label font-14 font-medium color-black" htmlFor="editArchitectureName">Architecture Name</label>
                                                <input
                                                    type="text"
                                                    id="editArchitectureName"
                                                    className="form-control custom-form color-black"
                                                    value={editArchitectureName}
                                                    onChange={(e) => setEditArchitectureName(e.target.value)}
                                                />
                                                {editNameError && <div className="text-danger font-12 mt-1">{editNameError}</div>}
                                            </div>
                                            <div className="col-md-12 mt-4">
                                                <label className="form-label font-14 font-medium color-black" htmlFor="editArchitectureUpload">Upload Architecture</label>
                                                <div className="position-relative d-flex align-items-center edit-architecture-popup gap-3">
                                                    <label className="file-upload-outline position-absolute rounded-3 d-flex align-items-center gap-2 font-12 font-semibold">
                                                        <img src="images/upload-icon-blue.svg" alt="upload-icon" />Upload
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control custom-file mb-4"
                                                        id="editArchitectureUpload"
                                                        onChange={(e) => handleFileChange(e, true)}
                                                        accept=".png,.jpg,.jpeg"
                                                        style={{ opacity: 0 }}
                                                        ref={fileInputRef}
                                                    />
                                                    {editFileName && (
                                                        <span className="font-12 font-medium color-grey">
                                                            {editFileName}
                                                            <img
                                                                src="images/close-icon-grey.svg"
                                                                alt="close icon"
                                                                className="ms-3 cursor-pointer"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setEditFile(null);
                                                                    setEditFileName('');
                                                                }}
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                                {editFileError && <div className="text-danger font-12 mt-1">{editFileError}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer px-4 border-0 justify-content-end pb-4 pt-4 gap-1">
                                        <a
                                            className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer"
                                            onClick={() => {
                                                setEditArchitectureName('');
                                                setEditFileName('');
                                                setEditFile(null);
                                                setEditNameError('');
                                                setEditFileError('');
                                            }}
                                        >
                                            Clear
                                        </a>
                                        <button
                                            type="button"
                                            className="btn btn-dark theme-primary-btn border-0 font-semibold"
                                            onClick={handleEditSave}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>
                )}
                {showDeleteModal && (
                    <div
                        className="modal fade show"
                        id="delete"
                        tabIndex={-1}
                        aria-hidden="true"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        style={{ display: 'block' }}
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content p-3">
                                <div className="modal-header pb-0 border-0">

                                    <button
                                        type="button"
                                        className="btn-close cursor-pointer"
                                        onClick={closeModal}
                                        aria-label="Close"
                                    ></button>
                                </div>

                                <div className="modal-body">
                                    <div className="w-100 text-center mb-3">
                                        <img src="images/delete-icon.svg" alt="delete" />
                                    </div>

                                    <p className="font-semibold font-24 text-center mb-2 red-400">
                                        Delete Confirmation
                                    </p>

                                    <p className="font-semibold font-16 text-center mb-5">
                                        Are you sure you want to delete?
                                    </p>

                                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-center mb-2 mb-md-0 w-100">
                                        <a
                                            className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer px-4"
                                            onClick={confirmDeleteArchitecture}
                                        >
                                            Yes
                                        </a>

                                        <button
                                            type="button"
                                            className="btn btn-danger red-400 btn-lg px-4 text-white font-14 font-semibold"
                                            onClick={closeModal}
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showDeleteModal && <div className="modal-backdrop fade show"></div>}
                {showToasterLoading && (
                    <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                        <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                            <div className="toast-body d-flex align-items-center ">
                                <div className="d-block me-3">
                                    <img
                                        src="images/loading.gif"
                                        alt="toast-success"
                                        style={{ height: "15px", width: "15px" }}
                                    />
                                </div>
                                <div className="d-block">
                                    <span className="font-medium font-16 color-grey">
                                        Saving...
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {showToasterPopup && (
                    <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                        <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                            <div className="toast-body d-flex align-items-center ">
                                <div className="d-block me-3">
                                    <img src="images/toast-success.svg" alt="toast-success" />
                                </div>
                                <div className="d-block">
                                    <span className="font-medium font-16 color-grey">
                                        Architecture has been added successfully
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {showToasterFailedPopup && (
                    <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                        <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                            <div className="toast-body d-flex align-items-center ">
                                <div className="d-block me-3">
                                    <img src="images/fail-icon.svg" alt="toast-fail" />
                                </div>
                                <div className="d-block">
                                    <span className="font-medium font-16 color-grey">
                                        Api failed.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {showDeletePopup && (
                    <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                        <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                            <div className="toast-body d-flex align-items-center ">
                                <div className="d-block me-3">
                                    <img src="images/toast-success.svg" alt="toast-success" />
                                </div>
                                <div className="d-block">
                                    <span className="font-medium font-16 color-grey">
                                        Architecture has been Deleted successfully.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const EditRecommendations = ({ setIsEditModalOpen, challenge, onSave }: any) => {

        const [editedChallenge, setEditedChallenge] = useState({
            title: challenge?.find((item: any) => item.attributeName === 'Title')?.attributeValue || '',
            challenges: challenge?.find((item: any) => item.attributeName === 'Challenges')?.attributeValue || '',
            recommendations: challenge?.find((item: any) => item.attributeName === 'Recommendations')?.attributeValue || ''
        });

        const [titleError, setTitleError] = useState('');
        const [challengesError, setChallengesError] = useState('');
        const [recommendationsError, setRecommendationsError] = useState('');

        useEffect(() => {
            setEditedChallenge({
                title: challenge?.find((item: any) => item.attributeName === 'Title')?.attributeValue || '',
                challenges: challenge?.find((item: any) => item.attributeName === 'Challenges')?.attributeValue || '',
                recommendations: challenge?.find((item: any) => item.attributeName === 'Recommendations')?.attributeValue || ''
            });
        }, [challenge]);

        const validateInputs = () => {
            let isValid = true;

            if (!editedChallenge.title.trim()) {
                setTitleError('Please enter the title ');
                isValid = false;
            } else if (editedChallenge.title.length > 100) {
                setTitleError('Title is too long');
                isValid = false;
            } else {
                setTitleError('');
            }

            if (!editedChallenge.challenges.trim()) {
                setChallengesError('Please enter the challenge');
                isValid = false;
            } else if (editedChallenge.challenges.length > 500) {
                setTitleError('Challenges is too long');
                isValid = false;
            } else {
                setChallengesError('');
            }

            if (!editedChallenge.recommendations.trim()) {
                setRecommendationsError('Please enter the recommendations');
                isValid = false;
            } else if (editedChallenge.recommendations.length > 500) {
                setTitleError('Recommendations is too long');
                isValid = false;
            } else {
                setRecommendationsError('');
            }

            return isValid;
        };

        const handleInputChange = (e: any) => {
            const { id, value } = e.target;
            setEditedChallenge(prev => ({
                ...prev,
                [id.replace('_', '')]: value
            }));
        };

        const handleSave = () => {
            if (!validateInputs()) return;

            const updatedChallenge = challenge.map((item: any) => ({
                ...item,
                attributeValue: editedChallenge[item.attributeName.toLowerCase() as keyof typeof editedChallenge]
            }));
            onSave(updatedChallenge);
            setIsEditModalOpen(false);
        };

        const handleClear = () => {
            setEditedChallenge({
                title: '',
                challenges: '',
                recommendations: ''
            });
            setTitleError('');
            setChallengesError('');
            setRecommendationsError('');
        };

        const handleClose = () => {
            setTitleError('');
            setChallengesError('');
            setRecommendationsError('');
            setIsEditModalOpen(false);
        }

        return (
            <>
                <div className="modal-backdrop fade show"></div>
                <div className={"modal fade show"}
                    id="_editRecommendation"
                    style={{ display: 'block' }} >
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content custom-popup w-650">
                            <div className="modal-header border-0 justify-content-between align-items-center position-relative px-4 pb-2 pt-3">
                                <h2 className="modal-title mb-0 font-20 font-semibold primary-textcolor" id="accept-promptLabel">
                                    Edit Recommendation
                                </h2>
                                <button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                    <img src="images/popup-close.svg" alt="Close" />
                                </button>
                            </div>
                            <div className="modal-body border-0 px-4 pt-0">
                                <div className="row row-gap-4">
                                    <div className="col-12">
                                        <label htmlFor="_title" className="form-label color-black font-16 font-medium mb-2">Title <span className="mandate">*</span></label>
                                        <input
                                            type="text"
                                            id="_title"
                                            className="form-control rounded-1 shadow-none"
                                            placeholder="Enter Title"
                                            value={editedChallenge.title}
                                            onChange={handleInputChange}
                                        />
                                        {titleError && <div className="text-danger font-12 mt-1">{titleError}</div>}
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="_challenges" className="form-label color-black font-16 font-medium mb-2">Challenges <span className="mandate">*</span></label>
                                        <textarea
                                            id="_challenges"
                                            className="resize-none form-control shadow-none rounded-1 h-120px"
                                            placeholder="Enter Challenges"
                                            value={editedChallenge.challenges}
                                            onChange={handleInputChange}
                                        />
                                        {challengesError && <div className="text-danger font-12 mt-1">{challengesError}</div>}
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="_recommendations" className="form-label color-black font-16 font-medium mb-2">Recommendation <span className="mandate">*</span></label>
                                        <textarea
                                            id="_recommendations"
                                            className="resize-none form-control shadow-none rounded-1 h-120px"
                                            placeholder="Enter Recommendation"
                                            value={editedChallenge.recommendations}
                                            onChange={handleInputChange}
                                        />
                                        {recommendationsError && <div className="text-danger font-12 mt-1">{recommendationsError}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer px-4 border-0 justify-content-end pb-4 pt-4 gap-1">
                                <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer" onClick={handleClear}>Clear</a>
                                <button type="button" className="btn btn-dark theme-primary-btn border-0 font-semibold " onClick={handleSave}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const ChallengesAndRecommendation = ({ challengesRecommendations, onUpdate }: any) => {

        const [isEditModalOpen, setIsEditModalOpen] = useState(false);
        const [editingChallenge, setEditingChallenge] = useState(null);
        const [newChallenge, setNewChallenge] = useState({
            title: '',
            challenges: '',
            recommendations: ''
        });
        const [error, setError] = useState<string>('');
        const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
        const [isAccordionOpen, setIsAccordionOpen] = useState(false);
        const [titleError, setTitleError] = useState('');
        const [challengesError, setChallengesError] = useState('');
        const [recommendationsError, setRecommendationsError] = useState('');

        const validateInputs = (challenge: any) => {
            let isValid = true;

            if (!challenge.title.trim()) {
                setTitleError('Please enter the title');
                isValid = false;
            } else if (challenge.title.length > 100) {
                setTitleError('Title length is too long');
                isValid = false;
            }
            else {
                setTitleError('');
            }

            if (!challenge.challenges.trim()) {
                setChallengesError('Please enter the challenge ');
                isValid = false;
            } else if (challenge.challenges.length > 500) {
                setTitleError('Challenge length is too long');
                isValid = false;
            } else {
                setChallengesError('');
            }

            if (!challenge.recommendations.trim()) {
                setRecommendationsError('Please enter the recommendations');
                isValid = false;
            } else if (challenge.recommendations.length > 500) {
                setTitleError('Recommendations is too long');
                isValid = false;
            } else {
                setRecommendationsError('');
            }

            return isValid;
        };

        const handleAccordionToggle = () => {
            setIsAccordionOpen(!isAccordionOpen);
        };

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { id, value } = e.target;
            setNewChallenge(prev => ({
                ...prev,
                [id.replace('_', '')]: value
            }));
        };

        const handleAddChallenge = async () => {
            if (!validateInputs(newChallenge)) return;
            const challengePayload = [
                {
                    organizationId: initialReportData.organizationId,
                    verticalId: initialReportData.verticalId,
                    projectId: initialReportData.projectId,
                    connectorId: initialReportData.connectors.find((c: any) => c.connectorName === "Challenges & Recommendations")?.connectorId,
                    connectorName: "Challenges & Recommendations",
                    attributeMasterId: "",
                    attributeId: "",
                    attributeName: "Title",
                    attributeValue: newChallenge.title ? escapeJsonString(newChallenge.title.trim().replace(regex, "")) : ""
                },
                {
                    organizationId: initialReportData.organizationId,
                    verticalId: initialReportData.verticalId,
                    projectId: initialReportData.projectId,
                    connectorId: initialReportData.connectors.find((c: any) => c.connectorName === "Challenges & Recommendations")?.connectorId,
                    connectorName: "Challenges & Recommendations",
                    attributeMasterId: "",
                    attributeId: "",
                    attributeName: "Challenges",
                    attributeValue: newChallenge.challenges ? escapeJsonString(newChallenge.challenges.trim().replace(regex, "")) : ""
                },
                {
                    organizationId: initialReportData.organizationId,
                    verticalId: initialReportData.verticalId,
                    projectId: initialReportData.projectId,
                    connectorId: initialReportData.connectors.find((c: any) => c.connectorName === "Challenges & Recommendations")?.connectorId,
                    connectorName: "Challenges & Recommendations",
                    attributeMasterId: "",
                    attributeId: "",
                    attributeName: "Recommendations",
                    attributeValue: newChallenge.recommendations ? escapeJsonString(newChallenge.recommendations.trim().replace(regex, "")) : ""
                }
            ];
            setShowToasterLoading(true);
            try {
                const response = await postArchitectureDetails(challengePayload);
                setShowToasterPopup(true);
                setIsLoading(true);
                await new Promise(resolve => setTimeout(resolve, 1000));
                onUpdate([...challengesRecommendations, response.data]);
                setNewChallenge({ title: '', challenges: '', recommendations: '' });
                setConclusionData([])
                setSaveSuccess(true);

            } catch (error) {
                setIsLoading(false);
                console.error("Error adding new challenge:", error);
                setError("An error occurred while adding the new challenge.");
                setShowToasterFailedPopup(true);
            } finally {
                setIsLoading(false)
                setShowToasterLoading(false);
                setTimeout(() => {
                    setShowToasterPopup(false);
                    setShowToasterFailedPopup(false);
                }, 4000);
            }
        };

        const handleEditSave = async (updatedChallenge: any) => {
            const editPayload = updatedChallenge.map((item: any) => ({
                organizationId: initialReportData.organizationId,
                verticalId: initialReportData.verticalId,
                projectId: initialReportData.projectId,
                connectorId: item.connectorId,
                connectorName: item.connectorName,
                attributeMasterId: item.attributeMasterId,
                attributeId: item.attributeId,
                attributeName: item.attributeName,
                attributeValue: item.attributeValue ? escapeJsonString(item.attributeValue.trim().replace(regex, "")) : ""
            }));
            setShowToasterLoading(true);
            try {
                const response = await postArchitectureDetails(editPayload);
                setShowToasterPopup(true);
                setIsLoading(true);
                await new Promise(resolve => setTimeout(resolve, 1000));
                const updatedChallengesRecommendations = challengesRecommendations.map((challenge: any) =>
                    challenge[0].attributeId === updatedChallenge[0].attributeId ? response.data : challenge
                );
                onUpdate(updatedChallengesRecommendations);
                setSaveSuccess(true);
                setConclusionData([]);
                setTimeout(() => setSaveSuccess(false), 2000);
            } catch (error) {
                setShowToasterFailedPopup(true);
                setIsLoading(false);
                console.error("Error updating challenge:", error);
                setError("An error occurred while updating the challenge.");
            } finally {
                setIsLoading(false)
                setShowToasterLoading(false);
                setTimeout(() => {
                    setShowToasterPopup(false);
                    setShowToasterFailedPopup(false);
                }, 4000);
            }
        };

        const handleClear = () => {
            setNewChallenge({
                title: '',
                challenges: '',
                recommendations: ''
            });
            setTitleError('');
            setChallengesError('');
            setRecommendationsError('');
        };

        const handleDeleteChallenges = async (challengeToDelete: any) => {
            const attributeMasterId = challengeToDelete[0].attributeMasterId;
            setDeletingItemId(attributeMasterId);
            setShowDeleteModal(true);
        };

        const confirmDeleteChallenges = async () => {
            try {
                setError('');
                const payload = { attributeMasterId: deletingItemId };
                const response = await deleteAttributeRecords(payload);

                if (response.status === 200) {
                    setShowDeletePopup(true);
                    setIsLoading(true);
                    closeModal();
                    await new Promise(resolve => setTimeout(resolve, 1000));

                    const updatedChallengesRecommendations = challengesRecommendations.filter(
                        (challenge: any) => challenge[0].attributeMasterId !== deletingItemId
                    );
                    onUpdate(updatedChallengesRecommendations);
                    setSaveSuccess(true);
                    setConclusionData([]);
                    setTimeout(() => setSaveSuccess(false), 2000);
                } else {
                    setIsLoading(false);
                    let toastData = {
                        toastType: 'error',
                        toastHeaderMessage: 'Error',
                        toastBodyMessage: 'API failed'
                    };
                    setToastProp(toastData);
                    setHideToast(false);
                    setError("Error: " + response.statusText);
                }
            } catch (error) {
                setShowToasterFailedPopup(true);
                setIsLoading(false);
                console.error('Error deleting challenge:', error);
                setError("An error occurred while deleting the challenge.");
            } finally {
                setIsLoading(false)
                setDeletingItemId(null);
                setTimeout(() => {
                    setShowDeletePopup(false);
                    setShowToasterFailedPopup(false);
                }, 4000);
            }

        }

        return (
            <div className="ms-lg-4">
                <div className="rounded-3 p-4 mb-5 bg-grey">
                    <span
                        className="d-flex align-items-center font-16 font-semibold color-black"
                        data-bs-toggle="collapse"
                        data-bs-target="#crm_Dev"
                        aria-expanded={isAccordionOpen}
                    >
                        <img
                            src="images/chevron-down.svg"
                            alt="accordion-arrow"
                            className="cursor-pointer accordion-arrow me-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#crm_Dev"
                            aria-expanded={isAccordionOpen}
                            onClick={handleAccordionToggle}
                        />
                        Add New Challenges & Recommendations
                    </span>
                    <div className={`collapse ${isAccordionOpen ? 'show' : ''}`} id="crm_Dev">
                        <div className="row mt-1 row-gap-4">
                            <div className="col-12">
                                <label htmlFor="_title" className="form-label color-black font-16 font-medium mb-2">Title <span className="mandate">*</span></label>
                                <input
                                    type="text"
                                    id="_title"
                                    className="form-control rounded-1 shadow-none bg-grey"
                                    placeholder="Enter Title"
                                    value={newChallenge.title}
                                    onChange={handleInputChange}
                                />
                                {titleError && <div className="text-danger font-12 mt-1">{titleError}</div>}
                            </div>
                            <div className="col-12">
                                <label htmlFor="_challenges" className="form-label color-black font-16 font-medium mb-2">Challenges <span className="mandate">*</span></label>
                                <textarea
                                    id="_challenges"
                                    className="resize-none form-control bg-grey shadow-none rounded-1 h-115px"
                                    value={newChallenge.challenges}
                                    placeholder="Enter Challenge"
                                    onChange={handleInputChange}
                                />
                                {challengesError && <div className="text-danger font-12 mt-1">{challengesError}</div>}
                            </div>
                            <div className="col-12">
                                <label htmlFor="_recommendations" className="form-label color-black font-16 font-medium mb-2">Recommendation <span className="mandate">*</span></label>
                                <textarea
                                    id="_recommendations"
                                    className="resize-none form-control bg-grey shadow-none rounded-1 h-115px"
                                    value={newChallenge.recommendations}
                                    placeholder="Enter Recommendation"
                                    onChange={handleInputChange}
                                />
                                {recommendationsError && <div className="text-danger font-12 mt-1">{recommendationsError}</div>}
                            </div>
                            <div className="col-12">
                                <div className="d-flex justify-content-end">
                                    <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer py-2" onClick={handleClear}>Clear</a>
                                    <button type="button" className="btn btn-dark theme-primary-btn-md border-0 font-semibold py-2" onClick={handleAddChallenge}>Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {error && <div className="alert alert-danger mt-2">{error}</div>}
                {saveSuccess && <div className="alert alert-success mt-2">Operation completed successfully!</div>}

                {challengesRecommendations.map((challenge: any, index: number) => {
                    const challengeValue = challenge.find((item: any) => item.attributeName === 'Challenges')?.attributeValue;

                    return challengeValue ? (
                        <div key={index}>
                            <div className="d-flex align-items-center mt-3 mb-4 justify-content-between">
                                <p className="font-16 font-bold m-0 color-black">{challenge.find((item: any) => item.attributeName === 'Title')?.attributeValue}</p>
                                <div className="d-flex align-items-center">
                                    <button
                                        type="button"
                                        className="btn edit-btn custom-btn font-medium"
                                        onClick={() => {
                                            setEditingChallenge(challenge);
                                            setIsEditModalOpen(true);
                                        }}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-outline-danger custom-btn ms-3 font-medium"
                                        onClick={() => handleDeleteChallenges(challenge)}
                                        disabled={deletingItemId === challenge[0].attributeMasterId}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                            <p className="font-16 font-semibold my-3 color-black">Challenges:</p>
                            <p className="font-regular font-16 color-black mt-3 mb-4">
                                {challengeValue}
                            </p>
                            <p className="font-16 font-semibold my-3 color-black">Recommendation:</p>
                            <p className="font-regular font-16 color-black mt-3 mb-4">
                                {challenge.find((item: any) => item.attributeName === 'Recommendations')?.attributeValue}
                            </p>
                        </div>
                    ) : null;
                })}
                {showDeleteModal && (
                    <div
                        className="modal fade show"
                        id="delete"
                        tabIndex={-1}
                        aria-hidden="true"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        style={{ display: 'block' }}
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content p-3">
                                <div className="modal-header pb-0 border-0">

                                    <button
                                        type="button"
                                        className="btn-close cursor-pointer"
                                        onClick={closeModal}
                                        aria-label="Close"
                                    ></button>
                                </div>

                                <div className="modal-body">
                                    <div className="w-100 text-center mb-3">
                                        <img src="images/delete-icon.svg" alt="delete" />
                                    </div>

                                    <p className="font-semibold font-24 text-center mb-2 red-400">
                                        Delete Confirmation
                                    </p>

                                    <p className="font-semibold font-16 text-center mb-5">
                                        Are you sure you want to delete?
                                    </p>

                                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-center mb-2 mb-md-0 w-100">
                                        <a
                                            className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer px-4"
                                            onClick={confirmDeleteChallenges}
                                        >
                                            Yes
                                        </a>

                                        <button
                                            type="button"
                                            className="btn btn-danger red-400 btn-lg px-4 text-white font-14 font-semibold"
                                            onClick={closeModal}
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showDeleteModal && <div className="modal-backdrop fade show"></div>}
                {showToasterLoading && (
                    <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                        <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                            <div className="toast-body d-flex align-items-center ">
                                <div className="d-block me-3">
                                    <img
                                        src="images/loading.gif"
                                        alt="toast-success"
                                        style={{ height: "15px", width: "15px" }}
                                    />
                                </div>
                                <div className="d-block">
                                    <span className="font-medium font-16 color-grey">
                                        Saving...
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {showToasterPopup && (
                    <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                        <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                            <div className="toast-body d-flex align-items-center ">
                                <div className="d-block me-3">
                                    <img src="images/toast-success.svg" alt="toast-success" />
                                </div>
                                <div className="d-block">
                                    <span className="font-medium font-16 color-grey">
                                        Challenges and Recommendations has been added successfully
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {showToasterFailedPopup && (
                    <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                        <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                            <div className="toast-body d-flex align-items-center ">
                                <div className="d-block me-3">
                                    <img src="images/fail-icon.svg" alt="toast-fail" />
                                </div>
                                <div className="d-block">
                                    <span className="font-medium font-16 color-grey">
                                        Api failed.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {showDeletePopup && (
                    <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                        <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                            <div className="toast-body d-flex align-items-center ">
                                <div className="d-block me-3">
                                    <img src="images/toast-success.svg" alt="toast-success" />
                                </div>
                                <div className="d-block">
                                    <span className="font-medium font-16 color-grey">
                                        Challenges and Recommendations has been Deleted successfully.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {isEditModalOpen && <EditRecommendations
                    setIsEditModalOpen={setIsEditModalOpen}
                    challenge={editingChallenge}
                    onSave={handleEditSave}
                />}
            </div>
        );
    }

    const Conclusion = () => {
        const initialConclusionData = conclusionData[0] ?? [];
        const [conclusionDescription, setConclusionDescription] = useState<string>(initialConclusionData[0]?.attributeValue || '');
        const [isLoading, setIsLoading] = useState<boolean>(false);
        const [error, setError] = useState<string>("");
        const [isEdit, setIsEdit] = useState<boolean>(false);
        const [showGenerateToaster, setShowGenerateToaster] = useState<boolean>(false)
        const [showSaveToaster, setShowSaveToaster] = useState<boolean>(false);
        const [showGenerateMessage, setShowGenerateMessage] = useState<boolean>(false)
        const [showSaveMessage, setShowSaveMessage] = useState<boolean>(false);
        const [toastState, setToastState] = useState({
            loading: false,
            success: false,
            error: false,
            message: ''
        });
        const [isGenerated, setIsGenerated] = useState<boolean>(!!initialConclusionData[0]?.attributeValue);
        const isBusinessOverviewEmpty = !(businessOverview.trim() === "");
        const isChallengesEmpty = (reportData?.challengesRecommendations?.length ?? 0) >= 1;
        const shouldShowGenerateButton = isBusinessOverviewEmpty && isChallengesEmpty && !isGenerated;

        const getConclusionDescription = async () => {
            setError("");
            setShowGenerateToaster(true)
            try {
                const payload = {
                    organizationId: reportData.organizationId,
                    verticalId: reportData.verticalId,
                    projectId: reportData.projectId,
                    connectorId: reportData.connectors.find((c: any) => c.connectorName === "Conclusion")?.connectorId,
                    connectorName: "Conclusion",
                    attributeMasterId: "",
                    attributeId: "",
                    attributeName: "Conclusion Description",
                    attributeValue: "",
                    businessOverview: escapeJsonString(businessOverview.replace(regex, "")),
                    challenges: reportData.challengesRecommendations.map((cr: any) => ({
                        title: escapeJsonString((cr[0].attributeValue).replace(regex, "")),
                        challenges: escapeJsonString((cr[1].attributeValue).replace(regex, "")),
                        recommendations: escapeJsonString((cr[2].attributeValue).replace(regex, "")),
                    }))
                };
                const [generateConclusionAPIResponse] = await Promise.all([
                    generateConclusionDetails(payload),
                    new Promise(resolve => setTimeout(resolve, 5000))
                ]);

                if (generateConclusionAPIResponse.data && generateConclusionAPIResponse.status === 200) {
                    setShowGenerateMessage(true);
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    setIsGenerated(true);

                    const newConclusionData = generateConclusionAPIResponse.data[0];
                    setConclusionData([[newConclusionData]]);
                    setConclusionDescription(newConclusionData.attributeValue ? escapeJsonString(newConclusionData.attributeValue.trim().replace(regex, "")) : "");
                    setReportData((prevState: any) => ({
                        ...prevState,
                        conclusion: [[newConclusionData]]
                    }));
                    await new Promise(resolve => setTimeout(resolve, 2000));
                } else {
                    setIsLoading(false);
                    setError("Error: " + generateConclusionAPIResponse.statusText);
                    let toastData = {
                        toastType: 'error',
                        toastHeaderMessage: 'Error',
                        toastBodyMessage: 'API failed'
                    }
                    setToastProp(toastData)
                    setHideToast(false);
                }
            } catch (error) {
                setShowToasterFailedPopup(true);
                console.error('Error generating conclusion:', error);
                setError("An error occurred while generating the conclusion.");
            } finally {
                setIsLoading(false)
                setShowGenerateToaster(false);
                setShowSaveMessage(false);
                setShowToasterFailedPopup(false);
            }
        };

        const handleSave = async (newValue: string) => {

            const updatedConclusionData = {
                ...conclusionData,
                attributeValue: newValue
            };

            const payload = {
                organizationId: reportData.organizationId,
                verticalId: reportData.verticalId,
                projectId: reportData.projectId,
                connectorId: reportData.connectors.find((c: any) => c.connectorName === "Conclusion")?.connectorId,
                connectorName: "Conclusion",
                attributeMasterId: updatedConclusionData[0][0].attributeMasterId,
                attributeId: updatedConclusionData[0][0].attributeId,
                attributeName: "Conclusion Description",
                attributeValue: newValue ? escapeJsonString(newValue.trim().replace(regex, "")) : "",
                businessOverview: escapeJsonString(businessOverview.replace(regex, "")),
                challenges: reportData.challengesRecommendations.map((cr: any) => ({
                    title: escapeJsonString((cr[0].attributeValue).replace(regex, "")),
                    challenges: escapeJsonString((cr[1].attributeValue).replace(regex, "")),
                    recommendations: escapeJsonString((cr[2].attributeValue).replace(regex, "")),
                }))
            };
            setShowSaveToaster(true)
            try {
                const updatedGenerateConclusionApiResponse = await generateConclusionDetails(payload);
                if (updatedGenerateConclusionApiResponse.data && updatedGenerateConclusionApiResponse.status === 200) {
                    setShowSaveMessage(true);
                    setIsLoading(true);
                    await new Promise(resolve => setTimeout(resolve, 3000));
                    const newConclusionData = updatedGenerateConclusionApiResponse.data[0];
                    setConclusionData([[newConclusionData]]);
                    setConclusionDescription(newConclusionData.attributeValue);
                    setReportData((prevState: any) => ({
                        ...prevState,
                        conclusion: [[newConclusionData]]
                    }));
                } else {
                    setIsLoading(false);
                    setError("Error: " + updatedGenerateConclusionApiResponse.statusText);
                    let toastData = {
                        toastType: 'error',
                        toastHeaderMessage: 'Error',
                        toastBodyMessage: 'API failed'
                    }
                    setToastProp(toastData)
                    setHideToast(false);
                }
            } catch (error) {
                setShowToasterFailedPopup(true);
                console.error('Error updating conclusion:', error);
                setError("An error occurred while updating the conclusion.");
            } finally {
                setIsLoading(false)
                setShowSaveToaster(false);
                setTimeout(() => {
                    setShowSaveMessage(false);
                    setShowToasterFailedPopup(false);
                }, 4000);
            }
        };

        const toggleEdit = () => {
            setIsEdit(!isEdit);
        };

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-12 mb-3">
                    <div className="ribbon py-2 px-3 ms-md-4">
                        <span className="font-12 font-medium">Please click Generate Conclusion again if there are any additions or changes in Challenges and Recommendations or Business Overview.</span>
                        <span className="ribbon-border" />
                    </div>
                </div>

                <div className="col-lg-12 col-md-12 col-12">
                    <div className="ps-lg-4">
                        <div className="d-flex justify-content-between align-items-center my-2 mb-4">
                            <h3 className="font-18 font-semibold color-black mb-0">
                                Conclusion
                            </h3>
                            {isGenerated && !isEdit && (
                                <button
                                    type="button"
                                    className={`btn edit-btn custom-btn font-medium px-4`}
                                    onClick={toggleEdit}
                                >
                                    Edit
                                </button>
                            )}
                        </div>

                        {shouldShowGenerateButton && (
                            <button
                                type="button"
                                className="btn btn-dark theme-outline-btn regenerate-btn d-flex gap-2 align-items-center font-14 font-semibold lh-1 mb-4"
                                onClick={getConclusionDescription}
                                disabled={isLoading}
                            >
                                Generate Conclusion
                            </button>
                        )}

                        {error && <p className="text-danger mt-2">{error}</p>}

                        {isGenerated ? (
                            <div className="mt-3">
                                {isEdit ? (
                                    <TextEditor
                                        value={conclusionDescription}
                                        onSave={handleSave}
                                        onCancel={toggleEdit}
                                    />
                                ) : (
                                    <div dangerouslySetInnerHTML={{ __html: conclusionDescription }} />
                                )}
                            </div>
                        ) : (
                            <div className="mt-3">
                                {!isBusinessOverviewEmpty && !isChallengesEmpty && (
                                    <p>Please add business overview and challenges to generate the conclusion.</p>
                                )}
                                {!isBusinessOverviewEmpty && isChallengesEmpty && (
                                    <p>Please add business overview to generate the conclusion.</p>
                                )}
                                {isBusinessOverviewEmpty && !isChallengesEmpty && (
                                    <p>Please add challenges and recommendations to generate the conclusion.</p>
                                )}
                            </div>
                        )}

                        {showGenerateToaster && (
                            <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                                <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                                    <div className="toast-body d-flex align-items-center ">
                                        <div className="d-block me-3">
                                            <img
                                                src="images/loading.gif"
                                                alt="toast-success"
                                                style={{ height: "15px", width: "15px" }}
                                            />
                                        </div>
                                        <div className="d-block">
                                            <span className="font-medium font-16 color-grey">
                                                Generating...
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )}

                        {showSaveToaster && (
                            <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                                <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                                    <div className="toast-body d-flex align-items-center ">
                                        <div className="d-block me-3">
                                            <img
                                                src="images/loading.gif"
                                                alt="toast-success"
                                                style={{ height: "15px", width: "15px" }}
                                            />
                                        </div>
                                        <div className="d-block">
                                            <span className="font-medium font-16 color-grey">
                                                Saving...
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )
                        }

                        {showSaveMessage && (
                            <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                                <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                                    <div className="toast-body d-flex align-items-center ">
                                        <div className="d-block me-3">
                                            <img src="images/toast-success.svg" alt="toast-success" />
                                        </div>
                                        <div className="d-block">
                                            <span className="font-medium font-16 color-grey">
                                                Conclusion has been saved successfully
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {showGenerateMessage && (
                            <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                                <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                                    <div className="toast-body d-flex align-items-center ">
                                        <div className="d-block me-3">
                                            <img src="images/toast-success.svg" alt="toast-success" />
                                        </div>
                                        <div className="d-block">
                                            <span className="font-medium font-16 color-grey">
                                                Conclusion has been generated successfully
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {showToasterFailedPopup && (
                            <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                                <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                                    <div className="toast-body d-flex align-items-center ">
                                        <div className="d-block me-3">
                                            <img src="images/fail-icon.svg" alt="toast-fail" />
                                        </div>
                                        <div className="d-block">
                                            <span className="font-medium font-16 color-grey">
                                                Api failed.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            {/* top header starts here */}

            {/* top header ends here */}
            <div className="container-fluid" style={{ marginTop: "105px" }}>
                <div className="row justify-content-center">
                    <div className="px-4">
                        {/* breadcrumb starts here */}
                        <BreadCrumbs data={breadCrumbs}></BreadCrumbs>
                        {/* breadcrumb ends here */}
                        {/* heading content starts here */}
                        <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-4">
                            <div className="d-flex align-items-center mb-3 mb-md-0">
                                <a href="#"><img src="images/backarrow.svg" alt="back-arrow" title="Back" onClick={(event: any) => {
                                    event.preventDefault();
                                    navigate("/manageassessment", { state: { organizationId: initialReportData.organizationId, organizationName: initialReportData.organizationName } })
                                }} /></a>
                                <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                                    MDPW Assessment
                                </h2>
                            </div>
                            <div className="d-grid gap-3 d-md-inline-flex justify-content-md-end">
                                <button type="button" className="btn btn-dark theme-primary-btn-md d-flex gap-2 align-items-center border-0 font-14 font-semibold" onClick={() => {
                                    navigate("/previewpage", {
                                        state: { data: initialReportData },
                                    });
                                }}
                                ><img src="images/dwnld-icon-white.svg" alt="download-icon" />
                                    Download Report
                                </button>
                            </div>
                        </div>
                        {/* heading content ends here */}
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-3 col-xl-2">
                                <div className="row">
                                    <div className="d-lg-flex d-none pe-0 align-items-start v-tab-border left-nav-brd">
                                        <div className="nav flex-column nav-pills custom-v-pills w-100" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            <p className="font-16 font-semibold mb-lg-2 mb-2 ps-0 ps-lg-3">Prerequisite</p>
                                            <button className={`nav-link text-start rounded-0 font-medium font-14 ${selectedNavBar === "uploadTranscript" ? "active" : ""}`} data-bs-toggle="pill" id="uploadTranscript" type="button" role="tab" aria-selected="true" onClick={(event: any) => { setSelectedNavBar(event.target.id) }}>Upload Transcript</button>                                            <p className="font-16 font-semibold my-lg-2  my-2 ps-0 ps-lg-3">Report</p>
                                            {BindMenus()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                                <div className="row">
                                    <div className="tab-content" id="v-pills-tabContent">
                                        {selectedNavBar === "uploadTranscript" &&
                                            <UploadTranscript fileInfo={summaryFile} setFileInfo={setSummaryFile} postTranscript={postUploadedTranscript.bind(null, "executiveSummary")} showToasterLoading={showToasterLoading}
                                                showToasterPopup={showToasterPopup}
                                                showToasterFailedPopup={showToasterFailedPopup}
                                                hideToast={hideToast}
                                                toastProp={toastProp} />}

                                        {selectedNavBar === "Business Flow" && (
                                            <BusinessAndWorkFlowTab
                                                flowType="Business Flow"
                                                flowData={businessFlowData}
                                                updateFlowData={(newData: any) => updateFlowData('businessFlow', newData)}
                                                reportData={reportData}
                                                updateReportData={(newData: any) => updateReportData('businessFlow', newData)}
                                            />
                                        )}
                                        {selectedNavBar === "Workflow Component" && (
                                            <BusinessAndWorkFlowTab
                                                flowType="Work Flow"
                                                flowData={workFlowData}
                                                updateFlowData={(newData: any) => updateFlowData('workflow', newData)}
                                                reportData={reportData}
                                                updateReportData={(newData: any) => updateReportData('workflow', newData)}
                                            />
                                        )}
                                        {selectedNavBar === "Executive Summary" &&
                                            <ExecutiveSummary verticalName="MDPW" summaryName={"Executive Summary"} summary={execSummary}
                                                postSummary={postSummary.bind(null, "executiveSummary")} sessions={sessionDetials} setSessions={setSessionDetials} postSession={postSession} deleteSession={deleteSession} confirmDeletesession={confirmDeletesession} setShowDeleteModal={setShowDeleteModal} showDeleteModal={showDeleteModal} showToasterLoading={showToasterLoading}
                                                showToasterPopup={showToasterPopup}
                                                showToasterFailedPopup={showToasterFailedPopup}
                                                hideToast={hideToast}
                                                toastProp={toastProp}
                                                showDeletePopup={showDeletePopup} />}
                                        {selectedNavBar === "Business Overview" && <ExecutiveSummary summaryName={"Business Overview"}
                                            summary={businessOverview}
                                            postSummary={postSummary.bind(null, "businessOverview")}
                                            verticalName="MDPW" showToasterPopup={showToasterPopup}
                                            showToasterFailedPopup={showToasterFailedPopup}
                                            hideToast={hideToast}
                                            toastProp={toastProp} />}

                                        {selectedNavBar === "Future State Architecture" && <FutureStateArchitecture />}
                                        {selectedNavBar === "Challenges & Recommendations" && <ChallengesAndRecommendation
                                            challengesRecommendations={challengesRecommendations}
                                            onUpdate={handleUpdate}
                                        />}
                                        {selectedNavBar === "Current Infrastructure Cost" && <CostEstimation
                                            type="current"
                                            state={{
                                                "organizationId": reportData.organizationId,
                                                "verticalId": reportData.verticalId,
                                                "projectId": reportData.projectId,
                                                "awsconnectorId": reportData.connectors.find((c: any) => c.connectorName === "AWS")?.connectorId,
                                                "inventoryconnectorId": reportData.connectors.find((c: any) => c.connectorName === "Inventory")?.connectorId,
                                                "isAWS": reportData.isAWS,
                                                "isInventory": reportData.isInventory
                                            }}
                                            setIsLoading={setIsLoading}
                                        />
                                        }
                                        {selectedNavBar === "Future State Infrastructure Cost" && <CostEstimation
                                            type="future"
                                            state={{
                                                "organizationId": reportData.organizationId,
                                                "verticalId": reportData.verticalId,
                                                "projectId": reportData.projectId,

                                            }}
                                            setIsLoading={setIsLoading} />
                                        }
                                        {selectedNavBar === "Roadmap" && <MigrationPlan
                                            state={{
                                                organizationId: initialReportData.organizationId,
                                                verticalId: initialReportData.verticalId,
                                                verticalName: initialReportData.verticalName,
                                                projectId: initialReportData.projectId,
                                                projectName: initialReportData.projectName,
                                            }}
                                            menuId={initialReportData.menus[6].menuId}
                                            setIsLoading={setIsLoading}
                                        ></MigrationPlan>}
                                        {selectedNavBar === "Conclusion" && <Conclusion />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="alert" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-3">
                        <div className="modal-header pb-0 border-0">
                            <h5 className="font-semibold font-18 font-semibold d-flex justify-content-between align-items-center">
                                Alert</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div>
                                <p className="font-medium font-14">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ratione quae consequuntur aperiam tempora est. Harum!</p>
                                <div className="col-md-12 gap-2 d-grid d-md-flex justify-content-center mt-4 ">
                                    <button data-bs-toggle="modal" data-bs-target="#progress" data-bs-dismiss="modal" type="button" className="btn btn-secondary  theme-secondary-btn-sm border-0 font-semibold px-4">Review What's Left</button>
                                    <button data-bs-toggle="modal" data-bs-target="#progress" data-bs-dismiss="modal" type="button" className="btn btn-dark theme-primary-btn-sm border-0 font-semibold px-4">Proceed Anyway</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Loader isLoading={isLoading} />
            {isSaving ? (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img
                                    src="images/loading.gif"
                                    alt="toast-success"
                                    style={{ height: "15px", width: "15px" }}
                                />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Saving...
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            {saveSuccess ? (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/toast-success.svg" alt="toast-success" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Saved successfully.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default AwsMdpwReport;
