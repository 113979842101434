import React, { useEffect, useLayoutEffect, useState } from "react";
import { controlAreasImpactImgModel, controlAreasImpactModel, controlAreasModel, inventoryDetailsModel } from "../interface/WafrReportModel";
import CircularPieChart from "./reportComponents/CircularPieChart";
import DonutChart from "./reportComponents/DonutChart";
import SectionVerticalBarChart from "./reportComponents/SectionVerticalBarChart";
import GaugeChart from "./reportComponents/GaugeChart";


const WafrDownloadReport = (props: any) => {
    const reportData = props.props
    const pieChartData = reportData.controlAreasImpact.map((data: controlAreasImpactModel) => {
        return {
            name: data.controlAreaName,
            value: data.impacts.highImpactCount
        }
    })
    const controlAreaImapctImg: controlAreasImpactImgModel = {
        "Security": <span className="legends-level-1 legend-cat-1 me-2 cursor-pointer" />,
        "Cost Optimization": <span className="legends-level-1 legend-cat-2 me-2 cursor-pointer" />,
        "Reliability": <span className="legends-level-1 legend-cat-3 me-2 cursor-pointer" />,
        "Operational Excellence": <span className="legends-level-1 legend-cat-4 me-2 cursor-pointer" />,
        "Performance Efficiency": <span className="legends-level-1 legend-cat-5 me-2 cursor-pointer" />,
        "Sustainability": <span className="legends-level-1 legend-cat-6 me-2 cursor-pointer" />
    }

    const pieChartColorCode = ['#C4D7EE', '#A992D3', '#F3C6E7', '#D7A8E5', ' #A4D4D6', '#96BDF4'];

    const barChartData = reportData.controlAreasImpact.map((data: controlAreasImpactModel) => {
        return {
            name: data.controlAreaName,
            highImpactCount: data.impacts.highImpactCount,
            mediumImpactCount: data.impacts.mediumImpactCount,
            lowImpactCount: data.impacts.lowImpactCount,
        }
    })

    const findHighImpactCount = (controlArea: string) => {
        return reportData?.controlAreasImpact?.find((data: controlAreasImpactModel) => {
            return data?.controlAreaName === controlArea
        })?.impacts?.highImpactCount
    }

    const bindInventoryDetails = () => {
        return (
            reportData.inventoryDetails?.map((data: inventoryDetailsModel) => {
                return (


                    <div style={{ width: '31.33%', float: 'left', padding: '0 1% 2% 1%' }}>
                        <div style={{ backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 2px #00000014', borderRadius: '0.5rem', padding: '1rem', overflow: 'hidden' }}>
                            <span style={{ float: 'left' }}>
                                <img src={`images/aws-${data.serviceName?.replace(" ", "")?.toLowerCase()}.svg`} alt="aws-ec2-icon" style={{ verticalAlign: 'middle', marginRight: '0.75rem' }} />
                                <span style={{ fontFamily: 'Arial', fontSize: '14px', fontWeight: 500, verticalAlign: 'middle' }}>{data.serviceName}</span>
                            </span>
                            <span style={{ fontFamily: 'Arial', float: 'right', fontSize: '20px', fontWeight: 600, verticalAlign: 'middle', lineHeight: '38px' }}>{data.count}</span>
                        </div>
                    </div>

                )
            })
        )
    }

    const bindRecommendations = (data: any) => {
        return (data?.map((recommendation: any) => {
            if (reportData?.selectedAWSRecommendations?.includes(recommendation?.checkId)) {
                return (

                    <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                        <td style={{ width: '38%', padding: '2% 1%', textAlign: 'left', verticalAlign: 'top', fontSize: '14px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                            {recommendation?.checkName}</td>
                        <td style={{ width: '18%', padding: '2% 1%', textAlign: 'left', verticalAlign: 'top', fontSize: '14px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                            {recommendation?.controlAreaName}
                        </td>
                        <td style={{ textAlign: 'center', width: '18%', padding: '2% 1%', verticalAlign: 'top', fontSize: '14px', fontFamily: '"Roboto"', lineHeight: '1.5' }}>
                            <span style={{ backgroundColor: '#FFECEC', borderRadius: '15px', padding: '6px 16px', color: '#F88A7F', fontFamily: '"Roboto"', fontSize: '14px' }}>
                                <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#FFA79D', borderRadius: '10px', marginRight: '12px' }} />
                                {recommendation?.impact}
                            </span>
                        </td>
                    </tr>
                )
            }
        }))



    }

    const bindControlAreas = () => {
        return (
            reportData?.controlAreaDetails?.map((data: any) => {
                return (
                    <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }}>
                        {/*Security Header starts here */}
                        <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                            <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '15px', fontWeight: '600px', margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                                {data?.controlAreaName} </h5>
                        </div>
                        {/*Security Header  ends here */}
                        <div style={{ width: '98%', float: 'left', marginTop: '3%' }}>
                            {/* Main header style */}
                            <div style={{ width: '98%', float: 'left', marginTop: '2%', padding: '2%', margin: '1% 0', textAlign: 'center', border: '1px solid #E3E3E3', borderRadius: '1rem' }}>
                                <h2 style={{ fontFamily: 'Roboto', color: '#000000', fontWeight: 600, marginTop: 0, fontSize: '17px', marginBottom: '35px', textAlign: 'left' }}>
                                    Based on your Score</h2>
                                <div style={{ width: '35%', float: 'left', margin: '0 2%' }}>
                                    {/* <img src="Images/security-chart.svg" alt="sample-donut-chart" style={{ height: '210px', width: '98%', padding: '1%', marginBottom: '3%' }} /> */}
                                    {<GaugeChart dataValue={[
                                        { name: "A", value: 33.3, color: "#ff796b" },
                                        { name: "B", value: 33.4, color: "#ffd772" },
                                        { name: "C", value: 33.3, color: "#78e07c" },
                                    ]} Gaugeval={parseInt(data?.impacts?.impactPercentage || 0)} />
                                    }
                                </div>
                                <div style={{ width: '57%', float: 'left', margin: '0 2%' }}>
                                    <p style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '12px', margin: '3% 0% 2% 0%', lineHeight: '20px', textAlign: 'left' }}>
                                        {data?.impacts?.overAllSummary}
                                    </p>
                                </div>
                            </div>
                            <div style={{ width: '98%', float: 'left', marginTop: '2%', padding: '2%', margin: 0, textAlign: 'center', border: '1px solid #E3E3E3', borderRadius: '1rem' }}>
                                <h2 style={{ fontFamily: 'Roboto', color: '#000000', fontWeight: 600, marginTop: 0, fontSize: '17px', marginBottom: '35px', textAlign: 'left' }}>
                                    Recommendation</h2>
                                <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '2%' }}>
                                    <thead style={{ backgroundColor: '#F0F0F0' }}>
                                        <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                                            <th style={{ textAlign: 'left', color: '#454545', fontSize: '14px', fontFamily: '"Roboto"', width: '38%', padding: '1%' }}>
                                                Description</th>
                                            <th style={{ textAlign: 'left', color: '#454545', fontSize: '14px', fontFamily: '"Roboto"', width: '18%', padding: '1%' }}>
                                                Control Areas</th>
                                            <th style={{ textAlign: 'center', color: '#454545', fontSize: '14px', fontFamily: '"Roboto"', width: '18%', padding: '1%' }}>
                                                Impact</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bindRecommendations(data?.awsRecommendations)}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                )

            })
        )
    }


    return (
        <>
            <div>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width,initial-scale=1.0," />
                <title>PDF New</title>
                <style dangerouslySetInnerHTML={{ __html: "\n    @page {\n      margin: 0;\n    }\n  " }} />
                <div style={{}}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td>
                                    {/*Reports page starts here */}
                                    <div style={{ width: '98%', height: '835px', float: 'left', padding: '2% 2% 3% 0', pageBreakInside: 'avoid', position: 'relative' }}>
                                        <img src="Images/pdf-bg-vector.svg" alt="background-vector" style={{ width: '100%', height: '80vh', position: 'absolute', zIndex: 1, bottom: 0, right: 0 }} />
                                        <div style={{ width: '94%', height: '835px', float: 'left', margin: '1% 0%', padding: '3%', backgroundColor: '#F3F3F3', position: 'relative' }}>
                                            {/* Main header style */}
                                            <img src="Images/prenuvo.svg" alt="prenuvo-logo" />
                                            <div style={{ width: '40%', position: 'absolute', bottom: '30%', right: '10%', textAlign: 'center' }}>
                                                <img src="Images/aws-large-logo.svg" alt="aws-logo" style={{ marginBottom: '10%' }} />
                                                <p style={{ fontFamily: 'Arial', textAlign: 'center', color: '#000', fontWeight: 700, fontSize: '20px', margin: '3% 0% 2% 0%', lineHeight: '20px' }}>
                                                    AWS Well-Architected
                                                    Framework Review</p>
                                            </div>
                                        </div>
                                        <p style={{ fontFamily: 'Arial', textAlign: 'center', color: '#000', fontWeight: 700, fontSize: '14px', marginTop: '2%', float: 'left' }}>Date | July 08, 2024</p>
                                        <img src="Images/zeb-logo.svg" alt="zeb-logo" style={{ float: 'right', marginTop: '2%' }} />
                                    </div>
                                    {/*Reports page ends here */}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td style={{ paddingTop: '80px' }}>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="Images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="Images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                </div>
            </div>

            <div style={{ width: '100%' }}>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td>
                                {/*Reports page starts here */}
                                <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }}>
                                    {/*Reports Header starts here */}
                                    <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                                        <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '15px', fontWeight: '600px', margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                                            Reports</h5>
                                    </div>
                                    {/*Reports Header  ends here */}
                                    <div style={{ width: '100%', float: 'left', margin: '1% 0%' }}>
                                        {/* Main header style */}
                                        <h4 style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 700, fontSize: '14px', margin: '3% 0% 2% 0%' }}>
                                            Table of content</h4>
                                        {/*page header style */}
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Executive Summary
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>
                                                3
                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Business Overview
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>
                                                3
                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                AWS Well - Architected Framework
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>
                                                6
                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Overall Summary
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>
                                                8
                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Security
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>
                                                9
                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Cost Optimization
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>
                                                10
                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Reliability
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>
                                                11
                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Operational Excellence
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>
                                                12
                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Performance Efficiency
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>
                                                13
                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Sustainability
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>
                                                14
                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                WAFR Final Report Walkthrough
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>
                                                15
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/*Reports page ends here */}
                                {/* Executive Summary page starts here */}
                                <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }}>
                                    {/*Executive Summary Header starts here */}
                                    <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                                        <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '15px', fontWeight: '600px', margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                                            Executive Summary</h5>
                                    </div>
                                    {/*Executive Summary Header  ends here */}
                                    <div style={{ width: '98%', float: 'left', margin: '1% 0%' }}>
                                        {/* Main header style */}
                                        <p style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '3% 0% 2% 0%', lineHeight: '20px' }}>
                                            {reportData?.executiveSummary?.attributeSummary}
                                        </p>

                                    </div>
                                </div>
                                {/* Executive Summary page ends here */}
                                {/* Business Overview page starts here */}
                                <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }}>
                                    {/*Business Overview Header starts here */}
                                    <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                                        <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '15px', fontWeight: '600px', margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                                            Business Overview</h5>
                                    </div>
                                    {/*Business Overview Header  ends here */}
                                    <div style={{ width: '98%', float: 'left', margin: '1% 0%' }}>
                                        {/* Main header style */}
                                        <p style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '3% 0% 2% 0%', lineHeight: '20px' }}>
                                            {reportData?.businessOverview?.attributeSummary}
                                        </p>

                                    </div>
                                </div>
                                {/* Executive Summary page ends here */}
                                {/* AWS Well-Architected Framework - Findings page starts here */}
                                <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }}>
                                    {/*AWS Well-Architected Framework - Findings Header starts here */}
                                    <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                                        <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '15px', fontWeight: '600px', margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                                            AWS Well-Architected Framework - Findings </h5>
                                    </div>
                                    {/*AWS Well-Architected Framework - Findings Header  ends here */}
                                    <div style={{ width: '100%', float: 'left', marginTop: '3%' }}>
                                        {/* Main header style */}
                                        <div>
                                            {bindInventoryDetails()}
                                            <div style={{ clear: 'both' }} />
                                        </div>
                                    </div>
                                </div>
                                {/* AWS Well-Architected Framework page ends here */}
                                {/* Overall Summary page starts here */}
                                <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }}>
                                    {/*Overall Summary Header starts here */}
                                    <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                                        <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '15px', fontWeight: '600px', margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                                            Overall Summary </h5>
                                    </div>
                                    {/*Overall Summary Header  ends here */}
                                    <div style={{ width: '100%', float: 'left', marginTop: '3%' }}>
                                        {/* Main header style */}
                                        <div style={{ width: '53%', float: 'left', padding: '2%', marginRight: '1%', marginLeft: 0, textAlign: 'center', border: '1px solid #E3E3E3', borderRadius: '1rem' }}>
                                            <h2 style={{ fontFamily: 'Arial', color: '#000000', fontWeight: 600, fontSize: '17px', marginBottom: '35px', marginTop: 0, textAlign: 'left' }}>
                                                High Risk count against each WAFR pillar&nbsp;</h2>
                                            <div style={{ width: '98%' }}>
                                                <CircularPieChart data={pieChartData} colorCode={pieChartColorCode} chartHeight={250} chartXaxis={250} chartYaxis={160} countXaxis={250} countYaxis={150} />
                                            </div>
                                            <div style={{ width: '42%', float: 'left', paddingRight: '2%', marginTop: '2%' }}>
                                                <div style={{ float: 'left' }}>
                                                    <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#ADADE8', borderRadius: '16px', marginRight: '12px' }} />
                                                    <label style={{ fontFamily: 'Arial', fontSize: '14px', color: '#000000' }}>
                                                        Security</label>
                                                </div>
                                                <div style={{ float: 'right' }}>
                                                    <label style={{ fontFamily: 'Arial', fontSize: '14px', fontWeight: 600, float: 'right' }}>{findHighImpactCount("Security")}</label>
                                                </div>
                                            </div>
                                            <div style={{ width: '54%', float: 'left', paddingLeft: '2%', marginTop: '2%' }}>
                                                <div style={{ float: 'left' }}>
                                                    <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#D58EF4', borderRadius: '16px', marginRight: '12px' }} />
                                                    <label style={{ fontFamily: 'Arial', fontSize: '14px', color: '#000000' }}>
                                                        Operational Efficiency</label>
                                                </div>
                                                <div style={{ float: 'right' }}>
                                                    <label style={{ fontFamily: 'Arial', fontSize: '14px', fontWeight: 600, float: 'right' }}>{findHighImpactCount("Operational Excellence")}</label>
                                                </div>
                                            </div>
                                            <div style={{ width: '42%', float: 'left', paddingRight: '2%', marginTop: '3%' }}>
                                                <div style={{ float: 'left' }}>
                                                    <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#AB83D6', borderRadius: '16px', marginRight: '12px' }} />
                                                    <label style={{ fontFamily: 'Arial', fontSize: '14px', color: '#000000' }}>
                                                        Cost Optimization</label>
                                                </div>
                                                <div style={{ float: 'right' }}>
                                                    <label style={{ fontFamily: 'Arial', fontSize: '14px', fontWeight: 600, float: 'right' }}>{findHighImpactCount("Cost Optimization")}</label>
                                                </div>
                                            </div>
                                            <div style={{ width: '54%', float: 'left', paddingLeft: '2%', marginTop: '3%' }}>
                                                <div style={{ float: 'left' }}>
                                                    <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#8CD8E8', borderRadius: '16px', marginRight: '12px' }} />
                                                    <label style={{ fontFamily: 'Arial', fontSize: '14px', color: '#000000' }}>
                                                        Performance Efficiency</label>
                                                </div>
                                                <div style={{ float: 'right' }}>
                                                    <label style={{ fontFamily: 'Arial', fontSize: '14px', fontWeight: 600, float: 'right' }}>{findHighImpactCount("Performance Efficiency")}</label>
                                                </div>
                                            </div>
                                            <div style={{ width: '42%', float: 'left', paddingRight: '2%', marginTop: '3%' }}>
                                                <div style={{ float: 'left' }}>
                                                    <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#F8A9E2', borderRadius: '16px', marginRight: '12px' }} />
                                                    <label style={{ fontFamily: 'Arial', fontSize: '14px', color: '#000000' }}>
                                                        Reliability</label>
                                                </div>
                                                <div style={{ float: 'right' }}>
                                                    <label style={{ fontFamily: 'Arial', fontSize: '14px', fontWeight: 600, float: 'right' }}>{findHighImpactCount("Reliability")}</label>
                                                </div>
                                            </div>
                                            <div style={{ width: '54%', float: 'left', paddingLeft: '2%', marginTop: '3%' }}>
                                                <div style={{ float: 'left' }}>
                                                    <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#8AA8FF', borderRadius: '16px', marginRight: '12px' }} />
                                                    <label style={{ fontFamily: 'Arial', fontSize: '14px', color: '#000000' }}>
                                                        Sustainability</label>
                                                </div>
                                                <div style={{ float: 'right' }}>
                                                    <label style={{ fontFamily: 'Arial', fontSize: '14px', fontWeight: 600, float: 'right' }}>{findHighImpactCount("Sustainability")}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ width: '37%', height: '265px', float: 'right', marginRight: 0, padding: '2%', textAlign: 'center', border: '1px solid #E3E3E3', borderRadius: '1rem' }}>
                                            <h2 style={{ fontFamily: 'Arial', color: '#000000', fontWeight: 600, fontSize: '17px', marginBottom: '35px', marginTop: 0, textAlign: 'left' }}>
                                                Recommendations based on Impacts</h2>
                                            {/* <img src="Images/sample-donut-chart.svg" alt="sample-donut-chart" style={{ height: '160px', width: '98%', padding: '1%', marginBottom: '3%' }} /> */}
                                            <DonutChart dataNameValue2={[
                                                { name: "High", value: reportData?.controlAreasImpact?.reduce((acc: number, data: controlAreasImpactModel) => acc + data.impacts.highImpactCount, 0) },
                                                { name: "Medium", value: reportData?.controlAreasImpact?.reduce((acc: number, data: controlAreasImpactModel) => acc + data.impacts.mediumImpactCount, 0) },
                                                { name: "Low", value: reportData?.controlAreasImpact?.reduce((acc: number, data: controlAreasImpactModel) => acc + data.impacts.lowImpactCount, 0) }
                                            ]} />
                                        </div>
                                        <div style={{ width: '96%', float: 'left', marginTop: '2%', padding: '2%', textAlign: 'center', border: '1px solid #E3E3E3', borderRadius: '1rem' }}>
                                            <h2 style={{ float: 'left', fontFamily: 'Arial', color: '#000000', fontWeight: 600, fontSize: '17px', marginTop: 0, marginBottom: '35px', textAlign: 'left' }}>
                                                High Risk count against each WAFR pillar&nbsp;</h2>
                                            <div style={{ width: '35%', float: 'right' }}>
                                                <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#9FF5A2', borderRadius: '16px', marginRight: '8px' }} />
                                                <label style={{ fontFamily: 'Arial', fontSize: '14px', color: '#000000', marginRight: '10%' }}>
                                                    Low</label>
                                                <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#FFD772', borderRadius: '16px', marginRight: '8px' }} />
                                                <label style={{ fontFamily: 'Arial', fontSize: '14px', color: '#000000', marginRight: '10%' }}>
                                                    Medium</label>
                                                <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#FF796B', borderRadius: '16px', marginRight: '8px' }} />
                                                <label style={{ fontFamily: 'Arial', fontSize: '14px', color: '#000000', marginRight: '2%' }}>
                                                    High</label>
                                            </div>
                                            {/* <img src="Images/samp-bar-chart.png" alt="sample-bar-chart" style={{ height: '250px', width: '90%', padding: '1%', marginBottom: '3%' }} /> */}
                                            {<SectionVerticalBarChart data={barChartData} />}
                                        </div>
                                        {/* <div style={{ width: '96%', float: 'left', marginTop: '2%', padding: '2%', textAlign: 'center', border: '1px solid #E3E3E3', borderRadius: '1rem' }}>
                                        <h2 style={{ float: 'left', fontFamily: 'Arial', color: '#000000', fontWeight: 600, fontSize: '17px', marginTop: 0, marginBottom: '35px', textAlign: 'left' }}>
                                            Recommendation</h2>
                                        <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '2%' }}>
                                            <thead style={{ backgroundColor: '#F0F0F0' }}>
                                                <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                                                    <th style={{ textAlign: 'left', color: '#454545', fontSize: '14px', fontFamily: '"Roboto"', width: '38%', padding: '1%' }}>
                                                        Description</th>
                                                    <th style={{ textAlign: 'left', color: '#454545', fontSize: '14px', fontFamily: '"Roboto"', width: '18%', padding: '1%' }}>
                                                        Control Areas</th>
                                                    <th style={{ textAlign: 'center', color: '#454545', fontSize: '14px', fontFamily: '"Roboto"', width: '18%', padding: '1%' }}>
                                                        Impact</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                                                    <td style={{ width: '38%', padding: '2% 1%', textAlign: 'left', verticalAlign: 'top', fontSize: '14px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                                        VMs with excessive memory usage and optimize memory allocation based on workload demands.</td>
                                                    <td style={{ width: '18%', padding: '2% 1%', textAlign: 'left', verticalAlign: 'top', fontSize: '14px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                                        Performance
                                                    </td>
                                                    <td style={{ textAlign: 'center', width: '18%', padding: '2% 1%', verticalAlign: 'top', fontSize: '14px', fontFamily: '"Roboto"', lineHeight: '1.5' }}>
                                                        <span style={{ backgroundColor: '#FFECEC', borderRadius: '15px', padding: '6px 16px', color: '#F88A7F', fontFamily: '"Roboto"', fontSize: '14px' }}>
                                                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#FFA79D', borderRadius: '10px', marginRight: '12px' }} />
                                                            High
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                                                    <td style={{ width: '38%', padding: '2% 1%', textAlign: 'left', verticalAlign: 'top', fontSize: '14px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                                        Use encryption protocols like SSH or SSL/TLS to encrypt the communication between the client
                                                        and the virtual machine, protecting sensitive data from interception.</td>
                                                    <td style={{ width: '18%', padding: '2% 1%', textAlign: 'left', verticalAlign: 'top', fontSize: '14px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                                        Performance
                                                    </td>
                                                    <td style={{ textAlign: 'center', width: '18%', padding: '2% 1%', verticalAlign: 'top', fontSize: '14px', fontFamily: '"Roboto"', lineHeight: '1.5' }}>
                                                        <span style={{ backgroundColor: '#FFECEC', borderRadius: '15px', padding: '6px 16px', color: '#F88A7F', fontFamily: '"Roboto"', fontSize: '14px' }}>
                                                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#FFA79D', borderRadius: '10px', marginRight: '12px' }} />
                                                            High
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '38%', padding: '2% 1%', textAlign: 'left', verticalAlign: 'top', fontSize: '14px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                                        VMs with excessive memory usage and optimize memory allocation based on workload demands.</td>
                                                    <td style={{ width: '18%', padding: '2% 1%', textAlign: 'left', verticalAlign: 'top', fontSize: '14px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                                        Performance
                                                    </td>
                                                    <td style={{ textAlign: 'center', width: '18%', padding: '2% 1%', verticalAlign: 'top', fontSize: '14px', fontFamily: '"Roboto"', lineHeight: '1.5' }}>
                                                        <span style={{ backgroundColor: '#FFECEC', borderRadius: '15px', padding: '6px 16px', color: '#F88A7F', fontFamily: '"Roboto"', fontSize: '14px' }}>
                                                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#FFA79D', borderRadius: '10px', marginRight: '12px' }} />
                                                            High
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> */}
                                    </div>
                                </div>


                                {/* Overall Summary page ends here */}
                                {/* Security page starts here */}
                                {bindControlAreas()}
                                {/* Security page ends here */}
                                {/* WAFR Final Report Walkthrough page starts here */}
                                <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }}>
                                    {/*WAFR Final Report Walkthrough Header starts here */}
                                    <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                                        <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '15px', fontWeight: '600px', margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                                            WAFR Final Report Walkthrough </h5>
                                    </div>
                                    {/*WAFR Final Report Walkthrough Header  ends here */}
                                    <div style={{ width: '98%', float: 'left', margin: '1% 0%' }}>
                                        {/* Main header style */}
                                        <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '14px', fontWeight: '600px', margin: '1% 0', textAlign: 'left', wordSpacing: '4px' }}>
                                            WAFR Final report Walk-through: </h5>
                                        <p style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '2% 0% 2% 0%', lineHeight: '20px' }}>
                                            {reportData?.finalReportWalkthrough?.attributeSummary}
                                        </p>

                                    </div>
                                </div>
                                {/* WAFR Final Report Walkthrough page ends here */}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td style={{ paddingTop: '80px' }}>
                                <span style={{ marginBottom: '10px', width: '100%', float: 'left', height: '80px', position: 'fixed', textAlign: 'center', bottom: '0px', backgroundColor: 'white', marginTop: '120px' }}>
                                    <img src="Images/footer-design.png" alt="footer design" style={{ height: '32px', width: '100%' }} />
                                    <span style={{ textAlign: 'center', marginBottom: '8px' }}>
                                        <img src="Images/zeb-logo.svg" alt="zeb-logo" style={{ width: '88px', height: '32px' }} />
                                    </span>
                                </span>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                {/* footer style starts here */}
                {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="Images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="Images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                {/* footer style ends here */}
            </div>
        </>

    )
}

export default WafrDownloadReport