import { useEffect, useRef, useState } from "react";
import Loader from './Loader';
import { encryptStorage, fileNameSlice } from "../constant/constant";
import { jwtDecode } from "jwt-decode";
import { getSasToken } from "../service/ReportApi";
import { getConnectorsData } from "../helpers/encryption";
import * as XLSX from "xlsx";
import { read, utils } from "xlsx";
import { getInfraInventoryDetails } from "../service/InfraAssessmentApi";
import { InfraInventoryDetails, InventoryDetailsRequestModel, ValidationRules } from "../interface/InfraAssessmentModel";
import { deleteInfraInventoryDetails } from "../service/InfraAssessmentApi";
import { InventoryDetail } from "../interface/InfraAssessmentModel";
import { ProcessedData } from "../interface/InfraAssessmentModel";
import ToastComponent from "./ToastComponent";
import uploadFileToBlob1 from "../helpers/BlobUpload";
import { postInfraInventoryDetails } from "../service/InfraAssessmentApi";
import { useLocation } from "react-router-dom";

export default function Inventory(props: any) {
    //PS-15 collecting props
    const { setIsLoading, infraAssessmentMenuId } = props;
    //PS-12 `inventoryData` and toastData constant is defined with default properties for an inventory item.
    const toastData: any = {
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: '',
    };

    const inventoryData: any = {
        infraAssessmentDetailsId: "",
        inventoryBlobUrl: "",
        executiveSummary: ""
    }
    //PS-13 `processedData` constant is defined to store processed data from inventory details.
    const attachment: any = useRef(null);
    const processedData: ProcessedData = {
        instanceCount: 0,
        windowsServerCount: 0,
        linuxServerCount: 0,
        vmCount: 0,
        serverNotPatched: 0,
        inventoryDetails: [],
    }
    //PS-1 `useState` is called to initialize `inventoryDetails` with an initial value based on the `inventoryData` structure.
    const [inventoryDetails, setInventoryDetails] = useState<InfraInventoryDetails>(inventoryData);
    //PS-10: `useState` is used to set up `showDeleteModal`, managing delete modal visibility.
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    //PS-11 `useState` is used to set up `deleteApplicationId` as an empty string.
    const [deleteApplicationId, setDeleteApplicationId] = useState<string>('');
    //PS-2 `useState` is called to initialize `data` with an initial value based on the `processedData` structure.
    const [data, setData] = useState<ProcessedData>(processedData);
    //PS-4: `useState` is used to set up `fileName` as an empty string.
    const [fileName, setFileName] = useState<string>("");
    //PS-6:`useState` is used to set up `file` as an empty string.
    const [file, setFile] = useState<string>("");
    //PS- 3:`useState` is used to set up `validationMessage` as an empty string.
    const [validationMessage, setValidationMessage] = useState<string>("");
    //PS- 8`useState` is used to set up `toastProp` as `false`, managing toast notifications.
    const [toastProp, setToastProp] = useState<any>(toastData)
    //PS-9 `useState` is used to set up `hideToast` as `false`, managing toast hiding.
    const [hideToast, setHideToast] = useState<boolean>(true);
    //PS-7 `useState` is used to set up `isForm` as `false`, indicating the component is not in form mode.
    const [isForm, setIsForm] = useState<Boolean>(false);
    const [flag, setFlag] = useState<Boolean>(false);
    //PS-5 `isDisabled` is computed based on the conditions `!inventoryDetails.executiveSummary
    const isDisabled = !fileName;


    const { state } = useLocation();
    //PS-15 `useEffect` hook is called with a callback function that invokes `fetchInfraInventoryDetails` upon component mount.
    useEffect(() => {
        fetchInfraInventoryDetails();
    }, []);
    //PS-15-PS-28:iF INVENTORY DETAILS EXISTS, SETISFORM TO FALSE
    const fetchInfraInventoryDetails = async () => {

        setIsLoading(true)
        try {
            const payload: InventoryDetailsRequestModel = {
                organizationId: state.organizationId,
                verticalId: state.verticalId
            }
            const response = await getInfraInventoryDetails(payload);
            //ps-21 If `response.statusCode` is not 200, `FC` sets a toast message indicating an API error.
            if (response.status !== 200) {
                let toastData = {
                    toastType: "error",
                    toastHeaderMessage: "Error",
                    toastBodyMessage: "Api Failed",
                };
                setToastProp(toastData);
                setHideToast(false);
            }

            //PS-23 If `response.statusCode` is 200 and data is a non-empty array, `FC` updates inventory details and file name accordingly.
            if (Array.isArray(response.data) && response.data.length > 0) {
                setInventoryDetails(response.data[0]);
                if (response.data[0].inventoryBlobUrl !== "") {
                    const fileName = fileNameSlice(response.data[0].inventoryBlobUrl) || ""
                    setFileName(fileName)
                }
                setIsForm(false);
            } else {
                setIsForm(true);
            }
            setIsLoading(false)
        }
        //ps-27 If an error happens in a catch block, it is logged to the console.
        catch (error) {
            setIsLoading(false)
            let toastData = {
                toastType: "error",
                toastHeaderMessage: "Error",
                toastBodyMessage: "Api Failed",
            };
            setToastProp(toastData);
            setHideToast(false);
        }
    };
    //PC-63 The onclick function associated with the 'Cancel' removeFile is called button is invoked to handle the cancellation process.
    const removeFile = () => {
        //PC-65 The `fileName` state is set to an empty string using `setFileName("")`, and `fetchInfraInventoryDetails()` is called to refresh data.
        setFileName("");
        setFile("");
        setValidationMessage("");
        attachment.current.value = null;
    };

    //PS -110 Begins a loop over each field that requires validation with the respective validation rule.
    const validationRules: ValidationRules = {
        //PS-112- 118  Confirms if the value for fields like 'Application', 'Environment', 'OS', 'OS Version' is a non-empty string.
        'Application': (value) => {
            return typeof value === 'string' && value.trim() !== "";
        },
        'Environment': (value) => {
            return typeof value === 'string' && value.trim() !== "";
        },
        'Server Status': (value) => {
            return typeof value === 'string' && ['up', 'down'].includes(value.trim().toLowerCase());
        },
        'Machine Type': (value) => {
            return typeof value === 'string' && ['physical machine', 'virtual machine', 'vm'].includes(value.trim().toLowerCase());
        },
        'OS': (value) => {
            return typeof value === 'string' && value.trim() !== "";
        },
        'OS Version': (value) => {
            return typeof value === 'string' && value.trim() !== "";
        },
        'Disk Size': (value) => {
            if (typeof value !== 'string') return false;
            const diskSizeRegex = /^\d+(\.\d+)?\sGB$/;
            return diskSizeRegex.test(value.trim());
        },
        'Percentage Used': (value) => {
            return typeof value === 'number' && value >= 0 && value <= 1;
        },
        'Total Memory': (value) => {
            if (typeof value !== 'string') return false;
            const totalMemoryRegex = /^\d+(\.\d+)?\sGB$/;
            return totalMemoryRegex.test(value.trim());
        },
        'Percent Memory Used': (value) => {
            return typeof value === 'number' && value >= 0 && value <= 1;
        },
        'CPU Count': (value) => {
            return typeof value === 'number' && Number.isInteger(value) && value > 0;
        },
        'CPU Load': (value) => {
            return typeof value === 'number' && value >= 0 && value <= 1;
        },
        'Last reboot': (value) => {
            const lastRebootRegex = /^(0?[1-9]|1[012])\/(0?[1-9]|[12]\d|3[01])\/\d{4}( \d{1,2}:\d{2}( (AM|PM|am|pm))?)?$/;
            return lastRebootRegex.test(formatDate(value));
        },
    };
    //PS-72 The `readFile` function is invoked when the user provides a file input by uploading an Excel file.
    const readFile = (event: any) => {

        //attachment.current = null;
        //PS-73 Within the `readFile` function, a variable `file` is created and assigned the first file from the `event.target.files` array.
        const file = event.target.files?.[0];
        if (file) {
            //PS-75 - 76 Calls `setFile`, SETFileName to update the file state with the selected file.
            setFile(file);
            setFileName(file.name)
            const typeArr = ["vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
            let type = typeArr.includes(file.type.split("/")[1]);
            //PS-79 If the file type matches the expected Excel MIME types, the variable `type` is set to `true`, indicating a valid XLSX file.
            if (type) {
                //PS-82 -84 For files with a valid type, a comparison is made between the file's size and a predefined maximum file size limit to ensure it doesn't exceed the limit.
                const maxFileSize = 50 * 1024 * 1024;
                if (file.size > maxFileSize) {
                    attachment.current.value = null;
                    setValidationMessage("Please upload file size less than 50MB");
                    return;
                }

                setValidationMessage("");
                //PS-85 Creates an instance of `FileReader` named `reader`.
                const reader = new FileReader();
                //PS-86 Calls `readAsArrayBuffer` on `reader`, passing in the `file` to start reading the file’s contents.
                reader.readAsArrayBuffer(file);
                //PS-88 - 93 Reads the excel sheet
                reader.onload = (e) => {
                    const workbook = read(e?.target?.result);
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const rows: any[] = XLSX.utils.sheet_to_json(sheet, { header: 1, defval: '' });

                    //PS-94 Verifies that `rows` contains at least one row of data, validating that file content is not empty.
                    if (rows.length >= 2) {
                        setFileName(event.target.files[0].name);
                        //PS-95   Resets `validationMessage` to an empty string as file contains data and no reading errors have occurred at this stage.
                        setValidationMessage("");
                        const headers = rows[0];
                        //PS-97-98 Defines an `expectedHeaders` array with the names of required columns.
                        const expectedHeaders = ['Application', 'Environment', 'Server Status', 'Machine Type', 'OS', 'OS Version', 'Disk Size', 'Percentage Used', 'Total Memory', 'Percent Memory Used', 'CPU Count', 'CPU Load', 'Last reboot'];
                        const isValidColumns = expectedHeaders.every(header => headers.includes(header));
                        //PS-99 If headers do not match, updates `fileName` and `file` state to empty, sets `validationMessage`, and aborts further processing.
                        if (!isValidColumns) {
                            setFileName("");
                            setFile("");
                            attachment.current.value = null;
                            setValidationMessage("Please upload the file with valid columns and data");
                            return;
                        }
                        //PS-100 -110 Assuming headers are correct, iterates over each row in `rows` to validate the data according to specific `validationRules`.
                        const rowHeaders = rows[0];
                        const rowData = rows.slice(1);
                        rowData.forEach((row: any, index: any) => {
                            index = 0;
                            Object.keys(validationRules).forEach((key: any) => {
                                const isValid = validationRules[key](row[index]);
                                if (!isValid) {
                                    //PS-104       Sets a validation message noting the requirement for valid data, typically due to validation checks failing.
                                    setFileName("");
                                    setFile("");
                                    attachment.current.value = null;
                                    setValidationMessage("Please upload the file with valid data");
                                    return;
                                }
                                index++;
                            });
                        });
                        //PS-106 If all data is found to be valid, processes the rows using a `processData` function and assigns the result to `processedData`.
                        const processedData = processData(rows as any[][]);
                        //PS 107 Calls `setData` with `processedData` to reflect the processed information in the component's state.
                        setData(processedData);
                    }
                    //PS-112 Calls `setValidationMessage` with a message prompt to upload a file containing valid data if no data rows are present.
                    else {
                        setFileName("");
                        setFile("");
                        attachment.current.value = null;
                        setValidationMessage("Please upload the file with valid data");
                        return;
                    }
                };
            }
            //PS-109 Calls `setValidationMessage` with a message prompt to upload a file containing valid data if no data rows are present.
            else {
                attachment.current.value = null;
                setValidationMessage("Please upload a XLSX file");
                setFileName("");
                setFile("");
                return;

            }
            //PS_90 The `processData` function is invoked with JSON data parsed from the uploaded Excel file.
            const processData = (jsonData: any[][]): ProcessedData => {

                const headers = jsonData[0];
                //PS-174 Creates a `serverData` array by slicing JSON data to exclude headers, leaving only server data rows.
                const serverData = jsonData.slice(1);
                //PS-175 Filters `serverData` and assigns the result's length to the variable `instanceCount`, counting rows with "Application" header.
                const instanceCount = serverData.filter((row) => row[headers.indexOf('Application')]).length;
                //PS-176 : Filters `serverData` by "OS" header with a value of "windows" and assigns the result's length to `windowsCount`.
                const windowsServerCount = serverData.filter((row) => row[headers.indexOf('OS')] === 'Windows').length;
                //PS-177 Filters `serverData` by "OS" header with a value of "linux" and assigns the result's length to `linuxCount`.
                const linuxServerCount = serverData.filter((row) => row[headers.indexOf('OS')] === 'Linux').length;
                //PS-178 Filters `serverData` by "Machine Type" header with a value of "VM" and assigns the result's length to `vmCount`.
                const vmCount = serverData.filter((row) => row[headers.indexOf('Machine Type')] === 'VM').length;
                // PS-179:Creates a `sixMonthsAgo` date object and adjusts the date to be six months prior to the current date.
                const sixMonthsAgo = new Date();

                sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
                const serverNotPatched = serverData.filter((row) => {
                    const lastReboot = new Date(formatDate(row[headers.indexOf('Last reboot')]));
                    return lastReboot < sixMonthsAgo;
                }).length;
                //PS 180- 188 Filters `serverData` for MEMORY USED, CPU DATA , DISKSIZE DATA  and then maps entries to `InventoryDetail` objects with memory utilized information.
                const memoryUsedData = serverData
                    .filter((row) => row[headers.indexOf('Machine Type')] === 'Physical Machine')
                    .map((row) => {
                        const memoryUsedValue = row[headers.indexOf('Percent Memory Used')];
                        const utilizationPercentage = parseFloat(memoryUsedValue) * 100; // Convert decimal to percentage
                        return {
                            inventoryName: 'memoryUsed' as const,
                            serverName: row[headers.indexOf('Application')],
                            utilizationPercentage,
                        };
                    })
                    .sort((a, b) => b.utilizationPercentage! - a.utilizationPercentage!)
                    .slice(0, 5);
                const cpuData = serverData
                    .filter((row) => row[headers.indexOf('Machine Type')] === 'Physical Machine')
                    .map((row) => ({

                        inventoryName: 'CPU' as const,
                        serverName: row[headers.indexOf('Application')],
                        utilizationPercentage: Math.round(parseFloat(row[headers.indexOf('CPU Load')]) * 100),

                    }))
                    .sort((a, b) => b.utilizationPercentage! - a.utilizationPercentage!)
                    .slice(0, 5);

                const diskSizeData = serverData
                    .filter((row) => row[headers.indexOf('Machine Type')] === 'Physical Machine')
                    .map((row) => ({
                        inventoryName: 'diskSize' as const,
                        serverName: row[headers.indexOf('Application')],
                        utilizationPercentage: Math.round(parseFloat(row[headers.indexOf('Percentage Used')]) * 100),
                    }))
                    .sort((a, b) => (b.utilizationPercentage || 0) - (a.utilizationPercentage || 0))
                    .slice(0, 5);

                const inventoryDetails = [memoryUsedData, cpuData, diskSizeData];

                //PS-107-Calls `setData` with `processedData` to update the component's state with the newly processed information.
                return {
                    instanceCount,
                    windowsServerCount,
                    linuxServerCount,
                    vmCount,
                    serverNotPatched,
                    inventoryDetails,
                };
            };
        }
        setValidationMessage("");
    }

    // format date- 106
    function formatDate(dateStr: any) {
        // Check if the date string matches format "M/D/YYYY HH:mm"
        let date = new Date(dateStr);
        if (!isNaN(date.getTime())) {
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
            let year = date.getFullYear();
            return `${month}/${day}/${year}`;
        }
        // Check if the date string matches format "MM-DD-YYYY hh.mm.ss A"
        let dateParts = dateStr.match(/^(\d{2})-(\d{2})-(\d{4}) (\d{2})\.(\d{2})\.(\d{2}) (AM|PM)$/);
        if (dateParts) {
            let month = dateParts[1].padStart(2, '0');
            let day = dateParts[2].padStart(2, '0');
            let year = dateParts[3];
            let hour = (dateParts[7] === 'PM' && dateParts[4] !== '12') ? parseInt(dateParts[4], 10) + 12 : dateParts[4];
            let minute = dateParts[5];
            return `${month}/${day}/${year}`;
        }

        // Return empty string or handle unrecognized format as needed
        return '';
    }
    //PS-132 INVOKES THE DOWNLOAD TEMPLATE
    const downloadTemplate = async () => {
        try {
            let value = encryptStorage.getItem("jwt")
            let parsedJtk: any = ""
            let storageAccountName: any, containerName: any;
            if (value) {
                //PS-133 Retrieve JWT from browser storage for authorization purposes.    
                parsedJtk = jwtDecode(value)
                storageAccountName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_ACCOUNT_NAME! });
                containerName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_CONTAINER! })
            }
            //PS-136 Request a SAS token from the backend with read permissions for downloading the template.
            const sas = await getSasToken("r");
            //PS-142 With the SAS token, generate the URL for downloading a solarwind template.
            if (true) {
                window.open(`https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/Infra Map - Solarwinds Template.xlsx` + `?${sas.data}`);
            }
        } catch (error) {
            console.error("Error occurred while downloading the template.", error);
        }
    };
    //PC-52 The `handleEdit` function is called as a result of the 'Edit' button click.
    const handleEdit = () => {
        //PC-53   The state variable `isForm` is set to `true` within the `handleEdit` function, enabling form mode.
        setIsForm(true);
    };
    //PC-47 The `closeModal` function is called, which invokes `setShowDeleteModal(false)`, dismissing the delete confirmation modal.
    const closeModal = () => {
        setShowDeleteModal(false);
        setFlag(false)
    };

    //PS-33:The `handleDelete` function is triggered by the delete button click.
    const handleDelete = async () => {
        //PS-34 The function `setShowDeleteModal` is called with `true`, causing a delete confirmation popup to be shown to the user.
        setShowDeleteModal(true);
        setFlag(true)


    };
    //PS-36-38  Upon user confirmation, `handleDeleteInventory` which encompasses the `confirmDelete` method is invoked to carry out the deletion process using `async/await`.
    const confirmDelete = async () => {
        setShowDeleteModal(false);
        setFlag(false);
        setIsLoading(true);
        try {
            const payload = {
                infraAssessmentDetailsID: inventoryDetails.infraAssessmentDetailsId,
                organizationId: state.organizationId,
                infraAssessmentMenuId: infraAssessmentMenuId,
                verticalId: state.verticalId
            }
            const response = await deleteInfraInventoryDetails(payload);
            setInventoryDetails(inventoryData)
            setFileName("");
            setFile("");
            setData(processedData);
            await fetchInfraInventoryDetails()
            setIsLoading(false);
            // Show success message
        } catch (error) {
            setIsLoading(false);
            // Show error message
        }


    };
    //ps-157 : Validation function `validateInventoryDetails` checks that the necessary inventory details fields are filled out properly.
    const validateInventoryDetails = (): boolean => {
        if (
            (inventoryDetails.infraAssessmentDetailsId !== "" && inventoryDetails.inventoryBlobUrl !== "") ||
            (inventoryDetails.infraAssessmentDetailsId === "" &&
                data !== null &&
                data.instanceCount !== undefined &&
                data.windowsServerCount !== undefined &&
                data.linuxServerCount !== undefined &&
                data.vmCount !== undefined &&
                data.serverNotPatched !== undefined &&
                data.inventoryDetails.length > 0)
        ) {
            return true;
        }
        return false;
    };

    //PS-155 The `handleSaveButton` function is called to handle saving the updated inventory details.
    const handleSaveButton = async () => {
        //PS-156 Within `handleSaveButton`, `setIsLoading(true)` is invoked, indicating the saving process is in progress.
        setIsLoading(true)
        //PS-157 Validation function `validateInventoryDetails` checks that the necessary inventory details fields are filled out properly.
        if (validateInventoryDetails()) {
            //PS-158 Constructs the blob storage path and URL in variables `path` and `blobUrl`.
            let path = state.organizationName.toString() + state.organizationId.toString()
            let blobUrl = inventoryDetails.inventoryBlobUrl
            if (file !== "") {
                //PS-159     If a file is selected (`file` is not an empty string), `uploadFileToBlob1(file, path)` is called to handle the file upload, and the `blobUrl` state is updated accordingly.
                blobUrl = await uploadFileToBlob1(file, path)
                //PS-160 `SaveInfraInventoryDetails` is invoked with `blobUrl` as an argument to save or update inventory details.
                setInventoryDetails(prevState => ({
                    ...prevState,
                    inventoryBlobUrl: blobUrl,
                }));
            }
            await SaveInfraInventoryDetails(blobUrl)
        }
        setIsLoading(false);
    }
    const SaveInfraInventoryDetails = async (blobUrl: any) => {
        //PS-161 Within `SaveInfraInventoryDetails`, a variable `mergedInventoryDetails` is created to combine provided inventory details.
        const mergedInventoryDetails = data.inventoryDetails.length > 0 ? data?.inventoryDetails[0].concat(data?.inventoryDetails[1], data?.inventoryDetails[2]) : data.inventoryDetails;
        //PS-162 Calls `postInfraInventoryDetails` function with a payload that includes necessary data to save inventory details.
        setIsLoading(true)
        try {
            const payload = {
                infraAssessmentDetailsID: inventoryDetails.infraAssessmentDetailsId,
                menuId: infraAssessmentMenuId,
                organizationId: state.organizationId,
                verticalId: state.verticalId,
                solarwindBlobUrl: blobUrl,
                executiveSummary: inventoryDetails.executiveSummary,
                instanceCount: data.instanceCount,
                windowsServerCount: data.windowsServerCount,
                linuxServerCount: data.linuxServerCount,
                windowsDesktopCount: data.vmCount,
                serverNotPatched: data.serverNotPatched,
                inventoryDetails: mergedInventoryDetails,
            };
            //PS-163 The `postInfraInventoryDetails` function constructs the appropriate API call by calling `apiCall` with the URL, method "POST", and the payload.
            const response = await postInfraInventoryDetails(payload);

            //PS-169 If the response status code is 200, the save operation succeeded, and `fetchInventoryDetails` is called to update the component with fresh data after the save operation.
            if (response.status == 200) {
                await fetchInfraInventoryDetails()
                setIsLoading(false)
            }
            //PS-168  If the backend response status code is not 200, indicating an error, a toast message is prepared and `setToastProp` is called to display an error notification to the user.
            else {
                setIsLoading(false)
                let toastData = {
                    toastType: "error",
                    toastHeaderMessage: "Error",
                    toastBodyMessage: "Api Failed",
                };
                setToastProp(toastData);
                setHideToast(false);
            }
            //PS-170 In case of an error caught by the catch block, the error is logged to the console for debugging purposes.
        } catch (error) {
            setIsLoading(false)
            let toastData = {
                toastType: "error",
                toastHeaderMessage: "Error",
                toastBodyMessage: "Api Failed",
            };
            setToastProp(toastData);
            setHideToast(false);
        }
    };

    const bindConnectorsForm = () => {
        const bindSaveAndCancel = () => {
            return (
                <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
                    <button type="button"
                        className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                        disabled={isDisabled}
                        onClick={() => {
                            handleSaveButton()
                        }}
                    >Save</button>
                    <a className="theme-link-btn cursor-pointer font-14 font-semibold text-center order-md-first"
                        onClick={() => {
                            attachment.current.value = null;
                            setInventoryDetails(inventoryData)
                            setFileName("")
                            setValidationMessage("")
                            fetchInfraInventoryDetails()
                        }}
                    >
                        Cancel</a>
                </div>
            );
        };
        return (
            <>
                <div className="row">
                    <div className="col-lg-8 col-md-12 col-12">
                        <p className="font-24 font-bold mt-3">
                            Inventory Configurations
                        </p>
                        <p className="font-14 font-bold color-black mb-4">Step 1</p>
                        <div className="d-flex mb-3">
                            <span onClick={() => { downloadTemplate() }}>
                                <img src="images/download.svg" alt="download" title="download" className="me-2" />
                                <span className="link-blue font-13 font-semibold link-blue cursor-pointer">Download Template</span>
                                <p className="form-label font-14 font-regular color-black  my-4">Download the template</p> </span>
                        </div>
                        <p className="font-14 font-bold color-black mb-4">Step 2</p>
                        <p className="form-label font-14 font-regular color-black  mb-4">Enter valid details on the template.</p>
                        <p className="font-14 font-bold color-black mb-3">Step 3</p>
                        <div className="mb-3 d-md-flex align-items-center " style={{ "cursor": "pointer" }}>
                            <span className="upload-btn-wrapper me-2 d-block ">
                                <button type="button" className="font-12 link-blue font-medium bg-white border-0 shadow-none"><img src="images/upload.svg" alt="browse" className="me-2" />Upload Template</button>
                                <input type="file" name="myfile" accept=".xlsx" className="cursor-pointer" ref={attachment} onChange={(e) => { readFile(e); }} />
                            </span>
                        </div>
                        <div>
                            <span className="font-14 font-medium color-grey mt-1 ms-2  d-block ">
                                {fileName}
                                <span className="cursor-pointer ms-3" title="Cancel">
                                    {fileName ? (<img src="images/upload-close.svg" alt="upload-close" onClick={() => {
                                        removeFile();
                                    }} />
                                    ) : (
                                        <></>
                                    )}
                                </span>
                            </span>
                            <p className="mt-2 mb-0 color-red font-14 font-regular">
                                {validationMessage}
                            </p>
                        </div>
                    </div>
                    <p className="font-24 font-bold mt-5">Summary</p>
                    <div className="mb-5">
                        <label className="form-label font-14 font-semibold color-black" htmlFor="ExecutiveSummary">Executive Summary</label>
                        <textarea className="form-control theme-form resize-none h-auto" rows={5} placeholder="Enter Executive Summary" id="executiveSummary"
                            value={inventoryDetails.executiveSummary}
                            onChange={
                                (e) => {
                                    setInventoryDetails(prevDetails => ({
                                        ...prevDetails, executiveSummary: e.target.value
                                    }));
                                }} />
                    </div>
                    {bindSaveAndCancel()}
                </div>

            </>);

    }

    const renderDetails = () => {
        return (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
                <p className="font-24 font-bold mt-3">Inventory Configurations</p>
                <div className="table-responsive mb-4 border border-1 rounded p-2">
                    <table className="table table-borderless mb-0">
                        <thead className="font-semibold"></thead>
                        <tbody className="font-regular">
                            <tr className="text-nowrap connector-table">
                                <td className="text-start text-nowrap">
                                    <img src="images/solarwinds.svg" alt="solarwinds" className="me-2" />
                                    <span className="font-14 font-semibold color-black-v2 me-3">Solarwind - 1</span>
                                </td>
                                <td className="text-end">
                                    <button type="button" className="btn edit-btn px-4" onClick={() => handleEdit()}>
                                        Edit
                                    </button>
                                    <button type="button" className="btn btn-outline-danger ms-3 px-3" onClick={() => handleDelete()}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    return (
        <>
            {showDeleteModal && (
                <div
                    className="modal fade show"
                    id="delete"
                    tabIndex={-1}
                    aria-hidden="true"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    style={{ display: 'block' }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-3">
                            <div className="modal-header pb-0 border-0">

                                <button
                                    type="button"
                                    className="btn-close cursor-pointer"
                                    onClick={closeModal}
                                    aria-label="Close"
                                ></button>
                            </div>

                            <div className="modal-body">
                                <div className="w-100 text-center mb-3">
                                    <img src="images/delete-icon.svg" alt="delete" />
                                </div>

                                <p className="font-semibold font-24 text-center mb-2 red-400">
                                    Delete Confirmation
                                </p>

                                <p className="font-semibold font-16 text-center mb-5">
                                    Do you really want to delete the Application?
                                </p>

                                <div className="d-grid gap-2 d-md-inline-flex justify-content-md-center mb-2 mb-md-0 w-100">
                                    <a
                                        className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer px-4"
                                        onClick={confirmDelete}
                                    >
                                        Yes
                                    </a>

                                    <button
                                        type="button"
                                        className="btn btn-danger red-400 btn-lg px-4 text-white font-14 font-semibold"
                                        onClick={closeModal}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isForm ?
                bindConnectorsForm() :
                renderDetails()
            }
            {flag && <div className="modal-backdrop fade show"></div>}
            {hideToast ? <></> :
                <ToastComponent name={toastProp} />
            }
        </>
    )
}
