import { useEffect, useLayoutEffect, useState } from "react";
import {
  ConnectorPropsModel,
  attributesValueModel,
  connectorKeysModel,
  multiSelectDropdownDataModel,
} from "../interface/questionnaireInterface";
import {
  deleteConnectors,
  getConnectedConnectors,
  getGenerateReportButtonFlag,
  getProfileAndWorkLoad,
  getServiceNowDropdown,
  PostAutoQuestionnarie,
  postConnectors,
  postCurrentCost,
  validateAwsConnector,
} from "../service/QuestionnaireApi";
import MobilityConnector from "./connectorComponents/MobilityConnectorForm";
import AppDevConnector from "./connectorComponents/AppDevConnectorForm";
import InfraAndCloudConnector from "./connectorComponents/InfraAndCloudConnectorForm";
import DataStrategyConnector from "./connectorComponents/DataStrategyConnectorForm";
import QAConnector from "./connectorComponents/QaConnectorForm";
import uploadFileToBlob1 from "../helpers/BlobUpload";
import IAMConnector from "./connectorComponents/IAMConnectorForm";
import ServiceNowConnector from "./connectorComponents/ServiceNowConnectorForm";
import WafrConnector from "./connectorComponents/WafrConnectorForm";
import WellArchitect from "./WellArchitectConnector";
import MAPConnector from "./connectorComponents/MAPConnector";
import InfraAssessment from "./InfraAssessment";
import Inventory from "./Inventory";

export default function Connectors(props: ConnectorPropsModel) {
  const {
    activeConnector,
    activeConnectorId,
    activeIds,
    isLoading,
    setGenerateButton,
    setConnectedConnectors,
    snControlAreaSelectedValues,
    setSnControlAreaSelectedValues,
    snSubMenuSelectedValues,
    setSnSubMenuSelectedValues,
    setIsConnectorProvided,
    verticalName,
    connectors,
    setShowToasterPopup,
    setShowToasterLoading,
    setShowToasterFailedPopup,
    setShowToaster,
    showToaster,
  } = props;

  const formDataObj = [
    {
      connectorName: "Sonar Cloud",
      connectorValue: {
        "Organization Key": "",
        "Access Key": "",
        "Project Key": [],
      },
    },
    {
      connectorName: "Git Repository",
      connectorValue: {
        "Repository URL": "",
        "Authentication API Key": "",
        Branch: "",
      },
    },
    {
      connectorName: "Azure",
      connectorValue: {
        "Connector Name": "",
        "Azure Tenant ID": "",
        "Azure Subscription ID": "",
        "Secret Key": "",
        "Client ID": "",
      },
    },
    {
      connectorName: "AWS",
      connectorValue:
        props.verticalName === "WAFR" || "MDPW"
          ? {
              "IAM Access Key": "",
              "Secret Key": "",
              Region: "",
              "Account ID": "",
            }
          : {
              "Connector Name": "",
              "Account ID": "",
              "Access ID": "",
              "Secret Key": "",
            },
    },
    {
      connectorName: "Azure AD",
      connectorValue: {
        "Microsoft Primary Domain": "https://graph.microsoft.com",
        "Enterprise Application ID": "",
        "Client Secret": "",
        "Tenant ID": "",
      },
    },
    {
      connectorName: "Okta",
      connectorValue: {
        "Okta Primary Domain": "",
        "Okta Token Endpoint": "",
        "Client ID": "",
        "Client Secret": "",
      },
    },

    {
      connectorName: "ServiceNow",
      connectorValue: {
        "Connector Name": "",
        "ServiceNow URL": "",
        Username: "",
        Password: "",
        "Product Suite": "",
        "Product Suite Subcategories": "",
        "Environment template": "",
      },
    },
    {
      connectorName: "Source Code",
      connectorValue: {
        "Source Control System": "Git",
        "Source Control Tool": "Git Lab",
        "Source Code": "",
        "Access Token": "",
      },
    },
    {
      connectorName: "Access Controls",
      connectorValue: {
        "Play Store URL": "",
        "Play Store Manager Access": "",
        "Google Play API key": "",
        "App Store URL": "",
        "App Store Manager Access": "",
        "App Store Connect API key": "",

        "System integrated with Crash Monitoring System?": "",
        "System integrated with Log Monitoring System?": "",
        "System integrated with Analytics System?": "",
      },
    },
    {
      connectorName: "JIRA",
      connectorValue: {
        "Tenant Domain URL": "",
        "Project Name": "",
        Username: "",
        Password: "",
      },
    },
    {
      connectorName: "Solarwinds Orion",
      connectorValue: {
        template: "",
      },
    },
    {
      connectorName: "Solarwinds",
      connectorValue: {
        template: "",
      },
    },
    {
      connectorName: "Inventory",
      connectorValue: {
        template: "",
      },
    },
    {
      connectorName: "Azure",
      connectorValue: {
        "Subscription ID": "",
        "Client ID": "",
        "Tenant ID": "",
        "Client Secret": "",
      },
    },
    {
      connectorName: "On Premise",
      connectorValue: {
        template: "",
      },
    },
  ];

  const CrashMonitoringDropdown = [
    {
      label: "Sentry",
      value: "Sentry",
    },
    {
      label: "Firebase Crashlytics",
      value: "Firebase Crashlytics",
    },
    {
      label: "App Center",
      value: "App Center",
    },
    {
      label: "Rollbar",
      value: "Rollbar",
    },
    {
      label: "Instabug",
      value: "Instabug",
    },
    {
      label: "New Relic Mobile",
      value: "New Relic Mobile",
    },
  ];

  const LogMonitoringDropdown = [
    {
      label: "Sentry",
      value: "Sentry",
    },
    {
      label: "Firebase Crashlytics",
      value: "Firebase Crashlytics",
    },
    {
      label: "App Center",
      value: "App Center",
    },
    {
      label: "Instabug",
      value: "Instabug",
    },
  ];

  const AnalyticsMonitoringDropdown = [
    {
      label: "Google Analytics for Mobile Apps",
      value: "Google Analytics for Mobile Apps",
    },
    {
      label: "Firebase Analytics",
      value: "Firebase Analytics",
    },
    {
      label: "Mixpanel",
      value: "Mixpanel",
    },
  ];

  let [connectorGridData, setConnectorGridData] = useState<
    connectorKeysModel[]
  >([]);
  let [showConnectorForm, setShowConnectorForm] = useState(true);
  let [connectorFormData, setConnectorFormData] = useState<any>({});

  let [snSubMenuTreeDataOptions, setSnSubMenuTreeDataOptions] = useState<any>(
    []
  );

  let [snApiTreeData, setSnApiTreeData] = useState<any>([]);
  let [excelTemplateData, setExcelTemplateData] = useState<any>([]);

  let [editedConnectorAttributeId, setEditedConnectorAttributeId] = useState<
    string | null
  >(null);

  let [renderGrid, setRenderGrid] = useState(false);

  let [connectorFormValidationMsg, setConnectorFormValidationMsg] =
    useState<any>({});
  let [connectorApiValidationMsg, setConnectorApiValidationMsg] =
    useState<string>("");

  const [mobilityActiveTab, setMobilityActiveTab] = useState(true);

  const addNewConnectorShow = ["Git Repository", "Azure", "AWS"];
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [hideToast, setHideToast] = useState(true);
  const [toastProp, setToastProp] = useState({
    toastType: '',
    toastHeaderMessage: '',
    toastBodyMessage: '',
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<any>("")
  const closeModal = () => {
    setShowDeleteModal(false);
  };

  const [profileDetails, setProfileDetails] = useState<any>({})
  const [workLoadDetails, setWorkLoadDetails] = useState<any>({})

  const connectedConnectorShow = [
    "Sonar Cloud",
    "ServiceNow",
    "Okta",
    "Azure AD",
  ];

  const attributesWithArray = [
    "Project Key",
    "Crash Monitoring Tool",
    "Log Monitoring Tool",
    "Analytics Tool",
    "Product Suite",
    "Product Suite Subcategories",
  ];

  useEffect(() => {
    setConnectorFormData({});
    loadActiveConnectors();
    setShowConnectorForm(true);

    setConnectorApiValidationMsg("");
    setEditedConnectorAttributeId("");
  }, [activeConnector, renderGrid, activeIds]);

  useLayoutEffect(() => {
    isLoading(true);
    getGenerateReportButtonFlag({
      organizationId: activeIds.organizationId,
      verticalId: activeIds.verticalId,
      projectId: activeIds.projectId || null,
    })
      .then((response) => {
        setGenerateButton(!response?.buttonFlag);
        setConnectedConnectors(response?.connectors);
        if (
          activeIds?.verticalName === "IAM & Security" &&
          !response?.connectors?.length
        ) {
          setGenerateButton(true);
        }
      })
      .catch((e) => {
        isLoading(false);
        //console.log(e);
      });
    isLoading(false);
  }, [renderGrid]);

  const loadActiveConnectors = async () => {
    try {
      if (activeConnectorId) {
        let dataParams = {
          organizationId: activeIds.organizationId,
          connectorId: activeConnectorId,
          verticalId: activeIds.verticalId,
          projectId: activeIds.projectId === null ? null : activeIds.projectId,
        };
        isLoading(true);

        if (activeConnector === "ServiceNow") {
          let serviceNowApiData = await getServiceNowDropdown();
          setSnApiTreeData(serviceNowApiData?.ControlAreaMenu);
        }

        if (activeConnector === "Well-Architected") {
          const response = await getProfileAndWorkLoad(dataParams);
          setProfileDetails(response?.profile)
          setWorkLoadDetails(response?.workLoad)
        }

        let connectorApiData = await getConnectedConnectors(dataParams);
        if (connectorApiData.length) {
          setGenerateButton(false);
          if (activeConnector === "Access Controls") {
            setMobilityActiveTab(true);
            editConnector(connectorApiData[0]);
          }
          if (activeConnector === "ServiceNow") {
            formDataObj.forEach((obj) => {
              if (obj.connectorName === activeConnector) {
                connectorApiData[0]?.Attributes?.forEach(
                  (val: attributesValueModel) => {
                    if (val.attributeName === "Product Suite")
                      setSnControlAreaSelectedValues(val.attributeValue);
                    else if (
                      val.attributeName === "Product Suite Subcategories"
                    )
                      setSnSubMenuSelectedValues(val.attributeValue);
                  }
                );
              }
            });
          }
          setConnectorGridData(connectorApiData);
        } else {
          setConnectorGridData([]);
          formDataObj.forEach((obj) => {
            if (obj.connectorName === activeConnector) {
              setConnectorFormData(obj.connectorValue);
              let cloneForm = JSON.parse(JSON.stringify(obj.connectorValue));
              for (const key in cloneForm) {
                cloneForm[key] = "";
              }
              setConnectorFormValidationMsg(cloneForm);
            }
          });
          setSnSubMenuSelectedValues([]);
          setSnControlAreaSelectedValues([]);
        }
      }

      isLoading(false);
    } catch (error) {
      isLoading(false);
      // console.error(error, "connected Connectors");
    }
  };


  const handleDeleteClick = (id: any) => {
    setShowDeleteModal(true);
    setItemToDelete(id);

  };

  // const deleteConnector = async (deleteId: string) => {

  //   const res = await deleteConnectors(deleteId);
  //   if (res.status === 200) {
  //     setConnectedConnectors([]);
  //     setEditedConnectorAttributeId("");
  //     setRenderGrid(!renderGrid);
  //   }
  //   isLoading(false);
  // };



  const confirmDelete = async () => {
    try {
      const response = await deleteConnectors(itemToDelete);
      if (response.status === 200) {
        setShowDeletePopup(true);
        isLoading(true);
        closeModal();
        setConnectedConnectors([]);
        setEditedConnectorAttributeId("");
        setRenderGrid(!renderGrid);
        setIsConnectorProvided(false);
      } else {
        isLoading(false);
        let toastData = {
          toastType: 'error',
          toastHeaderMessage: 'Error',
          toastBodyMessage: 'API failed'
        };
        setToastProp(toastData);
        setHideToast(false);
      }
    } catch (error) {
      setShowToasterFailedPopup(true);
      isLoading(false);
    }
    finally {
      isLoading(false);
      setTimeout(() => {
        setShowDeletePopup(false);
        setShowToasterFailedPopup(false);
      }, 4000);
    }
  };

  const editConnector = (connectorData: connectorKeysModel) => {
    let newFormObj: any = {};

    formDataObj.forEach((obj) => {
      if (obj.connectorName === activeConnector) {
        connectorData?.Attributes?.forEach((val) => {
          if (
            val.attributeValue.length > 1 ||
            attributesWithArray.includes(val.attributeName)
          )
            newFormObj[val.attributeName] = val.attributeValue;
          else newFormObj[val.attributeName] = val.attributeValue[0];

          if (activeConnector === "ServiceNow") {
            if (val.attributeName === "Product Suite")
              handleMultiSelectChange(val.attributeValue, "Product Suite");
            else if (val.attributeName === "Product Suite Subcategories")
              handleSnTreeSelectChange(val.attributeValue);
          }
        });
        let cloneForm = JSON.parse(JSON.stringify(obj.connectorValue));
        for (const key in cloneForm) {
          cloneForm[key] = "";
        }
        setConnectorFormValidationMsg(cloneForm);
      }
    });

    setConnectorFormData(newFormObj);

    setEditedConnectorAttributeId(connectorData?.attributeId);
    setIsConnectorProvided(false);
    setShowConnectorForm(false);
  };

  const connectorsOnChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    connectorFormData[e.target.name] = e.target.value;

    // connectorFormData[e.target.name] = e.target.value;
    if (activeConnector === "Source Code") {
      setConnectorApiValidationMsg("");
      if (
        e.target.name === "Source Control System" &&
        e.target.value === "Azure"
      ) {
        connectorFormData["Source Control System"] = "Azure";
        connectorFormData["Source Control Tool"] = "TFS";
        connectorFormData["Source Code"] = "";
        connectorFormData["Access Token"] = "";
        connectorFormValidationMsg["Source Code"] = "";
        connectorFormValidationMsg["Access Token"] = "";
      }
      if (
        e.target.name === "Source Control System" &&
        e.target.value === "Git"
      ) {
        connectorFormData["Source Control System"] = "Git";
        connectorFormData["Source Control Tool"] = "Git Lab";
        connectorFormData["Source Code"] = "";
        connectorFormData["Access Token"] = "";
        connectorFormValidationMsg["Source Code"] = "";
        connectorFormValidationMsg["Access Token"] = "";
      }
      if (
        e.target.name === "Source Control System" &&
        e.target.value === "Azure"
      ) {
        connectorFormData["Source Control System"] = "Azure";
        connectorFormData["Source Control Tool"] = "TFS";
        connectorFormData["Source Code"] = "";
        connectorFormData["Access Token"] = "";
        connectorFormValidationMsg["Source Code"] = "";
        connectorFormValidationMsg["Access Token"] = "";
      }
      if (e.target.name === "Source Control Tool") {
        connectorFormData["Source Code"] = "";
        connectorFormData["Access Token"] = "";
        connectorFormValidationMsg["Source Code"] = "";
        connectorFormValidationMsg["Access Token"] = "";
      }
    }
    // if (!emojiRegex.test(e.target.value)) {
    //   setConnectorFormData({ ...connectorFormData });
    // }
    setConnectorFormData({ ...connectorFormData });
    if (activeConnector === "Access Controls" && !mobilityActiveTab) {
      addOrRemoveMobilityCredsField("Crash Monitoring Tool", true);
      addOrRemoveMobilityCredsField("Log Monitoring Tool", true);
      addOrRemoveMobilityCredsField("Analytics Tool", true);
    }
  };

  const sonarCloudProjectkeyOnChange = (e: any) => {
    if (e.code === "Enter") {
      if (e.target.value?.trim() !== "") {
        connectorFormData["Project Key"].push(e.target.value);
        e.target.value = "";
      }
      setConnectorFormData({ ...connectorFormData });
    }
  };

  const handleMultiSelectChange = (values: string[], name: string) => {
    if (connectorFormData.hasOwnProperty(name)) {
      connectorFormData[name] = values as string[];
      setConnectorFormData({ ...connectorFormData });
    }
    if (activeConnector === "Access Controls") {
      connectorFormData[name]?.forEach((value: string) => {
        let newCredsObj = {
          [`${name}-${value}-Username`]: "",
          [`${name}-${value}-Password`]: "",
        };
        if (
          !connectorFormData.hasOwnProperty(`${name}-${value}-Username`) &&
          !connectorFormData.hasOwnProperty(`${name}-${value}-Password`)
        )
          setConnectorFormData({
            ...connectorFormData,
            ...newCredsObj,
          });
      });

      if (values.length === 0) {
        connectorFormData[name] = values as string[];
        deleteMobilityToolCredsInputFields(name);
      }
    }

    // Service Now On Change (Multi select)
    if (activeConnector === "ServiceNow") {
      snControlAreaSelectedValues.forEach((obj) => {
        if (!values?.includes(obj)) {
          let selectSubMenuRemoveItems = snSubMenuTreeDataOptions.find(
            (item: any) => item.title === obj
          )?.children;

          selectSubMenuRemoveItems.forEach((menu: any) => {
            snSubMenuSelectedValues.forEach((re: string, index: number) => {
              if (menu.value === re) {
                snSubMenuSelectedValues?.splice(index, 1);
              }
            });
          });
          connectorFormData["Product Suite Subcategories"] =
            snSubMenuSelectedValues;

          snSubMenuTreeDataOptions = snSubMenuTreeDataOptions.filter(
            (item: any) => !(obj === item.title)
          );
        }
      });
      setSnSubMenuSelectedValues([...snSubMenuSelectedValues]);
      setSnControlAreaSelectedValues(values as string[]);
      snApiTreeData.forEach((obj: any) => {
        values.forEach((vv: any) => {
          if (obj.title === vv && !snSubMenuTreeDataOptions.includes(obj)) {
            snSubMenuTreeDataOptions.push(obj);
          }
        });
      });

      setSnSubMenuTreeDataOptions(
        removeTreeDataDuplicates(snSubMenuTreeDataOptions)
      );
      setConnectorFormData({ ...connectorFormData });
    }
  };

  const removeTreeDataDuplicates = (arr: any[]) => {
    arr = arr.filter(
      (value: any, index: number, self: any) =>
        index === self.findIndex((t: any) => t.title === value.title)
    );
    return arr;
  };

  const deleteMobilityToolCredsInputFields = (name: string) => {
    let dropdownType: multiSelectDropdownDataModel[] = [];
    if (name === "Crash Monitoring Tool")
      dropdownType = CrashMonitoringDropdown;
    else if (name === "Log Monitoring Tool")
      dropdownType = LogMonitoringDropdown;
    else if (name === "Analytics Tool")
      dropdownType = AnalyticsMonitoringDropdown;

    dropdownType.forEach((item) => {
      if (connectorFormData.hasOwnProperty(`${name}-${item.value}-Username`)) {
        delete connectorFormData[`${name}-${item.value}-Username`];
      }
      if (connectorFormData.hasOwnProperty(`${name}-${item.value}-Password`))
        delete connectorFormData[`${name}-${item.value}-Password`];
    });
  };

  const handleSnTreeSelectChange = (newValue: string[]) => {
    connectorFormData["Product Suite Subcategories"] = newValue as string[];
    setConnectorFormData({ ...connectorFormData });
    setSnSubMenuSelectedValues(newValue);
  };

  const addOrRemoveMobilityCredsField = (
    toolType: string,
    isOnChange: boolean = false
  ) => {
    function addToolsOptionProperties(prop1: string, prop2: string) {
      if (
        !connectorFormData.hasOwnProperty(`${prop1}`) &&
        !connectorFormData.hasOwnProperty(`${prop2}`)
      ) {
        connectorFormData[`${prop1}`] = [];
        connectorFormData[`${prop2}`] = "";
      }
      setConnectorFormData({ ...connectorFormData });
    }

    function removeToolsOptionProperties(systemType: string) {
      deleteMobilityToolCredsInputFields(systemType);
      if (connectorFormData.hasOwnProperty(systemType)) {
        delete connectorFormData[systemType];
      }
      if (
        connectorFormData.hasOwnProperty(
          `Do we have access to the ${systemType}s?`
        )
      )
        delete connectorFormData[`Do we have access to the ${systemType}s?`];
    }

    // if (isOnChange) {
    if (
      connectorFormData["System integrated with Crash Monitoring System?"] ===
      "Yes"
    ) {
      addToolsOptionProperties(
        "Crash Monitoring Tool",
        "Do we have access to the Crash Monitoring Tools?"
      );
    } else {
      removeToolsOptionProperties("Crash Monitoring Tool");
    }
    if (
      connectorFormData["System integrated with Log Monitoring System?"] ===
      "Yes"
    ) {
      addToolsOptionProperties(
        "Log Monitoring Tool",
        "Do we have access to the Log Monitoring Tools?"
      );
    } else {
      removeToolsOptionProperties("Log Monitoring Tool");
    }
    if (
      connectorFormData["System integrated with Analytics System?"] === "Yes"
    ) {
      addToolsOptionProperties(
        "Analytics Tool",
        "Do we have access to the Analytics Tools?"
      );
    } else {
      removeToolsOptionProperties("Analytics Tool");
    }
    // } else {
    if (connectorFormData[`Do we have access to the ${toolType}s?`] === "No") {
      deleteMobilityToolCredsInputFields(toolType);
    } else {
      const bindArray: any = connectorFormData[toolType] || [];
      if (bindArray !== undefined) {
        bindArray?.forEach((value: string) => {
          if (
            !connectorFormData.hasOwnProperty(
              `${toolType}-${value}-Username`
            ) &&
            !connectorFormData.hasOwnProperty(`${toolType}-${value}-Password`)
          ) {
            connectorFormData[`${toolType}-${value}-Username`] = "";
            connectorFormData[`${toolType}-${value}-Password`] = "";
          }
        });
        setConnectorFormData({ ...connectorFormData });
      }
    }
  };

  /****
   * VALidations
   */
  const validateConnectorInput = async (templateFile?: any) => {
    let notValid = true;
    isLoading(true);
    if (activeConnector === "Access Controls" && !mobilityActiveTab) {
      addOrRemoveMobilityCredsField("Crash Monitoring Tool");
      addOrRemoveMobilityCredsField("Log Monitoring Tool");
      addOrRemoveMobilityCredsField("Analytics Tool");
      if (
        !(
          connectorFormData["Play Store URL"] != "" &&
          connectorFormData["Play Store Manager Access"] &&
          connectorFormData["Google Play API key"] != "" &&
          connectorFormData["App Store URL"] != "" &&
          connectorFormData["App Store Manager Access"] != "" &&
          connectorFormData["App Store Connect API key"] != ""
        )
      ) {
        delete connectorFormData["Play Store URL"];
        delete connectorFormData["Play Store Manager Access"];
        delete connectorFormData["Google Play API key"];
        delete connectorFormData["App Store URL"];
        delete connectorFormData["App Store Manager Access"];
        delete connectorFormData["App Store Connect API key"];
      }
    } else if (activeConnector === "Access Controls" && mobilityActiveTab) {
      if (
        !(
          connectorFormData[
            "System integrated with Crash Monitoring System?"
          ] != "" &&
          connectorFormData["System integrated with Log Monitoring System?"] &&
          connectorFormData["System integrated with Analytics System?"] != ""
        )
      ) {
        delete connectorFormData[
          "System integrated with Crash Monitoring System?"
        ];
        delete connectorFormData[
          "System integrated with Log Monitoring System?"
        ];
        delete connectorFormData["System integrated with Analytics System?"];
      }
    }
    let cloneObjectData = JSON.parse(JSON.stringify(connectorFormData));

    if (templateFile) {
      if (activeConnector === "ServiceNow") {
        if (templateFile.environment) {
          let link = await uploadTemplate(templateFile.environment);
          cloneObjectData["Environment template"] = link;
        }
        if (templateFile.itam) {
          let link = await uploadTemplate(templateFile.itam);
          cloneObjectData["ITAM template"] = link;
        }
      } else {
        let link = await uploadTemplate(templateFile);
        cloneObjectData.template = link;
      }
    }

    const keys = Object.keys(cloneObjectData);
    keys.forEach((key) => {
      const value = cloneObjectData[key];
      if (
        (key === "Crash Monitoring Tool" ||
          key === "Log Monitoring Tool" ||
          key === "Analytics Tool" ||
          key === "Product Suite" ||
          key === "Product Suite Subcategories") &&
        value.length === 0
      ) {
        isLoading(false);
        notValid = false;
        connectorFormValidationMsg[key] = "Please select any values";
      } else if (key.includes("?") && value === "") {
        isLoading(false);
        notValid = false;
        connectorFormValidationMsg[key] = "Please select any option";
      } else if (key === "Project Key" && value.length === 0) {
        isLoading(false);
        notValid = false;
        connectorFormValidationMsg[key] = "Please enter the " + key;
      } else if (key === "ITAM template" && value === "") {
        if (connectorFormData["Product Suite"].includes("ITAM")) {
          isLoading(false);
          notValid = false;
          connectorFormValidationMsg[key] = "Please upload the template file";
        }
      } else if (
        key === "Environment template" &&
        (value === "" || value.length === 0)
      ) {
        isLoading(false);
        notValid = false;
        connectorFormValidationMsg[key] = "Please upload the template file";
      } else if (key === "Region") {
        if (value.length === 0) {
          isLoading(false);
          notValid = false;
          connectorFormValidationMsg[key] = "Please select Region";
        } else {
          isLoading(true);
          notValid = true;
          connectorFormValidationMsg[key] = "";
        }
      } else if (value === "") {
        isLoading(false);
        notValid = false;
        connectorFormValidationMsg[key] = "Please enter the " + key;
      } else {
        connectorFormValidationMsg[key] = "";
      }
    });
    setConnectorFormValidationMsg({ ...connectorFormValidationMsg });

    if (
      (activeConnector === "Azure" ||
        activeConnector === "AWS" ||
        activeConnector === "Solarwinds" ||
        activeConnector === "Inventory" ||
        activeConnector === "On Premise" ||
        activeConnector == "Solarwinds Orion") &&
      excelTemplateData.length === 0
    ) {
      switch (verticalName) {
        case "WAFR":
        case "MDPW":
        case "VMware":
        case "MAP":
          setConnectorApiValidationMsg("");
          isLoading(false);
          break;
        default:
          isLoading(false);
          notValid = false;
      }
    }
    if (!validateServiceNowMultiSelect() && activeConnector === "ServiceNow") {
      isLoading(false);
      notValid = false;
    }

    if (activeConnector === "AWS" && notValid) {
      notValid =
        connectorFormData["IAM Access Key"].trim().length > 0 &&
        connectorFormData["Secret Key"].trim().length > 0 &&
        connectorFormData["Account ID"].trim().length > 0 &&
        connectorFormData["Region"].trim().length > 0;
    }

    if (activeConnector == "AWS" && notValid) {
      const awsCredrequest = {
        "iamAccessKey": connectorFormData["IAM Access Key"],
        "secretKey": connectorFormData["Secret Key"],
        "accountId": connectorFormData["Account ID"],
        "regions": [connectorFormData["Region"]],
      }
      const validateAws = await validateAwsConnector(awsCredrequest)

      if (validateAws.status === 200 && !validateAws?.data?.isValidAwsConnector) {
        notValid = false;
        setConnectorApiValidationMsg(
          "Please enter valid credentials"
        );
        isLoading(false);
        return;
      }
    }

    if (notValid) saveConnector(cloneObjectData);
  };

  const validateServiceNowMultiSelect = () => {
    let validateMSG: any = [];
    snApiTreeData.forEach((obj: any) => {
      snControlAreaSelectedValues.forEach((val: any) => {
        if (
          obj.title === val &&
          obj.children.every(
            (a: any) => !snSubMenuSelectedValues?.includes(a.value)
          )
        ) {
          obj.valid = false;
          validateMSG.push(obj.title);
        }
      });
    });
    setSnSubMenuTreeDataOptions([...snSubMenuTreeDataOptions]);

    if (!validateMSG.length) {
      connectorFormValidationMsg["Product Suite Subcategories"] = "";
      setConnectorFormValidationMsg({ ...connectorFormValidationMsg });
      return true;
    } else {
      connectorFormValidationMsg[
        "Product Suite Subcategories"
      ] = `Please select subCategories for ${validateMSG.join(", ")}`;
      setConnectorFormValidationMsg({ ...connectorFormValidationMsg });
      return false;
    }
  };

  const saveConnector = async (finalizedConnectorData: any) => {
    try {
      let postApiObjectData;
      let postIds = {
        organizationId: activeIds.organizationId,
        connectorId: activeConnectorId,
        connectorName: activeConnector,
        projectId: activeIds.projectId,
        verticalId: activeIds.verticalId,
        attributeId: editedConnectorAttributeId || null,
        verticalName: verticalName,
      };

      const keys = Object.keys(finalizedConnectorData);
      let postDataObj = keys?.map((obj) => {
        let object = {
          key: obj,
          value:
            typeof finalizedConnectorData[obj] === "string"
              ? [finalizedConnectorData[obj]]
              : finalizedConnectorData[obj],
        };
        return object;
      });
      postApiObjectData = {
        id: postIds,
        data: postDataObj,
        userName: "Dhilipan",
      };

      if (activeConnector === "Azure" || activeConnector === "AWS" || activeConnector == "On Premise") {
        postApiObjectData = {
          id: postIds,
          Recommendations: verticalName !== "MDPW" ? excelTemplateData : [],
          ConnectorDetails: postDataObj,
          userName: "Dhilipan",
        };
      }

      if (
        activeConnector == "Solarwinds Orion" ||
        activeConnector === "Solarwinds" 
      ) {
        postApiObjectData = {
          id: postIds,
          data: excelTemplateData,
          ConnectorDetails: postDataObj,
          userName: "Dhilipan",
        };
      }

      if (activeConnector == "ServiceNow") {
        postApiObjectData = {
          id: postIds,
          data: postDataObj,
          calculations: [
            {
              key: "enviromentData",
              value: parseInt(
                connectorFormData["environmentData"] === undefined
                  ? 0
                  : connectorFormData["environmentData"][0]
              ),
            },
            {
              key: "ITAMData",
              value: parseInt(
                connectorFormData["ITAMData"] === undefined
                  ? 0
                  : connectorFormData["ITAMData"][0]
              ),
            },
          ],
          userName: "Dhilipan",
        };
      }

      isLoading(true);
      let connectorsApiData = await postConnectors(postApiObjectData);

      if (connectorsApiData.status === 200) {
        setIsConnectorProvided(true);
        if (
          verticalName == "MDPW" &&
          postApiObjectData?.id?.connectorName == "AWS"
        ) {
          let postCurrentCostRequest = {
            organizationId: postApiObjectData.id.organizationId,
            verticalId: postApiObjectData.id.verticalId,
            projectId: postApiObjectData.id.projectId,
            connectorId: postApiObjectData.id.connectorId,
          };
          let currentCostResponse = await postCurrentCost(
            postCurrentCostRequest
          );
          if (currentCostResponse.status !== 200) {
            isLoading(false);
            setConnectorApiValidationMsg(
              "Error posting the AWS current cost details."
            );
            return;
          }
        }

        if (
          verticalName === "WAFR" &&
          postApiObjectData?.id?.connectorName == "AWS"
        ) {
          PostAutoQuestionnarie({
            organizationId: activeIds.organizationId,
            connectorId: activeConnectorId,
            verticalId: activeIds.verticalId,
            projectId: activeIds.projectId,
          }).then(() => {
            // console.log("Auto questionnaire completed successfully");
          }).catch((error) => {
            // console.error("Error in PostAutoQuestionnarie:", error);
          });
        }
        setConnectorApiValidationMsg("");
        let connectorGridApiData = await getConnectedConnectors({
          organizationId: activeIds.organizationId,
          connectorId: activeConnectorId || null,
          verticalId: activeIds.verticalId,
          projectId: activeIds.projectId,
        });
        if (connectorGridApiData[0]?.Attributes?.length != 0)
          setConnectorGridData(connectorGridApiData);
        else setConnectorGridData([]);
        setRenderGrid(!renderGrid);
      } else {
        setConnectorApiValidationMsg("Facing Error...");
      }
      isLoading(false);
    } catch (error) {
      isLoading(false);
      setConnectorApiValidationMsg("Please enter valid credentials");
    }
  };

  const bindConnectors = () => {
    switch (activeConnector) {
      // App Dev
      case "Sonar Cloud":
        return bindConnectedConnectorGrid(
          activeConnector,
          activeConnectorId,
          "images/sonarcloud.svg",
          "Sonar Cloud Configuration"
        );
      case "Git Repository":
        return bindConnectedConnectorGrid(
          activeConnector,
          activeConnectorId,
          "images/Git-logo.svg",
          "Git Repository Configuration"
        );

      // Infra & Cloud

      case "Azure":
        return bindConnectedConnectorGrid(
          activeConnector,
          activeConnectorId,
          "images/azure.svg",
          "Azure Connector"
        );

      case "On Premise":
        return bindConnectedConnectorGrid(
          activeConnector,
          activeConnectorId,
          "images/ms-excel-icon.svg",
          "On-Premise Connector"
        );
      case "Solarwinds":
        return bindConnectedConnectorGrid(
          activeConnector,
          activeConnectorId,
          "images/solarwinds.svg",
          "Solarwinds Configurations"
        );

      case "Inventory":
        return bindConnectedConnectorGrid(
          activeConnector,
          activeConnectorId,
          "images/ms-excel-icon.svg",
          "Inventory Configurations"
        );

      case "Azure":
        return bindConnectedConnectorGrid(
          activeConnector,
          activeConnectorId,
          "images/azure.svg",
          "Azure Connector"
        );

      case "Well-Architected":
        return (
          <WellArchitect projectDetails={{ 
            organizationId: activeIds.organizationId,
            verticalId: activeIds.verticalId, 
            projectId: activeIds.projectId, 
            connectorId: connectors[0].connectorId, 
            activeConnectorId: activeConnectorId }} 
            isLoading={isLoading} 
            setShowToasterFailedPopup={setShowToasterFailedPopup} 
            setShowToasterPopup={setShowToasterPopup} 
            setShowToasterLoading={setShowToasterLoading}
            setShowToaster={setShowToaster}
            profileDetails={profileDetails}
            setProfileDetails={setProfileDetails}
            workLoadDetails={workLoadDetails}
            setWorkLoadDetails={setWorkLoadDetails}
            showToaster={showToaster}
            />
        )

      case "AWS":
        switch (props.verticalName) {
          case "WAFR":
          case "MDPW":
            return bindConnectedConnectorGrid(
              activeConnector,
              activeConnectorId,
              "images/aws-logo.svg",
              "Aws Configurations"
            );
          default:
            return bindConnectedConnectorGrid(
              activeConnector,
              activeConnectorId,
              "images/aws-logo.svg",
              "AWS Connector"
            );
        }

      // IAM
      case "Azure AD":
        return bindConnectedConnectorGrid(
          activeConnector,
          activeConnectorId,
          "images/azureAd.svg",
          "Azure AD Configurations"
        );
      case "Okta":
        return bindConnectedConnectorGrid(
          activeConnector,
          activeConnectorId,
          "images/okta.svg",
          "Okta Configurations"
        );

      // ServiceNow
      case "ServiceNow":
        return bindConnectedConnectorGrid(
          "firstcare_sandbox",
          activeConnectorId,
          "images/servicenow.svg",
          "ServiceNow Configuration"
        );
        break;
      // Mobility
      case "Source Code":
        return bindConnectedConnectorGrid(
          activeConnector,
          activeConnectorId,
          "images/Github.svg",
          "Source Code Configuration"
        );
      case "Access Controls":
        return bindConnectorsForm();

      case "Solarwinds Orion":
        return bindConnectedConnectorGrid(
          activeConnector,
          activeConnectorId,
          "images/excel-logo.svg",
          "Solarwinds Orion Configuration"
        );

      case "JIRA":
        return bindConnectedConnectorGrid(
          activeConnector,
          activeConnectorId,
          "images/jira-logo.svg",
          "JIRA Cloud Configuration"
        );
    }
  };

  const bindConnectorsForm = () => {
    switch (activeConnector) {
      // App Dev
      case "Sonar Cloud":
        return (
          <AppDevConnector
            activeConnector={activeConnector}
            connectorFormData={connectorFormData}
            setConnectorFormData={setConnectorFormData}
            validateConnectorInput={validateConnectorInput}
            connectorFormValidationMsg={connectorFormValidationMsg}
            connectorsOnChange={connectorsOnChange}
            setShowConnectorForm={setShowConnectorForm}
            editedConnectorAttributeId={editedConnectorAttributeId}
            sonarCloudProjectkeyOnChange={sonarCloudProjectkeyOnChange}
            connectorApiValidationMsg={connectorApiValidationMsg}
            resetValidationMessages={resetValidationMessages}
          />
        );

      case "Git Repository":
        return (
          <AppDevConnector
            activeConnector={activeConnector}
            connectorFormData={connectorFormData}
            setConnectorFormData={setConnectorFormData}
            validateConnectorInput={validateConnectorInput}
            connectorFormValidationMsg={connectorFormValidationMsg}
            connectorsOnChange={connectorsOnChange}
            setShowConnectorForm={setShowConnectorForm}
            editedConnectorAttributeId={editedConnectorAttributeId}
            sonarCloudProjectkeyOnChange={sonarCloudProjectkeyOnChange}
            connectorApiValidationMsg={connectorApiValidationMsg}
            resetValidationMessages={resetValidationMessages}
          />
        );

      // Infra & Cloud

      case "Azure":
        case "On Premise":
          return (
            <MAPConnector
              activeConnector={activeConnector}
              connectorFormData={connectorFormData}
              setConnectorFormData={setConnectorFormData}
              validateConnectorInput={validateConnectorInput}
              connectorFormValidationMsg={connectorFormValidationMsg}
              connectorsOnChange={connectorsOnChange}
              setShowConnectorForm={setShowConnectorForm}
              editedConnectorAttributeId={editedConnectorAttributeId}
              connectorApiValidationMsg={connectorApiValidationMsg}
              resetValidationMessages={resetValidationMessages}
              formDataObj={formDataObj}
            />
          );

      case "Solarwinds":
      case "Inventory":
        return (
          <InfraAndCloudConnector
            verticalName={verticalName}
            activeConnector={activeConnector}
            connectorFormData={connectorFormData}
            setConnectorFormData={setConnectorFormData}
            validateConnectorInput={validateConnectorInput}
            connectorsOnChange={connectorsOnChange}
            setShowConnectorForm={setShowConnectorForm}
            formDataObj={formDataObj}
            editedConnectorAttributeId={editedConnectorAttributeId}
            setExcelTemplateData={setExcelTemplateData}
            connectorFormValidationMsg={connectorFormValidationMsg}
            resetValidationMessages={resetValidationMessages}
            connectorApiValidationMsg={connectorApiValidationMsg}
          />
        );
      case "Azure":
        return (
          <InfraAndCloudConnector
            activeConnector={activeConnector}
            connectorFormData={connectorFormData}
            setConnectorFormData={setConnectorFormData}
            validateConnectorInput={validateConnectorInput}
            setExcelTemplateData={setExcelTemplateData}
            connectorsOnChange={connectorsOnChange}
            setShowConnectorForm={setShowConnectorForm}
            formDataObj={formDataObj}
            editedConnectorAttributeId={editedConnectorAttributeId}
            connectorFormValidationMsg={connectorFormValidationMsg}
            resetValidationMessages={resetValidationMessages}
            uploadTemplate={uploadTemplate}
            connectorApiValidationMsg={connectorApiValidationMsg}
          />
        );

      case "AWS":
        switch (props.verticalName) {
          case "WAFR":
          case "MDPW":
            return (
              <WafrConnector
                activeConnector={activeConnector}
                connectorFormData={connectorFormData}
                setConnectorFormData={setConnectorFormData}
                validateConnectorInput={validateConnectorInput}
                connectorFormValidationMsg={connectorFormValidationMsg}
                connectorsOnChange={connectorsOnChange}
                setShowConnectorForm={setShowConnectorForm}
                editedConnectorAttributeId={editedConnectorAttributeId}
                sonarCloudProjectkeyOnChange={sonarCloudProjectkeyOnChange}
                connectorApiValidationMsg={connectorApiValidationMsg}
                resetValidationMessages={resetValidationMessages}
                isLoading={isLoading}
              />
            );

          default:
            return (
              <InfraAndCloudConnector
                activeConnector={activeConnector}
                connectorFormData={connectorFormData}
                setConnectorFormData={setConnectorFormData}
                validateConnectorInput={validateConnectorInput}
                connectorsOnChange={connectorsOnChange}
                setShowConnectorForm={setShowConnectorForm}
                formDataObj={formDataObj}
                editedConnectorAttributeId={editedConnectorAttributeId}
                setExcelTemplateData={setExcelTemplateData}
                connectorFormValidationMsg={connectorFormValidationMsg}
                resetValidationMessages={resetValidationMessages}
                connectorApiValidationMsg={connectorApiValidationMsg}
              />
            );
        }

      // IAM
      case "Azure AD":
        return (
          <IAMConnector
            activeConnector={activeConnector}
            connectorFormData={connectorFormData}
            setConnectorFormData={setConnectorFormData}
            validateConnectorInput={validateConnectorInput}
            connectorFormValidationMsg={connectorFormValidationMsg}
            connectorsOnChange={connectorsOnChange}
            setShowConnectorForm={setShowConnectorForm}
            editedConnectorAttributeId={editedConnectorAttributeId}
            sonarCloudProjectkeyOnChange={sonarCloudProjectkeyOnChange}
            connectorApiValidationMsg={connectorApiValidationMsg}
            resetValidationMessages={resetValidationMessages}
          />
        );
      case "Okta":
        return (
          <IAMConnector
            activeConnector={activeConnector}
            connectorFormData={connectorFormData}
            setConnectorFormData={setConnectorFormData}
            validateConnectorInput={validateConnectorInput}
            connectorFormValidationMsg={connectorFormValidationMsg}
            connectorsOnChange={connectorsOnChange}
            setShowConnectorForm={setShowConnectorForm}
            editedConnectorAttributeId={editedConnectorAttributeId}
            sonarCloudProjectkeyOnChange={sonarCloudProjectkeyOnChange}
            connectorApiValidationMsg={connectorApiValidationMsg}
            resetValidationMessages={resetValidationMessages}
          />
        );
      // ServiceNow
      case "ServiceNow":
        return (
          <ServiceNowConnector
            activeConnector={activeConnector}
            connectorFormData={connectorFormData}
            setConnectorFormData={setConnectorFormData}
            validateConnectorInput={validateConnectorInput}
            connectorFormValidationMsg={connectorFormValidationMsg}
            connectorsOnChange={connectorsOnChange}
            setShowConnectorForm={setShowConnectorForm}
            editedConnectorAttributeId={editedConnectorAttributeId}
            connectorApiValidationMsg={connectorApiValidationMsg}
            resetValidationMessages={resetValidationMessages}
            handleMultiSelectChange={handleMultiSelectChange}
            handleSnTreeSelectChange={handleSnTreeSelectChange}
            snSubMenuTreeDataOptions={snSubMenuTreeDataOptions}
            snSubMenuSelectedValues={snSubMenuSelectedValues}
            snControlAreaSelectedValues={snControlAreaSelectedValues}
            setExcelTemplateData={setExcelTemplateData}
          />
        );

      // Mobility
      case "Source Code":
        return (
          <MobilityConnector
            activeConnector={activeConnector}
            connectorFormData={connectorFormData}
            validateConnectorInput={validateConnectorInput}
            connectorsOnChange={connectorsOnChange}
            setShowConnectorForm={setShowConnectorForm}
            CrashMonitoringDropdown={CrashMonitoringDropdown}
            LogMonitoringDropdown={LogMonitoringDropdown}
            AnalyticsMonitoringDropdown={AnalyticsMonitoringDropdown}
            handleMultiSelectChange={handleMultiSelectChange}
            handleSnTreeSelectChange={handleSnTreeSelectChange}
            mobilityActiveTab={mobilityActiveTab}
            setMobilityActiveTab={setMobilityActiveTab}
            connectorFormValidationMsg={connectorFormValidationMsg}
            connectorApiValidationMsg={connectorApiValidationMsg}
            setConnectorFormData={setConnectorFormData}
            resetValidationMessages={resetValidationMessages}
            handleDeleteClick={handleDeleteClick}
            connectorGridData={connectorGridData}
          />
        );
      case "Access Controls":
        return (
          <MobilityConnector
            activeConnector={activeConnector}
            connectorFormData={connectorFormData}
            validateConnectorInput={validateConnectorInput}
            connectorsOnChange={connectorsOnChange}
            setShowConnectorForm={setShowConnectorForm}
            CrashMonitoringDropdown={CrashMonitoringDropdown}
            LogMonitoringDropdown={LogMonitoringDropdown}
            AnalyticsMonitoringDropdown={AnalyticsMonitoringDropdown}
            handleMultiSelectChange={handleMultiSelectChange}
            handleSnTreeSelectChange={handleSnTreeSelectChange}
            mobilityActiveTab={mobilityActiveTab}
            setMobilityActiveTab={setMobilityActiveTab}
            connectorFormValidationMsg={connectorFormValidationMsg}
            connectorApiValidationMsg={connectorApiValidationMsg}
            setConnectorFormData={setConnectorFormData}
            resetValidationMessages={resetValidationMessages}
            handleDeleteClick={handleDeleteClick}
            connectorGridData={connectorGridData}
          />
        );

      //DS
      case "Solarwinds Orion":
        return (
          <DataStrategyConnector
            activeConnector={activeConnector}
            connectorFormData={connectorFormData}
            setConnectorFormData={setConnectorFormData}
            validateConnectorInput={validateConnectorInput}
            setShowConnectorForm={setShowConnectorForm}
            formDataObj={formDataObj}
            editedConnectorAttributeId={editedConnectorAttributeId}
            setExcelTemplateData={setExcelTemplateData}
          />
        );

      case "JIRA":
        return (
          <QAConnector
            activeConnector={activeConnector}
            connectorFormData={connectorFormData}
            setConnectorFormData={setConnectorFormData}
            validateConnectorInput={validateConnectorInput}
            connectorFormValidationMsg={connectorFormValidationMsg}
            connectorsOnChange={connectorsOnChange}
            setShowConnectorForm={setShowConnectorForm}
            editedConnectorAttributeId={editedConnectorAttributeId}
            sonarCloudProjectkeyOnChange={sonarCloudProjectkeyOnChange}
            connectorApiValidationMsg={connectorApiValidationMsg}
            resetValidationMessages={resetValidationMessages}
          />
        );
    }
  };

  const bindConnectedConnectorGrid = (
    connector: string,
    connectorId: string,
    imgSource: string,
    connectorConfigName: string
  ) => {
    if (!connectorGridData?.length) {
      setIsConnectorProvided(false);
      return bindNoConnectorFound(connectorConfigName);
    } else {
      setIsConnectorProvided(true);
      return (
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
          <h3 className="font-18 font-semibold color-black mt-4 mb-4">
            {connectorConfigName}
          </h3>
          {connectorGridData?.map((grid, index) => {
            return (
              <div
                key={index}
                className="table-responsive mb-4 border border-1 rounded p-2 "
              >
                <table className="table table-borderless mb-0">
                  <thead className="font-semibold "></thead>
                  <tbody className="font-regular">
                    <tr className="text-nowrap connector-table">
                      <td className="text-start text-nowrap">
                        <img src={imgSource} alt="logo" className="me-2" />
                        <span className="font-14 font-semibold color-black-v2 me-3">
                          {/* Accounts Management */}
                          {connector}{" "}
                          {connectorGridData.length > 1 ? "-" + index + 1 : ""}
                        </span>
                        <span
                          hidden={
                            !connectedConnectorShow.includes(activeConnector)
                          }
                          className="severity severity-low-bg me-2 font-12"
                        >
                          <span className="severity-point severity-low me-2" />
                          Connected
                        </span>
                      </td>
                      <td className="d-flex justify-content-end">
                        {(verticalName.includes("MDPW")||verticalName.includes("VMware")) && activeConnector === "Inventory" && connectorGridData?.length >= 1 ? <></> :
                         (verticalName.toLowerCase() === "map" && activeConnector === "On Premise") ? <></>:
                          <button
                            type="button"
                            className="btn edit-btn custom-btn font-medium px-4"
                            onClick={() => {
                              editConnector(grid);
                            }}
                          >
                            Edit
                          </button>}
                        {verticalName.includes("WAFR") && connectorGridData?.length >= 1 ? <></> :
                          <button
                            type="button"
                            className="btn btn-outline-danger custom-btn ms-3 font-medium"
                            onClick={() => {
                              handleDeleteClick(grid.attributeId);
                            }}
                          >
                            Delete
                          </button>}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })}
          {verticalName.includes("WAFR") ||
          verticalName.includes("MDPW") ||
          verticalName.includes("MAP") ||
          (verticalName.includes("VMware") &&
            connectorGridData?.length >= 1) ? (
            <></>
          ) : (
            <div
              className="align-items-center"
              hidden={!addNewConnectorShow.includes(activeConnector)}
            >
              <span
                className="link-blue font-14 font-regular cursor-pointer"
                onClick={() => {
                  setShowConnectorForm(false);
                }}
              >
                <img src="images/plusBlue.svg" alt="Plus" className="me-2" />
                Add New Connector
              </span>
            </div>
          )}
        </div>
      );
    }
  };

  const bindNoConnectorFound = (connectorConfigName: string) => {
    return (
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
        <h3 className="font-18 font-semibold color-black mt-4 mb-4">
          {connectorConfigName}
        </h3>
        <div className="font-14 font-medium color-grey-v3 m-5 d-flex justify-content-center">
          <p className="mb-0">
            Sorry you don’t have any connectors configured. Please click on “Add
            New Connector” to add a new connector
          </p>
        </div>
        <div className="align-items-center">
          <span
            className="link-blue font-14 font-regular cursor-pointer"
            onClick={() => {
              setShowConnectorForm(false);
            }}
          >
            <img src="images/plusBlue.svg" alt="Plus" className="me-2" />
            Add New Connector
          </span>
        </div>
      </div>
    );
  };

  const resetValidationMessages = (connector: string) => {
    formDataObj.forEach((obj) => {
      if (obj.connectorName === connector) {
        if (activeConnector !== "Access Controls")
          setConnectorFormData(obj.connectorValue);
        let cloneForm = JSON.parse(JSON.stringify(obj.connectorValue));
        for (const key in cloneForm) {
          cloneForm[key] = "";
        }
        setConnectorFormValidationMsg(cloneForm);
      }
    });
    setConnectorApiValidationMsg("");
    setEditedConnectorAttributeId("");
    if (activeConnector === "ServiceNow") {
      setSnControlAreaSelectedValues([]);
      setSnSubMenuSelectedValues([]);
      setSnSubMenuTreeDataOptions([]);
    }
  };

  /**PS_AN_63-PS_AN_68
   * This function is used to download the template file uploaded in the blob
   */
  const uploadTemplate = async (file: any) => {
    try {
      return await uploadFileToBlob1(
        file,
        activeIds.organizationName.toString() +
          activeIds.organizationId.toString()
      );
    } catch (error) {
      // console.log(error, "error");
    }
  };
  return (
    <>
      <div className="row">
        {showConnectorForm ? bindConnectors() : bindConnectorsForm()}
        {showDeleteModal && (
          <div
            className="modal fade show"
            id="delete"
            tabIndex={-1}
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            style={{ display: 'block' }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content p-3">
                <div className="modal-header pb-0 border-0">
                  <button
                    type="button"
                    className="btn-close cursor-pointer"
                    onClick={closeModal}
                    aria-label="Close"
                  ></button>
                </div>

                <div className="modal-body">
                  <div className="w-100 text-center mb-3">
                    <img src="images/delete-icon.svg" alt="delete" />
                  </div>

                  <p className="font-semibold font-24 text-center mb-2 red-400">
                    Delete Confirmation
                  </p>

                  <p className="font-semibold font-16 text-center mb-5">
                    Are you sure you want to delete?
                  </p>

                  <div className="d-grid gap-2 d-md-inline-flex justify-content-md-center mb-2 mb-md-0 w-100">
                    <a
                      className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer px-4"
                      onClick={confirmDelete}
                    >
                      Yes
                    </a>

                    <button
                      type="button"
                      className="btn btn-danger red-400 btn-lg px-4 text-white font-14 font-semibold"
                      onClick={closeModal}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showDeleteModal && <div className="modal-backdrop fade show"></div>}
      </div>
    </>
  );
}
