import React, { useState, useEffect } from 'react';
import { getRegions } from '../../service/AssesmentApi';
import { regionsModel } from '../../interface/questionnaireInterface';
export default function WafrConnector(props: any) {
  const {
    activeConnector,
    validateConnectorInput,
    connectorsOnChange,
    setShowConnectorForm,
    connectorFormData,
    setConnectorFormData,
    connectorFormValidationMsg,
    connectorApiValidationMsg,
    resetValidationMessages,
    isLoading
  } = props;

  const [isRegionDropdownOpen, setIsRegionDropdownOpen] = useState(false);

  const [regions, setRegions] = useState<regionsModel[]>([]);

  useEffect(() => {
    fetchRegions();
  }, []);


  const fetchRegions = async () => {
    try {
      isLoading(true);
      const response = await getRegions();

      if (response.status === 200) {
        const formattedRegions = response.data.map((region: any) => ({
          regionId: region.aws_region_details_id,
          regionName: region.region_name
        }));
        setRegions(formattedRegions);
      }
    } catch (error) {
      console.error('Error fetching regions:', error);
    }
    isLoading(false)
  };

  const handleRegionChange = (regionName: string) => {
    const updatedRegions = connectorFormData["Region"] ? connectorFormData["Region"].split(',') : [];
    if (updatedRegions.includes(regionName)) {
      updatedRegions.splice(updatedRegions.indexOf(regionName), 1);
    } else {
      updatedRegions.push(regionName);
    }
    updateRegions(updatedRegions);
  };


  const updateRegions = (updatedRegions: string[]) => {
    connectorsOnChange({
      target: {
        name: "Region",
        value: updatedRegions.join(','),
      },
    });
  };

  const removeRegion = (regionName: string) => {
    const updatedRegions = connectorFormData["Region"].split(',').filter((r: string) => r !== regionName);
    updateRegions(updatedRegions);
  };

  const bindConnectorsForm = () => {
    const bindSaveAndCancel = () => {
      return (
        <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
          <button
            type="button"
            className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
            onClick={() => {
              validateConnectorInput(null);
            }}
          >
            Save
          </button>
          <a
            className="theme-link-btn cursor-pointer  font-semibold text-center order-md-first"
            onClick={() => {
              setConnectorFormData({});
              resetValidationMessages(activeConnector);
              setShowConnectorForm(true);
            }}
          >
            Cancel
          </a>
        </div>
      );
    };
    switch (activeConnector) {
      case "AWS":
        return (
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
            <h3 className="font-18 font-semibold color-black mt-4 mb-4">
              AWS Connector
            </h3>
            <div className="col-lg-8 col-md-12 col-12 mb-4">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="IAM Access Key"
                      className="form-label font-14 font-semibold color-black"
                    >
                      IAM Access Key
                    </label><span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="IAM Access Key"
                      name="IAM Access Key"
                      placeholder="Enter IAM Access Key"
                      value={connectorFormData["IAM Access Key"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["IAM Access Key"]}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Secret Key"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Secret Key
                    </label><span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Secret Key"
                      name="Secret Key"
                      placeholder="Enter Secret Key"
                      value={connectorFormData["Secret Key"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Secret Key"]}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Region"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Region
                    </label><span className="color-red"> *</span>
                    <div className="dropdown">
                      <button
                        className="form-control theme-form font-regular font-14 text-start color-fade d-flex justify-content-between align-items-center"
                        type="button"
                        id="regionDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded={isRegionDropdownOpen}
                        onClick={() => setIsRegionDropdownOpen(!isRegionDropdownOpen)}
                      >
                        <span>
                          {connectorFormData["Region"] ?
                            <div className="d-flex flex-wrap gap-2">
                              {connectorFormData["Region"].split(',').map((region: string) => (
                                <span key={region} className="badge font-regular font-12 bg-secondary">
                                  {region}
                                  <button
                                    type="button"
                                    className="btn-close btn-close-white ms-2"
                                    aria-label="Close"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      removeRegion(region);
                                    }}
                                  ></button>
                                </span>
                              ))}
                            </div>
                            :
                            'Select Regions'
                          }
                        </span>
                        <img src="images/arrow.png" alt="Dropdown arrow" height={15} width={15} className="ms-3" />
                      </button>
                      <ul className={`dropdown-menu w-100 overflow-auto dropdown-menu-scroll ${isRegionDropdownOpen ? 'show' : ''}`} aria-labelledby="regionDropdown">
                        {regions.map((region: regionsModel) => (
                          <li key={region.regionId}>
                            <div className="dropdown-item">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`region-${region.regionId}`}
                                  checked={connectorFormData["Region"] && connectorFormData["Region"].includes(region.regionName)}
                                  onChange={() => handleRegionChange(region.regionName)}
                                />
                                <label className="form-check-label" htmlFor={`region-${region.regionId}`}>
                                  {region.regionName}
                                </label>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Region"]}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Account ID"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Account ID
                    </label><span className="color-red"> *</span>
                    <input
                      type='password'
                      className="form-control theme-form font-regular font-14"
                      id="Account ID"
                      name="Account ID"
                      placeholder="Enter Account ID"
                      value={connectorFormData["Account ID"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Account ID"]}
                    </p>
                  </div>
                </div>
                <p className="mt-1 mb-0 color-red font-14 font-regular">
                  {connectorApiValidationMsg}
                </p>
                {bindSaveAndCancel()}
              </div>
            </div>
          </div>
        );
    }
  };

  return <>{bindConnectorsForm()}</>;
}