import { baseUrl, infraAssessmentEndpoints } from "../constant/constant";
import {apiCall} from "../client/apiCall";
//import { InventoryDetailsRequestModel } from "../interface/InfraAssessmentModel";
import { GetQuestionsRequestModel } from "../interface/InfraAssessmentModel";

export async function getInfraAssessmentMenus(payload: any) {
    try {
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.getInfraAssessmentMenus, 'POST', payload);
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getInfraAssessmentSubMenus(payload: any) {
    try {
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.getInfraAssessmentSubmenus, 'POST', payload);
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getInfraInventoryDetails(payload: any) {
    try {
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.getInfraInventoryDetails, 'POST', payload);
        return response;
    } catch (error) {
        throw error;
    }
}
//PS-164 `apiCall` in `Client.ts` performs the API call to the backend service using the Axios library with the provided configuration.
export async function postInfraInventoryDetails(payload: any) {
    try {
        //PS-165 `apiResponse` obtained from the backend is prepared to be sent back to the API layer.
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.postInfraInventoryDetails, 'POST', payload);
        //PS-167 The API layer passes the backend response back to the FC.
        return response;
    } catch (error) {
        throw error;
    }
}

export async function postInfraQuestionnaireDetails(payload: any) {
    try {
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.postInfraQuestionDetails, 'POST', payload);
        return response;
    } catch (error) {
        throw error;
    }
}

export async function postInfraApplicationDetails(payload: any) {
    try {
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.postInfraApplicationDetails, 'POST', payload);
        return response;
    } catch (error) {
        throw error;
    }
}
export async function getQuestionsForSubmenu(payload: GetQuestionsRequestModel) {
    try {
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.getInfraQuestions, 'POST', payload);
        return response;
    } catch (error) {
        throw error;
    }
}

//PC-38 :`deleteInventoryDetails` method within API.ts, which will initiate the actual API call.
export async function deleteInfraInventoryDetails(payload: any) {
    try {
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.deleteInfraInventoryDetails, 'POST', payload);
        return response;
    } catch (error) {
        throw error;
    }
}
export async function deleteInfraApplicationInventoryDetails(payload: any) {
    try {
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.deleteInfraApplicationDetails, 'POST', payload);
        return response;
    } catch (error) {
        throw error;
    }
}

export async function reportGenerationButton(payload: any) {
    try {
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.reportGenerationButton, 'POST', payload);
        return response;
    } catch (error) {
        throw error;
    }
}

// PS_MigrationStrategy_23 -  PS_MigrationStrategy_24
export async function getInfraAssessmentApplications(payload: any) {
    try {
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.getInfraAssessmentApplications, 'POST', payload);
        return response;
    } catch (error) {
        throw error;
    }
}

//ps -22 Executes `axios.get` or other axios method to send request to backend with provided configurations.
export async function getInfraApplicationDetails(payload: any) {
    try {
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.getInfraAssessmentApplications, 'POST', payload);
        return response;
        //ps-24 Response from the backend is stored in `apiResponse` and is returned to the API layer.
    } catch (error) {
        throw error;
    }
}

// PS_MigrationStrategy_36 -  PS_MigrationStrategy_37
export async function getInfraAssessmentQuestions(payload: any) {
    try {
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.getInfraAssessmentQuestions, 'POST', payload);
        return response;
    } catch (error) {
        throw error;
    }
}

// PS_MigrationStrategy_67
export async function postMigrationStrategyAnswers(payload: any) {
    try {
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.postMigrationStrategyAnswers, 'POST', payload);
        return response;
    } catch (error) {
        throw error;
    }
}
// PS_MigrationPlan_07 -  PS_MigrationPlan_08
export async function getMigrationPhaseDropdown(payload:any) {
    try {
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.getMigrationPhaseDropdown, 'POST',payload);
        return response;
    } catch (error) {
        throw error;
    }
}
// PS_MigrationPlan_93
export async function postMigrationPlanDetails(payload: any) {
    try {
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.postMigrationPlanDetails, 'POST', payload);
        return response;
    } catch (error) {
        throw error;
    }
}

// PS_MigrationPlan_32
export async function getMigrationPlans(payload: any) {
    try {
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.getMigrationPlans, 'POST', payload);
        return response;
    } catch (error) {
        throw error;
    }
}

// PS_MigrationPlan_122
export async function DeleteMigrationPlanDetails(payload: any) {
    try {
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.DeleteMigrationPlanDetails, 'POST', payload);
        return response;
    } catch (error) {
        throw error;
    }
}
export async function getMigrationProcessDropdown() {
    try {
        const response = await apiCall(baseUrl + infraAssessmentEndpoints.getMigrationProcessDropdown, 'GET', {});
        return response;
    } catch (error) {
        throw error;
    }
}



