import { useState, useRef, useEffect } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getRegeneratedSummary } from "../../service/ReportApi";

const TextEditor = (props: any) => {
    const { onSave, onCancel, VMwareRecommendation, summaryType, verticalName, showRegenerateButton } = props;
    const regex = /<[^>]*>/g;
    const backendRegex = /<[^>]*>|"/g;
    const [value, setValue] = useState<string>(props.value);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isInitialized, setIsInitialized] = useState(false);
    const [showToasterPopup, setShowToasterPopup] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showToasterFailedPopup, setShowToasterFailedPopup] = useState(false);
    const [showToasterLoading, setShowToasterLoading] = useState(false);
    const [hideToast, setHideToast] = useState(true);
    const [toastProp, setToastProp] = useState({
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: '',
    });
    const [isRegeneratingSpecificType, setIsRegeneratingSpecificType] = useState(false);
    const [isSpecificType, setIsSpecificType] = useState(false);
    const [isVMwareRecommendation, setisVMwareRecommendation] = useState(
        VMwareRecommendation ? true : false
    );
    const [characterCount, setCharacterCount] = useState(0);
    const [isExceedingLimit, setIsExceedingLimit] = useState(false);
    const [descriptionError, setDescriptionError] = useState<string>("");
    const CHARACTER_LIMIT = 2000;

    const quillRef = useRef<ReactQuill>(null);

    const specificTypes = ["Business Flow", "Workflow Component", "Current State Architecture"];

    // Define your toolbar options
    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],

        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ];

    // Define your modules and formats
    const modules = {
        toolbar: toolbarOptions,
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet'
    ];

    function escapeJsonString(str: string): string {
        return str.replace(/\\/g, '\\\\')
            .replace(/"/g, '\\"')
            .replace(/\n/g, '\\n')
            .replace(/\r/g, '\\r')
            .replace(/\t/g, '\\t')
            .replace(/\f/g, '\\f');
    }

    const regenerateSummary = async () => {

        const isSpecific = specificTypes.includes(summaryType);
        setIsSpecificType(isSpecific);

        if (isSpecific) {
            setIsRegeneratingSpecificType(true);
        } else {
            setShowToasterLoading(true);
        }

        try {
            const sanitizedValue = escapeJsonString(value.replace(backendRegex, ""));
            let payload = {
                verticalName: verticalName,
                executiveSummary: "",
                businessOverview: "",
                conclusion: "",
                applicationSummary: "",
                businessFlow: "",
                workFlow: "",
                currentStateArchitecture: "",
                sessionSummary: ""
            };

            switch (summaryType) {
                case "Executive Summary":
                    payload.executiveSummary = sanitizedValue;
                    break;
                case "Business Overview":
                    payload.businessOverview = sanitizedValue;
                    break;
                case "WAFR Final Report Walkthrough":
                    payload.conclusion = sanitizedValue;
                    break;
                case "conclusion":
                    payload.conclusion = sanitizedValue;
                    break;
                case "Application Summary":
                    payload.applicationSummary = sanitizedValue;
                    break;
                case "Business Flow":
                    payload.businessFlow = sanitizedValue;
                    break;
                case "Workflow Component":
                    payload.workFlow = sanitizedValue;
                    break;
                case "Current State Architecture":
                    payload.currentStateArchitecture = sanitizedValue;
                    break;
                case "Session Summary":
                    payload.sessionSummary = sanitizedValue;
                    break;
                default:
                    return;
            }
            const response = await getRegeneratedSummary(payload);
            if (response.status === 200) {

                if (!isSpecific) {
                    setShowToasterPopup(true);
                    setIsLoading(true);
                } else {
                    setIsRegeneratingSpecificType(false);
                }

                let regeneratedText = "";
                if (summaryType === "Executive Summary") {
                    regeneratedText = response.data.ExecutiveSummary;
                } else if (summaryType === "Business Overview") {
                    regeneratedText = response.data.BusinessOverview;
                } else if (summaryType === "WAFR Final Report Walkthrough" || summaryType === "conclusion") {
                    regeneratedText = response.data.conclusion;
                } else if (summaryType === "Application Summary") {
                    regeneratedText = response.data.ApplicationSummary;
                } else if (summaryType === "Business Flow") {
                    regeneratedText = response.data.businessFlow;
                } else if (summaryType === "Workflow Component") {
                    regeneratedText = response.data.workFlow;
                } else if (summaryType === "Current State Architecture") {
                    regeneratedText = response.data.currentStateArchitecture;
                } else if (summaryType === "Session Summary") {
                    regeneratedText = response.data.sessionSummary;
                }

                if (regeneratedText) {
                    setValue(regeneratedText);
                    setIsDisabled(false);
                    if (!isSpecificType) {
                        setShowToasterPopup(true);
                        setTimeout(() => {
                            setShowToasterPopup(false);
                        }, 4000);

                    }
                } else {
                    setShowToasterFailedPopup(true);
                    setIsRegeneratingSpecificType(false);
                    setTimeout(() => {
                        setShowToasterFailedPopup(false);
                    }, 4000);
                }
                setIsLoading(false);

            } else {
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }
        }
        catch (error) {
            setShowToasterFailedPopup(true);
            setIsLoading(false);
        }
        finally {
            setShowToasterLoading(false);
            if (isSpecificType) {
                setIsLoading(false);
                setIsRegeneratingSpecificType(false);
            } else {
                setTimeout(() => {
                    setShowToasterPopup(false);
                    setShowToasterFailedPopup(false);
                }, 4000);
            }
        }
    }

    const handleDescriptionOnChange = (html: string) => {
        const htmlString = html;
        if (quillRef.current) {
            const editor = quillRef.current.getEditor();
            const selection = editor.getSelection();
        }

        const textContentForCount = htmlString.replace(regex, "");
        const count = textContentForCount.length;
        setCharacterCount(count);

        const isWAFRExecutiveSummary = verticalName === "WAFR" && summaryType === "Executive Summary";

        if (!isWAFRExecutiveSummary) {
            setIsExceedingLimit(count > CHARACTER_LIMIT);
        }

        if (!isWAFRExecutiveSummary && count > CHARACTER_LIMIT) {
            setDescriptionError(`Length cannot exceed 2000 characters`) 
        } else {
            setDescriptionError("") 
        }

        const textContentForBackend = htmlString.replace(backendRegex, "");

        if (count > 0) {
            if (isWAFRExecutiveSummary || count <= CHARACTER_LIMIT) {
                setIsDisabled(false);
            } else {
                setIsDisabled(true);
            }
            setValue(html);
            if (!isInitialized) {
                setIsInitialized(true);
                return;
            }
        } else {
            setValue(html);
            if (specificTypes.includes(summaryType)) {
                setIsDisabled(false);
            } else {
                setIsDisabled(true);
            }
        }
    };

    const handleSave = (value: any) => {
        if (specificTypes.includes(summaryType) && value.replace(regex, "").length === 0) {
            setDescriptionError('Please enter the description');
            onSave(value);
            return;
        }
        setDescriptionError('');
        onSave(value);
    }

    useEffect(() => {
        const initialCount = props.value.replace(regex, "").length;
        setCharacterCount(initialCount);

        if (!(verticalName === "WAFR" && summaryType === "Executive Summary")) {
            setIsExceedingLimit(initialCount > CHARACTER_LIMIT);
        }
        setIsDisabled(initialCount === 0);
    }, []);

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    return (
        <>
            <div>
                <ReactQuill
                    theme="snow"
                    ref={quillRef}
                    value={value}
                    modules={modules}
                    formats={formats}
                    onChange={(text) => {
                        handleDescriptionOnChange(text)
                    }
                    }
                    placeholder="Enter here"
                    style={specificTypes.includes(summaryType) ? { height: 150, marginBottom: 50 } : {}}
                />
            </div>

            {(isExceedingLimit || descriptionError) && !(verticalName === "WAFR" && summaryType === "Executive Summary") && (
                <div className="text-danger font-12 mt-1">{descriptionError}</div>
            )}

            <div className="d-flex justify-content-between buttons-container">
                <div className="mt-4">
                    {showRegenerateButton || isVMwareRecommendation ? null : (
                        <button
                            type="button"
                            className={`btn btn-dark theme-outline-btn regenerate-btn d-flex gap-2 align-items-center font-14 font-semibold ${isRegeneratingSpecificType ? 'disabled' : ''}`}
                            disabled={value.replace(regex, "").length === 0 || (isExceedingLimit && !(verticalName === "WAFR" && summaryType === "Executive Summary")) || isRegeneratingSpecificType}
                            onClick={() => { regenerateSummary() }}>
                            {isRegeneratingSpecificType ? (
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            ) : (
                                <img src="images/regenerate-icon.svg" alt="Regenerate-icon" />
                            )}
                            {isRegeneratingSpecificType ? 'Regenerating' : 'Regenerate'}
                        </button>
                    )}
                </div>

                <div className="d-flex justify-content-end mt-4 mb-5 gap-3">
                    {/* data-bs-dismiss="modal" order-md-first  */}
                    <a className="theme-link-btn font-14 font-semibold text-center cursor-pointer" onClick={() => {
                        setValue("");
                        setDescriptionError("") 
                        onCancel();
                    }}>{specificTypes.includes(summaryType) ? 'Clear' : 'Cancel'}</a>
                    <button
                        type="button"
                        className="btn btn-dark theme-primary-btn-md border-0 font-semibold"
                        disabled={
                            isDisabled ||
                            (
                                !specificTypes.includes(summaryType) &&
                                !(summaryType === "summary") &&
                                characterCount > CHARACTER_LIMIT
                            )
                        }
                        onClick={() => {
                            handleSave(value)
                        }}
                    >
                        Save
                    </button>

                </div>
            </div>

            {showToasterLoading && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img
                                    src="images/loading.gif"
                                    alt="toast-success"
                                    style={{ height: "15px", width: "15px" }}
                                />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Regenerating...
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {
                showToasterPopup && !isRegeneratingSpecificType && !isSpecificType && (
                    <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                        <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                            <div className="toast-body d-flex align-items-center ">
                                <div className="d-block me-3">
                                    <img src="images/toast-success.svg" alt="toast-success" />
                                </div>
                                <div className="d-block">
                                    <span className="font-medium font-16 color-grey">
                                        Regenerated Successfully
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default TextEditor;