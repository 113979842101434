import { jwtDecode } from "jwt-decode";
import { useEffect, useRef, useState } from "react";
import { getConnectorsData } from "../../helpers/encryption";
import { getSasToken } from "../../service/ReportApi";
import { encryptStorage, fileNameSlice } from "../../constant/constant";
import { read, utils } from "xlsx";
import * as XLSX from "xlsx";
import { ValidationRules } from "../../interface/InfraAssessmentModel";

export default function MAPConnector(props: any) {
  const {
    activeConnector,
    validateConnectorInput,
    connectorsOnChange,
    setShowConnectorForm,
    formDataObj,
    connectorFormData,
    setConnectorFormData,
    connectorFormValidationMsg,
    connectorApiValidationMsg,
    resetValidationMessages,
  } = props;


  const attachment: any = useRef(null);

  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<any>();
  const [validationMessage, setValidationMessage] = useState<string>("");


  const OnPremiseValidationHeadings = [
    "Application",
    "Environment",
    "Server Status",
    "Machine Type",
    "OS",
    "OS Version",
    "Disk Size",
    "Percentage Used",
    "Total Memory",
    "Percent Memory Used",
    "CPU Count",
    "CPU Load",
    "Last reboot"

  ];

  useEffect(() => {
    if (connectorFormData["template"]) {
      let filename = fileNameSlice(connectorFormData["template"]) || "";
      setFileName(filename);
    }
  }, []);

  const removeFile = () => {
    //PC-65 The `fileName` state is set to an empty string using `setFileName("")`, and `fetchInfraInventoryDetails()` is called to refresh data.
    setFileName("");
    setFile("");
    setValidationMessage("");
    attachment.current.value = null;
  };

  const validationRules: ValidationRules = {
    //PS-112- 118  Confirms if the value for fields like 'Application', 'Environment', 'OS', 'OS Version' is a non-empty string.
    'Application': (value) => {
      return typeof value === 'string' && value.trim() !== "";
    },
    'Environment': (value) => {
      return typeof value === 'string' && value.trim() !== "";
    },
    'Server Status': (value) => {
      return typeof value === 'string' && ['up', 'down'].includes(value.trim().toLowerCase());
    },
    'Machine Type': (value) => {
      return typeof value === 'string' && ['physical machine', 'virtual machine', 'vm'].includes(value.trim().toLowerCase());
    },
    'OS': (value) => {
      return typeof value === 'string' && value.trim() !== "";
    },
    'OS Version': (value) => {
      return typeof value === 'string' && value.trim() !== "";
    },
    'Disk Size': (value) => {
      if (typeof value !== 'string') return false;
      const diskSizeRegex = /^\d+(\.\d+)?\sGB$/;
      return diskSizeRegex.test(value.trim());
    },
    'Percentage Used': (value) => {
      return typeof value === 'number' && value >= 0 && value <= 1;
    },
    'Total Memory': (value) => {
      if (typeof value !== 'string') return false;
      const totalMemoryRegex = /^\d+(\.\d+)?\sGB$/;
      return totalMemoryRegex.test(value.trim());
    },
    'Percent Memory Used': (value) => {
      return typeof value === 'number' && value >= 0 && value <= 1;
    },
    'CPU Count': (value) => {
      return typeof value === 'number' && Number.isInteger(value) && value > 0;
    },
    'CPU Load': (value) => {
      return typeof value === 'number' && value >= 0 && value <= 1;
    },
    'Last reboot': (value) => {
      const lastRebootRegex = /^(0?[1-9]|1[012])\/(0?[1-9]|[12]\d|3[01])\/\d{4}( \d{1,2}:\d{2}( (AM|PM|am|pm))?)?$/;
      return lastRebootRegex.test(formatDate(value));
    },
  };

  function formatDate(dateStr: any) {
    // Check if the date string matches format "M/D/YYYY HH:mm"
    let date = new Date(dateStr);
    if (!isNaN(date.getTime())) {
      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      let year = date.getFullYear();
      return `${month}/${day}/${year}`;
    }
    // Check if the date string matches format "MM-DD-YYYY hh.mm.ss A"
    let dateParts = dateStr.match(/^(\d{2})-(\d{2})-(\d{4}) (\d{2})\.(\d{2})\.(\d{2}) (AM|PM)$/);
    if (dateParts) {
      let month = dateParts[1].padStart(2, '0');
      let day = dateParts[2].padStart(2, '0');
      let year = dateParts[3];
      let hour = (dateParts[7] === 'PM' && dateParts[4] !== '12') ? parseInt(dateParts[4], 10) + 12 : dateParts[4];
      let minute = dateParts[5];
      return `${month}/${day}/${year}`;
    }

    // Return empty string or handle unrecognized format as needed
    return '';
  }

  const readFile = (event: any) => {
    //attachment.current = null;
    //PS-73 Within the `readFile` function, a variable `file` is created and assigned the first file from the `event.target.files` array.
    const file = event.target.files?.[0];
    if (file) {
      //PS-75 - 76 Calls `setFile`, SETFileName to update the file state with the selected file.
      setFile(file);
      setFileName(file.name)
      const typeArr = ["vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
      let type = typeArr.includes(file.type.split("/")[1]);
      //PS-79 If the file type matches the expected Excel MIME types, the variable `type` is set to `true`, indicating a valid XLSX file.


      if (type) {
        //PS-82 -84 For files with a valid type, a comparison is made between the file's size and a predefined maximum file size limit to ensure it doesn't exceed the limit.
        const maxFileSize = 50 * 1024 * 1024;
        if (file.size > maxFileSize) {
          attachment.current.value = null;
          setValidationMessage("Please upload file size less than 50 MB");
          return;
        }

        setValidationMessage("");
        //PS-85 Creates an instance of `FileReader` named `reader`.
        const reader = new FileReader();
        //PS-86 Calls `readAsArrayBuffer` on `reader`, passing in the `file` to start reading the file’s contents.
        reader.readAsArrayBuffer(file);
        //PS-88 - 93 Reads the excel sheet
        reader.onload = (e) => {
          const workbook = read(e?.target?.result);
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const rows: any[] = XLSX.utils.sheet_to_json(sheet, { header: 1, defval: '' });


          //PS-94 Verifies that `rows` contains at least one row of data, validating that file content is not empty.
          if (rows.length >= 2) {
            // setExcelTemplateData(rows);

            setFileName(event.target.files[0].name);
            //PS-95   Resets `validationMessage` to an empty string as file contains data and no reading errors have occurred at this stage.
            setValidationMessage("");
            const headers = rows[0];
            //PS-97-98 Defines an `expectedHeaders` array with the names of required columns.
            const expectedHeaders = ['Application', 'Environment', 'Server Status', 'Machine Type', 'OS', 'OS Version', 'Disk Size', 'Percentage Used', 'Total Memory', 'Percent Memory Used', 'CPU Count', 'CPU Load', 'Last reboot'];
            const isValidColumns = expectedHeaders.every(header => headers.includes(header));
            //PS-99 If headers do not match, updates `fileName` and `file` state to empty, sets `validationMessage`, and aborts further processing.
            if (!isValidColumns) {
              setFileName("");
              setFile("");
              attachment.current.value = null;
              setValidationMessage("Please upload the file with valid columns and data");
              return;
            }
            //PS-100 -110 Assuming headers are correct, iterates over each row in `rows` to validate the data according to specific `validationRules`.
            const rowHeaders = rows[0];
            const rowData = rows.slice(1);
            rowData.forEach((row: any, index: any) => {
              index = 0;
              Object.keys(validationRules).forEach((key: any) => {
                const isValid = validationRules[key](row[index]);
                if (!isValid) {
                  //PS-104       Sets a validation message noting the requirement for valid data, typically due to validation checks failing.
                  setFileName("");
                  setFile("");
                  attachment.current.value = null;
                  setValidationMessage("Please upload the file with valid data");
                  return;
                }
                index++;
              });
            });
          }
          //PS-112 Calls `setValidationMessage` with a message prompt to upload a file containing valid data if no data rows are present.
          else {
            setFileName("");
            setFile("");
            attachment.current.value = null;
            setValidationMessage("Please upload the file with valid data");
            return;
          }
        };
      }
      //PS-109 Calls `setValidationMessage` with a message prompt to upload a file containing valid data if no data rows are present.
      else {
        attachment.current.value = null;
        setValidationMessage("Please upload a XLSX file");
        setFileName("");
        setFile("");
        return;

      }

    }
    setValidationMessage("");
  }


  const downloadTemplate = async () => {
    try {
      let value = encryptStorage.getItem("jwt")
      let parsedJtk: any = ""
      let storageAccountName: any, containerName: any;
      if (value) {
        //PS-133 Retrieve JWT from browser storage for authorization purposes.    
        parsedJtk = jwtDecode(value)
        storageAccountName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_ACCOUNT_NAME! });
        containerName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_CONTAINER! })
      }
      //PS-136 Request a SAS token from the backend with read permissions for downloading the template.
      const sas = await getSasToken("r");
      //PS-142 With the SAS token, generate the URL for downloading a solarwind template.
      if (true) {
        window.open(`https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/Infra Map - Solarwinds Template.xlsx` + `?${sas.data}`);
      }
    } catch (error) {
      console.error("Error occurred while downloading the template.", error);
    }
  };

  const renderAzureConnector = () => (
    <>
      <div className="col-lg-7 col-md-12 col-12">
        <div className="ps-lg-4">
          <h3 className="font-18 font-semibold color-black mb-4">
            Azure Connector
          </h3>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-5">
                <label htmlFor="Subscription ID" className="form-label font-14 font-semibold color-black">
                  Subscription ID
                </label>
                <input
                  type="password"
                  className="form-control theme-form font-regular font-14"
                  id="Subscription ID"
                  name="Subscription ID"
                  value={connectorFormData["Subscription ID"]}
                  onChange={connectorsOnChange}
                  placeholder="Enter Subscription ID"
                />
                <p className="mt-2 mb-0 color-red font-14 font-regular">
                  {connectorFormValidationMsg["Subscription ID"]}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-5">
                <label htmlFor="Client ID" className="form-label font-14 font-semibold color-black">
                  Client ID
                </label>
                <input
                  type="password"
                  className="form-control theme-form font-regular font-14"
                  id="Client ID"
                  name="Client ID"
                  value={connectorFormData["Client ID"]}
                  onChange={connectorsOnChange}
                  placeholder="Enter Client ID"
                />
                <p className="mt-2 mb-0 color-red font-14 font-regular">
                  {connectorFormValidationMsg["Client ID"]}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-5">
                <label htmlFor="Tenant ID" className="form-label font-14 font-semibold color-black">
                  Tenant ID
                </label>
                <input
                  type="password"
                  className="form-control theme-form font-regular font-14"
                  id="Tenant ID"
                  name="Tenant ID"
                  value={connectorFormData["Tenant ID"]}
                  onChange={connectorsOnChange}
                  placeholder="Enter Tenant ID"
                />
                <p className="mt-2 mb-0 color-red font-14 font-regular">
                  {connectorFormValidationMsg["Tenant ID"]}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-5">
                <label htmlFor="Client Secret" className="form-label font-14 font-semibold color-black">
                  Client Secret
                </label>
                <input
                  type="password"
                  className="form-control theme-form font-regular font-14"
                  id="Client Secret"
                  name="Client Secret"
                  value={connectorFormData["Client Secret"]}
                  onChange={connectorsOnChange}
                  placeholder="Enter Client Secret"
                />
                <p className="mt-2 mb-0 color-red font-14 font-regular">
                  {connectorFormValidationMsg["Client Secret"]}
                </p>
              </div>
            </div>
          </div>
          <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
            <button
              type="button"
              className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
              onClick={() => validateConnectorInput()}
            >
              Save
            </button>
            <a
              className="theme-link-btn cursor-pointer font-semibold text-center order-md-first"
              onClick={() => {
                resetValidationMessages(activeConnector);
                setShowConnectorForm(true);
              }}
            >
              Cancel
            </a>
          </div>
          <p className="mt-2 mb-0 color-red font-14 font-regular">
            {connectorApiValidationMsg}
          </p>
        </div>
      </div>
    </>
  );

  const renderOnPremiseConnector = () => (
    <>
      <div className="col-lg-6 col-md-12 col-12">
        <div className="ps-lg-4">
          <p className="font-24 font-bold mt-1 mb-5">
            On-Premise Connector
          </p>
          <p className="font-14 font-bold color-black mb-4">Step 1</p>
          <div className="d-flex mb-3">
            <span
              onClick={() => {
                downloadTemplate();
              }}
            >
              <img
                src="images/download.svg"
                alt="download"
                title="download"
                className="me-2"
              />
              <span className="link-blue font-12 font-semibold link-blue cursor-pointer">
                Download Template
              </span>
            </span>
          </div>
          <p className="form-label font-14 font-regular color-black  mb-4">
            Download the template
          </p>
          <p className="font-14 font-bold color-black mb-4">Step 2</p>
          <p className="form-label font-14 font-regular color-black  mb-4">
            Enter valid details on the template
          </p>
          <p className="font-14 font-bold color-black mb-3">Step 3</p>
          <div className="mb-3 d-md-flex align-items-center">
            <span className="upload-btn-wrapper me-2 d-block ">
              <button
                type="button"
                className="font-14 link-blue font-medium bg-white border-0 shadow-none cursor-pointer"
              >
                <img
                  src="images/upload.svg"
                  alt="browse"
                  className="me-2"
                />
                Upload Template
              </button>
              <input
                type="file"
                name="myfile"
                ref={attachment}
                className="cursor-pointer"
                onChange={(e) => {
                  readFile(e);
                }}
              />
            </span>
          </div>
          <div>
            <span className="font-14 font-medium color-grey mt-1  d-block ">
              {fileName}
              <span className="cursor-pointer ms-3" title="Cancel">
                {fileName ? (
                  <img
                    src="images/upload-close.svg"
                    alt="upload-close"
                    onClick={() => {
                      removeFile();
                    }}
                  />
                ) : (
                  <></>
                )}
              </span>
            </span>
            <p className="mt-2 mb-0 color-red font-14 font-regular">
              {validationMessage}
            </p>
          </div>
          <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
            <button
              type="button"
              className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
              disabled={!fileName ? true : false}
              onClick={() => {
                validateConnectorInput(file);
              }}
            >
              Save
            </button>
            <a
              className="theme-link-btn cursor-pointer  font-semibold text-center order-md-first"
              onClick={() => {
                setConnectorFormData({});
                resetValidationMessages(activeConnector);
                setShowConnectorForm(true);
              }}
            >
              Cancel
            </a>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div>
      {activeConnector === "Azure" ? renderAzureConnector() : renderOnPremiseConnector()}
      <p className="mt-2 mb-0 color-red font-14 font-regular">{connectorApiValidationMsg}</p>
    </div>
  );
}