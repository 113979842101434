import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  Tooltip,
  ResponsiveContainer
} from "recharts";


const MapHorizondialBarChart: React.FC<any> = (props: any) => {
   
  const data = props?.barChartData?.map((element: any) => {
    return ({
      name: element.serverName.length > 4 ? element.serverName.slice(0, 3) + "..." : element.serverName,
      value: element.utilization,
    })
  })


  return (
    <ResponsiveContainer width="100%" height={370}>
      <BarChart width={500} height={300} data={data} layout="vertical">
        <XAxis type="number" dataKey="value" tickLine={false} axisLine={{ stroke: '#bbb', strokeWidth: 0.5 }} label={{ value: props.xAxisLabel, position: 'insideBottom', offset: -2, style: { fontSize: 14, fontWeight: 'bold', fill: '#222', fontFamily: "SFProText-Medium" }, }} style={{ fontSize: 11, fontWeight: 'bold', fill: '#777', fontFamily: "SFProText-Medium" }} />
        <YAxis type="category" dataKey="name" tickLine={false} axisLine={{ stroke: '#bbb', strokeWidth: 0.5 }} label={{ value: 'Server Name', position: 'outsideTop', dx: -29, style: { fontSize: 14, fontWeight: 'bold', fill: '#222', fontFamily: "SFProText-Medium" }, angle: -90 }} style={{ fontSize: 10, fontWeight: 'bold', fill: '#777', fontFamily: "SFProText-Medium" }} />
        <Tooltip cursor={{ fill: 'transparent' }} />
        <Bar dataKey="value" fill="#8caaff" barSize={30} >
          <LabelList dataKey="value" position="right" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MapHorizondialBarChart;
