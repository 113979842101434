import { useState, useRef } from "react"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getRegeneratedSummary } from "../../service/ReportApi";
const TextEditor = (props: any) => {
    const { onSave, onCancel } = props;
    const regex = /<[^>]*>|"/g;
    const [value, setValue] = useState<string>(props.value)
    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const [isInitialized, setIsInitialized] = useState(false);
    const [showToasterPopup, setShowToasterPopup] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showToasterFailedPopup, setShowToasterFailedPopup] = useState(false);
    const [showToasterLoading, setShowToasterLoading] = useState(false);
    const [hideToast, setHideToast] = useState(true);
    const [toastProp, setToastProp] = useState({
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: '',
    });

    const quillRef = useRef<ReactQuill>(null);
    // Define your toolbar options
    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button
    ];

    // Define your modules and formats
    const modules = {
        toolbar: toolbarOptions,
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
        'color', 'background', 'align',
        'clean',
        'code-block',
    ];

    function escapeJsonString(str: string): string {
        return str.replace(/\\/g, '\\\\')
            .replace(/"/g, '\\"')
            .replace(/\n/g, '\\n')
            .replace(/\r/g, '\\r')
            .replace(/\t/g, '\\t')
            .replace(/\f/g, '\\f');
    }

    const regenerateSummary = async () => {
        setShowToasterLoading(true);
        try {
            const sanitizedValue = escapeJsonString(value.replace(regex, ""));
            const response = await getRegeneratedSummary({ summary: sanitizedValue })
            if (response.status === 200) {
                setShowToasterPopup(true);
                setIsLoading(true);
                setValue(response.data.RegeneratesummaryLLM)
                setIsDisabled(false)
            } else {
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }
        }
        catch (error) {
            setShowToasterFailedPopup(true);
            setIsLoading(false);
        }
        finally {
            setIsLoading(false)
            setShowToasterLoading(false);
            setTimeout(() => {
                setShowToasterPopup(false);
                setShowToasterFailedPopup(false);
            }, 4000);
        }
    }

    const handleDescriptionOnChange = (html: string) => {
        const htmlString = html;
        if (quillRef.current) {
            const editor = quillRef.current.getEditor();
            const selection = editor.getSelection();
        }

        const textContent = htmlString.replace(regex, "");
        if (textContent.length !== 0) {
            setIsDisabled(false)
            setValue(html);
            if (!isInitialized) {
                setIsInitialized(true);
                return;
            }

        }
        else if (textContent.length === 0) {
            setValue(html);
            setIsDisabled(true)
        }

    };

    return (
        <>
            <ReactQuill
                theme="snow"
                ref={quillRef}
                value={value}
                modules={modules}
                formats={formats}
                onChange={handleDescriptionOnChange}
                placeholder="Enter here"
            />

            <div className="d-flex justify-content-between">
                <div className="mt-4">
                    <button type="button" className="btn btn-dark theme-outline-btn regenerate-btn d-flex gap-2 align-items-center font-14 font-semibold" disabled={value.length === 0} onClick={() => { regenerateSummary() }}><img src="images/regenerate-icon.svg" alt="Regenerate-icon" />Regenerate</button>
                </div>

                <div className="d-flex justify-content-end mt-4 mb-5 gap-3">
                    <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer" data-bs-dismiss="modal" onClick={onCancel}>Cancel</a>
                    <button type="button" className="btn btn-dark theme-primary-btn-md border-0 font-semibold " disabled={value.replace(regex, "").length === 0}
                        onClick={() => {
                            onSave(value)
                        }}>Save</button>
                </div>
            </div>
            {showToasterLoading && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img
                                    src="images/loading.gif"
                                    alt="toast-success"
                                    style={{ height: "15px", width: "15px" }}
                                />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Regenerating...
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>

    )
}


export default TextEditor;
