import React, { useEffect, useState } from "react"
import { strategySortOrderInferface, planSortOrderInferface, migrationStrategyDataInterface, migrationPlanDataInterface, phaseData, weekDataState } from "../interface/infraMapModel"
import MapHorizondialBarChart from "./reportComponents/MapHorizondialBarChart"
import MapLineChart from "./reportComponents/MapLineChart"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import PopupComponent from "./reportComponents/MapApplicationStrategyGridPopup"
import BreadCrumbs from "./breadCrumb"

const InfrastructureMapReport = (props: any) => {


  //  PC_MapInfraReportComp_02, PC_MapInfraReportComp_03, PC_MapInfraReportComp_04PC_MapInfraReportComp_05, PC_MapInfraReportComp_06, PC_MapInfraReportComp_07, PC_MapInfraReportComp_08, 
  //   PC_MapInfraReportComp_09, PC_MapInfraReportComp_10, PC_MapInfraReportComp_11, PC_MapInfraReportComp_12, PC_MapInfraReportComp_13, PC_MapInfraReportComp_14 - Initialize all the state variables
  const [reportData, setReportData] = useState<any>(props.reportData)
  const [migrationStrategyData, setMigrationStrategyData] = useState<any>([...props.reportData.migrationStrategy.applicationsStrategy])
  const [migrationPlanData, setMigrationPlanData] = useState<any>(JSON.parse(JSON.stringify(props.reportData.migrationPlan)))
  const [applicationDetails, setApplicationDetails] = useState<any>(props.reportData.discovery[0])
  const [strategySortOrder, setStrategySortOrder] = useState<strategySortOrderInferface>({ name: false, summary: false })
  const [planSortOrder, setPlanSortOrder] = useState<planSortOrderInferface>({ phase: false, process: false })
  const [selectedNavBar, setSelectedNavBar] = useState<string>(props.reportData.menus[0].menuName)
  const [weeks, setWeeks] = useState<string[]>([]);
  const [minDateAndRoundValue, setMinDateAndRoundValue] = useState<weekDataState>({ minDate: new Date(), weekNumber: 1 });
  const [tableExpand, setTableExpand] = useState<boolean>(false)
  const navigate = useNavigate()


  const breadCrumbs =
    [
      { title: "Assessments", path: "/homepage", isActive: true },
      { title: props.reportData.organizationName, path: "", isActive: true },
      { title: props.reportData.verticalName, path: "", isActive: false },
    ]


  useEffect(() => {
    const weekData = updateWeeks();
    setMinDateAndRoundValue(weekData);
  }, [migrationPlanData]);
  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      e.preventDefault(); // Prevent the page from reloading
      e.returnValue =
        "You may have unsaved changes. Are you sure you want to reload or leave?"; // Some browsers require a return value
    };

    window.onbeforeunload = function () {
      return "You may have unsaved changes. Are you sure you want to reload or leave?";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
  }, []);

  //PC_MapInfraReportComp_59 - Function to change the application details
  const onClickApplicationDropDown = (event: any) => {
    setApplicationDetails(reportData.discovery.find(((element: any) => element.applicationName === event.target.value)))
  }
  //PC_MapInfraReportComp_43 - Function to sort the migration strategy table
  const sortMigrationStrategy = (columnName: string) => {
    let sortedMigrationStrategy: migrationStrategyDataInterface[] = [];
    //PC_MapInfraReportComp_44 - Sort the application name
    if (columnName === 'name') {
      const applicationNames = migrationStrategyData?.map((element: any) => element.applicationName).sort();
      if (strategySortOrder.name) {
        applicationNames.reverse();
      }
      applicationNames.forEach((element: any) => {
        const foundItem = migrationStrategyData?.find((migrationStrategy: any) => {

          return migrationStrategy.applicationName === element
        });

        sortedMigrationStrategy.push(foundItem);

      });
      setStrategySortOrder({ ...strategySortOrder, name: !strategySortOrder.name });
    }
    //PC_MapInfraReportComp_45 - Sort the application summary
    else {
      let applicationSummaries: string[] = []
      migrationStrategyData.forEach((element: any) => {
        if (!applicationSummaries.includes(element.applicationStrategySummary))
          applicationSummaries.push(element.applicationStrategySummary)
      }
      )
      applicationSummaries.sort()
      if (strategySortOrder.summary) {
        applicationSummaries.reverse();
      }


      applicationSummaries.forEach((element: string) => {
        const foundItem = migrationStrategyData.filter((migrationStrategy: any, index: number) => migrationStrategy.applicationStrategySummary === element);
        if (foundItem) {
          sortedMigrationStrategy.push(...foundItem);
        }
      });
      setStrategySortOrder({ ...strategySortOrder, summary: !strategySortOrder.summary });
    }
    setMigrationStrategyData(sortedMigrationStrategy);
  }
  //PC_MapInfraReportComp_50 - Function to sort  migration plan processes
  const sortMigrationPlanProcess = (processDetails: any[], columnName: string): any[] => {
    let sortedProcessDetails = processDetails
      .map((element: any) => element.processName)
      .sort();

    if (planSortOrder.process) {
      sortedProcessDetails.reverse();
    }

    return sortedProcessDetails.map(processName => {
      return processDetails.find(processDetail => processDetail.processName === processName); // Assuming there will be a match
    });
  };
  //PC_MapInfraReportComp_48 - Function to sort migration plan
  const sortMigrationPlan = (columnName: string) => {
    let sortedMigrationPlan: migrationPlanDataInterface[];
    //PC_MapInfraReportComp_49 - Sort the migration plan phase
    if (columnName === 'phase') {
      const phaseNames = migrationPlanData
        .map((element: any) => element.phaseName)
        .sort();

      if (planSortOrder.phase) {
        phaseNames.reverse();
      }

      sortedMigrationPlan = phaseNames.map((phaseName: string) => {
        return migrationPlanData.find((migrationPlan: any) => migrationPlan.phaseName === phaseName)!; // Assuming there will be a match
      });
      setPlanSortOrder({ ...planSortOrder, phase: !planSortOrder.phase });

    }
    //PC_MapInfraReportComp_50 - Sort thr migration plan process
    else {
      sortedMigrationPlan = JSON.parse(JSON.stringify(migrationPlanData));
      sortedMigrationPlan.forEach((element, index) => {
        sortedMigrationPlan[index].processTimeLine = sortMigrationPlanProcess(element.processTimeLine, columnName);
      });
      setPlanSortOrder({ ...planSortOrder, process: !planSortOrder.process });
    }

    setMigrationPlanData(sortedMigrationPlan);
  };
  //PC_MapInfraReportComp_40 - Function to handle the sort event
  const onClickSort = (columnName: string) => {
    if (columnName === 'name' || columnName === 'summary') {
      sortMigrationStrategy(columnName);
    } else {
      sortMigrationPlan(columnName);
    }
  };





  const extractDatesFromProcesses = (data: phaseData[]) => {
    let datesArray: string[] = [];

    data?.forEach((phase) => {
      phase.processTimeLine.forEach((process) => {
        let startDate = moment(process.startWeek);
        let endDate = moment(process.endWeek);
        datesArray.push(startDate.format("YYYY-MM-DD"));
        datesArray.push(endDate.format("YYYY-MM-DD"));
      });
    });
    return datesArray;
  };

  const updateWeeks = () => {
    const allDates = extractDatesFromProcesses(migrationPlanData);

    const uniqueDates = Array.from(new Set(allDates));
    const dateObjects: Date[] = uniqueDates.map((date) => new Date(date));

    let minDate = new Date(dateObjects[0]);
    let maxDate = new Date(dateObjects[0]);

    dateObjects.forEach((date) => {
      if (date < minDate) minDate = new Date(date);
      if (date > maxDate) maxDate = new Date(date);
    });

    let weeks: string[] = [];
    let currentDate: Date = new Date(minDate);
    const millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7; // Milliseconds in a week
    const weeksBetween = Math.ceil((maxDate.getTime() - minDate.getTime()) / millisecondsPerWeek);
    let roundedIncrement;

    if (weeksBetween % 4 != 0) {
      roundedIncrement = Math.ceil(weeksBetween / 4)
    } else {
      roundedIncrement = Math.round(weeksBetween / 4);
    }

    let weekNumber: number
    if (roundedIncrement == 0) {
      roundedIncrement = 1
      weekNumber = roundedIncrement
    } else {
      weekNumber = roundedIncrement
    }

    for (let i = 1; i <= 4; i++) {
      weeks.push(`week ${weekNumber}`);
      currentDate.setDate(currentDate.getDate() + 7);
      weekNumber += roundedIncrement;
    }

    setWeeks(weeks);
    return { "minDate": minDate, "weekNumber": roundedIncrement };
  };


  const differenceInDays = (dateA: Date, dateB: Date): number => {
    const timeDifference = dateA.getTime() - dateB.getTime();
    return Math.abs(Math.round(timeDifference / (1000 * 3600 * 24)));
  };

  const getWeekIndex = (date: Date, minDate: Date): number => {
    const diffDays = differenceInDays(date, minDate);
    const diffWeeks = Math.ceil(diffDays / 6);
    return diffWeeks > 0 ? diffWeeks : 1;
  };

  // PS_MigrationPlan_70 -  PS_MigrationPlan_76
  const getWeekRange = (startDate: Date, endDate: Date, process: any, minDate: Date, weekNumber: number): any => {

    const startWeekIndex = getWeekIndex(startDate, minDate);
    const endWeekIndex = getWeekIndex(endDate, minDate);

    const { marginLeft, marginRight } = calculateMargins(startWeekIndex, endWeekIndex, weekNumber);
    if (startWeekIndex !== -1 && endWeekIndex !== -1) {
      if (startWeekIndex === endWeekIndex) {
        return { "weekRange": `Week ${startWeekIndex}`, "marginRight": marginRight, "marginLeft": marginLeft };
      } else {
        return { "weekRange": `Week ${startWeekIndex} - Week ${endWeekIndex}`, "marginRight": marginRight, "marginLeft": marginLeft };
      }
    } else {
      return {};
    }
  };

  function calculateMargins(startIndex: any, endIndex: any, weekNumber: number) {

    startIndex = Math.round(startIndex < weekNumber ? startIndex : startIndex / weekNumber)
    endIndex = Math.round(endIndex < weekNumber ? endIndex : endIndex / weekNumber)
    const totalParts = 4;
    const marginLeft = (startIndex - 1) * (100 / totalParts);
    const marginRight = (totalParts - endIndex) * (100 / totalParts);

    return {
      marginLeft: `${marginLeft}%`,
      marginRight: `${marginRight}%`
    };

  }


  const handleBindingWeekRange = (process: any, index: any) => {

    const startDate = new Date(process.startWeek);
    const endDate = new Date(process.endWeek);

    const weekData = getWeekRange(startDate, endDate, process, minDateAndRoundValue.minDate, minDateAndRoundValue.weekNumber);

    return (
      <td key={`week-range-${index}`} colSpan={4} className="my-2 px-0 py-2 position-relative" >
        <span className="d-block text-nowrap text-start font-13 cal-blue-label cal-label-sty" style={{ marginLeft: weekData.marginLeft, marginRight: weekData.marginRight }} >
          {weekData.weekRange}
        </span>
      </td>
    );
  }

  const formateDate = (updatedDate: string) => {
    const updatedDate1 = moment(updatedDate.split("T").at(0))
    return (updatedDate1.format("DD-MM-YYYY"))
  }

  //PC_MapInfraReportComp_60 - Function to bind the map menus
  const bindMapMenus = () => {
    return (reportData.menus.map((element: any) => {
      return (<button className={`nav-link text-start rounded-0 font-medium font-14 ${selectedNavBar === element.menuName ? "active" : ""}`} data-bs-toggle="pill" id={element.menuName} type="button" role="tab" aria-selected="true" onClick={(event: any) => { setSelectedNavBar(event.target.id) }}>{element.menuName === "Cloud Adoption Framework" ? "CAF" : element.menuName}</button>)
    }))
  }

  //PC_MapInfraReportComp_61 - Funciton to bind the application names
  const bindApplicationDropdown = () => {
    return reportData?.discovery.map((element: any) =>
      <option key={element.applicationId} value={element.applicationName}  >
        {element.applicationName}
      </option>)
  }

  //PC_MapInfraReportComp_62 - Function to bind the application details
  const bindApplicationDetails = () => {
    return (
      migrationStrategyData?.map((element: any, index: number) => {
        if (index < 4)
          return (
            <tr className="border-bottom" key={element.applicationId}>
              <td>{element.applicationName}</td>
              <td><a href="#" className="link-blue text-decoration-none">{element.applicationStrategy}</a>
              </td>
              <td className="text-wrap">
                {element.applicationStrategySummary}
              </td>
            </tr>
          )
      }
      )
    )
  }

  const ExecutiveSummary = () => {
    return (
      <div className="tab-pane fade show active" id="ExecutiveSummarytab" role="tabpanel">
        <p className="font-18 font-semibold mt-3 mb-4 color-black">Executive Summary</p>
        <p className="font-18 font-semibold my-3 color-black">Directive:</p>
        <p className="font-regular font-16 color-black mt-3 mb-4">
          Zeb, as the designated migration specialist for {reportData.organizationName},
          will spearhead the transition from on-premises infrastructure to
          Amazon Web Services (AWS), aligning closely with {reportData.organizationName}  strategic vision and operational objectives. With a meticulous focus
          on planning, assessment, and execution, Zeb will ensure a seamless
          migration process, minimizing disruption to business operations
          while maximizing the benefits of cloud computing. The expertise will guide
          {reportData.organizationName} through every stage of the journey, from initial evaluation to
          post-migration support, fostering a culture of innovation and efficiency within the organization.
        </p>
        <p className="font-18 font-semibold my-3 color-black">Outcome:</p>
        <p className="font-regular font-16 color-black mt-3 mb-4">
          Following the successful migration from on-premises infrastructure to Amazon Web Services (AWS), {reportData.organizationName} experiences a range of transformative outcomes. Firstly, there's a notable improvement
          in agility and scalability, enabling our IT resources to adapt swiftly to
          changing business demands without the constraints of physical hardware limitations. This newfound flexibility
          translates into accelerated innovation cycles, allowing our teams to experiment and deploy new solutions more rapidly.
          Additionally, the migration to AWS results in cost optimization, with the ability to scale resources up or down based on actual usage,
          elimiating the need for over-provisioning and reducing operational expenses
        </p>
        <p className="font-18 font-semibold my-3 color-black">Executive Summary:</p>
        <p className="font-regular font-16 color-black mt-3 mb-4">
          {reportData.executiveSummary}
        </p>
      </div>
    )
  }

  const Inventory = () => {
    return (<div className="tab-pane fade show active" id="Inventorytab" role="tabpanel"  >
      <h5 className="font-18 font-semibold color-black mb-5">Inventory</h5>
      <div className="col-md-12 ">
        <div className="row">
          <div className="col-xl-5 col-lg-12 col-md-6 col-sm-12">
            <div className="p-4 h-100">
              {/* <p class="font-semibold font-18 font-semibold mb-4">SQL Servers - Enterprise, Standard, Web</p> */}
              <div className="row g-5">
                <div className="col-sm-12 col-md-12 col-lg-10 ">
                  <h4 className="font-18 font-semibold color-black my-3">INVENTORY :</h4>
                  <div className="d-flex justify-content-between mb-4">
                    <span className="d-flex align-items-center">
                      <label className="font-15 color-black-v2 font-medium ">Total instances count</label>
                    </span>
                    <label className="font-32 font-semibold color-black">{reportData.inventory.instancesCount}</label>
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <span className="d-flex align-items-center">
                      <label className="font-15 color-black-v2 font-medium">Windows server count</label>
                    </span>
                    <label className="font-32 font-semibold color-black">{reportData.inventory.windowsServerCount}</label>
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <span className="d-flex align-items-center">
                      <label className="font-15 color-black-v2 font-medium">Linux server count</label>
                    </span>
                    <label className="font-32 font-semibold color-black">{reportData.inventory.linuxServerCount}</label>
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <span className="d-flex align-items-center">
                      <label className="font-15 color-black-v2 font-medium">Windows Desktops count</label>
                    </span>
                    <label className="font-32 font-semibold color-black">{reportData.inventory.windowsDestopsCount}</label>
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <span className="d-flex align-items-center">
                      <label className="font-15 color-black-v2 font-medium">Server not patched</label>
                    </span>
                    <label className="font-32 font-semibold color-black">{reportData.inventory.serverNotPatched}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-xl-7 col-md-12 col-12 mb-4">
            <div className="p-4 h-100 border rounded-3">
              <p className="font-semibold font-18 color-black mb-4">Percent Memory Used</p>
              <div className=" w-100 d-flex justify-content-center align-items-center">
                {/* Vertical bar chart starts here */}
                <MapHorizondialBarChart barChartData={reportData.inventory.maxMemoryUsed} xAxisLabel="Utilization Percentage" />
                {/* Vertical bar chart ends here */}
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-md-12 col-12 mb-4">
            <div className="p-4 h-100 border rounded-3">
              <p className="font-semibold font-18 color-black mb-4">CPU Load</p>
              <div className=" w-100 d-flex justify-content-center align-items-center">
                {/* Vertical bar chart starts here */}
                <MapHorizondialBarChart barChartData={reportData.inventory.maxCpuLoad} xAxisLabel="Load Percentage" />
                {/* Vertical bar chart ends here */}
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-md-12 col-12 mb-4">
            <div className="p-4 h-100 border rounded-3">
              <p className="font-semibold font-18 color-black mb-4">Disk Size</p>
              <div className=" w-100 d-flex justify-content-center align-items-center">
                {/* Vertical bar chart starts here */}
                <MapHorizondialBarChart barChartData={reportData.inventory.maxDiskSize} xAxisLabel="Disk Utilization" />
                {/* Vertical bar chart ends here */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>)
  }

  const Discovery = () => {
    return (<div className="tab-pane fade show active" id="PortfolioDiscoverytab" role="tabpanel" >
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          <h5 className="font-18 font-semibold color-black mt-3 pb-2">Discovery</h5>
          <h5 className="font-18 font-semibold color-black mt-4">APPLICATION SUMMARY:</h5>
          <p className="font-16 font-regular color-black my-3">The {applicationDetails?.applicationName} by {reportData.organizationName} {applicationDetails?.applicationSummary}</p>
          <h5 className="font-18 font-semibold color-black mt-4">Template:</h5>
          <p className="font-16 font-regular color-black my-3">
            The {applicationDetails?.applicationName} by {reportData.organizationName} is a dynamic solution crafted
            to streamline task organization, collaboration, and tracking for businesses of all sizes.
            It offers a centralized platform where teams can efficiently manage their tasks, deadlines,
            and priorities, fostering productivity and accountability. The Task Management Web
            Application is hosted on their on-premises server infrastructure.
          </p>
          <h5 className="font-18 font-semibold color-black mt-4">APPLICATION ARCHITECTURE:</h5>
          {applicationDetails?.applicationArchitUrl ?
            /* no image attached div starts here  */
            < div className="text-center py-5">
              <img src={applicationDetails?.applicationArchitUrl + `?${reportData.sasToken}`} alt="image-notuploded" />
            </div> :
            /* no image attached div ends here  */
            <div className="text-center py-5">
              <img src="images/image-notuploded.svg" alt="image-notuploded" />
              <p className="my-3 font-16 font-medium color-grey">Application Architecture has not been uploaded</p>
            </div>
          }
        </div>
      </div>
    </div>)
  }

  const Caf = () => {
    return (<div className="tab-pane fade show active" id="CAFtab" role="tabpanel" >
      <h3 className="font-24 color-black font-semibold">Cloud Adoption Framework :</h3>
      <div className="col-lg-12 col-xl-12 col-md-12 col-12 my-4">
        <div className="p-4 border rounded-3">
          <p className="font-semibold font-18 font-semibold mb-4">Score Card</p>
          <div className=" w-100 d-flex justify-content-center align-items-center">
            {/* Line chart starts here */}
            <MapLineChart lineChartData={reportData.caf} />
            {/* Line chart ends here */}
          </div>
        </div>
      </div>
    </div>)
  }

  const MigrationStrategy = () => {
    return (
      <div className="tab-pane fade show active" id="MigrationStrategystab" role="tabpanel" >
        <h5 className="font-18 font-semibold color-black">Migration Strategy :</h5>
        <div className="col-lg-12 col-xl-12 col-md-12 col-12 my-4">
          <div className="p-4 border rounded-3">
            <p className="font-semibold font-18 font-semibold d-flex justify-content-between align-items-center">
              Migration Strategy Table<img src="images/expand-icon.svg" alt="expand-icon" className="cursor-pointer" onClick={() => { setTableExpand(!tableExpand) }} data-bs-toggle="modal" data-bs-target="#recommendationsExapnMigrationStrategy" /></p>
            <div className=" w-100">
              <div className="table-responsive">
                <table className="table table-borderless themeTable mb-0">
                  <thead className="font-semibold sticky-top">
                    <tr>
                      <th>Application

                        <span className="ms-2 cursor-pointer" onClick={() => onClickSort("name")}>
                          {strategySortOrder.name ? " ↑" : " ↓"}
                        </span>

                      </th>
                      <th>7R</th>
                      <th>Summary
                        <span className="ms-2 cursor-pointer" onClick={() => onClickSort("summary")}>
                          {strategySortOrder.summary ? " ↑" : " ↓"}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font-medium">
                    {bindApplicationDetails()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <h3 className="font-24 font-semibold color-black mt-5">Recommended Architecture :</h3>
        {/* Recommended Architecture starts here */}
        <div>
          {reportData?.migrationStrategy?.recommendedArchitBlodUrl ? <img src={reportData?.migrationStrategy?.recommendedArchitBlodUrl + `?${reportData.sasToken}`} alt="sample-architecture-img" className="w-100 architect-hig" /> : <></>}
        </div>
        {/* Recommended Architecture ends here */}
        <div className="d-flex justify-content-between mt-3 mb-5">
          <label className="font-15 font-semibold color-black">ID:
            <a href={reportData?.migrationStrategy?.cloudGenId} className="link-blue">{`${reportData?.migrationStrategy?.cloudGenId?.split("=").at(1)}`}</a>
          </label>
          <label className="font-15 font-semibold color-black">Last Updated on:
            <span className="font-regular" style={{ marginLeft: "0.5px" }}>{formateDate(reportData?.migrationStrategy?.recommendedArchitUpdatedDate)}</span>
          </label>
        </div>
      </div>)
  }

  const MigrationPlan = () => {
    return (
      <div className="col-md-12">
      <p className="font-18 font-semibold color-black mt-3 mb-4">Migration Plan and Timeline :</p>
      <div className="table-responsive calender-scrl d-none d-md-block my-3">

        <table className="table table-borderless themeTable mig-strategy mb-0">
          <thead className="font-semibold ">
            <tr>
              <th className="border-bottom ps-4 table-primaryrs-th">
                Phase
                <span className="ms-2 cursor-pointer" onClick={() => { sortMigrationPlan("phase") }}>{planSortOrder.phase ? " ↑" : " ↓"}</span>
              </th>
              <th className="border-bottom ps-4 table-riskseverity-th">
                Process
                <span className="ms-2 cursor-pointer" onClick={() => { sortMigrationPlan("process") }}>{planSortOrder.process ? " ↑" : " ↓"}</span>
              </th>
              {weeks.map((week, index) => (
                <th key={index} className="border-bottom ps-4">{week}</th>
              ))}
            </tr>
          </thead>
          <tbody className="font-medium">
            {migrationPlanData.map((phaseData: any, phaseIndex: number) => (
              phaseData.processTimeLine.map((process: any, processIndex: number) => (
                <tr key={`${phaseIndex}-${processIndex}`}>
                  {processIndex === 0 && (
                    <td rowSpan={phaseData.processTimeLine.length} className="ps-4 text-wrap table-primaryrs-td tbl-brd-right">
                      {phaseData.phaseName}
                    </td>
                  )}

                  <td className="text-wrap table-riskseverity-td tbl-brd-right">
                    <div className="d-flex align-items-center justify-content-between">
                      {process.processName}

                    </div>
                  </td>

                  {/* Assuming handleBindingWeekRange is a function that returns a valid JSX component or HTML element */}
                  {handleBindingWeekRange(process, processIndex)}
                </tr>
              ))
            ))
            }
          </tbody>
        </table>
      </div>
      </div>)
  }

  return (
    <div>
      {/* top header starts here */}

      {/* top header ends here */}
      <div className="container-fluid" style={{ marginTop: "105px" }}>
        <div className="row justify-content-center">
          <div className="px-4">
            {/* breadcrumb starts here */}
            <BreadCrumbs data={breadCrumbs}></BreadCrumbs>
            {/* breadcrumb ends here */}
            {/* heading content starts here */}
            <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-5">
              <div className="d-flex align-items-center mb-3 mb-md-0">
                <a href="#" onClick={() => navigate("/homepage")}><img src="images/backarrow.svg" alt="back-arrow" title="Back" /></a>
                <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                  Assessment Summary
                </h2>
              </div>
              <div className="d-grid gap-3 d-md-inline-flex justify-content-md-end">
                {selectedNavBar === reportData.menus[2].menuName && (
                  <div className="btn-group" role="group">
                    <div className="btn-group w-100" role="group">
                      <select className="theme-form form-select font-regular font-14"
                        onChange={onClickApplicationDropDown}
                      // value={selectedApplicationId}
                      >
                        {bindApplicationDropdown()}
                      </select>
                    </div>
                  </div>
                )}
                {/* <span class="rounded-2 d-flex align-items-center justify-content-center  border-0 font-14 font-semibold severity-high-bg px-4"><span class="action-needed me-2"></span> Action Needed</span> */}
                <button type="button" className="btn btn-dark theme-primary-btn border-0 font-14 px-4 font-semibold " onClick={() => navigate("/previewpage", { state: { data: reportData } })}>
                  <span><img src="images/download-white.svg" alt="download-white" className="me-2" /></span>
                  Download Report
                </button>
              </div>
            </div>
            {/* heading content ends here */}
            <div className="row">
              <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                <div className="row">
                  <div className="d-lg-flex d-none pe-0 align-items-start v-tab-border left-nav-brd">
                    <div className="nav flex-column nav-pills custom-v-pills w-100" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                      <p className="font-16 font-semibold my-lg-4 my-2 ps-0 ps-lg-3">MAP</p>
                      {/* Map Menus Nav Bar starts here */}
                      {bindMapMenus()}
                      {/* Map Menus Nav Bar starts here */}
                    </div>
                  </div>
                  <select className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3">
                    <option selected>Executive Summary</option>
                    <option>Inventory & Summary</option>
                    <option>Portfolio Discovery</option>
                    <option>CAF</option>
                    <option>Migration Strategy - 7R’s</option>
                    <option>Migration Plan</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                <div className="row">
                  <div className="tab-content px-4" id="v-pills-tabContent">
                    {selectedNavBar === props.reportData.menus[0].menuName ? <ExecutiveSummary></ExecutiveSummary> : <></>}
                    {selectedNavBar === props.reportData.menus[1].menuName ? <Inventory /> : <></>}
                    {selectedNavBar === props.reportData.menus[2].menuName ? <Discovery /> : <></>}
                    {selectedNavBar === props.reportData.menus[3].menuName ? <Caf /> : <></>}
                    {selectedNavBar === props.reportData.menus[4].menuName ? <MigrationStrategy /> : <></>}
                    {selectedNavBar === props.reportData.menus[5].menuName ? <MigrationPlan /> : <></>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* alert popup starts here */}
      <div className="modal fade" id="alert" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-3">
            <div className="modal-header pb-0 border-0">
              <h5 className="font-semibold font-18 font-semibold d-flex justify-content-between align-items-center">
                Alert</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <div>
                <p className="font-medium font-14">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ratione quae consequuntur aperiam tempora est. Harum!</p>
                <div className="col-md-12 gap-2 d-grid d-md-flex justify-content-center mt-4 ">
                  <button data-bs-toggle="modal" data-bs-target="#progress" data-bs-dismiss="modal" type="button" className="btn btn-secondary  theme-secondary-btn-sm border-0 font-semibold px-4">Review What's Left</button>
                  <button data-bs-toggle="modal" data-bs-target="#progress" data-bs-dismiss="modal" type="button" className="btn btn-dark theme-primary-btn-sm border-0 font-semibold px-4">Proceed Anyway</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* alert popup ends here */}
      <PopupComponent data={reportData?.migrationStrategy?.applicationsStrategy} />
    </div >
  )
}

export default InfrastructureMapReport