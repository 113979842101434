/**PC_LN_1.0 , PC_LN_1.1, PC_LN_1.2, PC_LN_1.3
 * Import statements
 */
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  allIDModel,
  connectorsModel,
  controlAreaSubMenuModel,
  controlAreasModel,
  leftNavigationModel,
  progressIDModel,
  projectModel,
  questionModel,
  questionnaireModel,
  salesForceAPICloudModel,
  sfCloudModel,
  userAnsweredModel,
  userAttendedModel,
} from "../interface/questionnaireInterface";
import {
  getLeftNavigation,
  getControlAreas,
  getControlAreaSubMenuQuestions,
  getProjects,
  deleteProject,
  postQuestionnaire,
  addProjects,
  postQuestionnaireReport,
  postReassessProject,
  getGenerateReportButtonFlag,
  getSalesforceCloud,
  postSalesforceCloud,
  getNotReviewedItems,
  postChanllengesAndRecommendations,
  PostAutoQuestionnarie,
} from "../service/QuestionnaireApi";
import Connectors from "./Connectors";
import Questionarie from "./Questionnaire";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "./Loader";
import BreadCrumbs from "./breadCrumb";
import { isAuth } from "./Login/Auth";
import { useMsal } from "@azure/msal-react";
import ConnectorSelectDropdown from "./MultiSelectDropdown";
import ProgressToaster from "./connectorComponents/ProgressToaster";
import { fetchProgress } from "../service/AssesmentApi";
import { controlAreaModel } from "../interface/AssessmentGridModel";
import { progressModel } from "../interface/questionnaireInterface";

/*****
 * PC_LN_1.4
 * Create the exportable Main Component which renders the html
 */
export default function AssessmentMain() {
  const navigate = useNavigate();
  // PC_LN_1.6
  const { state } = useLocation();

  let allIdObject = {
    organizationId: state?.organizationId,
    verticalId: state?.verticalId,
    projectId: null,
    organizationName: state?.organizationName,
    verticalName: state?.verticalName,
    mode: state?.mode?.toLowerCase(),
  };
  let progressObject = {
    organizationId: state?.organizationId,
    verticalId: state?.verticalId,
    projectId: state?.projectId,
  }
  /**
   * PC_LN_1.7,
   * Create required State Variables
   */
  let [leftNavigationData, setLeftNavigationData] = useState<
    leftNavigationModel[]
  >([]);
  let [questionnarieData, setQuestionnarieData] = useState<
    questionnaireModel[]
  >([]);
  let [activeLeftNavigation, setActiveLeftNavigation] = useState({
    id: "",
    name: "",
    type: "connector",
  });
  let [activeControlAreaMenu, setActiveControlAreaMenu] = useState({
    id: "",
    name: "",
  });
  let [mainQuestions, setMainQuestions] = useState<questionModel[]>([]);
  let [branchQuestions, setBranchQuestions] = useState<questionModel[]>([]);
  let [isConnectorProvided, setIsConnectorProvided] = useState<boolean>(false);
  let [notAssessedControlAreas, setNotAssessedControlAreas] = useState<
    string[]
  >([]);
  let [notAssessedControlAreaSubMenus, setNotAssessedControlAreaSubMenus] =
    useState<string[]>([]);
  let [projectName, setProjectName] = useState<string>("");
  const [ind, setInd] = useState(-1);
  let [projectDropDownData, setProjectDropDownData] = useState<projectModel[]>(
    []
  );
  const [activeProject, setActiveProject] = useState<projectModel>({
    projectId: "",
    projectName: "",
    progress: "",
  });

  const [deleteProjectId, setDeleteProjectId] = useState<string>("");

  const [IDs, setIDs] = useState<allIDModel>(allIdObject);
  const [progressID, setProgressID] = useState<progressIDModel>(progressObject)
  const [isLoading, setIsLoading] = useState(false);

  const [generateButtonHide, setGenerateButtonHide] = useState(true);
  const [showToasterPopup, setShowToasterPopup] = useState(false);
  const [showToasterLoading, setShowToasterLoading] = useState(false);
  const [validationMsg, setValidationMsg] = useState<string>("");

  const [showToaster, setShowToaster] = useState(true);
  const [controlAreas, setControlAreas] = useState<progressModel[]>([]);
  const [error, setError] = useState(false);

  const handleRetry = () => {
    setError(false);
    setShowToaster(true)
    PostAutoQuestionnarie({
      organizationId: allIdObject.organizationId,
      connectorId: leftNavigationData[0]?.connectors[0].connectorId,
      verticalId: allIdObject.verticalId,
      projectId: allIdObject.projectId,
    }).then(() => {
      // console.log("Auto questionnaire completed successfully");
    }).catch((error) => {
      // console.error("Error in PostAutoQuestionnarie:", error);
      setError(true);
    });
    progressDetails();
  };

  const handleClose = () => {
    setShowToaster(false);
    setError(false);
  };

  const progressDetails = async () => {
    try {
      const response = await fetchProgress({
        organizationId: progressID.organizationId,
        verticalId: progressID.verticalId,
        projectId: progressID.projectId,
      });
      if (response.status === 200) {
        const newControlAreas: progressModel[] = response.data;
        setControlAreas(newControlAreas);
        setError(false);

        const allCompleted = newControlAreas?.every((area) => area.progress === 'Completed');

        const isProgressFailed = newControlAreas?.every((area) => area.progress === 'Failed');

        if (isProgressFailed) {
          setError(true);
          return;
        }

        if (!allCompleted) {
          setTimeout(progressDetails, 30000);
        } else {
          setTimeout(() => setShowToaster(false), 3000);
        }
      }
    } catch (error) {
      // console.error('Error fetching progress:', error);
      setError(true);
    }
  };

  useEffect(() => {
    if (isConnectorProvided && allIdObject.verticalName === "WAFR") {
      setShowToaster(true);
      setControlAreas([]);
      setError(false);
      progressDetails();
    }
  }, [isConnectorProvided, allIdObject.verticalName]);


  const { accounts } = useMsal();

  let [snControlAreaSelectedValues, setSnControlAreaSelectedValues] =
    useState<any>([]);
  let [snSubMenuSelectedValues, setSnSubMenuSelectedValues] = useState<any>([]);

  let [sfCloudValues, setSfCloudValues] = useState<salesForceAPICloudModel>({
    CloudOptions: [],
    SelectedCloud: null,
  });

  let [sfCloudSelectedValues, setSfCloudSelectedValues] = useState<string[]>(
    []
  );

  /** New add on  */
  const [connectedConnectors, setConnectedConnectors] = useState<
    connectorsModel[]
  >([]);
  const [breadCrumbData, setBreadCrumbData] = useState([
    { title: "Organization", path: "/homepage", isActive: true },
    { title: IDs.organizationName, path: "/manageassessment", isActive: true, state: { organizationId: state.organizationId, organizationName: state.organizationName } },
    { title: IDs.verticalName, path: "", isActive: false },
  ]);

  const [hideActionNeeded, setHideActionNeeded] = useState(false);

  const domainHasConnectors = [
    "Mobility",
    "Application Architecture",
    "QA Processes",
    "Infrastructure",
    "Data Strategy",
    "IAM & Security",
    "ServiceNow",
    "WAFR",
    "MDPW"
  ];

  const domainHasProjects = ["Mobility", "Application Architecture"];

  const ServiceNowProcesses = [
    "CSM",
    "HRSD",
    "IRM",
    "ITAM",
    "ITOM",
    "ITSM",
    "Portal",
    "SecOps",
    "SPM",
  ];


  const charRegex = /^(?=.*[A-Za-z])[A-Za-z0-9\s]*[*&]?[A-Za-z0-9\s]*$/;
  // To allow multiple special characters ^[A-Za-z]*[&*?]?[A-Za-z]+[A-Za-z0-9&*?]*$/

  // PC_LN_1.8, Invoke the initial pageload data function from api
  useLayoutEffect(() => {
    setGenerateButtonHide(true);
    loadLeftNavigation();
    setIDs(allIdObject);
  }, [activeProject]);

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      e.preventDefault(); // Prevent the page from reloading
      e.returnValue =
        "You may have unsaved changes. Are you sure you want to reload or leave?"; // Some browsers require a return value
    };

    window.onbeforeunload = function () {
      return "You may have unsaved changes. Are you sure you want to reload or leave?";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    // return () => {
    //   window.removeEventListener("beforeunload", handleBeforeUnload);
    // };
  }, []);

  useEffect(() => {
    if (!isAuth(accounts)) navigate("/");
    if (state === null) navigate("/");
  }, []);

  /**
   * PC_LN_1.10 to PC_LN_1.18
   * Make Api calls to get the left navigation data, questionnaire control areas, salesforce cloud and projects
   */
  const loadLeftNavigation = async () => {
    try {
      // PC_LN_1.11
      setIsLoading(true);
      let navigationApiData = await getLeftNavigation(IDs.verticalId);

      let projectApiData = await getProjects(
        IDs.verticalId,
        IDs.organizationId
      );

      if (IDs.mode !== "reassess" && projectApiData.length) {
        projectApiData = projectApiData.filter((item: any) => {
          return item.progress !== "Completed";
        });
      }

      let questionApiData = await getControlAreas({
        verticalId: IDs.verticalId,
        organizationId: IDs.organizationId,
      });

      questionApiData?.forEach((obj: questionnaireModel) => {
        obj.controlAreaValue = obj.controlAreaValue?.sort((a: any, b: any) =>
          a.controlAreaSubMenuName < b.controlAreaSubMenuName ? -1 : 1
        );
      });

      if (IDs.verticalName === "ServiceNow") {
        navigationApiData[0].controlAreas?.push({
          controlArea: "Process",
          controlAreaId: "",
          controlAreaOrder: 0,
        });
      }

      setLeftNavigationData(navigationApiData);

      /**If vertical is salesforce */
      if (IDs.verticalName === "Salesforce") {
        let sfCloudApiData = await getSalesforceCloud({
          organizationId: IDs.organizationId,
          verticalId: IDs.verticalId,
        });
        sfCloudApiData?.SelectedCloud?.forEach(({ value }: any) => {
          sfCloudSelectedValues.push(value);
        });
        setSfCloudValues(sfCloudApiData);
      }

      if (projectApiData.length) {
        if (activeProject.projectId === "" && IDs?.mode !== "reassess") {
          setActiveProject(projectApiData[0]);
        }
        setProjectDropDownData(projectApiData);

        setIDs({
          ...IDs,
          ["projectId"]: activeProject.projectId || projectApiData[0].projectId,
        });
      }
      // PC_LN_1.12

      setQuestionnarieData(questionApiData);
      // setIsLoading(false);
      // if (IDs?.mode !== "reassess" && activeProject.projectId === "")
      //   await checkGenerateReportButton(projectApiData[0]?.projectId);

      // PC_LN_1.18
      let defaultConnector = {
        id:
          navigationApiData[0]?.connectors !== null
            ? navigationApiData[0]?.connectors?.sort(
              (a: connectorsModel, b: connectorsModel) =>
                a.connector < b.connector ? -1 : 1
            )[0]?.connectorId
            : navigationApiData[0]?.controlAreas?.sort(
              (a: any, b: any) => a.controlAreaOrder - b.controlAreaOrder
            )[0]?.controlAreaId,
        name:
          navigationApiData[0]?.connectors !== null
            ? navigationApiData[0]?.connectors[0]?.connector
            : navigationApiData[0]?.controlAreas?.sort(
              (a: any, b: any) => a.controlAreaOrder - b.controlAreaOrder
            )[0]?.controlArea,
        type:
          navigationApiData[0]?.connectors !== null
            ? "connector"
            : "controlArea",
      };

      if (IDs?.mode !== "reassess" && activeProject.projectId === "") {
        setActiveLeftNavigation(defaultConnector);
        await checkGenerateReportButton(projectApiData[0]?.projectId);
      }

      if (
        IDs?.mode === "reassess" &&
        !domainHasProjects.includes(state?.verticalName)
      ) {
        setActiveLeftNavigation(defaultConnector);
      }
      // Domain without connectors
      if (
        navigationApiData[0]?.connectors === null &&
        questionApiData !== null
      ) {
        const getControlAreaValueData: any = findControlAreaSubMenuData(
          questionApiData,
          navigationApiData[0]?.controlAreas?.sort(
            (a: any, b: any) => a.controlAreaOrder - b.controlAreaOrder
          )[0]?.controlAreaId
        );
        await controlAreaMenuTabsOnClick(
          getControlAreaValueData?.menuValues?.controlAreaSubMenuId,
          getControlAreaValueData?.menuValues?.controlAreaSubMenuName,
          navigationApiData[0]?.controlAreas?.sort(
            (a: any, b: any) => a.controlAreaOrder - b.controlAreaOrder
          )[0]?.controlAreaId,
          questionApiData
        );
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (e) {

    }
  };

  const checkGenerateReportButton = async (projectID?: string) => {
    try {
      await getGenerateReportButtonFlag({
        organizationId: IDs.organizationId,
        verticalId: IDs.verticalId,
        projectId: projectID || activeProject?.projectId || null,
      })
        .then((response) => {
          setGenerateButtonHide(!response?.buttonFlag);
          setConnectedConnectors(response?.connectors);
          if (
            IDs.verticalName === "IAM & Security" &&
            response?.connectors?.length
          ) {
            setActiveLeftNavigation({
              id: response?.connectors[0]?.connectorId,
              name: response?.connectors[0]?.connector,
              type: "connector",
            });
          }
          // else if (IDs.verticalName === "IAM & Security" &&
          //   !response?.connectors?.length) {
          //   setGenerateButtonHide(true);
          // }
        })
        .catch((e) => {
          throw e;
        });
    } catch (error) { }
  };

  const findControlAreaSubMenuData = (
    questionnaireArrayData: questionnaireModel[],
    controlAreaId?: string,
    subMenuId?: string
  ) => {
    const findControlAreaIndex: number = questionnaireArrayData?.findIndex(
      (ind: questionnaireModel) =>
        ind.controlAreaId == (controlAreaId || activeLeftNavigation.id)
    );

    // PC_LN_1.37
    let extractControlArea: controlAreaSubMenuModel[] =
      questionnaireArrayData[findControlAreaIndex]?.controlAreaValue ===
        undefined
        ? []
        : questionnaireArrayData[findControlAreaIndex]?.controlAreaValue;

    // PC_LN_1.38
    const findControlAreaTopicIndex: number = extractControlArea?.findIndex(
      (ind: controlAreaSubMenuModel) => {
        if (subMenuId != undefined) {
          return ind.controlAreaSubMenuId == subMenuId;
        } else {
          return (
            ind.controlAreaSubMenuId ==
            extractControlArea[0]?.controlAreaSubMenuId
          );
        }
      }
    );

    // PC_LN_1.40
    const controlAreaSubMenuData =
      extractControlArea[findControlAreaTopicIndex];

    return {
      menuValues: controlAreaSubMenuData,
      controlAreaIndex: findControlAreaIndex,
      controlAreaMenuIndex: findControlAreaTopicIndex,
    };
  };

  /**
   * PC_LN_1.32 - PC_LN_1.47
   * @param id
   * @param controlAreaId as optional parameter
   */
  const controlAreaMenuTabsOnClick = async (
    id: string,
    name: string,
    controlAreaId?: string,
    questionsApiData?: questionnaireModel[]
  ) => {
    try {
      // PC_LN_1.34
      setActiveControlAreaMenu({
        id: id,
        name: name,
      });
      // PC_LN_1.33
      let newMainQuestions: questionModel[] = [],
        newBranchQuestions: questionModel[] = [];

      const questionDataArray = questionsApiData || questionnarieData;

      const findIndices = findControlAreaSubMenuData(
        questionDataArray,
        controlAreaId,
        id
      );
      // PC_LN_1.40
      const controlAreaSubMenuData = findIndices?.menuValues;

      // PC_LN_1.41
      if (!controlAreaSubMenuData?.controlAreaSubMenuValues?.length) {
        // PC_LN_1.42
        setIsLoading(true);
        let subMenuQuestionApiData = await getControlAreaSubMenuQuestions({
          controlAreaSubMenuId: controlAreaSubMenuData?.controlAreaSubMenuId,
          projectId: activeProject?.projectId || null,
          organizationId: IDs.organizationId,
        });

        if (
          controlAreaSubMenuData?.controlAreaSubMenuId ===
          subMenuQuestionApiData?.controlAreaSubMenuId
        ) {
          questionDataArray[findIndices.controlAreaIndex].controlAreaValue[
            findIndices.controlAreaMenuIndex
          ].controlAreaSubMenuValues =
            subMenuQuestionApiData.controlAreaSubMenuValues || [];
        } else {
          questionDataArray[findIndices.controlAreaIndex].controlAreaValue[
            findIndices.controlAreaMenuIndex
          ].controlAreaSubMenuValues = [];
        }
        setIsLoading(false);
      }
      // PC_LN_1.43
      setQuestionnarieData([...questionDataArray]);

      let cloneQuestionnaireData = JSON.parse(
        JSON.stringify(questionDataArray)
      );
      // PC_LN_1.44
      cloneQuestionnaireData[findIndices.controlAreaIndex]?.controlAreaValue[
        findIndices.controlAreaMenuIndex
      ].controlAreaSubMenuValues?.forEach((que: questionModel) => {
        if (que.branchingOption != 0) {
          newBranchQuestions.push(que);
        } else {
          newMainQuestions.push(que);
        }
      });

      // PC_LN_1.45, PC_LN_1.46, PC_LN_1.47
      setMainQuestions(newMainQuestions);
      setBranchQuestions(newBranchQuestions);
      updateUserAttendQuestionnaire(newMainQuestions, newBranchQuestions);
    } catch (e) {
      // console.log(e, "controlAreaMenuTabsOnClick()");
    }
  };

  /**
   * PC_LN_1.55 - PC_LN_1.70
   */
  const saveQuestionnaire = async () => {
    try {
      // PC_LN_1.56
      if (mainQuestions.length && activeLeftNavigation.type === "controlArea") {
        const findIndices = findControlAreaSubMenuData(
          questionnarieData,
          activeLeftNavigation.id,
          activeControlAreaMenu.id
        );
        // PC_LN_1.60
        const sumScores = (arr: userAnsweredModel[]) => {
          let sum = 0;
          arr.forEach((obj) => {
            sum += obj.optionScore;
          });
          return sum;
        };
        // PC_LN_1.61
        const extractId = (arr: userAnsweredModel[]) => {
          return arr.map((obj) => obj.optionId);
        };
        // PC_LN_1.62
        let filterQuestionData = mainQuestions.map(
          ({ questionId, userAnswered }: userAttendedModel) => ({
            questionId,
            userAnswered,
          })
        );

        let questionsAnsweredCount = 0;
        // PC_LN_1.63
        filterQuestionData.forEach((obj: userAttendedModel) => {
          if (obj.userAnswered === undefined || obj.userAnswered.length === 0) {
            obj.optionId = null;
            if (state?.verticalName === "Mobility") obj.score = -1;
            else obj.score = 0;
          } else if (obj.userAnswered && obj.userAnswered.length > 1) {
            questionsAnsweredCount += 1;
            obj.optionId = extractId(obj.userAnswered);
            obj.score = sumScores(obj.userAnswered);
          } else {
            questionsAnsweredCount += 1;
            obj.optionId = [];
            obj.optionId[0] = obj.userAnswered[0].optionId;
            obj.score = obj.userAnswered[0].optionScore;
          }
        });
        // PC_LN_1.64
        let postQuestionsData = filterQuestionData.map(
          ({ questionId, optionId, score }: userAttendedModel) => ({
            questionId,
            optionId,
            score,
          })
        );
        // PC_LN_1.65
        questionnarieData[findIndices.controlAreaIndex].controlAreaValue[
          findIndices.controlAreaMenuIndex
        ].controlAreaSubMenuValues = removeMainQuestionDuplicates([
          ...mainQuestions,
          ...branchQuestions,
        ]);

        setQuestionnarieData([...questionnarieData]);
        let checkAnswered = false;

        postQuestionsData.forEach((obj) => {
          if (obj.optionId != null) checkAnswered = true;
        });
        // PC_LN_1.66
        const postData = {
          verticalName: IDs.verticalName,
          controlAreaId: activeLeftNavigation.id,
          controlAreaName: activeLeftNavigation.name,
          controlAreaSubMenuId: activeControlAreaMenu.id,
          controlAreaSubMenuName: activeControlAreaMenu.name,
          projectId: activeProject?.projectId || null,
          questionsAndAnswers: postQuestionsData,
          totalNoOfQuestion: mainQuestions?.length,
          totalNoOfAnswers: questionsAnsweredCount,
        };

        if (checkAnswered) {
          // setIsLoading(true);
          setShowToasterLoading(true);
          // PC_LN_1.67
          let postQuestionsApiData = await postQuestionnaire(
            IDs.organizationId,
            IDs.verticalId,
            postData
          );
          if (postQuestionsApiData.status === 200) {
            if (notAssessedControlAreaSubMenus.length != 0) {
              checkNotAssessedMenus();
            } else {
              checkNotAssessedMenus(true);
            }
            invokeToaster();
          }
          // if (IDs.verticalName !== "IAM & Security")
          setGenerateButtonHide(false);
          // setIsLoading(false);
          setShowToasterLoading(false);
        }
      }
    } catch (e) {
      // console.log(e, "saveQuestionnaire()");
    }
  };

  /**
   * PC_LN_1.25 - PC_LN_1.31
   * @param id - Connector id or control area id
   * @param navName - Connector name or control area name
   * @param navType - connector or controlarea
   */
  const handleLeftNavigationClick = async (
    id: string,
    navName: string,
    navType: string
  ) => {
    try {
      // PC_LN_1.27
      setActiveLeftNavigation({
        id: id,
        name: navName,
        type: navType,
      });

      if (
        IDs.verticalName === "ServiceNow" &&
        ServiceNowProcesses.includes(navName)
      ) {
        setIsLoading(true);
        let questionApiData = await getControlAreas({
          verticalId: IDs.verticalId,
          organizationId: IDs.organizationId,
        });

        questionnarieData = questionApiData?.filter(
          (obj: questionnaireModel) => {
            if (ServiceNowProcesses.includes(obj?.controlArea)) {
              return snControlAreaSelectedValues?.some(
                (item: string) => item === obj?.controlArea
              );
            } else {
              return obj;
            }
          }
        );

        questionApiData?.forEach((obj: questionnaireModel) => {
          if (ServiceNowProcesses.includes(obj?.controlArea)) {
            obj.controlAreaValue = obj?.controlAreaValue?.filter(
              (item: controlAreaSubMenuModel) =>
                snSubMenuSelectedValues?.some(
                  (sMenu: string) => sMenu === item?.controlAreaSubMenuId
                )
            );
          }
        });
        setIsLoading(false);
      }

      if (navType === "controlArea") {
        // PC_LN_1.28
        const findControlAreaIndex: number = questionnarieData.findIndex(
          (ind: questionnaireModel) => ind.controlAreaId == id
        );
        setIsLoading(true);
        let subMenuQuestionApiData;
        if (findControlAreaIndex != -1) {
          // PC_LN_1.29
          subMenuQuestionApiData = await getControlAreaSubMenuQuestions({
            controlAreaSubMenuId:
              questionnarieData[findControlAreaIndex].controlAreaValue[0]
                .controlAreaSubMenuId,
            projectId: activeProject?.projectId || null,
            organizationId: IDs.organizationId,
          });
          questionnarieData[
            findControlAreaIndex
          ].controlAreaValue[0].controlAreaSubMenuValues =
            subMenuQuestionApiData?.controlAreaSubMenuValues || [];
          setQuestionnarieData([...questionnarieData]);
        }
        // PC_LN_1.30
        setIsLoading(false);

        // PC_LN_1.31
        questionnarieData.forEach((questionObj: questionnaireModel) => {
          if (questionObj.controlAreaId.trim() == id.trim()) {
            setActiveControlAreaMenu({
              id: questionObj.controlAreaValue[0].controlAreaSubMenuId,
              name: questionObj.controlAreaValue[0].controlAreaSubMenuName,
            });
            controlAreaMenuTabsOnClick(
              questionObj.controlAreaValue[0].controlAreaSubMenuId,
              questionObj.controlAreaValue[0].controlAreaSubMenuName,
              id,
              undefined
            );
          }
        });
      }

      if (notAssessedControlAreaSubMenus.length != 0) {
        checkNotAssessedMenus();
      }
    } catch (e) {
      // console.log(e, "handleLeftNavigation()");
    }
  };

  /**
   * PC_LN_1.48
   * @param main - main Questions array to bind in questionnaire
   * @param branch - branch Questions array to keep the branching questions hidden
   */
  const updateUserAttendQuestionnaire = (
    main: questionModel[],
    branch: questionModel[]
  ) => {
    // PC_LN_1.49
    const cloneMainQuestions = JSON.parse(
      JSON.stringify(removeMainQuestionDuplicates(main))
    );
    // PC_LN_1.50
    branch.forEach((obj: questionModel, branchIndex: number) => {
      cloneMainQuestions.forEach((main: questionModel, index: number) => {
        if (
          main.userAnswered.some(
            (ind: userAnsweredModel) => ind.optionId == obj.branchingOption
          ) &&
          !cloneMainQuestions.includes(obj)
        ) {
          cloneMainQuestions.splice(index + 1, 0, obj);
        }
      });
    });
    // PC_LN_1.51
    setMainQuestions(removeMainQuestionDuplicates(cloneMainQuestions));
  };

  /**
   * PC_LN_1.52 - PC_LN_1.54
   * @param arr
   * @returns
   */
  const removeMainQuestionDuplicates = (arr: questionModel[]) => {
    // PC_LN_1.53
    arr = arr.filter(
      (value: questionModel, index: number, self: any) =>
        index ===
        self.findIndex((t: questionModel) => t.questionId === value.questionId)
    );
    return arr;
  };

  const bindConnectors = (connectors: connectorsModel[]) => {
    return state.verticalName === "IAM & Security"
      ? connectors
        ?.sort((a, b) => (a.connector < b.connector ? -1 : 1))
        ?.map((obj: connectorsModel, connectorIndex: number) => (
          <div>
            <button
              key={connectorIndex}
              className={`nav-link text-start rounded-0 font-regular font-14 w-100 adjust-height menu-list ${obj.connector === activeLeftNavigation.name ? "active" : ""
              } ${connectedConnectors?.length
                  ? connectedConnectors[0]?.connector === obj.connector
                    ? ""
                    : "cursorNotAllowed"
                  : ""
                }`}
              id="v-pills-sonarCloud-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-sonarCloud"
              type="button"
              role="tab"
              aria-controls="v-pills-sonarCloud"
              aria-selected="true"
              onClick={() => {
                handleLeftNavigationClick(
                  obj.connectorId,
                  obj.connector,
                  "connector"
                );
              }}
              disabled={
                connectedConnectors?.length
                  ? connectedConnectors[0]?.connector === obj.connector
                    ? false
                    : true
                  : false
              }
            >
              {obj.connector}
              <span hidden={true} className="action-needed ms-2" />
            </button>
          </div>
        ))
      : connectors
        ?.sort((a, b) => (a.connector < b.connector ? -1 : 1))
        ?.map((obj: connectorsModel, connectorIndex: number) => (
          <div>
            <button
              key={connectorIndex}
              className={`nav-link text-start rounded-0 font-regular font-14 w-100 adjust-height menu-list ${obj.connector === activeLeftNavigation.name ? "active" : ""
              }`}
              id="v-pills-sonarCloud-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-sonarCloud"
              type="button"
              role="tab"
              aria-controls="v-pills-sonarCloud"
              aria-selected="true"
              onClick={() => {
                handleLeftNavigationClick(
                  obj.connectorId,
                  obj.connector,
                  "connector"
                );
              }}
              disabled={
                IDs.mode === "reassess" &&
                activeProject.projectId === "" &&
                (IDs.verticalName === "Mobility" ||
                  IDs.verticalName === "Application Architecture")
              }
            >
              {obj.connector}
              <span hidden={true} className="action-needed ms-2" />
            </button>
          </div>
        ));
  };

  const bindControlAreas = (controlAreas: controlAreasModel[]) => {
    return controlAreas
      ?.sort((a, b) => a.controlAreaOrder - b.controlAreaOrder)
      ?.map((obj: controlAreasModel, controlAreaIndex: number) => {
        if (IDs.verticalName === "ServiceNow") {
          return (
            <div>
              {obj.controlArea === "Process" ? (
                <>
                  <button
                    className="nav-link text-start rounded-0 font-medium font-14 sub-acrd collapsed d-flex align-items-center inactive-controlarea"
                    data-bs-toggle="collapse"
                    data-bs-target="#servicenow-sub"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-performEff"
                    aria-selected="false"
                  >
                    Process
                    <img
                      src="images/sub-acrd-arrow.svg"
                      className="sub-acrd-arrow ms-2 inactive-controlarea"
                      alt="sub-acrd-arrow"
                    />
                  </button>
                </>
              ) : (
                <button
                  key={controlAreaIndex}
                  hidden={ServiceNowProcesses.includes(obj.controlArea)}
                  className={`nav-link text-start rounded-0 font-regular font-14 w-100 adjust-height text-truncate menu-list ${obj.controlArea === activeLeftNavigation.name
                    ? "active"
                    : ""
                    }`}
                  id="v-pills-modular-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-modular"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-modular"
                  aria-selected="true"
                  onClick={() => {
                    handleLeftNavigationClick(
                      obj.controlAreaId,
                      obj.controlArea,
                      "controlArea"
                    );
                  }}
                  title={obj.controlArea}
                >
                  {obj.controlArea}
                  <span
                    hidden={
                      !notAssessedControlAreas.includes(obj.controlAreaId)
                    }
                    className="action-needed ms-2"
                  />
                </button>
              )}
              <div id="servicenow-sub" className="collapse">
                <div
                  className="nav flex-column nav-pills custom-v-pills w-100"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {connectedConnectors.length > 0
                    ? snControlAreaSelectedValues?.map((area: string) => {
                      if (area === obj.controlArea) {
                        return (
                          <button
                          className={`nav-link text-start rounded-0 font-regular font-14 w-100 adjust-height text-truncate menu-list ${obj.controlArea === activeLeftNavigation.name
                            ? "active"
                              : ""
                              }`}
                            id="v-pills-modular-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-modular"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-modular"
                            aria-selected="true"
                            onClick={() => {
                              handleLeftNavigationClick(
                                obj.controlAreaId,
                                obj.controlArea,
                                "controlArea"
                              );
                            }}
                            title={obj.controlArea}
                          >
                            {obj.controlArea}
                            <span
                              hidden={
                                !notAssessedControlAreas.includes(
                                  obj.controlAreaId
                                )
                              }
                              className="action-needed ms-2"
                            />
                          </button>
                        );
                      }
                    })
                    : ""}
                </div>
              </div>
            </div>
          );
        } else
          return (
            <div>
              <button
                key={controlAreaIndex}
                className={`nav-link text-start rounded-0 font-regular font-14 w-100 adjust-height text-truncate menu-list ${obj.controlArea === activeLeftNavigation.name ? "active" : ""
                }`}
                id="v-pills-modular-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-modular"
                type="button"
                role="tab"
                aria-controls="v-pills-modular"
                aria-selected="true"
                onClick={() => {
                  handleLeftNavigationClick(
                    obj.controlAreaId,
                    obj.controlArea,
                    "controlArea"
                  );
                }}
                title={obj.controlArea}
                disabled={
                  IDs.mode === "reassess" &&
                  activeProject.projectId === "" &&
                  (IDs.verticalName === "Mobility" ||
                    IDs.verticalName === "Application Architecture")
                }
              >
                {obj.controlArea}
                <span
                  hidden={!notAssessedControlAreas.includes(obj.controlAreaId)}
                  className="action-needed ms-2"
                />
              </button>
            </div>
          );
      });
  };

  /**
   * PC_LN_1.20
   * @returns left navigation panel html
   */
  const bindLeftNavigation = () => {
    return leftNavigationData?.map(
      (mainPanel: leftNavigationModel, index: number) => (
        <div key={index} className="col-12 col-md-12 col-lg-3 col-xl-2  pe-0">
          <div className="d-lg-flex d-none align-items-start v-tab-border left-nav-brd">
            <div
              className="nav flex-column nav-pills custom-v-pills w-100"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <p
                className={`font-16 font-semibold mb-lg-3  my-2 ps-0 ps-lg-2 ${mainPanel?.connectors === null ? "hideActionNeeded" : ""
                  }`}
              >
                Connectors
              </p>
              {mainPanel?.connectors === undefined
                ? ""
                : bindConnectors(mainPanel?.connectors)}
              <p className="font-16 font-semibold mb-lg-3  my-2 ps-0 ps-lg-2">
                Control Areas
              </p>

              {bindControlAreas(mainPanel.controlAreas)}
            </div>
          </div>
          <select
            onChange={(e) => {
              handleLeftNavigationClick(
                e.target.value?.split("#")[0],
                e.target.value?.split("#")[1],
                e.target.value?.split("#")[2]
              );
            }}
            disabled={
              IDs.mode === "reassess" &&
              activeProject.projectId === "" &&
              (IDs.verticalName === "Mobility" ||
                IDs.verticalName === "Application Architecture")
            }
            className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3"
          >
            <optgroup label="Connector">
              {mainPanel?.connectors
                ?.sort((a, b) => (a.connector < b.connector ? -1 : 1))
                ?.map((obj: connectorsModel, connectorIndex: number) => (
                  <option
                    key={connectorIndex}
                    value={obj.connectorId + "#" + obj.connector + "#connector"}
                  >
                    {obj.connector}
                  </option>
                ))}
            </optgroup>
            <optgroup label="Control Area">
              {mainPanel?.controlAreas
                ?.sort((a, b) => a.controlAreaOrder - b.controlAreaOrder)
                ?.map((obj: controlAreasModel, controlAreaIndex: number) => (
                  <option
                    key={controlAreaIndex}
                    value={
                      obj.controlAreaId + "#" + obj.controlArea + "#controlArea"
                    }
                  >
                    {obj.controlArea}
                  </option>
                ))}
            </optgroup>
          </select>
        </div>
      )
    );
  };

  /**
   * PC_LN_1.70 - PC_LN_1.73
   */
  const checkNotAssessedMenus = async (notAssessed: boolean = false) => {
    // PC_LN_1.71
    // let tempNotAssessedSubMenus: string[] = [];
    // let tempNotAssessedControlAreas: string[] = [];
    // PC_LN_1.72
    // questionnarieData.forEach((data: questionnaireModel) => {
    //   // Check if the sub menu contains values. If not,
    //   // push the control area and sub menu name in the state variables.
    //   data.controlAreaValue.forEach((controlAreaValue) => {
    //     if (controlAreaValue?.controlAreaSubMenuValues?.length === 0) {
    //       tempNotAssessedSubMenus.push(controlAreaValue.controlAreaSubMenuId);
    //       tempNotAssessedControlAreas.push(data.controlArea);
    //     } else {
    //       // Check whether the questions in sub menu are answered or not. If not answered,
    //       // push the control area and sub menu name in the state variables.
    //       controlAreaValue.controlAreaSubMenuValues?.forEach((notAnswered) => {
    //         if (notAnswered?.userAnswered.length === 0) {
    //           tempNotAssessedControlAreas.push(data.controlArea);
    //           tempNotAssessedSubMenus.push(
    //             controlAreaValue.controlAreaSubMenuId
    //           );
    //         }
    //       });
    //     }
    //   });
    // });
    setIsLoading(true);
    let getActionNeededItems = await getNotReviewedItems({
      organizationId: IDs.organizationId,
      verticalId: IDs.verticalId,
      projectId: activeProject?.projectId || null,
    });
    setIsLoading(false);
    // tempNotAssessedSubMenus = getActionNeededItems?.notAnsweredSubMenus;
    // tempNotAssessedControlAreas = getActionNeededItems?.notAnsweredControlAreas;
    // PC_LN_1.73
    if (!notAssessed) {
      setNotAssessedControlAreaSubMenus(
        getActionNeededItems?.notAnsweredSubMenus
      );
      setNotAssessedControlAreas(getActionNeededItems?.notAnsweredControlAreas);
    }
    setHideActionNeeded(getActionNeededItems?.allReviewed);
  };

  const handleProjectDropdown = async (selectedPid: string) => {
    if (IDs?.mode === "reassess" && activeProject.projectId === "") {
      setIsLoading(true);
      let postReassessApiData = await postReassessProject({
        organizationId: IDs.organizationId,
        verticalId: IDs.verticalId,
        projectId: selectedPid,
      });
      setIsLoading(false);
    }
    if (selectedPid !== activeProject.projectId) {
      const selectedProject = projectDropDownData.find(
        (project) => project.projectId === selectedPid
      );
      if (selectedProject !== undefined) {
        setActiveProject(selectedProject);
        setIDs({ ...IDs, ["projectId"]: selectedProject.projectId });
        await checkGenerateReportButton(selectedProject.projectId);
      }
      setActiveLeftNavigation({
        id:
          leftNavigationData[0]?.connectors?.sort((a, b) =>
            a.connector < b.connector ? -1 : 1
          )[0]?.connectorId ||
          leftNavigationData[0]?.controlAreas?.sort(
            (a, b) => a.controlAreaOrder - b.controlAreaOrder
          )[0]?.controlAreaId,
        name:
          leftNavigationData[0]?.connectors[0]?.connector ||
          leftNavigationData[0]?.controlAreas?.sort(
            (a, b) => a.controlAreaOrder - b.controlAreaOrder
          )[0]?.controlArea,
        type: leftNavigationData[0]?.connectors[0]?.connector
          ? "connector"
          : "controlArea",
      });
    }
  };

  const deleteProjectDropdownOption = async (selectedProjectId: string) => {
    try {
      setIsLoading(true);
      let deleteProjectApiData = await deleteProject({
        organizationId: IDs.organizationId,
        verticalId: IDs.verticalId,
        projectId: selectedProjectId,
      });
      if (deleteProjectApiData.status === 200) {
        let projectApiData = await getProjects(
          IDs.verticalId,
          IDs.organizationId
        );
        if (IDs?.mode !== "reassess") {
          projectApiData = projectApiData.filter((item: any) => {
            return item.progress !== "Completed";
          });
        }
        setProjectDropDownData(projectApiData);
        setActiveProject(
          projectApiData[0] || {
            projectId: "",
            projectName: "",
            progress: "",
          }
        );
        setIDs({
          ...IDs,
          ["projectId"]:
            activeProject.projectId || projectApiData[0]?.projectId,
        });
      }
      setIsLoading(false);
    } catch (e) {
      // console.log(e, "deleteProjectDropdownOption()");
    }
  };

  const bindProjectDropdown = () => {
    return projectDropDownData.map((value: any, index: number) => {
      return (
        <li
          key={index}
          className="dropdown-item custom-dropdown-li dropdown-submenu ps-1 pe-4 d-flex"
          style={{ justifyContent: "space-between" }}
          id={value.projectId}
          onClick={(e: any) => {
            e.stopPropagation();
            handleProjectDropdown(e.target.id);
          }}
          onMouseEnter={() => setInd(index)}
          onMouseLeave={() => setInd(-1)}
        >
          <span
            id={value.projectId}
            onClick={(e: any) => {
              e.stopPropagation();
              handleProjectDropdown(e.target.id);
            }}
            key={value.projectId}
            title={value.projectName}
            onMouseEnter={() => setInd(index)}
            onMouseLeave={() => setInd(-1)}
          >
            {value.projectName?.length > 17
              ? value.projectName?.slice(0, 17) + "..."
              : value.projectName}
          </span>
          <img
            src="images/dropdown-arrow.svg"
            className="rotate-img"
            style={ind === index ? { transform: "none" } : {}}
          ></img>
          <ul
            className="dropdown-menu py-1 m-0"
            style={ind === index ? { display: "block" } : { display: "none" }}
          >
            <li
              className="dropdown-item custom-dropdown-li ps-3 py-1 pe-1"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => {
                setDeleteProjectId(value.projectId);
              }}
            >
              <a className="color-red font-14 font-medium text-decoration-none cursor-pointer">
                Delete
              </a>
            </li>
          </ul>
        </li>
      );
    });
  };

  const addNewProject = async () => {
    try {
      let dataParams = {
        organizationId: state.organizationId,
        verticalId: state.verticalId,
        projectName: projectName,
      };
      setIsLoading(true);
      if (projectName?.trim() !== "") {
        let addProjectApiData = await addProjects(dataParams);
        if (addProjectApiData.status === 200) {
          let projectApiData = await getProjects(
            IDs.verticalId,
            IDs.organizationId
          );
          if (IDs?.mode !== "reassess") {
            projectApiData = projectApiData.filter((item: any) => {
              return item.progress !== "Completed";
            });
          }
          setProjectDropDownData(projectApiData);
          if (activeProject.projectId === "" || IDs.projectId === null) {
            setActiveProject(projectApiData[0]);
            setIDs({
              ...IDs,
              ["projectId"]:
                activeProject.projectId || projectApiData[0].projectId,
            });
          }
        }
        setProjectName("");
      } else {
        setValidationMsg("Please enter a project name");
      }
      setIsLoading(false);
    } catch (e) {
      // console.log(e, "addNewProject()");
    }
  };

  /**
   * PC_LN_1.81, navigate the user to report page
   */
  const navigateReportGrid = async () => {
    await saveQuestionnaire();
    let tempArray: any = [];

    if (IDs?.verticalName === "ServiceNow") {
      questionnarieData = questionnarieData?.filter((con) => {
        if (snControlAreaSelectedValues?.includes(con.controlArea)) {
          return (con.controlAreaValue = con?.controlAreaValue?.filter(
            (sub: any) =>
              snSubMenuSelectedValues?.includes(sub.controlAreaSubMenuId)
          ));
        } else if (!ServiceNowProcesses?.includes(con.controlArea)) {
          return con;
        }
      });
    }

    questionnarieData.forEach((obj) => {
      let subTemp: any = {
        controlAreaId: obj.controlAreaId,
        controlAreaName: obj.controlArea,
        controlAreaValues: [],
      };
      obj.controlAreaValue.forEach((sub) => {
        let temp: any = {
          controlAreaSubMenuId: sub.controlAreaSubMenuId,
          controlAreaSubMenuName: sub.controlAreaSubMenuName,
          controlAreaSubMenuValues: 0,
        };

        subTemp.controlAreaValues.push(temp);
      });
      tempArray.push(subTemp);
    });
    setIsLoading(true);
    let reportGridApiData = await postQuestionnaireReport(
      state.organizationId,
      state.verticalId,
      activeProject.projectId || null,
      tempArray
    );

    if (reportGridApiData.status === 200) {

      if (IDs?.verticalName === "MDPW") {
        let request = {
          organizationId: state.organizationId,
          verticalId: state.verticalId,
          verticalName: state.verticalName,
          projectId: activeProject.projectId,
          projectName: activeProject.projectName,
        };

        const response = await postChanllengesAndRecommendations(request)

        if (response.status !== 200) {
          console.error("error in posting the challenges and recommendations", response.data)
        }
      }
      setIsLoading(false);

      navigate("/reports", {
        state: {
          organizationId: state.organizationId,
          organizationName: state.organizationName,
          verticalId: state.verticalId,
          verticalName: state.verticalName,
          projectId: activeProject.projectId ?? state.projectId,
          projectName: activeProject.projectName,
          projectStatus: activeProject.progress,
          IsInitialReportGeneration: true,
        },
      });

    }
  };

  const saveSalesforceCloud = async (values: string[]) => {
    setIsLoading(true);
    let cloudData: sfCloudModel[] = [];
    values.forEach((obj) => {
      cloudData.push({
        cloudId: obj,
        cloudName: "",
      });
    });
    if (values?.length === 0) {
      setNotAssessedControlAreaSubMenus([]);
      setNotAssessedControlAreas([]);
    }
    await postSalesforceCloud({
      organizationId: IDs.organizationId,
      verticalId: IDs.verticalId,
      selectedCloud: cloudData,
      userName: "Dhilipan",
    })
      .then(async (resp) => {
        if (resp.status === 200) {
          let questionApiData = await getControlAreas({
            verticalId: IDs.verticalId,
            organizationId: IDs.organizationId,
          });
          setQuestionnarieData(questionApiData);
          await checkGenerateReportButton();
          if (
            leftNavigationData[0]?.connectors === null &&
            questionApiData !== null
          ) {
            const getControlAreaValueData: any = findControlAreaSubMenuData(
              questionApiData,
              activeLeftNavigation?.id
            );
            setIsLoading(true);
            await controlAreaMenuTabsOnClick(
              getControlAreaValueData?.menuValues?.controlAreaSubMenuId,
              getControlAreaValueData?.menuValues?.controlAreaSubMenuName,
              activeLeftNavigation?.id,
              questionApiData
            );
            setIsLoading(false);
          }
        }
      })
      .catch((e) => {
        setIsLoading(false);
        // console.log(e);
      });
    setIsLoading(false);
  };

  // PC_LN_1.79, Show and hide the toaster popup
  const invokeToaster = () => {
    setShowToasterPopup(true);
    setTimeout(() => {
      setShowToasterPopup(false);
    }, 4000);
  };

  const bindSalesforceDropdown = () => {
    return (
      <div
        hidden={IDs.verticalName !== "Salesforce"}
        className="multi-select-dropdown"
        role="group"
      >
        <ConnectorSelectDropdown
          dataOptions={sfCloudValues.CloudOptions}
          dataValue={sfCloudSelectedValues}
          handleChange={(value: string[]) => {
            setSfCloudSelectedValues(value as string[]);
            saveSalesforceCloud(value as string[]);
          }}
          mode={"select"}
          dropdownName={"Salesforce Dropdown"}
        />
      </div>
    );
  };

  /**********
   * Return Main HTML body
   **********/
  return projectDropDownData.length === 0 &&
    domainHasProjects.includes(state?.verticalName) ? (
    <div>
      <Loader isLoading={isLoading} />
      <div className="container-fluid" style={{ marginTop: "105px" }}>
        <div className="row justify-content-center">
          <div className="px-4">
            <BreadCrumbs data={breadCrumbData} />

            {/* heading content starts here */}
            <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-4">
              <div className="d-flex align-items-center mb-4 mb-md-0">
                <a href="" className="cursor-pointer">
                  <img src="images/backarrow.svg" alt="back-arrow" title="Back" onClick={(event: any) => {
                    event.preventDefault();
                    navigate("/manageassessment", { state: { organizationId: IDs.organizationId, organizationName: IDs.organizationName } })
                  }} /></a>

                <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                  {state?.verticalName} Assessment
                </h2>
              </div>
            </div>
            {/* heading content ends here */}
            <div className="row">
              <h2 className="font-18 font-semibold color-black mb-4 ps-5">
                Add a New Project
              </h2>
              <div className="col-md-6 ps-5">
                <div className="mb-5 ">
                  <label
                    htmlFor="projectName"
                    className="form-label font-14 font-semibold color-black "
                  >
                    Project Name
                  </label>
                  <input
                    onChange={(e: any) => {
                      if (
                        (!charRegex.test(e.target.value)) ||
                        e.target.value === ""
                      ) {
                        setProjectName(e.target.value);
                      }
                    }}
                    value={projectName}
                    maxLength={60}
                    type="text"
                    className="form-control theme-form font-regular font-14"
                    id="projectName"
                    placeholder="Enter the project name"
                  />
                  <p className="mt-2 mb-0 color-red font-14 font-regular">
                    {validationMsg}
                  </p>
                </div>
                <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
                  <button
                    type="button"
                    className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold cursor-pointer"
                    onClick={() => {
                      addNewProject();
                    }}
                  >
                    Proceed
                  </button>
                  <a
                    href="/homepage"
                    className="theme-link-btn cursor-pointer font-14 font-semibold text-center order-md-first"
                    onClick={() => {
                      setProjectName("");
                    }}
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div>
        <Loader isLoading={isLoading}></Loader>
        <div className="container-fluid" style={{ marginTop: "105px" }}>
          <div className="row justify-content-center">
            <div className="px-4">
              {/* breadcrumb starts here */}
              <BreadCrumbs data={breadCrumbData} />


              {/* breadcrumb ends here */}
              {/* heading content starts here */}
              <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-4">
                <div className="d-flex align-items-center mb-4 mb-md-0">
                  <a href="" className="cursor-pointer">
                    <img src="images/backarrow.svg" alt="back-arrow" title="Back" onClick={(event: any) => {
                      event.preventDefault();
                      navigate("/manageassessment", { state: { organizationId: IDs.organizationId, organizationName: IDs.organizationName } })
                    }} /></a>
                  <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                    {state?.verticalName || "Application Architecture"}{" "}
                    Assessment
                  </h2>
                </div>
                <div className="d-md-flex d-grid gap-3 ">
                  <div className="d-grid gap-3 d-md-inline-flex justify-content-md-end">
                    <span
                      className={`rounded-2 d-flex align-items-center justify-content-center  border-0 font-14 font-semibold severity-high-bg px-4 py-2 ${notAssessedControlAreaSubMenus.length === 0
                        ? "hideActionNeeded"
                        : "'"
                        }`}
                    >
                      <span className="action-needed me-2" /> Action Needed
                    </span>
                    {bindSalesforceDropdown()}
                    {/* <div
                      hidden={projectDropDownData.length === 0 ? true : false}
                      className="btn-group"
                      role="group"
                    >
                      <div className="btn-group w-100" role="group">
                        <button
                          type="button"
                          className="theme-form text-start custom-dropdown-toggle groupbtn add-proj-btn bg-white border-grey ps-2 pe-4 font-14 font-medium cursor-pointer"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          title={
                            activeProject?.projectName?.length > 17
                              ? activeProject?.projectName
                              : ""
                          }
                        >
                          {(activeProject?.projectName?.length > 17
                            ? activeProject?.projectName?.slice(0, 17) + "..."
                            : activeProject?.projectName) ||
                            "Select any Project"}
                        </button>
                        <ul className="dropdown-menu bg-white font-14 font-medium group-dropdown px-1 w-100">
                          {bindProjectDropdown()}
                        </ul>
                      </div>
                      <button
                        type="button"
                        className="btn theme-form groupbtn border-grey px-3 cursor-pointer"
                        data-bs-toggle="modal"
                        data-bs-target="#AddProject"
                      >
                        <img src="images/plus.svg" alt="plus" title="Add" />
                      </button>
                    </div> */}
                    {/* {projectDropdownOptions()} */}
                    <div
                      hidden={generateButtonHide}
                      className={`d-grid gap-2 d-md-inline-flex justify-content-md-end ${generateButtonHide ? "hideActionNeeded" : ""
                        }`}
                    >
                      {hideActionNeeded ? (
                        <button
                          type="button"
                          className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold pt-2 pb-2 cursor-pointer text-nowrap"
                          onClick={() => {
                            navigateReportGrid();
                          }}
                        >
                          Generate Report
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold pt-2 pb-2 cursor-pointer text-nowrap"
                          data-bs-toggle="modal"
                          data-bs-target="#generateReport"
                        >
                          Generate Report
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* heading content ends here */}
              <div className="row">
                {bindLeftNavigation()}
                <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                  <div className="tab-content" id="v-pills-tabContent">
                    {/* overall summary content starts here */}
                    <div
                      className="tab-pane fade show active mb-5"
                      id="v-pills-sonarCloud"
                      role="tabpanel"
                    >
                      <div className="row">
                        {/* add new connectors starts  */}
                        {activeLeftNavigation.type === "connector" &&
                          domainHasConnectors.includes(state?.verticalName) ? (
                          IDs.mode === "reassess" &&
                            activeProject.projectId === "" &&
                            projectDropDownData?.length ? (
                            <div className="row text-center">
                              <p className="font-16 font-medium color-grey-v3 my-5">
                                Select any project to reassess
                              </p>
                            </div>
                          ) : (
                            <Connectors
                              verticalName={allIdObject.verticalName}
                              setIsConnectorProvided={setIsConnectorProvided}
                              activeConnector={activeLeftNavigation.name}
                              activeConnectorId={activeLeftNavigation.id}
                              activeIds={IDs}
                              isLoading={setIsLoading}
                              setGenerateButton={setGenerateButtonHide}
                              setConnectedConnectors={setConnectedConnectors}
                              snControlAreaSelectedValues={
                                snControlAreaSelectedValues
                              }
                              setSnControlAreaSelectedValues={
                                setSnControlAreaSelectedValues
                              }
                              snSubMenuSelectedValues={snSubMenuSelectedValues}
                              setSnSubMenuSelectedValues={
                                setSnSubMenuSelectedValues
                              }
                              connectors={leftNavigationData[0]?.connectors}
                            />
                          )
                        ) : (
                          <Questionarie
                            controlAreaSelectedId={activeLeftNavigation.id}
                            controlAreaSubMenuSelectedId={
                              activeControlAreaMenu.id
                            }
                            questionsData={questionnarieData}
                            controlAreaSubMenuClick={controlAreaMenuTabsOnClick}
                            mainQuestions={mainQuestions}
                            branchQuestions={branchQuestions}
                            setMainQuestions={setMainQuestions}
                            setBranchQuestions={setBranchQuestions}
                            notAssessedSubMenus={notAssessedControlAreaSubMenus}
                            activeIds={IDs}
                            isLoading={setIsLoading}
                            saveQuestionnaire={saveQuestionnaire}
                          />
                        )}
                        {/* add new connectors ends  */}
                      </div>
                    </div>
                    {/* overall summary content ends here */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add project popup*/}
        <div
          className="modal fade"
          id="AddProject"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          data-bs-target="AddProject"
          tabIndex={-1}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-3">
              <div className="modal-header pb-0 border-0">
                <h5 className="font-semibold font-18 font-semibold d-flex justify-content-between align-items-center">
                  Add a New Project
                </h5>
                <button
                  type="button"
                  className="btn-close cursor-pointer"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setProjectName("");
                  }}
                />
              </div>
              <div className="modal-body">
                <div className="col-md-12">
                  <div className="mb-5">
                    <label
                      htmlFor="pp-Project-Name"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Project Name
                    </label>
                    <input
                      value={projectName}
                      onChange={(e: any) => {
                        if (
                          (!charRegex.test(e.target.value)) ||
                          e.target.value === ""
                        ) {
                          setProjectName(e.target.value);
                        }
                      }}
                      maxLength={60}
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="pp-Project-Name"
                      placeholder="Enter the project name"
                    />
                  </div>
                </div>
                <div className="col-md-12 d-flex justify-content-end mt-4 mb-2">
                  <a
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer"
                    onClick={() => {
                      setProjectName("");
                    }}
                  >
                    Cancel
                  </a>
                  <button
                    data-bs-dismiss="modal"
                    type="button"
                    className="btn btn-dark theme-primary-btn-sm border-0 font-semibold px-4 cursor-pointer"
                    onClick={() => {
                      addNewProject();
                    }}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/** Generate report popup */}
        <div
          className="modal fade"
          id="generateReport"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-3">
              <div className="modal-header pb-0 border-0 position-relative">
                <h5 className="font-semibold font-18 w-100 text-center mb-0">
                  Alert
                </h5>
                <button
                  type="button"
                  className="btn-close cursor-pointer position-absolute top-0 end-0 mt-2 me-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div>
                  <p className="font-medium font-14">
                    There are areas left incomplete. You are trying to generate
                    a report without completing the entire assessment.
                  </p>
                  <div className="col-md-12 gap-2 d-grid d-md-flex justify-content-center mt-4 ">
                    <button
                      // data-bs-toggle="modal"
                      // data-bs-target="#progress"
                      data-bs-dismiss="modal"
                      type="button"
                      className="btn btn-secondary  theme-secondary-btn-sm border-0 font-semibold px-4 cursor-pointer"
                      onClick={() => {
                        checkNotAssessedMenus();
                      }}
                    >
                      Review What's Left
                    </button>
                    {IDs.verticalName === "Application Architecture" &&
                      connectedConnectors.some(
                        (item) => item.connector === "Sonar Cloud"
                      ) ? (
                      <button
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                        type="button"
                        className="btn btn-dark theme-primary-btn-sm border-0 font-semibold px-4 cursor-pointer"
                        data-bs-target="#sonarCloudPopup"
                      >
                        Proceed Anyway
                      </button>
                    ) : (
                      <button
                        data-bs-dismiss="modal"
                        type="button"
                        className="btn btn-dark theme-primary-btn-sm border-0 font-semibold px-4 cursor-pointer"
                        onClick={() => {
                          navigateReportGrid();
                        }}
                      >
                        Proceed Anyway
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/**Toaster Start end */}
        {showToasterLoading ? (
          <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
            <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
              <div className="toast-body d-flex align-items-center ">
                <div className="d-block me-3">
                  <img
                    src="images/loading.gif"
                    alt="toast-success"
                    style={{ height: "15px", width: "15px" }}
                  />
                </div>
                <div className="d-block">
                  <span className="font-medium font-16 color-grey">
                    Saving...
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {showToasterPopup ? (
          <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
            <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
              <div className="toast-body d-flex align-items-center ">
                <div className="d-block me-3">
                  <img src="images/toast-success.svg" alt="toast-success" />
                </div>
                <div className="d-block">
                  <span className="font-medium font-16 color-grey">
                    Saved successfully.
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {/* toast end*/}
        {/**Delete Popup */}
        <div
          className="modal fade"
          id="delete"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-3">
              <div className="modal-header pb-0 border-0">
                <button
                  type="button"
                  className="btn-close cursor-pointer"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <div className="w-100 text-center mb-3">
                  <img src="images/delete-icon.svg" alt="delete" />
                </div>

                <p className="font-semibold font-24 text-center mb-2 red-400">
                  Delete Confirmation
                </p>

                <p className="font-semibold font-16 text-center mb-5">
                  Do you really want to delete the Project?
                </p>

                <div className="d-grid gap-2 d-md-inline-flex justify-content-md-center mb-2 mb-md-0 w-100">
                  <a
                    className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer px-4"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      deleteProjectDropdownOption(deleteProjectId);
                    }}
                  >
                    Yes
                  </a>

                  <button
                    type="button"
                    className="btn btn-danger red-400 btn-lg px-4  text-white font-14 font-semibold "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/**Delete Popup end */}
        {/** App Dev Sonar Cloud project popup*/}
        <div
          className="modal fade"
          id="sonarCloudPopup"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-3">
              <div className="modal-header pb-0 border-0">
                <h5 className="font-semibold font-18 font-semibold d-flex justify-content-between align-items-center">
                  Alert
                </h5>
                <button
                  type="button"
                  className="btn-close cursor-pointer"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div>
                  <p className="font-medium font-14">
                    Please make sure the Sonarcloud is in executed condition
                  </p>
                  <div className="col-md-12 gap-2 d-grid d-md-flex justify-content-center mt-4 ">
                    <button
                      onClick={() => {
                        navigateReportGrid();
                      }}
                      data-bs-dismiss="modal"
                      type="button"
                      className="btn btn-secondary  theme-secondary-btn-sm border-0 font-semibold px-4 cursor-pointer"
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/** App Dev sonar cloud popup end */}
      </div>
      {(showToaster && isConnectorProvided && allIdObject.verticalName === "WAFR") && (
        <ProgressToaster
          controlAreas={controlAreas}
          error={error}
          onRetry={handleRetry}
          onClose={handleClose}
        />
      )}
    </>
  );
}
