import React from "react";

import { useNavigate } from "react-router-dom";

interface breadCrumbModel {
  title: string;
  path: string;
  isActive: boolean;
  state?: any;
}

function BreadCrumbs(props: any) {
  const [breadCrumbs, setBreadcrumbs] = React.useState<breadCrumbModel[]>([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    setBreadcrumbs(props.data);
  }, [props]);


  const titleClick = (breadcrumb: breadCrumbModel) => {
    if (breadcrumb.title != "MDPW") {
      if (window.confirm("Are you sure you want to leave this page?")) {
        if (breadcrumb.state) {
          navigate(breadcrumb.path, { state: breadcrumb.state });
        } else if (breadcrumb.path) {
          navigate(breadcrumb.path);
        }
      }
    }
  };

  return (
    <ol className="breadcrumb font-12 font-semibold  mb-4">
      {breadCrumbs !== undefined
        ? breadCrumbs?.map((breadcrumb: breadCrumbModel, index: number) => {
          return breadcrumb.isActive ? (
            <li
              key={index}
              className="breadcrumb-item inactive"
              aria-current="page"
            >
              <a
                className="cursor-pointer"
                onClick={() => {
                  titleClick(breadcrumb);
                }}
              >
                {breadcrumb.title}
              </a>
            </li>
          ) : (
            <li key={index} className="breadcrumb-item active">{breadcrumb.title}</li>
          );
        })
        : null}
    </ol>
  );
}

export default BreadCrumbs;
