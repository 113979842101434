import { useEffect, useLayoutEffect, useState } from "react"
import { wafrReportSortOrderModel } from "../../interface/WafrReportModel"

const WafrRecommendationsGrid = (props: any) => {
    const initialSortData: wafrReportSortOrderModel = {
        checkName: "asc",
        impact: "asc",
        controlAreaName: "asc"
    }
    const { reportData, setReportData } = props
    const tempRecommendation = props?.recommendations || []
    const [recommendations, setRecommendations] = useState<any>([...tempRecommendation])
    const [selectedImpact, setSelectedImpact] = useState<string>("All")
    const [type, setType] = useState<string>(props.type)
    const [sortOrder, setSortOrder] = useState<any>(initialSortData)

    useEffect(() => {
        setType((prev) => props.type)
        if (props.type === "overallSummary") {
            const sortedData = sortRecommendations([...tempRecommendation], "impact");
            setRecommendations(sortedData)
        }
        else {
            setRecommendations([...tempRecommendation])
        }
        setSelectedImpact((prev: any) => "All")
    }, [props?.recommendations])



    useEffect(() => {
        const sortedData = sortRecommendations([...tempRecommendation], "impact");
        setRecommendations(sortedData)
    }, [])

    const handleFilterUpdate = (event: any) => {
        setRecommendations((prev: any) => {
            const filteredRecommendations = props?.recommendations?.filter((data: any) => {
                if (event.target.value === "All")
                    return true;
                else if (event.target.value === data?.impact)
                    return true;
                return false;
            });
            return filteredRecommendations;
        });
        setSelectedImpact(event?.target?.value);
    }

    const sortRecommendations = (sortedData: any, id: string) => {
        sortedData?.sort((a: any, b: any) => {
            const order = sortOrder[id] === "asc" ? 1 : -1
            if (sortOrder[id] === "asc")
                setSortOrder({ ...sortOrder, [id]: "desc" })
            else
                setSortOrder({ ...sortOrder, [id]: "asc" })
            return order * a[id].localeCompare(b[id])
        })
        return sortedData
    }
    const bindResourseDetails = (columnName: string[], resourceDetails: any) => {
        if (!columnName) {
            return (
                <div className="rounded-1 shadow-sm p-3">
                    <p className="font-11 color-black mb-0 text-center">No Resourse Details</p>
                </div>
            )
        }
        return (
            resourceDetails?.map((resourse: any) => {
                return <tr className="border-bottom">
                    {columnName?.map((key: string) => {
                        return <td>
                            {resourse[key] || "-"}
                        </td>
                    })
                    }
                </tr>
            })
        )
    }

    const bindRecommendationGrid = (limit: number) => {

        if (!recommendations.length) {
            return (<tr>
                <td colSpan={3}>
                    <div className="row text-center">
                        <img src="images/no-records-found.svg" width={"300"} height={"300"} />
                        <p className="font-16 font-medium color-grey-v3 my-5">
                            No Records Found
                        </p>
                    </div>
                </td>
            </tr>)
        }

        return (
            recommendations?.map((data: any, index: number) => {
                if (index >= limit)
                    return
                return (
                    <>
                        <tr className="border-bottom">
                            <td className=" text-wrap ps-3 d-flex"><span><img src="images/accordion-down-arrow.svg"
                                alt="accordion-arrow" className="cursor-pointer accordion-arrow collapsed me-2"
                                data-bs-toggle="collapse" data-bs-target={`#Table1${index}`}
                                aria-expanded="false" /></span><span>

                                    {type === "controlAreas" && recommendations.length ? <input className="theme-check form-check-input label-bold me-2 mt-0" type="checkbox" id="firstquesopt21" checked={reportData?.selectedAWSRecommendations?.includes(data?.checkId)}
                                        onChange={() => {
                                            reportData?.selectedAWSRecommendations?.includes(data?.checkId) ? setReportData({ ...reportData, selectedAWSRecommendations: reportData.selectedAWSRecommendations?.filter((id: string) => id !== data?.checkId) }) :
                                                setReportData({ ...reportData, selectedAWSRecommendations: [...reportData?.selectedAWSRecommendations, data?.checkId] })
                                        }} /> : <></>}

                                </span><span>{data.checkName || "-"}</span></td>
                            {type === "controlAreas" ? <></> : <td className=" text-wrap">{data.controlAreaName || "-"}</td>}
                            <td className="text-center"><span className="d-inline-block"><span className={`severity severity-${data?.impact?.toLowerCase() || "high"}-bg`}><span className={`severity-point severity-${data?.impact?.toLowerCase() || "high"} me-2`} />{data.impact}</span></span></td>
                        </tr>
                        <tr className="accordion-container ">
                            <td colSpan={3} className="collapse  p-0 border-0" id={`Table1${index}`}>
                                {/* first level accordion starts  */}
                                <div className="mb-4 px-4">
                                    <div className="table-responsive custom-shadow rounded-3">
                                        <table className="table table-bordered themeTable mb-0">
                                            <thead className="font-semibold ">
                                                <tr>
                                                    {data?.columnName?.map((columnName: string) => {
                                                        return <th>{columnName}</th>
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody className="font-medium">
                                                {bindResourseDetails(data?.columnName, data?.resourceDetails)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* first level accordion starts ends */}
                            </td>
                        </tr>
                    </>
                )
            })
        )
    }



    return (
        <>
            <div
                className="modal fade"
                id="recommendationsExapnMigrationStrategy"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                data-bs-target="recommendationsExapnMigrationStrategy"
                tabIndex={-1}
                aria-hidden="true" >
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header pb-0 border-0">
                            <h5 className="font-semibold font-18 font-semibold d-flex justify-content-between align-items-center">
                                Recommendations
                            </h5>
                            <div className="d-flex align-items-center">
                                <div className="d-flex gap-3 align-items-center me-2">
                                    <label
                                        className="font-14 d-md-block d-none font-regular color-grey text-nowrap"
                                        htmlFor="rec-Impact"
                                    >
                                        Impacts
                                    </label>
                                    <select
                                        className="shadow-none d-md-block d-none theme-form form-select font-regular color-black font-14 me-3"
                                        id="rec-Impact"
                                        value={selectedImpact}
                                        onChange={handleFilterUpdate}
                                    >
                                        <option value="All" >All</option>
                                        <option value="High">High</option>
                                        <option value="Medium">Medium</option>
                                        <option value="Low">Low</option>
                                    </select>
                                    {/* <Select
                                        mode={undefined}
                                        data-testid='select-sub'
                                        style={{ width: '100%', minHeight: '40px' }}
                                        placeholder="Select Contacts"
                                        value={selectedImpact}
                                        options={options}
                                        onChange={(value) => handleFilterUpdate(value)}
                                        filterOption={false}
                                    >
                
                                    </Select> */}
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <div
                                className="table-responsive mb-4 slim-scroll"
                                style={{ height: 500 }}
                            >
                                <table className="table table-borderless themeTable mb-0">
                                    <thead className="font-semibold sticky-top">
                                        <tr>

                                            <th>
                                                {type === "controlAreas" && recommendations?.length ? <><span><input className="theme-check form-check-input label-bold me-2 mt-0" type="checkbox" id="firstquesopt21" checked={recommendations?.every((data: any) => reportData?.selectedAWSRecommendations?.includes(data?.checkId))}
                                                    onChange={(event: any) => {
                                                        const checkIds = recommendations?.map((data: any) => data?.checkId)
                                                        if (event.target.checked) {
                                                            setReportData({ ...reportData, selectedAWSRecommendations: [...reportData?.selectedAWSRecommendations, ...checkIds] })
                                                        }
                                                        else {
                                                            setReportData({ ...reportData, selectedAWSRecommendations: reportData?.selectedAWSRecommendations?.filter((data: any) => !checkIds?.includes(data)) })
                                                        }
                                                    }} /></span><span></span></> :
                                                    <></>
                                                }
                                                Description<span
                                                    className="ms-2 cursor-pointer"
                                                    onClick={() => {
                                                        const sortedData = sortRecommendations(recommendations, "checkName");
                                                        setRecommendations(sortedData)
                                                    }}
                                                >
                                                    {sortOrder?.checkName === "asc" ? " ↑" : " ↓"}
                                                </span></th>

                                            {type === "controlAreas" ? <></> : <th>Control Areas <span
                                                className="ms-2 cursor-pointer"
                                                onClick={() => {
                                                    const sortedData = sortRecommendations(recommendations, "controlAreaName");
                                                    setRecommendations(sortedData)
                                                }}
                                            >
                                                {sortOrder?.controlAreaName === "asc" ? " ↑" : " ↓"}
                                            </span></th>}

                                            <th className="text-center">Impact<span
                                                className="ms-2 cursor-pointer"
                                                onClick={() => {
                                                    const sortedData = sortRecommendations(recommendations, "impact");
                                                    setRecommendations(sortedData)
                                                }}
                                            >
                                                {sortOrder?.impact === "asc" ? " ↑" : " ↓"}
                                            </span></th>
                                        </tr>
                                    </thead>
                                    <tbody className="font-medium">
                                        {bindRecommendationGrid(recommendations?.length)}
                                    </tbody>
                                </table>
                            </div>
                            <p className="font-12 font-regular color-grey-v3 mb-0">
                                No of Rec :{recommendations.length}
                                <span className="color-black">
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default WafrRecommendationsGrid