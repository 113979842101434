import React, { useState, useEffect } from 'react';
import { CreateProfileInput, ProfileQuestionUpdate } from '../interface/CreateProfileAndWorkloadModel';
import CreateProfile from './CreateProfile';
import CreateWorkload from './CreateWorkload';
import { getProfileAndWorkLoad, postProfileAndWorkload } from '../service/QuestionnaireApi';
import { profileQuestionModel } from '../interface/questionnaireInterface';
import Loader from './Loader';


export default function WellArchitectConnector(props: any) {

    const {isLoading}= props
    const { organizationId, verticalId, projectId, connectorId, } = props?.projectDetails;
    const [selectedPage, setSelectedPage] = useState<number>(1)
    const [profileDetails, setProfileDetails] = useState<any>({})
    const [workLoadDetails, setWorkLoadDetails] = useState<any>({})


    useEffect(() => {
        getProfileAndWorkLoadDetails()
    }, []);

   


    const getProfileAndWorkLoadDetails = async () => {
        try {
            const req = {
                "organizationId": organizationId,
                "verticalId": verticalId,
                "projectId": projectId,
                "connectorId": connectorId
            };
            const response = await getProfileAndWorkLoad(req);
            setProfileDetails(response?.profile)
            setWorkLoadDetails(response?.workLoad)
        }
        catch (error) {
            // console.log(error)
        }
    }

    const transformQuestions = (questions: any) => {
        return questions.map((question: any) => ({
            QuestionId: question.questionId,
            SelectedChoiceIds: question.selectedOptionIds
        }));
    };

    const handleSave = async () => {
        const profileData = {
            ProfileArn: profileDetails.profileArn,
            ProfileName: profileDetails.profileName,
            ProfileDescription: profileDetails.profileDescription,
            ProfileQuestions: transformQuestions(profileDetails.profileQuestions)
        }

        const workloadData = {
            WorkloadId: workLoadDetails.workLoadId,
            WorkloadName: workLoadDetails.workLoadName,
            Description: workLoadDetails.workLoadDescription,
            Environment: workLoadDetails.environment,
            ArchitecturalDesign: workLoadDetails.architecturalDesign,
            ReviewOwner: workLoadDetails.reviewOwnerName,
            IndustryType: workLoadDetails.industryType,
            Lenses: workLoadDetails.lenses
        }

        const request = {
            organizationId: organizationId,
            verticalId: verticalId,
            projectId: projectId,
            connectorId: connectorId,
            profileDetails: profileData,
            workLoadDetails: workloadData
        }

        const postProfileAndWorkloadResponse = await postProfileAndWorkload(request)
    }

    return (
        <>
            <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                <div className="row">
                    <div className="tab-content" id="v-pills-tabContent">
                        <div
                            className="tab-pane fade show active mb-5"
                            id="Well-Architected"
                            role="tabpanel"
                        >
                            <div className="row">
                                <div className="d-flex justify-content-center w-100 mb-4 pb-3 border-bottom">
                                    <div className="wizard-conatiner d-flex justify-content-between">
                                        <div className="wiz-step cursor-pointer active">
                                            <span className="wiz-count cursor-pointe font-semibold font-14 me-2 " onClick={() => { setSelectedPage(1) }}>1</span>
                                            <span className="font-semibold d-none d-sm-inline-block" >
                                                Create Profile
                                            </span>
                                        </div>
                                        <div className={`wiz-step  ${selectedPage === 2 ? "active" : ""}`}>
                                            <span className="wiz-count font-semibold font-14 me-2" >2</span>
                                            <span className="font-semibold d-none d-sm-inline-block">
                                                Create Workload
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {selectedPage === 1 ? <CreateProfile profileDetails={profileDetails} setProfileDetails={setProfileDetails} questions={profileDetails.profileQuestions} setSelectedPage={setSelectedPage} projectDetails={props.projectIds} setIsLoading={isLoading} /> :
                                    <CreateWorkload setWorkLoadDetails={setWorkLoadDetails} workLoadDetails={workLoadDetails} handleSave={handleSave} setSelectedPage={setSelectedPage} projectDetails={props?.projectDetails} setIsLoading={isLoading} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}