import { useEffect, useRef, useState } from "react";
import { editedSessionDataModel } from "../../interface/WafrReportModel";

const SessionForm = (props: any) => {
    const { setHideSessionForm, postSession, sessionData, showDeletePopup } = props
    const initialSessionSummary: editedSessionDataModel = {
        attributeMasterId: "",
        titleAttributeId: "",
        titleName: "",
        description: "",
        descriptionId: "",
        fileName: "",
        fileData: "",
        fileType: "",
        base64Transcript: ""
    }
    const charRegex = /^(?=.*[A-Za-z])[A-Za-z0-9\s]*$/;
    const [editedSession, setEditedSession] = useState<editedSessionDataModel>({ ...initialSessionSummary, ...sessionData })
    const [titleError, setTitleError] = useState<string>("")
    const [fileError, setFileError] = useState<string>("")
    const attachments = useRef(null);
    const regex = /<[^>]*>|"/g;
    const fileInputRef = useRef(null);

    function escapeJsonString(str: string): string {
        return str.replace(/\\/g, '\\\\')
            .replace(/"/g, '\\"')
            .replace(/\n/g, '\\n')
            .replace(/\r/g, '\\r')
            .replace(/\t/g, '\\t')
            .replace(/\f/g, '\\f');
    }

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileError("");

        let file = event.target.files?.[0];
        if (file) {
            const maxFileSize = 50 * 1024 * 1024; // 50MB
            if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                // Check file size
                if (file.size > maxFileSize) {
                    event.target.value = ""; // Clear the input
                    setEditedSession((prev: any) => ({
                        ...prev,
                        fileName: "",
                        fileData: "",
                        fileType: "",
                        base64Transcript: ""
                    }));
                    setFileError("Please upload a file smaller than 50MB");
                    resetFileInput();
                    return;
                }

                const reader = new FileReader();
                reader.onloadend = async (e: any) => {
                    try {
                        const fileContent = reader.result as string;

                        if (fileContent.trim().length === 0) {
                            event.target.value = ""; // Clear the input
                            setEditedSession((prev: any) => ({
                                ...prev,
                                fileName: "",
                                fileData: "",
                                fileType: "",
                                base64Transcript: ""
                            }));
                            setFileError("The file appears to be empty. Please upload a valid file.");
                            resetFileInput();
                            return;
                        }

                        const base64Data = escapeJsonString(fileContent.split(",")[1].replace(regex, ""));
                        setEditedSession((prev: any) => ({
                            ...prev,
                            fileName: file?.name,
                            fileData: fileContent.trim(),
                            fileType: file?.type,
                            base64Transcript: base64Data
                        }));

                    } catch (error) {
                        // console.error("Error processing file:", error);
                        event.target.value = ""; // Clear the input
                        setEditedSession((prev: any) => ({
                            ...prev,
                            fileName: "",
                            fileData: "",
                            fileType: "",
                            base64Transcript: ""
                        }));
                        setFileError("Error processing the document. Please try again.");
                        resetFileInput();
                    }
                };
                reader.readAsDataURL(file);
            } else {
                event.target.value = "";
                setEditedSession((prev: any) => ({
                    ...prev,
                    fileName: "",
                    fileData: "",
                    fileType: "",
                    base64Transcript: ""
                }));
                setFileError("Please upload a Word document (.docx)");
                resetFileInput();
            }
        }
    };

    const resetFileInput = () => {
        if (fileInputRef.current) {
            (fileInputRef.current as HTMLInputElement).value = '';
        }
    };
 

    const clearForm = () => {
        resetFileInput();
        setEditedSession({ ...initialSessionSummary });
        setTitleError("");
        setFileError("");
    }

    const validateForm = () => {
        let isValid = true;

        if (!editedSession.titleName.trim()) {
            setTitleError("Required");
            isValid = false;
        } else if (!charRegex.test(editedSession.titleName)) {
            setTitleError("Enter a valid title");
            isValid = false;
        } else {
            setTitleError("");
        }

        if (!editedSession.fileName) {
            setFileError("Please upload a transcript file to proceed");
            isValid = false;
        } else {
            setFileError("");
        }

        return isValid;
    }

    const handleSave = () => {
        if (validateForm()) {
            postSession({ ...editedSession });
            setHideSessionForm(true);
        }
    }

    return (
        <>
            {/* <div className="modal-backdrop fade show" style={{ display: "block" }}> */}
            <div className="modal-backdrop fade show"></div>
            <div className={"modal fade show"}
                id="_editRecommendation"
                style={{ display: 'block' }} >
                <div className="modal-dialog modal-dialog-centered " style={{ position: 'absolute', inset: '0px auto auto 0px', left: '70%', top: '10%', margin: '0px', transform: 'translate(-442px, 42px)' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content custom-popup w-650">
                            <div className="modal-header border-0 justify-content-between align-items-center position-relative px-4 pt-4 pb-2">
                                <h2 className="modal-title mb-0 font-20 font-semibold primary-textcolor" id="accept-promptLabel">
                                    Add Session</h2>
                                <button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close"><img src="images/popup-close.svg" alt="Close" onClick={() => {
                                    clearForm()
                                    setHideSessionForm(true)
                                }} /></button>
                            </div>
                            <div className="modal-body border-0 px-4">
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <label className="form-label font-14 font-medium color-black" htmlFor="assesName" placeholder="Enter Session Title">Session Title</label>
                                        <input
                                            type="text"
                                            id="assesName"
                                            className="form-control custom-form color-black"
                                            value={editedSession.titleName}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setTitleError("")
                                                setEditedSession({ ...editedSession, titleName: event.target.value })
                                            }}
                                        />
                                        {titleError && <div className="text-danger mt-1">{titleError}</div>}
                                    </div>
                                    <div className="col-md-12">
                                        <label className="form-label font-14 font-medium color-black" htmlFor="assesName">Upload Transcript</label>
                                        <div className="position-relative">
                                            <label className="file-upload-outline position-absolute rounded-3 d-flex align-items-center gap-2 font-12 font-semibold mt-2">
                                                <img src="images/upload-icon-blue.svg" alt="upload-icon" />Upload Transcript
                                            </label>
                                            <input
                                                type="file"
                                                className="form-control custom-file1"
                                                id="orgLogo"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setFileError("")
                                                    handleFileChange(event)
                                                }}
                                                accept=".docx"
                                                ref={fileInputRef}
                                            />
                                        </div>
                                        {editedSession.fileName && (
                                            <div className="mt-4">
                                                <span className="font-14 font-medium color-grey">
                                                    {editedSession.fileName}
                                                    <span
                                                        className="cursor-pointer ms-3"
                                                        title="Cancel"
                                                        onClick={() => {
                                                            attachments.current = null;
                                                            setEditedSession({
                                                                ...editedSession,
                                                                fileName: "",
                                                                fileData: "",
                                                                fileType: "",
                                                                base64Transcript: ""
                                                            })
                                                            resetFileInput()
                                                        }}
                                                    >
                                                        <img src="images/upload-close.svg" alt="Clear file" />
                                                    </span>
                                                </span>
                                            </div>
                                        )}
                                        {fileError && <div className="text-danger mt-3">{fileError}</div>}

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer px-4 border-0 justify-content-end pb-4 pt-4 gap-1">
                                <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer" onClick={clearForm}>Clear</a>
                                <button type="button" className="btn btn-dark theme-primary-btn border-0 font-semibold" onClick={handleSave}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SessionForm