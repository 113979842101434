import React, { useEffect, useState } from "react";
import { getInfraAssessmentSubMenus } from "../service/InfraAssessmentApi";
import { ActiveSubmenu, QuestionData, QuestionnaireProps, SelectedOption, SubmenuItem } from "../interface/InfraAssessmentModel";
import { getQuestionsForSubmenu } from "../service/InfraAssessmentApi";
import { postInfraQuestionnaireDetails } from "../service/InfraAssessmentApi";
import Loader from "./Loader";
import ToastComponent from "./ToastComponent";

const toastData: any = {
    toastType: '',
    toastHeaderMessage: '',
    toastBodyMessage: '',
};

const activeSubmenuData = {
    submenuId: "",
    submenuName: ""
}


const Questionnaire: React.FC<QuestionnaireProps> = ({ menuId, state, setIsLoading }) => {

    const [showToasterPopup, setShowToasterPopup] = useState(false);
    const [showToasterLoading, setShowToasterLoading] = useState(false);
    const [subMenus, setSubMenus] = useState<SubmenuItem[]>([]);
    const [questions, setQuestions] = useState<QuestionData[]>([]);
    const [activeSubmenu, setActiveSubmenu] = useState<ActiveSubmenu>(activeSubmenuData);
    const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([]);
    const [specifiedValue, setSpecifiedValue] = useState<string>("")
    const [isOtherOptionSeleted, setIsOtherOptionSelected] = useState<boolean>(false)
    const [selectedQuestionIdForOtherOption, setSelectedQuestionIdForOtherOption] = useState<string>("")
    const [toastProp, setToastProp] = useState<any>(toastData)
    const [hideToast, setHideToast] = useState<boolean>(true);
    const [OtherOptions, setOtherOptions] = useState<any[]>([]);


    useEffect(() => {
        fetchSubMenus();
    }, [menuId]);

    useEffect(() => {
        if (activeSubmenu) {
            fetchQuestions(activeSubmenu.submenuId);
        }
    }, [activeSubmenu]);

    const invokeToaster = () => {
        setShowToasterPopup(true);
        setTimeout(() => {
            setShowToasterPopup(false);
        }, 4000);
    };


    const fetchSubMenus = async () => {
        try {
            setIsLoading(true);
            const payload = {
                infraAssessmentMenuId: menuId,
            };
            const response = await getInfraAssessmentSubMenus(payload);
            if (response.status !== 200) {
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }
            setSubMenus(response.data);
            if (response.data.length > 0) {
                setActiveSubmenu({
                    submenuId: response.data[0].submenuId,
                    submenuName: response.data[0].submenuName
                })
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let toastData = {
                toastType: 'error',
                toastHeaderMessage: 'Error',
                toastBodyMessage: 'API failed'
            }
            setToastProp(toastData)
            setHideToast(false);
        }
    };

    const fetchQuestions = async (submenuId: string) => {
        try {
            setIsLoading(true);
            const payload = {
                organizationId: state.organizationId,
                verticalId: state.verticalId,
                menuId: menuId,
                submenuId: submenuId
            };

            const response = await getQuestionsForSubmenu(payload);

            if (response.status !== 200) {
                setIsLoading(false)
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }


            const fetchedQuestions = response.data.questions.map((question: any) => ({
                submenuId: submenuId,
                questionId: question.questionId,
                questionOrder: question.questionOrder,
                question: question.question,
                questionType: question.questionType,
                options: question.options,
                answeredOptionId: question.answeredOptionId
            }));

            setQuestions(fetchedQuestions);
            if (response.data.infraMigrationEnvironment !== "") {
                const infraArray = response.data.infraMigrationEnvironment.split(',');

                const questionOrder3 = response.data.questions.find((question: any) => question.questionOrder === 3);

                if (questionOrder3) {
                    const options = questionOrder3.options.map((option: any) => option.option);
                    setOtherOptions(options);
                    const differenceValue = infraArray.filter((option: any) => !options.includes(option));
                    setSpecifiedValue(differenceValue[0] ? differenceValue[0] : "")
                }
            }
            const otherOptionSelected = response.data.questions.find((question: any) => {
                return question.answeredOptionId.some((optionId: string) => {
                    const selectedOption = question.options.find((option: any) => option.optionId === optionId);
                    return selectedOption?.option === "Other.";
                });
            });

            if (otherOptionSelected) {
                setIsOtherOptionSelected(true);
                setSelectedQuestionIdForOtherOption(otherOptionSelected.questionId);
            } else {
                setIsOtherOptionSelected(false);
                setSelectedQuestionIdForOtherOption("");

            }

            const selectedOptionIds = response.data.questions.flatMap((question: any) => {
                return question.answeredOptionId.map((answeredId: string) => {
                    const selectedOption = question.options.find((option: any) => option.optionId === answeredId);
                    return {
                        questionId: question.questionId,
                        optionId: answeredId,
                        option: selectedOption.option,
                        score: selectedOption ? selectedOption.score : null,
                        type: question.questionType,
                        questionOrder: question.questionOrder
                    };
                });
            });

            setSelectedOptions(selectedOptionIds)
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let toastData = {
                toastType: 'error',
                toastHeaderMessage: 'Error',
                toastBodyMessage: 'API failed'
            }
            setToastProp(toastData)
            setHideToast(false);
        }
    };

    const handleSaveButton = async () => {
        setShowToasterLoading(true);
 
        setTimeout(async () => {
        const selectedOptionsCount = selectedOptions.reduce((totalCount: number, option: any) => {
            if (option.optionId !== "" && option.score !== "") {
                return totalCount + 1;
            }
            return totalCount;
        }, 0);
 
        if (selectedOptionsCount >= questions.length) {
            await SaveInfraQuestionnaireDetails();
        } else {
            let toastData = {
                toastType: 'error',
                toastHeaderMessage: 'Error',
                toastBodyMessage: 'Please answered for all the questions.'
            }
            setToastProp(toastData)
            setHideToast(false);
        }
        setShowToasterLoading(false);
    }, 2000);
        return;
    }

    const SaveInfraQuestionnaireDetails = async () => {

        try {
            const userAnsweredOptions = selectedOptions.filter((option) => {
                return option.optionId !== "" && option.score !== "" && option.score !== null && option.optionId !== null;
            });
            let awsService = "";
            let migrationEnvironments: string[] = [];
            let databaseSystems = "";
            if (activeSubmenu.submenuName === "Infra") {
                userAnsweredOptions.forEach(option => {
                    if (option.questionOrder === 1) {
                        awsService = option.option;
                    } else if (option.questionOrder === 2) {
                        databaseSystems = option.option;
                    } else if (option.questionOrder === 3) {
                        if (option.option !== "Other.") {
                            migrationEnvironments.push(option.option)
                        } else {
                            migrationEnvironments.push(specifiedValue)
                        }
                    }
                });
            }

            const payload = {
                organizationId: state.organizationId,
                verticalId: state.verticalId,
                menuId: menuId,
                submenuId: activeSubmenu.submenuId,
                awsService: awsService,
                databaseSystems: databaseSystems,
                migrationEnvironments: migrationEnvironments.join(","),
                userAnsweredDetails: userAnsweredOptions
            };
            setIsLoading(true)
            const response = await postInfraQuestionnaireDetails(payload);

            if (response.status == 200) {
                setIsLoading(false)
                invokeToaster();
            }
            if (response.status !== 200) {
                setIsLoading(false)
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }
            setShowToasterLoading(false);
        } catch (error) {
            setIsLoading(false)
            setShowToasterLoading(false);
            let toastData = {
                toastType: 'error',
                toastHeaderMessage: 'Error',
                toastBodyMessage: 'API failed'
            }
            setToastProp(toastData)
            setHideToast(false);
        }
    };
    const handleSubmenuClick = (submenuId: string, submenuName: string) => {
        setActiveSubmenu({
            submenuId: submenuId,
            submenuName: submenuName
        });
        setIsOtherOptionSelected(false);
        setSpecifiedValue("");
        setSelectedOptions([]);
    };

    const handleOptionChange = (
        questionId: string,
        option: string,
        optionId: string,
        optionScore: number | string,
        isChecked: boolean,
        questionType: "radio" | "checkbox",
        questionOrder: number
    ) => {
        setSelectedOptions(prevState => {
            let newState: SelectedOption[];
            if (isChecked) {
                if (questionType === "radio") {
                    newState = prevState.filter(
                        opt => !(opt.questionId === questionId && opt.type === questionType)
                    );
                } else {
                    newState = [...prevState];
                }

                newState.push({
                    questionId,
                    optionId,
                    option,
                    score: optionScore,
                    type: questionType,
                    questionOrder
                });
            } else {
                newState = prevState.filter(
                    opt => !(opt.questionId === questionId && opt.optionId === optionId && opt.type === questionType)
                );
            }
            return newState;
        });

        if (option === "Other.") {
            if (isChecked) {
                setIsOtherOptionSelected(true);
                setSelectedQuestionIdForOtherOption(questionId);
            } else {
                setIsOtherOptionSelected(false);
                setSelectedQuestionIdForOtherOption("");
                setSpecifiedValue(isChecked ? "" : "");
            }
        }
    };


    const handleInputChange = (event: any) => {
        setSpecifiedValue(event.target.value)
    }

    return (
        <>
            <>
                {hideToast ? <ToastComponent name={toastProp} /> : <></>}
            </>
            <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                <div className="tab-content" id="v-pills-tabContent">
                    <div className="tab-pane fade show active mb-5" role="tabpanel">
                        <div className="row">
                            <div className="tab-pane fade show active mb-5" role="tabpanel">
                                <div className="row sticky-top bg-white" >
                                    <div className="d-flex nav-tabs">
                                        <div className="nav border-tabs flex-nowrap table-responsive nav-scroll" role="tablist">
                                            {subMenus.map((submenu, index) => (
                                                <button
                                                    key={submenu.submenuId}
                                                    className={`nav-link text-nowrap ${index === 0 ? "active" : ""}`}
                                                    id={`${submenu.submenuName.toLowerCase().replace(/\s/g, "")}-tab`}
                                                    data-bs-toggle="tab"
                                                    data-bs-target={`#${submenu.submenuName.toLowerCase().replace(/\s/g, "")}`}
                                                    type="button"
                                                    role="tab"
                                                    aria-controls={`${submenu.submenuName.toLowerCase().replace(/\s/g, "")}`}
                                                    aria-selected={index === 0 ? "true" : "false"}
                                                    onClick={() => handleSubmenuClick(submenu.submenuId, submenu.submenuName)}
                                                >
                                                    {submenu.submenuName}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                {/* Tab content */}
                                <div className="tab-content" id="nav-UserExperience">
                                    <div className="tab-pane fade pt-4 active show mb-4" id="Businesstab" role="tabpanel">
                                        {questions.map((question, questionIndex) => (
                                            <>
                                                <div key={question.questionId} className={`mb-4 ${question.submenuId === activeSubmenu.submenuId ? "show active" : ""}`} id={`${question.questionId}`}>
                                                    <p className="font-16 font-regular">{questionIndex + 1 + ". " + question.question}</p>
                                                    {question.options.map((option, optionIndex) => (
                                                        <>
                                                            <div className="form-check ms-3">
                                                                <input type={question.questionType} className={`form-check-input theme-radio label-bold ${question.questionType === 'radio' ? 'theme-radio' : 'theme-check'}`} name={`ques${question.questionId}opt${option.optionId}`} value={`ques${question.questionId}opt${option.optionId}`} id={`ques${question.questionId}opt${option.optionId}`}
                                                                    checked={(selectedOptions.some(selectedOption =>
                                                                        selectedOption.questionId === question.questionId &&
                                                                        selectedOption.optionId === option.optionId)
                                                                    )}

                                                                    onChange={(e) => handleOptionChange(
                                                                        question.questionId,
                                                                        option.option,
                                                                        option.optionId,
                                                                        option.score,
                                                                        e.target.checked,
                                                                        question.questionType,
                                                                        question.questionOrder
                                                                    )} />
                                                                <label className="form-check-label mb-2 ms-2 font-14 font-regular" htmlFor={`ques${question.questionId}opt${option.optionId}`}>
                                                                    {option.option}
                                                                </label>
                                                            </div>
                                                        </>
                                                    ))}
                                                </div>
                                                {(isOtherOptionSeleted && selectedQuestionIdForOtherOption == question.questionId) ? <div className="ms-3 mb-4">
                                                    <input type="text" className="form-control theme-form font-regular font-14 w-50 w-md-100" placeholder="Please specify" value={specifiedValue} onChange={(e) => handleInputChange(e)} />
                                                </div> : <></>}
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <div className="fixed-bottom py-2 mx-3 Admin-footer bg-white">
                    <div className="d-grid d-md-flex justify-content-md-end mx-3 ">
                        <div className="button-container">
                            <button type="button" className="btn theme-primary-btn font-14 font-medium my-2" disabled={selectedOptions.length < questions.length || (isOtherOptionSeleted && specifiedValue.trim() === "")} onClick={() => handleSaveButton()}>Save</button>
                        </div>
                    </div>
                </div>
            </div >
            {/**Toaster Start end */}
            {showToasterLoading ? (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img
                                    src="images/loading.gif"
                                    alt="toast-success"
                                    style={{ height: "15px", width: "15px" }}
                                />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Saving...
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            {showToasterPopup ? (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/toast-success.svg" alt="toast-success" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Saved successfully.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            {/* toast end*/}
        </>
    );
};

export default Questionnaire;