import { useEffect, useState } from "react";
import {
  MigrationFormModel,
  PhaseData,
  PhaseDropDown,
  PlanSortOrderInterface,
  ProcessDropdown,
  ValidateMigrationFormModel,
  WeekDataState,
} from "../interface/InfraAssessmentModel";
import {
  DeleteMigrationPlanDetails,
  getMigrationPhaseDropdown,
  getMigrationPlans,
  getMigrationProcessDropdown,
  postMigrationPlanDetails,
} from "../service/InfraAssessmentApi";
import moment from "moment";

const MigrationPlan = (props: any) => {
  const { state, menuId, setIsLoading } = props;

  const isMDPW = state.verticalName === "MDPW";

  const isVMWare = state.verticalName === "VMware";

  const isMAP = state.verticalName === "MAP";

  const toastData: any = {
    toastType: "",
    toastHeaderMessage: "",
    toastBodyMessage: "",
  };

  let objectDetails: MigrationFormModel = {
    migrationpPlanDetailsId: "",
    migrationPhaseId: "",
    migrationPhaseName: "",
    migrationProcessId: "",
    processName: "",
    StartDate: "",
    EndDate: "",
  };
  // PS_MigrationPlan_04
  const [minDateAndRoundValue, setMinDateAndRoundValue] =
    useState<WeekDataState>({ minDate: new Date(), weekNumber: 1 });
  const [weeks, setWeeks] = useState<string[]>([]);
  const [showToasterPopup, setShowToasterPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showToasterFailedPopup, setShowToasterFailedPopup] = useState(false);
  const [showToasterLoading, setShowToasterLoading] = useState(false);
  const [phaseDropdown, setPhaseDropdown] = useState<PhaseDropDown[]>([]);
  const [gridData, setGridData] = useState<PhaseData[]>([]);
  const [editingProcessId, setEditingProcessId] = useState<string | null>(null);
  const [editedName, setEditedName] = useState<string>("");
  const [formDetails, setFormDetails] =
    useState<MigrationFormModel>(objectDetails);
  const [formDetailsValidationMsg, setFormDetailsValidationMsg] =
    useState<ValidateMigrationFormModel>({
      migrationPhaseId: "",
      processName: "",
      StartDate: "",
      EndDate: "",
    });
  const [planSortOrder, setPlanSortOrder] = useState<PlanSortOrderInterface>({
    phase: false,
    process: false,
  });
  const [toastProp, setToastProp] = useState<any>(toastData);
  const [hideToast, setHideToast] = useState<boolean>(true);
  const [originalPhaseId, setOriginalPhaseId] = useState<string>("");
  const [processDropdown, setProcessDropdown] = useState<ProcessDropdown[]>([]);
  const [itemToDelete, setItemToDelete] = useState<any>("");

  // PS_MigrationPlan_06
  useEffect(() => {
    getInitialPageData();
  }, []);

  const phaseOrder = ["Kickoff", "Mobilize", "Migrate", "Support"];
  const sortPhases = (a: any, b: any) => {
    return phaseOrder.indexOf(a.phase) - phaseOrder.indexOf(b.phase);
  };

  interface MigrationProcess {
    migrationPlanId: string;
    process: string;
    // Add other properties as needed
  }

  interface MigrationPhaseData {
    phase: string;
    processes: MigrationProcess[];
  }

  type OrderMap = {
    [key: string]: string[];
  };

  const orderMap: OrderMap = {
    kickoff: ["project kickoff and environment access procurement"],
    mobilize: [
      "landing zone design",
      "setup and configuration of landing zone",
      "landing zone walkthrough",
      "segregating workloads by batch",
    ],
    migrate: [
      "pilot batch migration",
      "testing and validation of pilot batch",
      "pilot batch cutover and signoff",
      "batch migration",
      "testing and validation of batch",
      "batch cutover and signoff",
    ],
    support: [
      "project signoff & post production support",
      "project closure email and customer success email",
    ],
  };

  const sortMigrationPlanProcess = (
    processDetails: any[],
    columnName: string,
    isReversed: boolean
  ): any[] => {
    let sortedProcessDetails = [...processDetails];
  
    if (columnName === "process") {
      sortedProcessDetails.sort((a: any, b: any) => {
        const comparison = a.processOrder - b.processOrder;
        return isReversed ? -comparison : comparison;
      });
    } else {
      // Default sorting by process name if not sorting by process order
      sortedProcessDetails.sort((a: any, b: any) => {
        const comparison = a.process.localeCompare(b.process);
        return isReversed ? -comparison : comparison;
      });
    }
  
    return sortedProcessDetails;
  };


const sortMigrationPlan = (columnName: string) => {
  let sortedMigrationPlan: PhaseData[];

  if (columnName === "phase") {
    // Toggle the sort order first
    const newPhaseOrder = !planSortOrder.phase;
    setPlanSortOrder({ ...planSortOrder, phase: newPhaseOrder });

    const sortedPhases = [...gridData].sort((a: any, b: any) => {
      const comparison = a.phaseOrder - b.phaseOrder;
      return newPhaseOrder ? -comparison : comparison;
    });
    sortedMigrationPlan = sortedPhases.map((phase: any) => ({
      ...phase,
      processes: sortMigrationPlanProcess(phase.processes, "process", planSortOrder.process)
    }));

    setGridData(sortedMigrationPlan);
  } else {
    // Toggle the sort order first
    const newProcessOrder = !planSortOrder.process;
    setPlanSortOrder({ ...planSortOrder, process: newProcessOrder });

    // Sort processes within each phase based on their process order
    sortedMigrationPlan = gridData.map((element) => ({
      ...element,
      processes: sortMigrationPlanProcess(element.processes, columnName, newProcessOrder)
    }));

    setGridData(sortedMigrationPlan);
  }
};

  const closeModal = () => {
    setShowDeleteModal(false);
  };

  const getInitialPageData = async () => {
    const payload = {
      organizationId: state.organizationId,
      verticalId: state.verticalId,
      projectId: state.projectId,
    };

    const phasepayload = {
      verticalId: state.verticalId,
      projectId:state.projectId
    };
    try {
      setIsLoading(true);
      const response = await getMigrationPhaseDropdown(phasepayload);
      if (response.status === 200) {
        setPhaseDropdown(response.data);
      } else {
        let toastData = {
          toastType: "error",
          toastHeaderMessage: "Error",
          toastBodyMessage: "API failed",
        };
        setToastProp(toastData);
        setHideToast(false);
      }
      const gridApiResponse = await getMigrationPlans(payload);
      if (gridApiResponse.status === 200) {
        const sortedData = gridApiResponse.data.phases.sort((a: any, b: any) => a.phaseOrder - b.phaseOrder) // Sort phases by phaseOrder
        .map((phase: any) => ({
            ...phase,
            processes: phase.processes.sort((p1: any, p2: any) => p1.processOrder - p2.processOrder) // Sort processes by processOrder
        }));
        setGridData(sortedData);
      } else {
        let toastData = {
          toastType: "error",
          toastHeaderMessage: "Error",
          toastBodyMessage: "API failed",
        };
        setToastProp(toastData);
        setHideToast(false);
      }

      const processResponse = await getMigrationProcessDropdown();
      if (processResponse.status === 200) {
        setProcessDropdown(processResponse.data);
      } else {
        setIsLoading(false);
        let toastData = {
          toastType: "error",
          toastHeaderMessage: "Error",
          toastBodyMessage: "API failed",
        };
        setToastProp(toastData);
        setHideToast(false);
      }
      setIsLoading(false);
    } catch (error) {
      // console.log("Error fetching dropdown", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const weekData = updateWeeks();
    setMinDateAndRoundValue(weekData);
  }, [gridData]);

  const extractDatesFromProcesses = (data: PhaseData[]) => {
    let datesArray: string[] = [];

    data.forEach((phase) => {
      phase.processes.forEach((process) => {
        let startDate = moment(process.startDate);
        let endDate = moment(process.endDate);
        datesArray.push(startDate.format("YYYY-MM-DD"));
        datesArray.push(endDate.format("YYYY-MM-DD"));
      });
    });
    return datesArray;
  };

  // PS_MigrationPlan_49 - PS_MigrationPlan_69
  const updateWeeks = () => {
    const allDates = extractDatesFromProcesses(gridData);

    const uniqueDates = Array.from(new Set(allDates));
    const dateObjects: Date[] = uniqueDates.map((date) => new Date(date));

    let minDate = new Date(dateObjects[0]);
    let maxDate = new Date(dateObjects[0]);

    dateObjects.forEach((date) => {
      if (date < minDate) minDate = new Date(date);
      if (date > maxDate) maxDate = new Date(date);
    });

    let weeks: string[] = [];
    let currentDate: Date = new Date(minDate);
    const millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7; // Milliseconds in a week
    // const weeksBetween = Math.ceil((maxDate.getTime() - minDate.getTime()) / millisecondsPerWeek);
    const weeksBetween = getWeekIndex(maxDate, minDate);

    let roundedIncrement;

    const endWeekIndex = getWeekIndex(maxDate, minDate);

    if (weeksBetween % 4 != 0) {
      roundedIncrement = Math.ceil(weeksBetween / 4);
    } else {
      roundedIncrement = Math.round(weeksBetween / 4);
    }

    let weekNumber: number;
    if (roundedIncrement == 0) {
      roundedIncrement = 1;
      weekNumber = roundedIncrement;
    } else {
      weekNumber = roundedIncrement;
    }

    for (let i = 1; i <= 4; i++) {
      weeks.push(`week ${weekNumber}`);
      currentDate.setDate(currentDate.getDate() + 7);
      weekNumber += roundedIncrement;
    }

    setWeeks(weeks);
    return { minDate: minDate, weekNumber: roundedIncrement };
  };

  const getWeekIndex = (date: Date, minDate: Date): number => {
    const startOfWeek = new Date(minDate);
    const targetDate = new Date(date);

    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
    targetDate.setDate(targetDate.getDate() - targetDate.getDay());

    const weeksDiff = Math.round(
      (targetDate.getTime() - startOfWeek.getTime()) / (7 * 24 * 60 * 60 * 1000)
    );

    return weeksDiff + 1;
  };

  // PS_MigrationPlan_70 -  PS_MigrationPlan_76
  const getWeekRange = (
    startDate: Date,
    endDate: Date,
    process: any,
    minDate: Date,
    weekNumber: number
  ): any => {
    const startWeekIndex = getWeekIndex(startDate, minDate);
    const endWeekIndex = getWeekIndex(endDate, minDate);

    if (startWeekIndex !== -1 && endWeekIndex !== -1) {
      const totalBlocks = 4;
      const weeksPerBlock = weekNumber;

      const startBlock = Math.floor((startWeekIndex - 1) / weeksPerBlock);
      const endBlock = Math.floor((endWeekIndex - 1) / weeksPerBlock);

      const marginLeft = (startBlock / totalBlocks) * 100;
      const marginRight = ((totalBlocks - endBlock - 1) / totalBlocks) * 100;

      if (startWeekIndex === endWeekIndex) {
        return {
          weekRange: `Week ${startWeekIndex}`,
          marginLeft: `${marginLeft}%`,
          marginRight: `${marginRight}%`,
        };
      } else {
        return {
          weekRange: `Week ${startWeekIndex} - Week ${endWeekIndex}`,
          marginLeft: `${marginLeft}%`,
          marginRight: `${marginRight}%`,
        };
      }
    } else {
      return {};
    }
  };

  const handleBindingWeekRange = (process: any, index: any) => {
    const startDate = new Date(process.startDate);
    const endDate = new Date(process.endDate);

    const weekData = getWeekRange(
      startDate,
      endDate,
      process,
      minDateAndRoundValue.minDate,
      minDateAndRoundValue.weekNumber
    );

    return (
      <td
        key={`week-range-${index}`}
        colSpan={4}
        className="my-2 px-0 py-2 position-relative"
      >
        <span
          className="d-block text-nowrap text-start font-13 cal-blue-label cal-label-sty"
          style={{
            marginLeft: weekData.marginLeft,
            marginRight: weekData.marginRight,
          }}
        >
          {weekData.weekRange}
        </span>
      </td>
    );
  };
  // PS_MigrationPlan_28
  const bindPhaseDropdown = () => {
    return phaseDropdown.map((data) => (
      <option key={data.migrationPhaseId} value={data.migrationPhaseId}>
        {data.phaseName}
      </option>
    ));
  };

  // PS_MigrationPlan_83
  const onChangeFormData = async (event: any) => {
    const { id, value } = event.target;

    let validationKey: any;
    switch (id) {
      case "StartDate":
        validationKey = "StartDate";
        break;
      case "EndDate":
        validationKey = "EndDate";
        break;
      case "migrationPhaseName":
        validationKey = "migrationPhaseId";
        break;
      case "processName":
        validationKey = "processName";
        break;
      default:
        validationKey = id;
        break;
    }

    setFormDetailsValidationMsg((prevMsg) => ({
      ...prevMsg,
      [validationKey]: "",
    }));

    setFormDetails((prevData) => ({
      ...prevData,
      [id]: value,
    }));

    if (id === "migrationPhaseName") {
      if (isMDPW || isVMWare) {
        const matchingPhase = phaseDropdown.find(
          (phase) =>
            phase.phaseName.toLowerCase().trim() === value.toLowerCase().trim()
        );
        if (value !== formDetails.migrationPhaseName) {
          setOriginalPhaseId("");
        }
        setFormDetails((prevData) => ({
          ...prevData,
          migrationPhaseId:
            originalPhaseId ||
            (matchingPhase ? matchingPhase.migrationPhaseId : ""),
          migrationPhaseName: value,
        }));
      } else if (isMAP) {
        // For VMware, handle dropdown selection
        const selectedPhase = phaseDropdown.find(
          (phase) => phase.migrationPhaseId === value
        );
        setFormDetails((prevData) => ({
          ...prevData,
          migrationPhaseId: value,
          migrationPhaseName: selectedPhase ? selectedPhase.phaseName : "",
        }));
      }
    } else if (id === "processName") {
      if (isMDPW || isVMWare) {
        const matchingProcess = processDropdown.find(
          (process) =>
            process.processName.toLowerCase().trim() ===
            value.toLowerCase().trim()
        );
        setFormDetails((prevData) => ({
          ...prevData,
          migrationProcessId: matchingProcess
            ? matchingProcess.migrationProcessId
            : "",
          processName: value,
        }));
      } else if (isMAP) {
        const selectedProcess = processDropdown.find(
          (process) => process.migrationProcessId === value
        );
        setFormDetails((prevData) => ({
          ...prevData,
          processName: selectedProcess ? selectedProcess.processName : "",
          migrationProcessId: value,
        }));
      }
    } else {
      setFormDetails((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }

    if (id === "StartDate" || id === "EndDate") {
      const startDate = id === "StartDate" ? value : formDetails.StartDate;
      const endDate = id === "EndDate" ? value : formDetails.EndDate;

      if (startDate && endDate) {
        if (new Date(startDate) >= new Date(endDate)) {
          setFormDetailsValidationMsg((prevMsg) => ({
            ...prevMsg,
            StartDate: "Start Date should be before End Date",
            EndDate: "End Date should be after Start Date",
          }));
        } else {
          setFormDetailsValidationMsg((prevData) => ({
            ...prevData,
            StartDate: "",
            EndDate: "",
          }));
        }
      }
    }
  };

  // PS_MigrationPlan_91 -  PS_MigrationPlan_98
  const onSubmitValidateData = (): boolean => {
    let isValid = true;

    let cloneObj = {
      migrationPhaseId: "",
      processName: "",
      StartDate: "",
      EndDate: "",
    };

    if (isMDPW || isVMWare) {
      if (formDetails.migrationPhaseName.trim() === "") {
        cloneObj.migrationPhaseId = "Required";
        isValid = false;
      }
    } else if (isMAP) {
      if (formDetails.migrationPhaseId === "") {
        cloneObj.migrationPhaseId = "Required";
        isValid = false;
      }
    }

    if (isMAP) {
      if (formDetails.migrationProcessId === "") {
        cloneObj.processName = "Required";
        isValid = false;
      }
    } else {
      if (formDetails.processName.trim() === "") {
        cloneObj.processName = "Required";
        isValid = false;
      }
    }

    if (formDetails.StartDate === "") {
      cloneObj.StartDate = "Required";
      isValid = false;
    }

    if (formDetails.EndDate === "") {
      cloneObj.EndDate = "Required";
      isValid = false;
    }

    if (formDetails.StartDate && formDetails.EndDate) {
      const startDate = new Date(formDetails.StartDate);
      const endDate = new Date(formDetails.EndDate);

      if (startDate >= endDate) {
        cloneObj.StartDate = "Start Date should be before End Date";
        cloneObj.EndDate = "End Date should be after Start Date";
        isValid = false;
      }
    }

    setFormDetailsValidationMsg(cloneObj);
    return isValid;
  };

  // PS_MigrationPlan_90 -  PS_MigrationPlan_119
  const onSubmitFormData = async () => {
    if (onSubmitValidateData()) {
      let payloadData = {
        migrationpPlanDetailsId: formDetails.migrationpPlanDetailsId,
        organizationId: state.organizationId,
        verticalId: state.verticalId,
        projectId: state.projectId,
        MenuId: menuId,
        migrationPhaseId: formDetails.migrationPhaseId,
        migrationPhaseName: formDetails.migrationPhaseName,
        migrationProcessId: formDetails.migrationProcessId,
        processName: formDetails.processName,
        startDate: formDetails.StartDate,
        endDate: formDetails.EndDate,
      };
      setShowToasterLoading(true);

      try {
        let submitApiResponse = await postMigrationPlanDetails(payloadData);

        if (submitApiResponse.status === 200) {
          setShowToasterPopup(true);
          setIsLoading(true);
        } else {
          setIsLoading(false);
          let toastData = {
            toastType: "error",
            toastHeaderMessage: "Error",
            toastBodyMessage: "API failed",
          };
          setToastProp(toastData);
          setHideToast(false);
        }
      } catch (error) {
        console.error("Error submitting form data", error);
        setShowToasterFailedPopup(true);
        setIsLoading(false);
      } finally {
        setShowToasterLoading(false);
        setTimeout(() => {
          setShowToasterPopup(false);
          setShowToasterFailedPopup(false);
        }, 4000);
        setFormDetails(objectDetails);
        resetForm();
      }
    }
  };

  // PS_MigrationPlan_135 -  PS_MigrationPlan_157
  const handleEditStart = (
    planid: string,
    process: string,
    event: React.MouseEvent
  ) => {
    event.preventDefault();

    let editProcess: any;
    let editPhase: any;
    for (const phase of gridData) {
      editProcess = phase.processes.find((p) => p.migrationPlanId === planid);
      if (editProcess) {
        editPhase = phase;
        break;
      }
    }

    if (editProcess && editPhase) {
      if (isMDPW || isVMWare) {
        setOriginalPhaseId(editPhase.phaseId.toString());
        setFormDetails({
          migrationpPlanDetailsId: planid,
          migrationPhaseId: editPhase.phaseId.toString(),
          migrationPhaseName: editPhase.phase.toString(),
          migrationProcessId: "",
          processName: editProcess.process,
          StartDate: moment(editProcess.startDate).format("YYYY-MM-DD"),
          EndDate: moment(editProcess.endDate).format("YYYY-MM-DD"),
        });
      } else if (isMAP) {
        const matchingPhase = phaseDropdown.find(
          (phase) =>
            phase.phaseName.toLowerCase().trim() ===
            editPhase.phase.toLowerCase().trim()
        );
        const matchingProcess = processDropdown.find(
          (process) =>
            process.processName.toLowerCase().trim() ===
            editProcess.process.toLowerCase().trim()
        );
        setFormDetails({
          migrationpPlanDetailsId: planid,
          migrationPhaseId: matchingPhase ? matchingPhase.migrationPhaseId : "",
          migrationPhaseName: editPhase.phase.toString(),
          migrationProcessId: matchingProcess
            ? matchingProcess.migrationProcessId
            : "",
          processName: editProcess.process,
          StartDate: moment(editProcess.startDate).format("YYYY-MM-DD"),
          EndDate: moment(editProcess.endDate).format("YYYY-MM-DD"),
        });
      }

      const formElement = document.getElementById("migrate");
      // Programmatically click the button to show the collapse only if it's not already shown
      if (formElement && !formElement.classList.contains("show")) {
        const collapseButton = document.querySelector(
          'button[data-bs-target="#migrate"]'
        ) as HTMLElement;
        collapseButton?.click();
      }
      // Optionally scroll to the top of the form
      if (formElement) {
        formElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } else {
      setEditingProcessId(planid);
      setEditedName(process);
    }
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedName(e.target.value);
  };

  const handleClear = () => {
    // Reset form details to default/initial state
    setFormDetails({
      migrationpPlanDetailsId: "",
      migrationPhaseId: "",
      migrationPhaseName: "",
      migrationProcessId: "",
      processName: "",
      StartDate: "",
      EndDate: "",
    });
    setFormDetailsValidationMsg({
      migrationPhaseId: "",
      processName: "",
      StartDate: "",
      EndDate: "",
    });
  };

  const handleEditSave = async (migrationpPlanDetailsId: string) => {
    const updatedPhases = gridData.map((phase) => ({
      ...phase,
      processes: phase.processes.map((process) =>
        process.migrationPlanId === migrationpPlanDetailsId
          ? {
            ...process,
            process: editedName,
            startDate: formDetails.StartDate,
            endDate: formDetails.EndDate,
          }
          : process
      ),
    }));

    let editedProcess;
    let phaseId;
    let phaseName: any;

    for (const phase of updatedPhases) {
      editedProcess = phase.processes.find(
        (process) => process.migrationPlanId === migrationpPlanDetailsId
      );
      if (editedProcess) {
        phaseId = phase.phaseId;
        phaseName = phase.phase;
        break;
      }
    }

    if (!editedProcess) {
      setShowToasterLoading(false);
      return;
    }

    const matchingPhase = phaseDropdown.find(
      (phase) =>
        phase.phaseName.toLowerCase().trim() === phaseName.toLowerCase().trim()
    );

    const payloadData = {
      migrationpPlanDetailsId: migrationpPlanDetailsId,
      organizationId: state.organizationId,
      verticalId: state.verticalId,
      projectId: state.projectId,
      migrationPhaseId: matchingPhase ? matchingPhase.migrationPhaseId : "",
      migrationPhaseName: phaseName,
      processName: editedName,
      startDate: formDetails.StartDate,
      endDate: formDetails.EndDate,
      menuId: menuId,
    };

    setShowToasterLoading(true);

    try {
      const submitApiResponse = await postMigrationPlanDetails(payloadData);

      if (submitApiResponse.status === 200) {
        setIsLoading(true);
        setShowToasterPopup(true);
        setGridData(updatedPhases);
        setEditingProcessId(null);
      } else {
        setIsLoading(false);
        let toastData = {
          toastType: "error",
          toastHeaderMessage: "Error",
          toastBodyMessage: "API failed",
        };
        setToastProp(toastData);
        setHideToast(false);
      }
    } catch (error) {
      console.error("Error updating grid data", error);
      setIsLoading(false);
    } finally {
      setShowToasterLoading(false);
      setTimeout(() => {
        setShowToasterPopup(false);
        setShowToasterFailedPopup(false);
      }, 4000);
      resetForm();
    }
  };

  // PS_MigrationPlan_121
  const handleDelete = async (migrationPlanId: any) => {
    setItemToDelete(migrationPlanId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    setIsLoading(true);
    const payloadData = {
      organizationId: state.organizationId,
      verticalId: state.verticalId,
      projectId: state.projectId,
      migrationPlanId: itemToDelete,
      infraAssessmentMenuId: menuId,
    };

    try {
      const submitApiResponse = await DeleteMigrationPlanDetails(payloadData);
      if (submitApiResponse.status === 200) {
        setShowDeletePopup(true);
        resetForm();
      } else {
        setIsLoading(false);
        let toastData = {
          toastType: "error",
          toastHeaderMessage: "Error",
          toastBodyMessage: "API failed",
        };
        setToastProp(toastData);
        setHideToast(false);
      }
    } catch (error) {
      setShowToasterFailedPopup(true);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setShowDeletePopup(false);
        setShowToasterFailedPopup(false);
      }, 4000);
      resetForm();
    }
  };

  const handleEditCancel = () => {
    setEditingProcessId(null);
    setEditedName("");
  };

  const resetForm = async () => {
    await getInitialPageData();
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <p className="font-20 font-semibold color-black mt-3 mb-4">{isVMWare ? "Timeline" : "Roadmap"}</p>
          <button
            type="button"
            className="btn theme-secondary-btn-sm col-md-12 my-12 detailes-btn shadow-none font-16 font-medium color-black collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#migrate"
            aria-expanded="false"
          >
            <img
              src="images/button-arrow-icon.svg"
              alt="button-arrow-icon"
              className="me-2 btn-arrow float-start cursor-pointer  accordion-arrow collapsed mt-2"
              data-bs-target="#migrate"
              data-bs-toggle="collapse"
              aria-expanded="false"
            />

            <span className="float-start">Add New Details</span>
          </button>

          <div
            className="row bg-gray p-3 mx-0 rounded-2 my-3 collapse"
            id="migrate"
          >
            <p className="font-16  color-black font-medium">Add New Details</p>
            <div className="col-md-3">
              <div className="mb-5">
                <label
                  htmlFor="migrationPhaseName"
                  className="form-label font-14 font-semibold color-black"
                >
                  Enter Phase
                </label>
                {isMDPW || isVMWare ? (
                  <input
                    type="text"
                    className="form-control theme-form font-regular font-14"
                    id="migrationPhaseName"
                    placeholder="Enter Phase"
                    onChange={onChangeFormData}
                    value={formDetails.migrationPhaseName}
                  />
                ) : isMAP ? (
                  <select
                    className="form-select theme-form font-regular font-14"
                    id="migrationPhaseName"
                    onChange={onChangeFormData}
                    value={formDetails.migrationPhaseId}
                  >
                    <option value="">Select Phase</option>
                    {bindPhaseDropdown()}
                  </select>
                ) : null}
                <p className="mt-2 mb-0 color-red font-14 font-regular">
                  {formDetailsValidationMsg.migrationPhaseId}
                </p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-5">
                <label
                  htmlFor="EnterProcess"
                  className="form-label font-14 font-semibold color-black"
                >
                  Enter Process
                </label>

                {isMAP ? (
                  <select
                    className="form-select theme-form font-regular font-14"
                    id="processName"
                    onChange={onChangeFormData}
                    value={formDetails.migrationProcessId}
                  >
                    <option value="">Select Process</option>
                    {processDropdown.map((process) => (
                      <option
                        key={process.migrationProcessId}
                        value={process.migrationProcessId}
                      >
                        {process.processName}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    className="form-control theme-form font-regular font-14"
                    id="processName"
                    placeholder="Enter Process"
                    onChange={onChangeFormData}
                    value={formDetails.processName}
                    maxLength={225}
                  />
                )}
                <p className="mt-2 mb-0 color-red font-14 font-regular">
                  {formDetailsValidationMsg.processName}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-5">
                <label
                  htmlFor="StartWeek"
                  className="form-label font-14 font-semibold color-black"
                >
                  Start Week
                </label>
                <input
                  type="date"
                  className="form-control theme-form font-regular font-14"
                  id="StartDate"
                  placeholder="mm/dd/yyy"
                  onChange={(e) => onChangeFormData(e)}
                  value={formDetails.StartDate}
                />
                <p className="mt-2 mb-0 color-red font-14 font-regular">
                  {formDetailsValidationMsg.StartDate}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-5">
                <label
                  htmlFor="EndWeek"
                  className="form-label font-14 font-semibold color-black"
                >
                  End Week
                </label>
                <input
                  type="date"
                  className="form-control theme-form font-regular font-14"
                  id="EndDate"
                  placeholder="mm/dd/yyy"
                  onChange={(e) => onChangeFormData(e)}
                  value={formDetails.EndDate}
                />
                <p className="mt-2 mb-0 color-red font-14 font-regular">
                  {formDetailsValidationMsg.EndDate}
                </p>
              </div>
            </div>
            <div className="d-grid gap-3 d-md-inline-flex justify-content-md-end">
              <a
                className="theme-link-btn font-16 font-semibold text-center order-md-first cursor-pointer"
                onClick={handleClear}
              >
                Clear
              </a>
              <button
                type="button"
                className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold px-4 mb-3 "
                onClick={() => onSubmitFormData()}
              >
                Save Details
              </button>
            </div>
          </div>
          {/* <p className="font-20 font-semibold color-black mt-3 mb-4">{isVMWare ? "Migration Plan and Timeline" : "Roadmap"}</p> */}
          <div className="border rounded-3 p-3 mb-5 mt-5">
            <span className="d-flex align-items-center font-16 font-medium color-black-v1">
              <img
                src="images/accordion-arrow.svg"
                alt="accordion-arrow"
                className="me-2 btn-arrow float-start mt-2"
              />
              View Instructions
            </span>
            <div className="px-3 collapse show" id="crmDev" style={{}}>
              <ul className="pb-0 mt-3 mb-2">
                <li className="font-14 font-medium color-grey-v3">
                  To modify or update process information, simply hover over the
                  process and choose the option to Edit or Delete it.
                </li>
              </ul>
            </div>
          </div>
          {gridData.length != 0 && (
            <div className="table-responsive calender-scrl d-none d-md-block my-3">
              <table className="table table-borderless themeTable mig-strategy mb-0">
                <thead className="font-semibold ">
                  <tr>
                    <th className="border-bottom ps-4 table-primaryrs-th">
                      Phase
                      <span
                        className="ms-2 cursor-pointer"
                        onClick={() => {
                          setPlanSortOrder(prevState => ({ ...prevState, phase: !prevState.phase }));
                          // setPlanSortOrder({ ...planSortOrder, phase: !planSortOrder.phase });
                          sortMigrationPlan("phase")}}
                      >
                        {" "}
                        <img
                          src={
                            planSortOrder.phase
                              ? "images/sortarrowup.svg"
                              : "images/sortarrow.svg"
                          }
                          alt="sortarrow"
                          title="Sort"
                        />
                      </span>
                    </th>
                    <th className="border-bottom ps-4 table-riskseverity-th">
                      Process
                      <span
                        className="ms-2 cursor-pointer"
                        onClick={() => {
                          setPlanSortOrder(prevState => ({ ...prevState, process: !prevState.process }));
                          // setPlanSortOrder({ ...planSortOrder, process: !planSortOrder.process });
                          sortMigrationPlan("process")}}
                      >
                        <img
                          src={
                            planSortOrder.process
                              ? "images/sortarrowup.svg"
                              : "images/sortarrow.svg"
                          }
                          alt="sortarrow"
                          title="Sort"
                        />
                      </span>
                    </th>
                    {weeks.map((week, index) => (
                      <th key={index} className="border-bottom ps-4">
                        {week}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="font-medium">
                  {gridData
                    .map((phaseData: PhaseData, phaseIndex: number) =>
                      phaseData.processes
                        .sort((a: MigrationProcess, b: MigrationProcess) => {
                          const indexA =
                            orderMap[phaseData.phase]?.indexOf(a.process) ?? -1;
                          const indexB =
                            orderMap[phaseData.phase]?.indexOf(b.process) ?? -1;

                          // If both processes are not in orderMap, maintain original order
                          if (indexA === -1 && indexB === -1) return 0;

                          // If one process is not in orderMap, put it last
                          if (indexA === -1) return 1;
                          if (indexB === -1) return -1;

                          return indexA - indexB;
                        })
                        .map(
                          (process: MigrationProcess, processIndex: number) => (
                            <tr key={`${phaseIndex}-${processIndex}`}>
                              {processIndex === 0 && (
                                <td
                                  rowSpan={phaseData.processes.length}
                                  className="ps-4 text-wrap table-primaryrs-td tbl-brd-right"
                                >
                                  {phaseData.phase}
                                </td>
                              )}
                              {editingProcessId === process.migrationPlanId ? (
                                <td className="text-wrap table-riskseverity-td rs-edit-txt tbl-brd-right ">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <input
                                      type="text"
                                      className="border-0 shadow-none bg-transparent form-control font-regular font-14 color-black shadow-none"
                                      id={process.migrationPlanId}
                                      onChange={(e) => handleEditChange(e)}
                                      value={editedName}
                                      maxLength={225}
                                    />
                                    <span className="d-flex align-items-center rs-action-btn">
                                      <img
                                        src="images/edit-tick-icon.svg"
                                        alt="edit-tick-icon"
                                        className="cursor-pointer me-2 rs-tbl-icon"
                                        onClick={() =>
                                          handleEditSave(
                                            process.migrationPlanId
                                          )
                                        }
                                      />
                                      <img
                                        src="images/edit-close-icon.svg"
                                        alt="edit-close-icon"
                                        className="cursor-pointer rs-tbl-icon"
                                        onClick={() => handleEditCancel()}
                                      />
                                    </span>
                                  </div>
                                </td>
                              ) : (
                                <td className="text-wrap table-riskseverity-td tbl-brd-right">
                                  <div className="d-flex align-items-center justify-content-between">
                                    {process.process}
                                    <span className="d-flex align-items-center rs-action-btn ">
                                      <img
                                        src="images/editIcon.svg"
                                        alt="editIcon"
                                        className="cursor-pointer me-2 rs-tbl-icon action-btn-hover"
                                        onClick={(event) =>
                                          handleEditStart(
                                            process.migrationPlanId,
                                            process.process,
                                            event
                                          )
                                        }
                                      />

                                      <img
                                        src="images/delete-icon.svg"
                                        alt="delete-icon"
                                        className="cursor-pointer rs-tbl-icon"
                                        onClick={() =>
                                          handleDelete(process.migrationPlanId)
                                        }
                                      />
                                    </span>
                                  </div>
                                </td>
                              )}
                              {handleBindingWeekRange(process, processIndex)}
                            </tr>
                          )
                        )
                    )}
                </tbody>
              </table>
            </div>
          )}

          {gridData.length == 0 && (
            <div className="table-responsive calender-scrl d-none d-md-block my-3">
              <table className="table table-borderless themeTable mig-strategy mb-0">
                <thead className="font-semibold ">
                  <tr>
                    <th className="border-bottom ps-4 table-primaryrs-th">
                      Phase
                      <span className="ms-2 cursor-pointer">
                        <img
                          src="images/sortarrow.svg"
                          alt="sortarrow"
                          title="Sort"
                        />
                      </span>
                    </th>
                    <th className="border-bottom ps-4 table-riskseverity-th">
                      Process
                      <span className="ms-2 cursor-pointer">
                        <img
                          src="images/sortarrow.svg"
                          alt="sortarrow"
                          title="Sort"
                        />
                      </span>
                    </th>
                    <th className="border-bottom ps-4">week 1</th>
                    <th className="border-bottom ps-4">week 2</th>
                    <th className="border-bottom ps-4">week 3</th>
                    <th className="border-bottom ps-4">week 4</th>
                  </tr>
                </thead>
                <tbody className="font-medium">
                  <tr>
                    <td colSpan={6} className="text-center py-5">
                      <img
                        src="images/no-records-found.svg"
                        alt="grid-nodata-found-img"
                        className="grid-no-rec-img mt-5"
                      />
                      <p className="font-18 font-medium color-grey-v1 my-4 ">
                        No Records Found
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      {showToasterLoading ? (
        <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
          <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
            <div className="toast-body d-flex align-items-center ">
              <div className="d-block me-3">
                <img
                  src="images/loading.gif"
                  alt="toast-success"
                  style={{ height: "15px", width: "15px" }}
                />
              </div>
              <div className="d-block">
                <span className="font-medium font-16 color-grey">
                  Saving...
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showToasterPopup ? (
        <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
          <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
            <div className="toast-body d-flex align-items-center ">
              <div className="d-block me-3">
                <img src="images/toast-success.svg" alt="toast-success" />
              </div>
              <div className="d-block">
                <span className="font-medium font-16 color-grey">
                  Process has been added successfully
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showToasterFailedPopup ? (
        <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
          <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
            <div className="toast-body d-flex align-items-center ">
              <div className="d-block me-3">
                <img src="images/fail-icon.svg" alt="toast-success" />
              </div>
              <div className="d-block">
                <span className="font-medium font-16 color-grey">
                  Api failed.
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showDeletePopup ? (
        <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
          <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
            <div className="toast-body d-flex align-items-center ">
              <div className="d-block me-3">
                <img src="images/toast-success.svg" alt="toast-success" />
              </div>
              <div className="d-block">
                <span className="font-medium font-16 color-grey">
                  Process has been Deleted successfully.
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showDeleteModal && (
        <div
          className="modal fade show"
          id="delete"
          tabIndex={-1}
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-3">
              <div className="modal-header pb-0 border-0">
                <button
                  type="button"
                  className="btn-close cursor-pointer"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <div className="w-100 text-center mb-3">
                  <img src="images/delete-icon.svg" alt="delete" />
                </div>

                <p className="font-semibold font-24 text-center mb-2 red-400">
                  Delete Confirmation
                </p>

                <p className="font-semibold font-16 text-center mb-5">
                  Are you sure you want to delete?
                </p>

                <div className="d-grid gap-2 d-md-inline-flex justify-content-md-center mb-2 mb-md-0 w-100">
                  <a
                    className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer px-4"
                    // onClick={confirmDelete}
                    onClick={() => {
                      closeModal();
                      confirmDelete();
                    }}
                  >
                    Yes
                  </a>

                  <button
                    type="button"
                    className="btn btn-danger red-400 btn-lg px-4 text-white font-14 font-semibold"
                    onClick={closeModal}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showDeleteModal && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default MigrationPlan;
