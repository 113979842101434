import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, Cell } from 'recharts';
import { controlAreasImpactModel, impactModel } from '../../interface/WafrReportModel';


const SectionVerticalBarChart = (props:any) => {

    let {data} = props
    data=data.map((values:any)=>{
        return({
            name: values.name,
            "High Impact Count": values.highImpactCount,
            "Medium Impact Count": values.mediumImpactCount,
            "Low Impact Count": values.lowImpactCount,
        }
        )
    })
    const getBarTotal = (entry:any) => {
        return entry["High Impact Count"] + entry["Medium Impact Count"] + entry["Low Impact Count"];
    };
 

    return(<BarChart
        width={1000}
        height={400}
        data={data}
        margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 70,
        }}
        barSize={30}
    >
        <XAxis
            dataKey="name"
            angle={0}
            textAnchor="middle"
            height={70}
            interval={0}
            tickLine={false}
            axisLine={{ stroke: '#999', strokeWidth: 0.5 }}
            style={{ fontSize: 13, fontWeight: '900px', fill: 'rgb(33, 37, 41)', fontFamily: "sans-serif" }}
        />
        <YAxis
            domain={[0, 24]}
            tickLine={false}
            axisLine={{ stroke: '#999', strokeWidth: 0.5 }}
            style={{ fontSize: 13, fontWeight: '900px', fill: 'rgb(33, 37, 41)', fontFamily: "sans-serif" }}
        />
        <Tooltip cursor={{ fill: 'transparent' }} />

        <Bar dataKey="High Impact Count" stackId="a" fill="#ff7f7f">
            {data.map((entry:controlAreasImpactModel, index:number) => (
                <Cell key={`cell-${index}`} fill="#ff7f7f" />
            ))}
        </Bar>
        <Bar dataKey="Medium Impact Count" stackId="a" fill="#ffd700">
            {data.map((entry:controlAreasImpactModel, index:number) => (
                <Cell key={`cell-${index}`} fill="#ffd700" />
            ))}
        </Bar>
        <Bar dataKey="Low Impact Count" stackId="a" fill="#90ee90">
            {data.map((entry:controlAreasImpactModel, index:number) => (
                <Cell key={`cell-${index}`} fill="#90ee90" />
            ))}
            <LabelList dataKey={getBarTotal} position="top" style={{ fontSize: 13, fontWeight: '200px', fill: '#777', fontFamily: "sans-serif" }}
            />
        </Bar>
    </BarChart>)


}

export default SectionVerticalBarChart