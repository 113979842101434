import React, { useState, useEffect } from 'react';
import { checkSecurityHubStatus, checkTrustedAdvisorStatus } from '../service/QuestionnaireApi';
import Loader from './Loader';
const Select = require('react-select').default;

interface Option {
    value: string;
    label: string;
}

interface WorkloadDetails {
    workLoadName: string;
    reviewOwnerName: string;
    workLoadDescription: string;
    environment: string;
    architecturalDesign: string;
    industryType: string;
    isAwsTrustedAdvisorActivated: boolean;
    isAwsSecurityHubActivated: boolean;
    lenses: string[];
}

interface PopupDataModel {
    hidePopup: boolean,
    type: string
}

interface ValidationMsgModel{
    workLoadName: string,
    reviewOwnerName: string,
    architecturalDesign: string
}

interface CreateWorkloadProps {
    setWorkLoadDetails: React.Dispatch<React.SetStateAction<WorkloadDetails>>;
    workLoadDetails: WorkloadDetails;
    handleSave: () => void;
    projectDetails: any;
    setSelectedPage: (page: number) => void;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}


const initialPopupData = {
    hidePopup: true,
    type: ""
}

const initialValidationMsg={
    workLoadName: "",
    reviewOwnerName: "",
    architecturalDesign: ""
}



const CreateWorkload: React.FC<CreateWorkloadProps> = (props) => {
    const { setWorkLoadDetails, workLoadDetails, handleSave, projectDetails, setSelectedPage, setIsLoading } = props;
    //Initializing the state varoables
    const [popupData, setPopupData] = useState<PopupDataModel>(initialPopupData)
    const [validationMsg, setValidationMsg]= useState<ValidationMsgModel>(initialValidationMsg)
    const [isDisable, setIsDisable] = useState<boolean>(true)
    const regexPattern = /^[a-zA-Z][a-zA-Z0-9-_\.]{0,15}$/;
    const webUrlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+([\/\w-]*)*\/?(\?\S*)?(,\s*(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+([\/\w-]*)*\/?(\?\S*)?)*$/;


    const lensesDropdown: Option[] = [
        { value: 'wellarchitected', label: 'Well-Architected' },
        { value: 'serverless', label: 'Serverless' },
        { value: 'saas', label: 'SaaS' },
    ];

    const industryTypeDropdown = [
        { value: 'Agriculture', label: 'Agriculture' },
        { value: 'Automotive', label: 'Automotive' },
        { value: 'Defense', label: 'Defense' },
        { value: 'Design_And_Engineering', label: 'Design And Engineering' },
        { value: 'Digital_Advertising', label: 'Digital Advertising' },
        { value: 'Education', label: 'Education' },
        { value: 'Environmental_Protection', label: 'Environmental Protection' },
        { value: 'Financial_Services', label: 'Financial Services' },
        { value: 'Gaming', label: 'Gaming' },
        { value: 'General_Public_Services', label: 'General Public Services' },
        { value: 'Healthcare', label: 'Healthcare' },
        { value: 'Hospitality', label: 'Hospitality' },
        { value: 'InfoTech', label: 'InfoTech' },
        { value: 'Justice_And_Public_Safety', label: 'Justice And Public Safety' },
        { value: 'Life_Sciences', label: 'Life Sciences' },
        { value: 'Manufacturing', label: 'Manufacturing' },
        { value: 'Media_Entertainment', label: 'Media Entertainment' },
        { value: 'Mining_Resources', label: 'Mining Resources' },
        { value: 'Oil_Gas', label: 'Oil & Gas' },
        { value: 'Power_Utilities', label: 'Power Utilities' },
        { value: 'Professional_Services', label: 'Professional Services' },
        { value: 'Real_Estate_Construction', label: 'Real Estate Construction' },
        { value: 'Retail_Wholesale', label: 'Retail & Wholesale' },
        { value: 'Social_Protection', label: 'Social Protection' },
        { value: 'Telecommunications', label: 'Telecommunications' },
        { value: 'Travel_Transportation_Logistics', label: 'Travel, Transportation & Logistics' },
        { value: 'Other', label: 'Other' }
    ];

    const [industryTypes] = useState<Option[]>(industryTypeDropdown);
    const [lenses] = useState<Option[]>(lensesDropdown);

    useEffect(() => {
        if (!workLoadDetails.lenses || workLoadDetails.lenses.length === 0) {
            setWorkLoadDetails((prevDetails) => ({
                ...prevDetails,
                lenses: ['wellarchitected']
            }));
        }
    }, []);

    useEffect(() => {
        setIsDisable(![workLoadDetails?.workLoadName?.trim()?.length !== 0, workLoadDetails?.reviewOwnerName?.trim()?.length !== 0, workLoadDetails?.environment?.length !== 0,
        workLoadDetails?.isAwsTrustedAdvisorActivated, workLoadDetails?.lenses?.length !== 0, validationMsg?.reviewOwnerName?.length ===0,validationMsg?.architecturalDesign?.length ===0, validationMsg?.workLoadName?.length===0].every(Boolean))
    }, [workLoadDetails])


    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setValidationMsg({...validationMsg, [name]:""})
        setWorkLoadDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value
        }));
        if((name ==="workLoadName" || name==="reviewOwnerName") && !regexPattern.test(value)){
            setValidationMsg({...validationMsg, [name]:"Enter a valid name"})
        }
        else if (name==="architecturalDesign" && !webUrlRegex.test(value.trim())){
            setValidationMsg({...validationMsg, [name]:"Enter a valid url"})
        }
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWorkLoadDetails((prevDetails) => ({
            ...prevDetails,
            environment: event.target.value
        }));
    };

    const handleCheckboxChange = async (id: string, event: any) => {
        const { name, checked } = event.target;
        setWorkLoadDetails((prevDetails) => ({
            ...prevDetails,
            [name]: checked
        }));
        if (checked) {
            const request = {
                organizationId: projectDetails?.organizationId,
                verticalId: projectDetails?.verticalId,
                projectId: projectDetails?.projectId,
                connectorId: projectDetails?.connectorId
            }


            let response:any
            try {
                setIsLoading(true)
                if (id === "Trusted Advisor") {
                    response = await checkTrustedAdvisorStatus(request)
                    setIsLoading(false)
                    setPopupData({ hidePopup: response.trustedAdvisorIsEnabled, type: "Trusted Advisor" })
                    setWorkLoadDetails((prevDetails) => ({
                        ...prevDetails,
                        [name]: response.trustedAdvisorIsEnabled
                    }));

                } else {
                    response = await checkSecurityHubStatus(request)
                    setIsLoading(false)
                    setPopupData({ hidePopup: response.securityHubIsEnabled, type: "Security Hub" })
                    setWorkLoadDetails((prevDetails) => ({
                        ...prevDetails,
                        [name]: response.securityHubIsEnabled
                    }));
                }

            }
            catch (error) {
                setIsLoading(false)
                // console.log("Api Failed")
            }
        }
    };

    const handleMultiSelectChange = (selectedOptions: Option[], actionMeta: any) => {
        setWorkLoadDetails((prevDetails) => ({
            ...prevDetails,
            [actionMeta.name]: selectedOptions.map((option) => option.value)
        }));
    };

    const handleSingleSelectChange = (selectedOption: Option | null, actionMeta: any) => {
        if (selectedOption) {
            setWorkLoadDetails((prevDetails) => ({
                ...prevDetails,
                [actionMeta.name]: selectedOption.value
            }));
        }
    };

    const FormComponent = () => {
        return (
            <div className="modal fade show" style={{ display: 'block', position: "absolute", top: "20px" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-3">
                        <div className="modal-header pb-0 border-0"><button type="button" className="btn-close cursor-pointer" aria-label="Close" onClick={() => {
                            setPopupData({ ...popupData, hidePopup: true })
                        }} /></div>
                        <div className="modal-body">
                            <div className="w-100 text-center mb-3"><img src="images/alert-circle.svg" alt="delete" /></div>
                            <p className="font-semibold font-24 text-center mb-2">{popupData?.type}</p>
                            <p className="font-semibold font-16 text-center mb-5 color-grey-v1">{`Activate ${popupData?.type}  by subscribing to the AWS
                                Business Support Plan to receive tailored recommendations.`}</p>
                            <div className="d-grid gap-2 d-md-inline-flex justify-content-md-center mb-2 mb-md-0 w-100"><button type="button" className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold " onClick={() => {
                                setPopupData({ ...popupData, hidePopup: true })
                            }}>Understood</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    return (
        <>
            <div className="col-lg-8 col-md-12 col-12">
                <div className="ps-lg-4">
                    <h3 className="font-18 font-bold mt-3 mb-5">Create Workload</h3>
                    <div className="row">
                        <div className="col-md-6 mb-5">
                            <label className="form-label font-14 font-semibold" htmlFor="workLoadName">
                                Name<span
                                className="color-red ms-1">*</span><img src="images/info.svg" data-bs-toggle="tooltip"
                                data-bs-placement="right" title="A unique identifier for the workload." className="ms-1" alt="Work Load Name"/>
                            </label>
                            <input
                                type="text"
                                id="workLoadName"
                                name="workLoadName"
                                className="form-control custom-form"
                                placeholder="Name"
                                value={workLoadDetails.workLoadName}
                                onChange={handleChange}
                            />
                            <span className='color-red'>{validationMsg.workLoadName}</span>
                        </div>
                        <div className="col-md-6 mb-5">
                            <label className="form-label font-14 font-semibold" htmlFor="reviewOwnerName">
                                Review Owner<span
                                className="color-red ms-1">*</span><img src="images/info.svg" data-bs-toggle="tooltip"
                                data-bs-placement="right" title="The name, email address, or identifier for the primary individual or group that owns the review process." className="ms-1" alt="Review Owner Name"/>
                            </label>
                            <input
                                type="text"
                                id="reviewOwnerName"
                                name="reviewOwnerName"
                                className="form-control custom-form"
                                placeholder="Review Owner"
                                value={workLoadDetails.reviewOwnerName}
                                onChange={handleChange}
                            />
                            <span className='color-red'>{validationMsg.reviewOwnerName}</span>
                        </div>
                        <div className="col-md-12 mb-5">
                            <label className="form-label font-14 font-semibold" htmlFor="workLoadDescription">
                                Description<span
                                className="color-red ms-1">*</span><img src="images/info.svg" data-bs-toggle="tooltip"
                                data-bs-placement="right" title="A brief description of the workload to document its scope and intended purpose." className="ms-1"alt="Description"/>
                            </label>
                            <textarea
                                id="workLoadDescription"
                                name="workLoadDescription"
                                className="border rounded-1 custom-form ps-4 pe-5 textarea-height"
                                value={workLoadDetails.workLoadDescription}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4">
                            <p className="font-16 font-medium">
                                Environment<span
                                className="color-red ms-1">*</span><img src="images/info.svg" data-bs-toggle="tooltip"
                                data-bs-placement="right" title="The environment in which your workload runs." className="ms-1" alt="Environment"/>
                            </p>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input theme-radio label-bold"
                                    type="radio"
                                    name="environment"
                                    id="PRODUCTION"
                                    value="PRODUCTION"
                                    checked={workLoadDetails.environment === "PRODUCTION"}
                                    onChange={handleRadioChange}
                                />
                                <label className="form-check-label mb-2 ms-2 font-14 font-regular" htmlFor="PRODUCTION">
                                    Production
                                </label>
                            </div>
                            <div className="form-check form-check-inline ms-3">
                                <input
                                    className="form-check-input theme-radio label-bold"
                                    type="radio"
                                    name="environment"
                                    id="PREPRODUCTION"
                                    value="PREPRODUCTION"
                                    checked={workLoadDetails.environment === "PREPRODUCTION"}
                                    onChange={handleRadioChange}
                                />
                                <label className="form-check-label mb-2 ms-2 font-14 font-regular" htmlFor="PREPRODUCTION">
                                    Pre-Production
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <label className="form-label font-14 font-semibold" htmlFor="architecturalDesign">
                                Architectural design<img src="images/info.svg" data-bs-toggle="tooltip"
                                data-bs-placement="right" title="A link to your architectural design." className="ms-1" alt="Architectural design"/>
                            </label>
                            <input
                                type="text"
                                id="architecturalDesign"
                                name="architecturalDesign"
                                className="form-control custom-form"
                                placeholder="Enter URL"
                                value={workLoadDetails.architecturalDesign}
                                onChange={handleChange}
                            />
                            <span className='color-red'>{validationMsg.architecturalDesign}</span>
                        </div>
                        <div className="col-md-6 mb-5">
                            <label className="form-label font-14 font-semibold" htmlFor="industryType">
                                Industry Type<img src="images/info.svg" data-bs-toggle="tooltip"
                                data-bs-placement="right" title="The industry that your workload is associated with AWS Trusted Advisor." className="ms-1" alt="Industry Type"/>
                            </label>
                            <Select
                                name="industryType"
                                options={industryTypes}
                                className="basic-single"
                                classNamePrefix="select"
                                value={industryTypes.find(option => option.value === workLoadDetails.industryType)}
                                onChange={handleSingleSelectChange}
                            />
                        </div>
                        <div className="col-md-6 mb-5">
                            <p className="form-label font-14 font-semibold">
                                AWS Trusted Advisor<span className="color-red ms-1">*</span>
                            </p>
                            <div className="check-card">
                                <span className="form-check align-items-center">
                                    <input
                                        className="theme-check form-check-input label-bold"
                                        type="checkbox"
                                        name="isAwsTrustedAdvisorActivated"
                                        id="isAwsTrustedAdvisorActivated"
                                        checked={workLoadDetails.isAwsTrustedAdvisorActivated}
                                        onClick={(event: any) => handleCheckboxChange("Trusted Advisor", event)}
                                    />
                                    <label className="form-check-label mb-2 ms-2 font-14 font-regular" htmlFor="isAwsTrustedAdvisorActivated">
                                        Activate Trusted Advisor
                                    </label>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <p className="form-label font-14 font-semibold">
                                AWS Security Hub
                            </p>
                            <div className="check-card">
                                <span className="form-check align-items-center">
                                    <input
                                        className="theme-check form-check-input label-bold"
                                        type="checkbox"
                                        name="isAwsSecurityHubActivated"
                                        id="isAwsSecurityHubActivated"
                                        checked={workLoadDetails.isAwsSecurityHubActivated}
                                        onChange={(event: any) => { handleCheckboxChange("Security Hub", event) }}
                                    />
                                    <label className="form-check-label mb-2 ms-2 font-14 font-regular" htmlFor="isAwsSecurityHubActivated">
                                        Activate Security Hub
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="font-18 font-bold mt-3 mb-5 border-bottom pb-3 ps-lg-4">
                Apply Lenses
            </h3>
            <div className="row">
                <div className="col-lg-8 col-md-12 col-12 ps-lg-4">
                    <div className="col-md-6 mb-5">
                        <label className="form-label font-14 font-semibold" htmlFor="lenses">
                            Lens Alias Name<span className="color-red">*</span>
                        </label>
                        <Select
                            isMulti
                            name="lenses"
                            options={lenses}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={lenses.filter(option => workLoadDetails.lenses?.includes(option.value))}
                            onChange={handleMultiSelectChange}
                            defaultValue={lenses.find(option => option.value === 'wellarchitected')}
                        />
                    </div>
                    <div className="d-md-flex justify-content-between mb-5 ">
                        <div className="d-grid gap-2 d-md-inline-flex justify-content-md-start mb-2 mb-md-0">
                            <button
                                type="button"
                                className="btn btn-secondary theme-secondary-btn font-14 font-semibold "
                                onClick={() => setSelectedPage(1)}
                            >
                                Previous
                            </button>
                        </div>
                        <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                            <button
                                type="button"
                                className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                                onClick={handleSave}
                                disabled={isDisable}
                            >
                                Save
                            </button>
                            <a className="theme-link-btn cursor-pointer font-14 font-semibold text-center order-md-first">
                                Clear
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {popupData?.hidePopup ? <></> :
                <FormComponent />
            }
            {popupData?.hidePopup ? <></> :
            <div className="modal-backdrop fade show"></div>}
        </>
    )
}

export default CreateWorkload;